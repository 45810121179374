import { ASSET_STATUSES_LABELS, INFORMATION_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { CrudBaseList } from 'components/base/CrudBaseList';
import AssetStatus from 'components/ui/AssetStatus';
import { AssetTags } from 'components/ui/AssetTags';
import { ALL_ASSETS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_ASSET } from 'redux/entities_config';
import { booleanToYesNo, userFullName } from 'utils';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import AssetForm from './AssetForm';


const dataTableColumns = [
    {
        field: 'status',
        header: 'Record Status',
        body: (rowData) => (INFORMATION_STATUSES_LABELS[rowData.status]),
        sortable: true,
    },
    {
        field: 'asset_status',
        header: 'Asset Status',
        body: (rowData) => (<AssetStatus asset={rowData} />),
        sortable: true,
        align: 'center',
    },
    {
        field: 'asset_type.asset_type_name',
        header: 'Type',
        sortable: true,
    },
    {
        field: 'workstation.primary_use.workflow_name',
        header: 'Workstation Primary Use',
        sortable: true,
    },
    {
        field: 'the_make.choice',
        header: 'Make',
        sortable: true,
    },
    {
        field: 'the_model.choice',
        header: 'Model',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        header: 'Tags',
        body: (rowData) => (<AssetTags asset={rowData} />),
    },
    {
        field: 'asset_name',
        header: 'Asset Name',
        sortable: true,
    },
    {
        field: 'printer.ip_address',
        header: 'Printer IP',
        sortable: true,
    },
    {
        field: 'printer.printer_queue_name',
        header: 'Printer Queue Name',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'workstation.bca_pc',
        header: 'BCA PC',
        body: (rowData) => booleanToYesNo(rowData.workstation?.bca_pc),
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        sortable: true,
        body: (rowData) => (
            userFullName(rowData.last_modified_by)
        ),
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => (
            rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'School Code',
    'Department',
    'Suite #',
    'Location',
    'Room',
    'Primary Use',
    'Main Asset Id',
    'Asset Id',
    'Asset Type',
    'Workstation Type',
    'Asset Name',
    'Serial #',
    'CAM Tag',
    'MSIT Tag',
    'Zeno #',
    'Make',
    'Model',
    'OS',
    'Size',
    'Resolution',
    'Screen Orientation',
    'Workstation IP',
    'Rover',
    'Wall Mount',
    'BCA PC',
    'Welcome Kiosk',
    'Login Strategy',
    'Printer Type',
    'Printer Queue Name',
    'Printer IP',
    'Printer Hostname',
    'Printer Tray 1 Content',
    'Printer Tray 2 Content',
    'Printer Tray 3 Content',
    'Printer Tray 4 Content',
    'Printer Tray 5 Content',
    'Printer Tray 6 Content',
    'Printer Manual Tray Content',
    'Printer Comments',
    'New Notes',
    'Update Notes',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Asset Id': rowData.id,
        'Asset Status': ASSET_STATUSES_LABELS[rowData.asset_status],
        'Notes': rowData.notes,
        'New Notes': null,
        'Type': rowData.asset_type ? rowData.asset_type.asset_type_name : '',
        'Workstation Type': rowData.workstation ? rowData.workstation.workstation_type.workstation_type_name : '',
        'Workstation Primary Use': rowData.workstation?.primary_use?.workflow_name ?? '',
        'Make': rowData.the_make ? rowData.the_make.choice : '',
        'Model': rowData.the_model ? rowData.the_model.choice : '',
        'Serial #': rowData.serial_num,
        'CAM tag': rowData.cam_tag_num,
        'MSIT tag': rowData.msit_tag_num,
        'Zeno number': rowData.printer?.zeno_num,
        'Asset Name': rowData.asset_name,
        'Printer Type': rowData.printer?.printer_type.printer_type_name,
        'Printer IP': rowData.printer?.ip_address,
        'Printer Queue Name': rowData.printer?.printer_queue_name,
        'Dept': rowData.department ? rowData.department.department_name : '',
        'Owner': rowData.owner,
        // 'Epic Dept': rowData.epic_department ? rowData.epic_department.epic_department_name : '',
        'Building Code': rowData.building ? rowData.building.code : '',
        'Suite #': rowData.suite_num,
        'Room #': rowData.room_num,
        'Asset Number': rowData.w_p_num,
        'BCA PC': booleanToYesNo(rowData.workstation?.bca_pc),
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 18 }, // Asset Id
    { wch: 20 }, // Asset Status
    { wch: 80 }, // Notes
    { wch: 80 }, // New Notes
    { wch: 40 }, // Type
    { wch: 40 }, // Workstation Type
    { wch: 80 }, // Workstation Primary Use
    { wch: 50 }, // Make
    { wch: 50 }, // Model
    { wch: 50 }, // Serial #
    { wch: 40 }, // CAM tag
    { wch: 12 }, // MSIT tag
    { wch: 12 }, // Zeno number
    { wch: 80 }, // Asset Name
    { wch: 20 }, // Printer Type
    { wch: 10 }, // Printer IP
    { wch: 12 }, // Printer Queue Name
    { wch: 40 }, // Dept
    { wch: 20 }, // Owner
    // { wch: 40 }, // Epic Dept
    { wch: 10 }, // Building Code
    { wch: 10 }, // Suite #
    { wch: 10 }, // Room #
    { wch: 10 }, // Asset Number
];

const AllAssetsList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_ASSET}
            CrudBaseForm={AssetForm}
            bulkUpdateRoute={ALL_ASSETS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="All Assets View"
        />
    );
};

export default AllAssetsList;
