import axios from "axios";

import { LOGIN_FORM } from "navigation/ROUTE_CONSTANTS";
import { logoutSuccess, refreshTokenSuccess } from "redux/actions/auth.actions";
import { serverValidationSet } from "redux/actions/server_validations.actions";
import { setNavigateTo, setToast } from "redux/actions/ui.actions";
import { resetUserState } from "redux/actions/users.actions";
import { TOAST_SEVERITY_ERROR } from "redux/reducers/ui.reducer";
import { TOKEN_REFRESH } from "services/API_CONSTANTS";

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

export async function invalidTokenInterceptor(error, store, updateAxiosRequestInterceptor) {
    // Property appData must be set only on first declared interceptor !
    error.appData = {
        redirectToLogin: false,
        setToast: null,
        validationFields: null,
    };

    if (error.response && error.response.status === 401 && error.response.data.code === 'token_not_valid') {
        try {
            const { response: errorResponse } = error;
            const refreshToken = error.config.userData.refresh;

            if (!refreshToken) {
                error.appData.redirectToLogin = true;
                error.appData.setToast = {
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Session expired',
                    detail: 'Your session has expired. Please login again.',
                }
                return Promise.reject(error);
            }

            const retryOriginalRequest = new Promise(resolve => {
                addSubscriber(access_token => {
                    resolve(axios(errorResponse.config));
                });
            });

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;

                const axiosInstance = axios.create();
                axiosInstance.defaults.baseURL = error.config.baseURL;

                let responseData = null;
                let responseStatus = null;
                try {
                    const response = await axiosInstance({
                        method: 'post',
                        url: TOKEN_REFRESH,
                        data: { refresh: refreshToken }
                    });
                    responseData = response.data;
                    responseStatus = response.status;
                } catch (e) {
                    responseStatus = e.response?.status;
                }

                if (responseStatus === 401 && window.location.pathname !== LOGIN_FORM) {
                    error.appData.redirectToLogin = true;
                    error.appData.setToast = {
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Session expired',
                        detail: 'Your session has expired. Please login again.',
                    }
                    return Promise.reject(error);
                }

                if (!responseData) {
                    error.appData.redirectToLogin = true;
                    error.appData.setToast = {
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Session expired',
                        detail: 'Your session has expired. Please login again or contact support.',
                    }
                    return Promise.reject(error);
                }

                isAlreadyFetchingAccessToken = false;
                const newAccessToken = responseData.access;
                if (newAccessToken) {
                    store.dispatch(refreshTokenSuccess(newAccessToken));
                    updateAxiosRequestInterceptor(newAccessToken, refreshToken);
                    onAccessTokenFetched(newAccessToken);
                } else {
                    error.appData.redirectToLogin = true;
                    error.appData.setToast = {
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Session expired',
                        detail: 'Your session has expired. Please login again or contact support.',
                    }
                    return Promise.reject(error);
                }
            }

            return retryOriginalRequest;

        } catch (err) {
            error.appData.setToast = {
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Authentication error',
                detail: JSON.stringify(err),
            }
            return Promise.reject(error);
        }
    }

    return Promise.reject(error);
}

export function networkErrorInterceptor(error) {
    if (!!error.isAxiosError && !error.response) { // Network Error
        error.appData.setToast = {
            severity: TOAST_SEVERITY_ERROR,
            summary: 'Connection error',
            detail: 'Please check your internet connection and try again.',
            life: 8000,
        }
    }

    return Promise.reject(error);
}

export function otherErrorsInterceptor(error) {
    // Catch any error not handled by previous declared interceptors
    if (!error.appData.setToast && error.response) {
        if (error.response.status >= 500) {

            error.appData.setToast = {
                severity: TOAST_SEVERITY_ERROR,
                summary: error.response.statusText,
                detail: 'Please contact support.',
                life: 5000
            }

        } else if (error.response.status === 400 && typeof error.response.data === 'object' && !error.response.data.detail && ['post', 'patch', 'put'].includes(error.config.method)) {

            error.appData.validationFields = error.response.data;

        } else {
            let errorMessage = '';

            if (typeof error.response.data === 'object') {
                for (const [key, value] of Object.entries(error.response.data)) {
                    // Remove key from error message when it is not related to a field
                    if (!['non_field_errors', 'detail'].includes(key)) {
                        errorMessage += key + ': ';
                    }

                    if (Array.isArray(value)) {
                        errorMessage += value.join('\n');
                    } else {
                        errorMessage += value + '\n';
                    }
                }
            } else {
                errorMessage = error.message + '. Url: ' + error.config.url;
            }

            let summary = 'Error From Server';
            if (error.response.status === 401) {
                summary = 'Authentication';
            } else if (error.response.status === 403) {
                summary = 'Authorization';
            } else if (error.response.status === 404) {
                summary = 'Not Found';
            }

            error.appData.setToast = {
                severity: TOAST_SEVERITY_ERROR,
                summary,
                detail: errorMessage,
                life: 5000
            }
        }
    }

    return Promise.reject(error);
}

export function handleAppDataInterceptor(error, store) {
    if (error.appData.validationFields) {
        store.dispatch(serverValidationSet(error.appData.validationFields));
    }

    if (error.appData.setToast) {
        store.dispatch(setToast(error.appData.setToast));
    }

    if (error.appData.redirectToLogin) {
        store.dispatch(logoutSuccess());
        store.dispatch(resetUserState());
        store.dispatch(setNavigateTo(LOGIN_FORM));
    }

    return Promise.reject(error);
}
