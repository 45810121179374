import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CrudBaseList } from 'components/base/CrudBaseList';
import { Badge } from 'reactstrap';
import { getWorkflowReqCategory } from 'redux/actions/workflow_req_categories.actions';
import { ENTITY_GAP_REPORT } from 'redux/entities_config';

const fixedDataTableColumns = [
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'suite_num',
        header: 'Suite #',
        sortable: true,
    },
];

const dataTableExpansionDefinition = [
    {
        header: 'Specialties',
        body: (rowData) => (
            rowData.specialties ?
                (rowData.specialties.map((specialty, key) => <Badge color='secondary' key={key} className="m-1">{specialty.specialty_name}</Badge>)) :
                ''
        ),
    },
    {
        field: 'building.street',
        header: 'Street',
    },
    {
        field: 'building.city',
        header: 'City',
    },
];

const GapReportList = () => {
    const dispatch = useDispatch();
    const [dataTableColumns, setDataTableColumns] = useState([]);
    const [exportExcelColumnInfo, setExportExcelColumnInfo] = useState([]);
    const workflowReqCategoriesMap = useSelector(state => state.workflowReqCategories.workflowReqCategoriesMap);

    const exportExcelFieldMap = useCallback((rowData) => {
        let schema = {
            'School': rowData.ou.ou_name,
            'Department': rowData.department_name,
            'Street': rowData.building.street,
            'Suite #': rowData.suite_num,
            'Specialties': rowData.specialties ?
                (rowData.specialties.map((specialty) => specialty.specialty_name).join(', ')) :
                '',
        };

        for (let key in workflowReqCategoriesMap) {
            let keyInt = parseInt(key);
            let workflowReqCategory = rowData.workflow_req_categories.find(item => item.workflow_req_category_id === keyInt);
            let assetsHave = null;
            // let assetsRequired = null;
            let assetsNeed = null;
            if (workflowReqCategory) {
                assetsHave = workflowReqCategory.assets_have;
                // assetsRequired = workflowReqCategory.assets_required;
                assetsNeed = workflowReqCategory.assets_need;
            }

            if (assetsHave > 0) {
                schema[workflowReqCategoriesMap[key] + ' - Have'] = assetsHave;
            } else {
                schema[workflowReqCategoriesMap[key] + ' - Have'] = '';
            }
            if (assetsNeed !== null) {
                schema[workflowReqCategoriesMap[key] + ' - Need'] = assetsNeed;
            } else {
                schema[workflowReqCategoriesMap[key] + ' - Need'] = '';
            }
        }

        return schema;
    }, [workflowReqCategoriesMap]);

    useEffect(() => {
        if (Object.keys(workflowReqCategoriesMap).length > 0) {
            let reqCategoryTableColumns = [];
            let localExportExcelColumnInfo = [];
            for (let key in workflowReqCategoriesMap) {
                let keyInt = parseInt(key);
                reqCategoryTableColumns.push({
                    header: workflowReqCategoriesMap[key],
                    sortable: false,
                    align: 'center',
                    body: (rowData) => {
                        let workflowReqCategory = rowData.workflow_req_categories.find(item => item.workflow_req_category_id === keyInt);
                        let assetsHave = null;
                        // let assetsRequired = null;
                        let assetsNeed = null;
                        if (workflowReqCategory) {
                            assetsHave = workflowReqCategory.assets_have;
                            // assetsRequired = workflowReqCategory.assets_required;
                            assetsNeed = workflowReqCategory.assets_need;
                        }
                        return (
                            <div className="vstack">
                                {assetsHave > 0 &&
                                    <Badge color='success' className="m-1">Have: <b className='fs-7'>{assetsHave}</b></Badge>
                                }
                                {
                                    /*assetsRequired > 0 &&
                                        <Badge color='primary' className="m-1">Required: <b className='fs-7'>{assetsRequired}</b></Badge>
                                    */
                                }
                                {assetsNeed !== null &&
                                    <Badge color='warning' className="m-1">Need: <b className='fs-7'>{assetsNeed}</b></Badge>
                                }
                            </div>
                        );
                    },
                });
                // For Have column
                localExportExcelColumnInfo.push({ wch: 18 });
                // For Required column
                localExportExcelColumnInfo.push({ wch: 18 });
            }

            setDataTableColumns([
                ...fixedDataTableColumns,
                ...reqCategoryTableColumns,
            ]);

            setExportExcelColumnInfo([
                { wch: 10 }, // School
                { wch: 30 }, // Department
                { wch: 30 }, // Street
                { wch: 10 }, // Suite #
                { wch: 30 }, // Specialties
                ...localExportExcelColumnInfo,
            ]);
        } else {
            dispatch(getWorkflowReqCategory());
        }
    }, [dispatch, workflowReqCategoriesMap]);

    return (
        <CrudBaseList
            entityCode={ENTITY_GAP_REPORT}
            // CrudBaseForm={WorkflowReqForm}
            // bulkUpdateRoute={WORKFLOW_DEFAULT_REQS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            dataTableExpansionDefinition={dataTableExpansionDefinition}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle={'Gap Report'}
            hasCreateButton={false}
        />
    );
};

export default GapReportList;
