import { CRUD_BASE_DELETE_SUCCESS, CRUD_BASE_GET_LIST_FAILED, CRUD_BASE_GET_LIST_START, CRUD_BASE_GET_LIST_SUCCESS, CRUD_BASE_GET_ONE_RESET, CRUD_BASE_GET_ONE_START, CRUD_BASE_GET_ONE_SUCCESS, CRUD_BASE_RESET_STATE } from "redux/actions/crud_base.actions";

const initialCrudBaseState = {
    list: [],
    next: null,
    previous: null,
    count: 0,
    activeRecord: null,
    loading: false,
    refreshList: false,
};

export const CrudBaseReducer = (state = initialCrudBaseState, action) => {
    switch (action.type) {
        case CRUD_BASE_GET_LIST_START:
            return {
                ...state,
                loading: true
            };
        case CRUD_BASE_GET_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                loading: false,
                refreshList: false,
            };
        case CRUD_BASE_GET_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case CRUD_BASE_GET_ONE_START:
        case CRUD_BASE_GET_ONE_RESET:
            return {
                ...state,
                activeRecord: null
            };
        case CRUD_BASE_GET_ONE_SUCCESS:
            return {
                ...state,
                activeRecord: action.payload
            };
        case CRUD_BASE_DELETE_SUCCESS:
            return {
                ...state,
                refreshList: true,
            };
        case CRUD_BASE_RESET_STATE:
            return { ...initialCrudBaseState };
        default:
            return state;
    }
}