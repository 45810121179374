import { Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import AppFormik from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { USER_PROFILE } from 'navigation/ROUTE_CONSTANTS';
import { setNavigateTo, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { userChangePassword } from 'services/authService';


export const ChangePassword = () => {
    const dispatch = useDispatch();

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">Change Password</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <AppFormik
                                enableReinitialize={true}
                                initialValues={{
                                    old_password: '',
                                    new_password: '',
                                    confirm_password: '',
                                }}
                                validationSchema={Yup.object({
                                    old_password: Yup.string()
                                        .required('Please your current password'),
                                    new_password: Yup.string()
                                        .required('Please enter a new password'),
                                    confirm_password: Yup.string()
                                        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                                        .required('Please confirm your new password'),
                                })}
                                serverFieldMap={{
                                    old_password: 'old_password',
                                    new_password: 'new_password',
                                }}
                                onSubmit={(values) => {
                                    userChangePassword({
                                        old_password: values.old_password,
                                        new_password: values.new_password
                                    })
                                        .then((response) => {
                                            if (response.code === 200) {
                                                dispatch(setToast({
                                                    severity: TOAST_SEVERITY_SUCCESS,
                                                    summary: 'Password Changed',
                                                    detail: 'Your password has been changed successfully'
                                                }));
                                                dispatch(setNavigateTo(USER_PROFILE));
                                            }
                                        })
                                        .catch((err) => {
                                        });
                                }}
                            >
                                {() => (
                                    <Form className="justify-content-center">

                                        <FormGroup className="row justify-content-center">
                                            <AppInputFieldComponent
                                                fieldType="password"
                                                fieldName="old_password"
                                                inputPlaceHolder="Old Password"
                                                inputProps={{ autoComplete: 'current-password' }}
                                            />
                                        </FormGroup>

                                        <FormGroup className="row justify-content-center">
                                            <AppInputFieldComponent
                                                fieldType="password"
                                                fieldName="new_password"
                                                inputPlaceHolder="New Password"
                                                inputProps={{ autoComplete: 'new-password' }}
                                            />
                                        </FormGroup>

                                        <FormGroup className="row justify-content-center">
                                            <AppInputFieldComponent
                                                fieldType="password"
                                                fieldName="confirm_password"
                                                inputPlaceHolder="Confirm Password"
                                                inputProps={{ autoComplete: 'new-password' }}
                                            />
                                        </FormGroup>

                                        <div className="text-center mt-5">
                                            <Link to={USER_PROFILE} className="btn btn-secondary w-100 mw-333 me-4">Cancel</Link>
                                            <Button type='submit' color='primary' className='w-100 mw-333'>Save</Button>
                                        </div>
                                    </Form>
                                )}
                            </AppFormik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container >
    )
};

export default ChangePassword;
