import { Form } from 'formik';
import { FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { AppFormik } from 'components/ui/AppFormik';
import AppInputFieldComponent from 'components/ui/AppInputFieldComponent';

export const CreateUserDetailsComponent = ({ onSubmit }) => {

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={{
                first_name: '',
                last_name: '',
            }}
            validationSchema={Yup.object({
                first_name: Yup.string()
                    .min(2, 'First name must be at least 2 characters length')
                    .max(150, 'First name must be smaller than 150 characters length.')
                    .required('Enter first name'),
                last_name: Yup.string()
                    .min(2, 'Last name must be at least 2 characters length')
                    .max(150, 'Last name must be smaller than 150 characters length.')
                    .required('Enter last name'),
            })}
            initialErrors={{
                first_name: 'Enter first name',
                last_name: 'Enter last name',
            }}
            serverFieldMap={{
                first_name: 'first_name',
                last_name: 'last_name',
            }}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ isValid }) => (
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9">
                        <Form>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldName="first_name"
                                    inputPlaceHolder="First name"
                                />
                            </FormGroup>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldName="last_name"
                                    inputPlaceHolder="Last name"
                                />
                            </FormGroup>
                            <Row className="justify-content-center">
                                <button className="w-100 btn btn-infinity-primary mw-333" disabled={!isValid} type="submit">
                                    Next
                                </button>
                            </Row>
                        </Form>
                    </div>
                </div >
            )}
        </AppFormik>
    )
}

export default CreateUserDetailsComponent;
