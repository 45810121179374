import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { triggerUiAction } from "redux/actions/ui.actions";

const TriggerActionButton = ({ actionCode, actionData, disabled = false }) => {
    const dispatch = useDispatch();

    return (
        <Button color='secondary' size='sm' className='p-button-text' outline disabled={disabled} onClick={
            () => dispatch(triggerUiAction({ actionCode, actionData }))
        } >
            <i className='ri-pencil-fill'></i>
        </Button>
    );
}

export default TriggerActionButton;
