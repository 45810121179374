import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { ENTITY_CITY } from 'redux/entities_config';
import { CityForm } from './CityForm';
import { CITIES_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';


const dataTableColumns = [
    {
        field: 'city_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'state.state_name',
        header: 'State',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Name',
    'State',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.city_name,
        'State': rowData.state.state_name,
        'Status': rowData.active ? 'Active' : 'Inactive',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 100 }, // Name
    { wch: 40 }, // State
    { wch: 10 }, // Active
];

const CitiesList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_CITY}
            CrudBaseForm={CityForm}
            bulkUpdateRoute={CITIES_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default CitiesList;
