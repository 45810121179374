import { Form, Formik } from 'formik';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Nav, NavLink, Button as RSButton, Row, TabContent, TabPane } from 'reactstrap';
import * as XLSX from 'xlsx';
import * as Yup from 'yup';

import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import BulkUpdateErrors from 'components/ui/BulkUpdateErrors';
import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { INITIAL_ROWS_PER_PAGE } from 'redux/entities_config';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { duplicateEpicWorkstation } from 'services/bulkUpdateService';
import { createEpicTicket } from 'services/workstationService';
import { convertWSNameToIdentifier, saveAsExcelFile } from 'utils';
import DuplicationFromServiceNow from './DuplicationFromServiceNow';

import 'styles/datatables.scss';
import 'styles/list_components.scss';

const initialRowsPerPage = INITIAL_ROWS_PER_PAGE;

const IMPORT_BLOCK_SIZE = 50;

const DUPLICATION_STATUS_IN_QUEUE = 'In Queue';
const DUPLICATION_STATUS_PROCESSING = 'Processing';
const DUPLICATION_STATUS_COMPLETED = 'Completed';
const DUPLICATION_STATUS_FAILED = 'Failed';

const statusBadgeColor = {
    [DUPLICATION_STATUS_IN_QUEUE]: 'secondary',
    [DUPLICATION_STATUS_PROCESSING]: 'info',
    [DUPLICATION_STATUS_COMPLETED]: 'success',
    [DUPLICATION_STATUS_FAILED]: 'danger',
};

const excelRowToApiData = (rowData) => {
    return {
        'id': null,
        'old_workstation': rowData['old_workstation_name'] || null,
        'new_workstation': rowData['new_workstation_name'] || null,
    }
};

export const apiToExcelColumn = {
    'old_workstation': 'Old Name',
    'new_workstation': 'New Name',
};

export const WorkstationsDuplicate = () => {
    const dispatch = useDispatch();

    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

    const [tableRowsData, setTableRowsData] = useState([]);
    const [duplicateActiveTab, setDuplicateActiveTab] = useState('1');
    const [queueItemIdentifier, setQueueItemIdentifier] = useState(2);

    const [clearListDialogState, setClearListDialogState] = useState(false);
    const [startDuplicationDialogState, setStartDuplicationDialogState] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    const [isProcessingQueue, setIsProcessingQueue] = useState(false);
    const [currentProcessingIndex, setCurrentProcessingIndex] = useState(null);
    const [isAllRowsProcessed, setIsAllRowsProcessed] = useState(false);
    const [rowsStatusFailedExist, setRowsStatusFailedExist] = useState(false);
    const [xlsProcessedRows, setXlsProcessedRows] = useState(null);
    const [countPerStatus, setCountPerStatus] = useState({});
    const [batchIdsCreated, setBatchIdsCreated] = useState([]);

    const [createTicketOnPoc, setCreateTicketOnPoc] = useState(true);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 400);

    useEffect(() => {
        setFilters({
            ...filters,
            global: {
                ...filters.global,
                value: globalFilterDebouncedValue
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilterDebouncedValue]);

    const getRowId = (oldWsName, NewWsName) => (
        oldWsName + '__' + NewWsName
    )

    const onFileUpload = ({ files, options }) => {
        const file = files[0];
        const reader = new FileReader();

        let fileRowsData = [];
        dispatch(enableOverlay('Loading xls file ...'));

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsName = wb.SheetNames[0];
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            let firstRow = true;
            let localQueueItemIdentifier = queueItemIdentifier;
            data.forEach((row) => {
                if (firstRow) {
                    firstRow = false;
                    return;
                }
                if (row[0] || row[1]) {
                    fileRowsData.push({
                        id: getRowId(row[0], row[1]),
                        old_workstation_name: row[0],
                        new_workstation_name: row[1],
                        new_workstation_identifier: convertWSNameToIdentifier(row[1]),
                        duplication_status: DUPLICATION_STATUS_IN_QUEUE,
                        duplication_errors: null,
                        queue_item_identifier: localQueueItemIdentifier
                    });
                    localQueueItemIdentifier++;
                }
            });

            setQueueItemIdentifier(localQueueItemIdentifier);
            setTableRowsData((prevState) => [...prevState, ...fileRowsData]);
            dispatch(disableOverlay());
            options.clear();
        };

        reader.readAsBinaryString(file);
        setFileUploaded(true);

        return true;
    };

    useEffect(() => {
        if (isProcessingQueue && currentProcessingIndex != null) {
            let localCountPerStatus = {
                [DUPLICATION_STATUS_FAILED]: 0,
                [DUPLICATION_STATUS_COMPLETED]: 0,
                ...countPerStatus,
            };
            let tableRowsDataCopy = [...tableRowsData];
            let localBatchIdsCreated = new Set(batchIdsCreated);

            let apiData = [];
            const importRowsDataCount = tableRowsData.length;
            let lastIndex = importRowsDataCount;
            for (let i = currentProcessingIndex; i < importRowsDataCount; i++) {
                if (apiData.length >= IMPORT_BLOCK_SIZE) {
                    break;
                }
                lastIndex = i;
                if (![DUPLICATION_STATUS_IN_QUEUE, DUPLICATION_STATUS_FAILED].includes(tableRowsDataCopy[i].duplication_status)) {
                    continue;
                }

                let rowApiData = excelRowToApiData(tableRowsData[i]);

                rowApiData.queue_item_identifier = tableRowsDataCopy[i].queue_item_identifier;
                apiData.push(rowApiData);

                tableRowsDataCopy[i].duplication_status = DUPLICATION_STATUS_PROCESSING;
                tableRowsDataCopy[i].duplication_errors = null;
            }

            setTableRowsData(tableRowsDataCopy);

            duplicateEpicWorkstation(apiData)
                .then((res) => {
                    let tableRowsDataCopy = [...tableRowsData];

                    for (let i = 0; i < res.length; i++) {
                        let row = res[i];
                        let rowIndex = tableRowsDataCopy.findIndex((item) => item.queue_item_identifier === row.queue_item_identifier);

                        if (rowIndex === -1) {
                            continue;
                        }

                        if (row.queue_success === 'ok') {
                            tableRowsDataCopy[rowIndex].duplication_status = DUPLICATION_STATUS_COMPLETED;
                            localCountPerStatus[DUPLICATION_STATUS_COMPLETED]++;
                            localBatchIdsCreated.add(row.queue_batch_id);
                        } else {
                            tableRowsDataCopy[rowIndex].duplication_status = DUPLICATION_STATUS_FAILED;
                            delete row.queue_success;
                            delete row.queue_item_identifier;
                            delete row.queue_batch_id;
                            tableRowsDataCopy[rowIndex].duplication_errors = { ...row };
                            setRowsStatusFailedExist(true);
                            localCountPerStatus[DUPLICATION_STATUS_FAILED]++;
                        }
                    }

                    setTableRowsData(tableRowsDataCopy);
                    setBatchIdsCreated([...localBatchIdsCreated]);
                    if (lastIndex >= (tableRowsData.length - 1)) {
                        dispatch(disableOverlay());
                        setIsProcessingQueue(false);
                        setCurrentProcessingIndex(null);
                        setIsAllRowsProcessed(true);
                        setXlsProcessedRows(tableRowsDataCopy.length);
                    } else {
                        setCurrentProcessingIndex(lastIndex + 1);
                        setXlsProcessedRows(lastIndex + 1);
                    }
                    setCountPerStatus(localCountPerStatus);
                })
                .catch((err) => {
                    dispatch(disableOverlay());
                    console.error(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProcessingIndex, isProcessingQueue]);

    useEffect(() => {
        if (createTicketOnPoc && isAllRowsProcessed && batchIdsCreated.length > 0) {
            dispatch(enableOverlay('Creating tickets in POC ...'));
            // Take 1st batch id to create ticket
            let localBatchIdsCreated = [...batchIdsCreated];
            let batchId = localBatchIdsCreated.shift();
            createEpicTicket(batchId)
                .then((res) => {
                    if (res.success) {
                        dispatch(setToast({
                            severity: TOAST_SEVERITY_SUCCESS,
                            summary: 'Ticket created in POC',
                            detail: res.message
                        }));
                    } else {
                        dispatch(setToast({
                            severity: TOAST_SEVERITY_ERROR,
                            summary: 'Error when creating ticket in POC',
                            detail: res.message
                        }));
                    }
                })
                .catch((err) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Error when creating ticket in POC',
                        detail: Object.values(err.response.data).map((value, index) => (
                            value
                        )).join('. '),
                    }));
                })
                .finally(() => {
                    setBatchIdsCreated(localBatchIdsCreated);
                    dispatch(disableOverlay());
                });
        }
    }, [isAllRowsProcessed, createTicketOnPoc, batchIdsCreated, dispatch]);

    const addToDuplicateServiceNow = (duplicationRows) => {
        let localQueueItemIdentifier = queueItemIdentifier + 1;
        let newRows = [];
        duplicationRows.forEach((row) => {
            let old_workstation_name = row.old_ws_name;
            let new_workstation_name = row.new_ws_name;

            let newRow = {
                old_workstation_name,
                new_workstation_name,
                id: getRowId(old_workstation_name, new_workstation_name),
                new_workstation_identifier: convertWSNameToIdentifier(new_workstation_name),
                duplication_status: DUPLICATION_STATUS_IN_QUEUE,
                duplication_errors: null,
                queue_item_identifier: localQueueItemIdentifier,
            }
            newRows.push(newRow);
            localQueueItemIdentifier++;
        });

        setQueueItemIdentifier(localQueueItemIdentifier);
        setTableRowsData((prevState) => [...prevState, ...newRows]);
    }

    const handleClearListButton = () => {
        setClearListDialogState(true);
    }

    const clearListDialogHide = () => {
        setClearListDialogState(false);
    }

    const clearListPerform = () => {
        setTableRowsData([]);
        setFileUploaded(false);
        setIsAllRowsProcessed(false);
        setIsProcessingQueue(false);
        setCurrentProcessingIndex(null);
        setXlsProcessedRows(null);
        setRowsStatusFailedExist(false);
        setCountPerStatus({});
        setClearListDialogState(false);
        setQueueItemIdentifier(2);
        setBatchIdsCreated([]);
    }

    const handleStartDuplicationButton = () => {
        setStartDuplicationDialogState(true);
    }

    const startDuplicationDialogHide = () => {
        setStartDuplicationDialogState(false);
    }

    const startDuplicationPerform = () => {
        setStartDuplicationDialogState(false);
        setIsProcessingQueue(true);
        setRowsStatusFailedExist(false);
        setCurrentProcessingIndex(0);
        dispatch(enableOverlay(`Duplicating ${tableRowsData.length} workstations. This process may take several minutes to complete.`));
    }

    const exportExcel = (rowStatus) => {
        import('xlsx').then((xlsx) => {
            dispatch(enableOverlay(`Exporting ${rowStatus} to xlsx (${countPerStatus[rowStatus]} rows)... `));

            let dataRowsForExport = tableRowsData
                .filter((row) => row.duplication_status === rowStatus)
                .map((row) => {
                    return {
                        'Old Name': row.old_workstation_name,
                        'New Name': row.new_workstation_name,
                        'New Identifier': row.new_workstation_identifier,
                        'Status': row.duplication_status,
                        'Errors': row.duplication_errors ? JSON.stringify(row.duplication_errors) : null,
                    };
                });

            const worksheet = xlsx.utils.json_to_sheet(dataRowsForExport, { dense: true });

            worksheet['!cols'] = [
                { wch: 20 }, // Old Name
                { wch: 20 }, // New Name
                { wch: 20 }, // New Identifier
                { wch: 20 }, // Status
                { wch: 100 }, // Errors
            ];

            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            let exportFilename = 'Workstation_Duplication_' + rowStatus + '_' + (new Date()).toISOString().slice(0, 10);
            saveAsExcelFile(exportFilename, excelBuffer);
            dispatch(disableOverlay());
        });
    };

    const paginatorLeft = () => {
        return (
            <Fragment>
                <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                <span className="p-input-icon-left">
                    <i className="ri-search-line" style={{ marginTop: -10 }} />
                    <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                </span>
                <Button disabled={!countPerStatus[DUPLICATION_STATUS_COMPLETED]} label="Export Completed" icon="ri-download-2-line" className="record-action-button ms-2" onClick={() => exportExcel(DUPLICATION_STATUS_COMPLETED)} />
                <Button disabled={!countPerStatus[DUPLICATION_STATUS_FAILED]} label="Export Failed" icon="ri-download-2-line" className="record-action-button ms-2" onClick={() => exportExcel(DUPLICATION_STATUS_FAILED)} />
            </Fragment>
        );
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: initialRowsPerPage, value: initialRowsPerPage },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <span className="me-2">Rows: {options.totalRecords} |</span>
                    <span className="page-number-button">Page: {options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">Duplicate EPIC Workstations</h4>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row className="datatable-crud-base">
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col className='pe-3'>
                                        {!xlsProcessedRows &&
                                            <Row>
                                                <Col md={3}>
                                                    <Nav pills className="flex-column" role="tablist" aria-orientation="vertical">
                                                        <NavLink id="v-pills-home-tab" style={{ cursor: "pointer" }}
                                                            aria-controls="v-pills-from-file" aria-selected="true"
                                                            className={[
                                                                'mb-2',
                                                                duplicateActiveTab === '1' && 'active'
                                                            ].filter(Boolean).join(' ')}
                                                            onClick={() => setDuplicateActiveTab('1')} >
                                                            Add from File
                                                        </NavLink>

                                                        <NavLink id="v-pills-profile-tab" style={{ cursor: "pointer" }}
                                                            aria-controls="v-pills-from-form" aria-selected="true"
                                                            className={[
                                                                'mb-2',
                                                                duplicateActiveTab === '2' && 'active'
                                                            ].filter(Boolean).join(' ')}
                                                            onClick={() => setDuplicateActiveTab('2')} >
                                                            Add using Form
                                                        </NavLink>

                                                        <NavLink id="v-pills-profile-tab" style={{ cursor: "pointer" }}
                                                            aria-controls="v-pills-from-servicenow" aria-selected="true"
                                                            className={[
                                                                'mb-2',
                                                                duplicateActiveTab === '3' && 'active'
                                                            ].filter(Boolean).join(' ')}
                                                            onClick={() => setDuplicateActiveTab('3')} >
                                                            Add using ServiceNow
                                                        </NavLink>
                                                    </Nav>
                                                </Col>
                                                <Col md={9}>
                                                    <TabContent activeTab={duplicateActiveTab} className="text-muted mt-4 mt-md-0">
                                                        <TabPane tabId="1" role="tabpanel" aria-labelledby="v-pills-from-file">
                                                            {(!fileUploaded || tableRowsData.length === 0) &&
                                                                <FileUpload
                                                                    name="duplication_map"
                                                                    customUpload
                                                                    auto
                                                                    uploadHandler={onFileUpload}
                                                                    accept=".xls,.xlsx"
                                                                    maxFileSize={2000000}
                                                                    emptyTemplate={
                                                                        <p className="m-0">
                                                                            Upload Excel File With 2 Columns: <span className='fw-bold'>Old Workstation Name</span> and <span className='fw-bold'>New Workstation Name</span>.
                                                                            Drag and drop the file to here to upload.</p>
                                                                    }
                                                                />
                                                            }
                                                            {fileUploaded && tableRowsData.length > 0 &&
                                                                <p>File successfully imported into duplication table!<br />
                                                                    You can add more duplication using the form or press Clear button to start over.<br />
                                                                    When you are ready to start duplication, press Start Duplication button.
                                                                </p>
                                                            }
                                                        </TabPane>
                                                        <TabPane tabId="2" role="tabpanel" aria-labelledby="v-pills-from-form">
                                                            <Card title="Add Duplication">
                                                                <CardBody className='pt-3'>
                                                                    <CardTitle tag="h3">
                                                                        Add Duplication
                                                                    </CardTitle>
                                                                    <Formik
                                                                        enableReinitialize={true}
                                                                        initialValues={{
                                                                            old_workstation_name: '',
                                                                            new_workstation_name: '',
                                                                        }}
                                                                        validationSchema={Yup.object({
                                                                            old_workstation_name: Yup.string()
                                                                                .required('Please enter old workstation name'),
                                                                            new_workstation_name: Yup.string()
                                                                                .required('Please enter new workstation name')
                                                                                .test('is-new-workstation-name-exist', 'New workstation name already exist in table', (value) => {
                                                                                    return !tableRowsData.some((row) => row.new_workstation_name === value);
                                                                                }),
                                                                        })}
                                                                        onSubmit={(values) => {
                                                                            let localQueueItemIdentifier = queueItemIdentifier + 1;
                                                                            let newRow = {
                                                                                ...values,
                                                                                id: getRowId(values.old_workstation_name, values.new_workstation_name),
                                                                                new_workstation_identifier: convertWSNameToIdentifier(values.new_workstation_name),
                                                                                duplication_status: DUPLICATION_STATUS_IN_QUEUE,
                                                                                duplication_errors: null,
                                                                                queue_item_identifier: localQueueItemIdentifier,
                                                                            }
                                                                            setQueueItemIdentifier(localQueueItemIdentifier);
                                                                            setTableRowsData((prevState) => [newRow, ...prevState]);
                                                                        }}
                                                                    >
                                                                        {() => (
                                                                            <Form className="justify-content-center">
                                                                                <FormGroup className="row justify-content-center">
                                                                                    <AppInputFieldComponent
                                                                                        fieldName="old_workstation_name"
                                                                                        inputPlaceHolder="Enter old workstation name"
                                                                                    />
                                                                                </FormGroup>

                                                                                <FormGroup className="row justify-content-center">
                                                                                    <AppInputFieldComponent
                                                                                        fieldType="new_workstation_name"
                                                                                        fieldName="new_workstation_name"
                                                                                        inputPlaceHolder="Enter new workstation name"
                                                                                    />
                                                                                </FormGroup>

                                                                                <Row className="justify-content-center">
                                                                                    <RSButton color='primary' type='submit' className='w-100 mw-333'>Add To Duplicate</RSButton>
                                                                                </Row>
                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </CardBody>
                                                            </Card>
                                                        </TabPane>
                                                        <TabPane tabId="3" role="tabpanel" aria-labelledby="v-pills-from-servicenow">
                                                            <DuplicationFromServiceNow onAddToDuplicate={addToDuplicateServiceNow} />
                                                        </TabPane>
                                                    </TabContent>
                                                </Col>
                                            </Row>
                                        }

                                        {xlsProcessedRows > 0 &&
                                            <Row className='mb-3'>
                                                <Col sm={4}>
                                                    <Panel header="Status">
                                                        <Badge color="success" className='ms-2 fs-6'>{DUPLICATION_STATUS_COMPLETED}: {countPerStatus[DUPLICATION_STATUS_COMPLETED] || 0}</Badge>
                                                        <Badge color="danger" className='ms-2 fs-6'>{DUPLICATION_STATUS_FAILED}: {countPerStatus[DUPLICATION_STATUS_FAILED] || 0}</Badge>
                                                    </Panel>
                                                </Col>
                                                <Col sm={8}>
                                                    {isAllRowsProcessed && rowsStatusFailedExist &&
                                                        <Alert color="warning">
                                                            There are rows in status <b>{DUPLICATION_STATUS_FAILED}</b>.
                                                            Please check the errors and retry them by pressing <b>Process Queue</b> again.
                                                            To start over press <b>Clear</b> and upload a new file.
                                                        </Alert>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                    <Col md={3} className='ps-3 border-start'>
                                        <div className="d-grid gap-3">
                                            <div>
                                                <Checkbox className='align-middle' inputId="checkbox-create-ticket-on-poc" name="create_ticket_on_poc" onChange={(e) => setCreateTicketOnPoc(e.checked)} checked={createTicketOnPoc} />
                                                <label htmlFor="checkbox-create-ticket-on-poc" className="ms-2">Create Ticket in POC</label>
                                            </div>
                                            <Button severity='primary' label="Start Duplication" icon="ri-play-line" onClick={handleStartDuplicationButton}
                                                disabled={isAllRowsProcessed || tableRowsData.length < 1} loading={isProcessingQueue}
                                            />
                                            <Button severity='secondary' label="Clear" icon="ri-delete-bin-line" onClick={handleClearListButton} disabled={isProcessingQueue} />
                                        </div>
                                    </Col>
                                </Row>

                                <Divider align="left">
                                    <b>Workstations to Duplicate</b>
                                </Divider>

                                <DataTable value={tableRowsData}
                                    dataKey="id"
                                    rows={rowsPerPage}
                                    paginator
                                    paginatorPosition='top'
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorLeft={paginatorLeft}
                                    totalRecords={tableRowsData.length}
                                    onFilter={(e) => setFilters(e.filters)}
                                    emptyMessage="No duplication defined."
                                    filters={filters}
                                    globalFilterFields={['old_workstation_name', 'new_workstation_name', 'new_workstation_identifier', 'duplication_status']}
                                    exportFilename="epic_ws_duplication"
                                >
                                    <Column field="old_workstation_name" header="Old Name" sortable />
                                    <Column field="new_workstation_name" header="New Name" sortable />
                                    <Column field="new_workstation_identifier" header="New Identifier" sortable />
                                    <Column field="duplication_status" header="Status" sortable body={
                                        (rowData) => {
                                            return (
                                                <Badge color={statusBadgeColor[rowData.duplication_status]}>{rowData.duplication_status}</Badge>
                                            );
                                        }
                                    } />
                                    <Column field="duplication_errors" header="Errors" body={(rowData) =>
                                        <BulkUpdateErrors backendErrors={rowData.duplication_errors} fromApiToExcelColumn={apiToExcelColumn} />
                                    } />
                                </DataTable>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ConfirmDialog visible={clearListDialogState}
                onHide={clearListDialogHide}
                message={
                    <p>Are you sure you want to CLEAR the list?</p>
                }
                header='Clear Duplication Queue'
                acceptLabel='Clear'
                rejectLabel='Cancel'
                accept={clearListPerform} reject={clearListDialogHide} />

            <ConfirmDialog visible={startDuplicationDialogState}
                onHide={startDuplicationDialogHide}
                message={
                    <p>Are you sure you want to DUPLICATE all the workstations on this list?</p>
                }
                header='Start Duplication'
                acceptLabel='Start Duplication'
                rejectLabel='Cancel'
                accept={startDuplicationPerform} reject={startDuplicationDialogHide} />

        </Fragment>
    );
};

export default WorkstationsDuplicate;
