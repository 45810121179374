import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CrudBaseList } from 'components/base/CrudBaseList';
import { Badge } from 'reactstrap';
import { ENTITY_CLINIC_SUMMARY } from 'redux/entities_config';
import ClinicSummaryToolbar from './ClinicSummaryToolbar.js';

const fixedDataTableColumns = [
    {
        field: 'workflow_req_category_name',
        header: 'Asset Category',
    },
    {
        field: 'assets_have',
        header: 'Have',
        align: 'center',
    },
    {
        field: 'assets_need',
        header: 'Need',
        align: 'center',
    },
];


const ClinicSummaryList = () => {
    const dispatch = useDispatch();
    const [dataTableColumns, setDataTableColumns] = useState([]);
    const [exportExcelColumnInfo, setExportExcelColumnInfo] = useState([]);
    const [primaryUses, setPrimaryUses] = useState({});
    const workflowReqCategories = useSelector(state => state.crudBase.list);

    const exportExcelFieldMap = useCallback((rowData) => {
        let schema = {
            'Asset Category': rowData.workflow_req_category_name,
            'Have': rowData.assets_have,
            'Need': rowData.assets_need,
        };

        for (let key in primaryUses) {
            let workflow = rowData.primary_uses.find(item => item.workflow_id === key);
            let assetsHave = null;
            // let assetsRequired = null;
            let assetsNeed = null;
            if (workflow) {
                assetsHave = workflow.assets_have;
                // assetsRequired = workflow.assets_required;
                assetsNeed = workflow.assets_need;
            }

            if (assetsHave > 0) {
                schema[primaryUses[key] + ' - Have'] = assetsHave;
            } else {
                schema[primaryUses[key] + ' - Have'] = '';
            }
            if (assetsNeed !== null) {
                schema[primaryUses[key] + ' - Need'] = assetsNeed;
            } else {
                schema[primaryUses[key] + ' - Need'] = '';
            }
        }

        return schema;
    }, [primaryUses]);

    useEffect(() => {
        if (Object.keys(workflowReqCategories).length > 0) {
            let localPrimaryUses = {};
            for (let wrc of workflowReqCategories) {
                for (let pu of wrc.primary_uses) {
                    if (!(pu.workflow_id in localPrimaryUses)) {
                        localPrimaryUses[pu.workflow_id] = pu.workflow_name;
                    }
                }
            }
            setPrimaryUses(localPrimaryUses);
        }
    }, [workflowReqCategories]);

    useEffect(() => {
        let localDataTableColumns = [...fixedDataTableColumns];
        let localExportExcelColumnInfo = [
            { wch: 20 }, // Asset Category
            { wch: 10 }, // Have
            { wch: 10 }, // Need
        ];
        for (let key in primaryUses) {
            localDataTableColumns.push({
                header: primaryUses[key],
                sortable: false,
                align: 'center',
                body: (rowData) => {
                    let workflow = rowData.primary_uses.find(item => item.workflow_id === key);
                    let assetsHave = null;
                    // let assetsRequired = null;
                    let assetsNeed = null;
                    if (workflow) {
                        assetsHave = workflow.assets_have;
                        // assetsRequired = workflow.assets_required;
                        assetsNeed = workflow.assets_need;
                    }

                    return (
                        <div className="vstack">
                            {assetsHave > 0 &&
                                <Badge color='success' className="m-1">Have: <b className='fs-7'>{assetsHave}</b></Badge>
                            }
                            {
                                /*assetsRequired > 0 &&
                                    <Badge color='primary' className="m-1">Required: <b className='fs-7'>{assetsRequired}</b></Badge>
                                */
                            }
                            {assetsNeed !== null &&
                                <Badge color='warning' className="m-1">Need: <b className='fs-7'>{assetsNeed}</b></Badge>
                            }
                        </div>
                    );
                },
            });
            // For Have column
            localExportExcelColumnInfo.push({ wch: 20 });
            // For Required column
            localExportExcelColumnInfo.push({ wch: 20 });
        }

        setDataTableColumns(localDataTableColumns);
        setExportExcelColumnInfo(localExportExcelColumnInfo);
    }, [dispatch, primaryUses]);

    return (
        <CrudBaseList
            entityCode={ENTITY_CLINIC_SUMMARY}
            // CrudBaseForm={WorkflowReqForm}
            // bulkUpdateRoute={WORKFLOW_DEFAULT_REQS_BULK_UPDATE}
            StartToolbarContent={ClinicSummaryToolbar}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle={'Clinic Inventory Summary'}
            hasCreateButton={false}
            hasGlobalSearchField={false}
            initialRowsPerPage={50}
        />
    );
};

export default ClinicSummaryList;
