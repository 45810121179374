import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { MENU_SECTIONS, MENU_SECTIONS_LABELS } from "assets/static_data/backend_exports";
import { BASE_PATH_INVENTORY, BASE_PATH_WORKSTATION } from "navigation/ROUTE_CONSTANTS";
import { menuOptions } from "navigation/menu_constants";
import { setActiveMenuSection } from "redux/actions/ui.actions";

export const SidebarMenuOptions = ({ onUpdatedMenuOptions }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const activeMenuSection = useSelector(state => state.ui.activeMenuSection);
    const userPermissions = useSelector(state => state.users.userPermissions);
    const [currentUserMenuOptions, setCurrentUserMenuOptions] = useState([]);

    useEffect(() => {
        // Find which menuOptions key contains an element which matches the link attribute with the current location.pathname
        const menuSectionCode = Object.keys(menuOptions).find((key) => {
            let wasFound = menuOptions[key].find((item) =>
                item.link && item.link === location.pathname
            );
            if (!wasFound) {
                wasFound = menuOptions[key].find((item) =>
                    item.subMenu && item.subMenu.find((subItem) =>
                        subItem.link === location.pathname
                    )
                );
            }
            return wasFound;
        });

        // If a menuSection was found, set the current menuSection as the active one
        if (menuSectionCode && menuSectionCode !== MENU_SECTIONS.COMMON && menuSectionCode !== activeMenuSection) {
            dispatch(setActiveMenuSection(menuSectionCode));
        } else {
            // Try to infer from pathname
            if (location.pathname.startsWith(BASE_PATH_INVENTORY)) {
                dispatch(setActiveMenuSection(MENU_SECTIONS.INVENTORY));
            } else if (location.pathname.startsWith(BASE_PATH_WORKSTATION)) {
                dispatch(setActiveMenuSection(MENU_SECTIONS.WORKSTATION));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (userPermissions && userPermissions.length > 0 && activeMenuSection) {
            let currentUserMenuOptions = menuOptions[activeMenuSection].concat(menuOptions[MENU_SECTIONS.COMMON]);
            // Deep copying of currentUserMenuOptions
            currentUserMenuOptions = JSON.parse(JSON.stringify(currentUserMenuOptions));

            // Loop on elements of key subMenu to check if it is available for the current user permissions
            currentUserMenuOptions = currentUserMenuOptions.map(menuOption => {
                if (menuOption.subMenu) {
                    menuOption.subMenu = menuOption.subMenu.filter(subMenuOption => {
                        if (subMenuOption.availableForPermissions) {
                            return subMenuOption.availableForPermissions.some(permission => userPermissions.includes(permission));
                        } else {
                            return true;
                        }
                    });
                }

                return menuOption;
            });

            // Loop on elements of currentUserMenuOptions to check if it contains items on subMenu and also is available for the current user permissions
            currentUserMenuOptions = currentUserMenuOptions.filter(menuOption => {
                if (menuOption.subMenu) {
                    return menuOption.subMenu.length > 0;
                } else {
                    if (menuOption.availableForPermissions) {
                        return menuOption.availableForPermissions.some(permission => userPermissions.includes(permission));
                    } else {
                        return true;
                    }
                }
            });

            setCurrentUserMenuOptions(currentUserMenuOptions);
        }

    }, [activeMenuSection, userPermissions]);

    useEffect(() => {
        if (currentUserMenuOptions.length > 0) {
            onUpdatedMenuOptions();
        }
    }, [onUpdatedMenuOptions, currentUserMenuOptions]);

    return (
        <Fragment>

            <li className={['menu-title', 'menu-section-title', activeMenuSection].join(' ')}>
                <span>{MENU_SECTIONS_LABELS[activeMenuSection]}</span>
            </li>

            {currentUserMenuOptions.map((menuOption, index) => (
                <Fragment key={index}>
                    {menuOption.link && (
                        <li>
                            <NavLink to={menuOption.link} className="waves-effect">
                                <i className={menuOption.icon}></i>
                                <span className="ms-1">{menuOption.name}</span>
                            </NavLink>
                        </li>

                    )}
                    {menuOption.subMenu && (
                        <li>
                            <a href="/#" className="has-arrow waves-effect">
                                <i className={menuOption.icon}></i>
                                <span className="ms-1">{menuOption.name}</span>
                            </a>
                            <ul className="sub-menu">
                                {menuOption.subMenu.map((subMenuOption, index2nd) => (
                                    <li key={index2nd}>
                                        <NavLink to={subMenuOption.link}>{subMenuOption.name}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    )}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SidebarMenuOptions;
