import { ConfirmDialog } from 'primereact/confirmdialog';
import { useDebounce } from 'primereact/hooks';
import { OrderList } from 'primereact/orderlist';
import { PickList } from 'primereact/picklist';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Card, CardBody, Col, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_INFO } from 'redux/reducers/ui.reducer';
import { API_EPIC_WORKSTATIONS, API_SERVICE_NOW_WORKSTATIONS } from 'services/API_CONSTANTS';
import { crudBaseGetList } from 'services/crudBaseService';
import { updateServiceNowWorkstations } from 'services/workstationService';

import 'styles/p_orderlist.scss';
import 'styles/p_picklist.scss';

const includePosition = (items) => items.map((item, index) => ({
    ...item,
    position: index + 1,
}));

const RETRIEVE_BLOCK = 100;

export const DuplicationFromServiceNow = ({ onAddToDuplicate }) => {
    const dispatch = useDispatch();

    const [oldWsFilterInputValue, oldWsFilterDebouncedValue, setOldWsFilterDebouncedValue] = useDebounce('', 400);
    const [newWsFilterInputValue, newWsFilterDebouncedValue, setNewWsFilterDebouncedValue] = useDebounce('', 400);

    const [oldWsSource, setOldWsSource] = useState([]);
    const [oldWsTarget, setOldWsTarget] = useState([]);

    const [newWsSource, setNewWsSource] = useState([]);
    const [newWsTarget, setNewWsTarget] = useState([]);

    const [assignmentOldWs, setAssignmentOldWs] = useState([]);
    const [assignmentNewWs, setAssignmentNewWs] = useState([]);

    const [oldWsChanged, setOldWsChanged] = useState(false);
    const [newWsChanged, setNewWsChanged] = useState(false);

    const [oldWsCountSelected, setOldWsCountSelected] = useState(0);
    const [newWsCountSelected, setNewWsCountSelected] = useState(0);

    const [oldWsRecordCount, setOldWsRecordCount] = useState(0);
    const [newWsRecordCount, setNewWsRecordCount] = useState(0);

    const [activeTab, setActiveTab] = useState(1);

    const [addToDuplicateDialogState, setAddToDuplicateDialogState] = useState(false);

    const toggleTab = useCallback((tab) => {
        if (tab <= 3 && tab >= 1) {
            setActiveTab(tab);
        }
        if (tab === 3) {
            if (oldWsChanged) {
                setAssignmentOldWs(includePosition(oldWsTarget));
                setOldWsChanged(false);
            }
            if (newWsChanged) {
                setAssignmentNewWs(includePosition(newWsTarget));
                setNewWsChanged(false);
            }
        }
    }, [newWsChanged, newWsTarget, oldWsChanged, oldWsTarget]);

    const handleAddToDuplicateButton = () => {
        setAddToDuplicateDialogState(true);
    }

    const addToDuplicateDialogHide = () => {
        setAddToDuplicateDialogState(false);
    }

    const addToDuplicatePerform = () => {
        let duplicationRows = assignmentOldWs.map((ws, index) => ({
            old_ws_name: ws.ws_name,
            new_ws_name: assignmentNewWs[index].ws_name,
        }));

        onAddToDuplicate(duplicationRows);
        dispatch(setToast({
            severity: TOAST_SEVERITY_INFO,
            summary: 'Records added to duplication queue',
            detail: 'Records have been added to duplication queue',
        }));
    }

    const loadEpicWorkstations = () => {
        // Load all old workstations from API_EPIC_WORKSTATIONS
        crudBaseGetList(API_EPIC_WORKSTATIONS, {
            limit: RETRIEVE_BLOCK,
            search: oldWsFilterDebouncedValue,
            sortField: 'last_modified_date',
            sortOrder: -1,
        }).then((data) => {
            let oldWs = data.results.map((ws) => {
                return {
                    id: ws.id,
                    cid: ws.cid,
                    ws_name: ws.ws_name,
                    status: ws.status,
                    functional_type: ws.functional_type,
                }
            });

            // Filter ws already exist into oldWsTarget
            let oldWsTargetIds = oldWsTarget.map((ws) => ws.id);
            oldWs = oldWs.filter((ws) => !oldWsTargetIds.includes(ws.id));

            setOldWsSource(oldWs);
            setOldWsRecordCount(data.count);
        }).catch((err) => {
            dispatch(setToast({
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Error when retrieving old workstations',
                detail: Object.values(err.response.data).map((value, index) => (
                    value
                )).join('. '),
            }));
        });
    }

    const loadServiceNowWorkstations = () => {
        // Load all new workstations from API_SERVICE_NOW_WORKSTATIONS
        crudBaseGetList(API_SERVICE_NOW_WORKSTATIONS, {
            limit: RETRIEVE_BLOCK,
            search: newWsFilterDebouncedValue,
            sortField: 'sys_updated_on',
            sortOrder: -1,
        }).then((data) => {
            let newWs = data.results.map((ws) => {
                return {
                    id: ws.id,
                    display_name: ws.display_name,
                    ws_name: ws.ci,
                    company: ws.company,
                    department: ws.department,
                    sys_created_on: ws.sys_created_on,
                }
            });

            // Filter ws already exist into newWsTarget
            let newWsTargetIds = newWsTarget.map((ws) => ws.id);
            newWs = newWs.filter((ws) => !newWsTargetIds.includes(ws.id));

            setNewWsSource(newWs);
            setNewWsRecordCount(data.count);
        }).catch((err) => {
            dispatch(setToast({
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Error when retrieving new workstations',
                detail: Object.values(err.response.data).map((value, index) => (
                    value
                )).join('. '),
            }));
        });
    }

    useEffect(() => {
        loadEpicWorkstations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldWsFilterDebouncedValue]);

    useEffect(() => {
        loadServiceNowWorkstations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newWsFilterDebouncedValue]);

    const oldWsItemTemplate = (item) => {
        return (
            <b>{item.ws_name}</b>
        );
    }

    const newWsItemTemplate = (item) => {
        return (
            <div>
                <b>{item.ws_name}</b> | <span>{item.department}</span> | <span>{item.display_name}</span>
            </div>
        );
    }

    const assignmentOldWsItemTemplate = (item) => {
        return (
            <div className="d-flex flex-row">
                <div>
                    {item.position}
                </div>
                <div className='ms-1'>
                    <b>{item.ws_name}</b>
                </div>
            </div>
        );
    }

    const assignmentNewWsItemTemplate = (item) => {
        return (
            <div className="d-flex flex-row">
                <div>
                    {item.position}
                </div>
                <div className='ms-1'>
                    <b>{item.ws_name}</b>
                </div>
            </div>
        );
    }

    const oldWsOnChangeHandler = (e) => {
        setOldWsSource(e.source);
        setOldWsTarget(e.target);
        setOldWsChanged(true);
        setOldWsCountSelected(e.target.length);
    }

    const newWsOnChangeHandler = (e) => {
        setNewWsSource(e.source);
        setNewWsTarget(e.target);
        setNewWsChanged(true);
        setNewWsCountSelected(e.target.length);
    }

    const assignmentOldWsOnChangeHandler = (e) => {
        setAssignmentOldWs(includePosition(e.value));
    }

    const assignmentNewWsOnChangeHandler = (e) => {
        setAssignmentNewWs(includePosition(e.value));
    }

    const handleRefreshServiceNowButton = () => {
        dispatch(enableOverlay('Getting updates from ServiceNow...'));
        updateServiceNowWorkstations()
            .then((data) => {
                loadServiceNowWorkstations();
            }).catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error when getting updates from ServiceNow',
                    detail: Object.values(err.response.data).map((value, index) => (
                        value
                    )).join('. '),
                }));
            })
            .finally(() => {
                dispatch(disableOverlay());
            });
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Add using ServiceNow</h4>

                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                            <NavItem>
                                <NavLink active={activeTab === 1} onClick={() => { toggleTab(1); }} >
                                    <span className="step-number">01</span>
                                    <span className="step-title">Old Workstations</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 2} onClick={() => { toggleTab(2); }} >
                                    <span className="step-number">02</span>
                                    <span className="step-title">New Workstations</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 3} onClick={() => { toggleTab(3); }} >
                                    <span className="step-number">03</span>
                                    <span className="step-title">Assignment</span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                            <TabPane tabId={1}>
                                <PickList dataKey="id" source={oldWsSource} target={oldWsTarget} onChange={oldWsOnChangeHandler} itemTemplate={oldWsItemTemplate} filter filterBy="ws_name" breakpoint="1680px"
                                    sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '16rem' }} targetStyle={{ height: '16rem' }} showSourceControls={false} showTargetControls={false}
                                    sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name"
                                    onSourceFilterChange={(e) => setOldWsFilterDebouncedValue(e.value)} sourceFilterValue={oldWsFilterInputValue} />
                                <Row>
                                    <Col>
                                        {oldWsRecordCount > RETRIEVE_BLOCK &&
                                            <span className="text-muted fs-7">Showing {RETRIEVE_BLOCK} of {oldWsRecordCount} records. Refine your search to see more.</span>
                                        }
                                    </Col>
                                    <Col className='text-end'>
                                        <span className="text-muted">Count selected: {oldWsCountSelected}</span>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                <PickList dataKey="id" source={newWsSource} target={newWsTarget} onChange={newWsOnChangeHandler} itemTemplate={newWsItemTemplate} filter filterBy="ws_name,department,display_name" breakpoint="1680px"
                                    sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '16rem' }} targetStyle={{ height: '16rem' }} showSourceControls={false} showTargetControls={false}
                                    sourceFilterPlaceholder="Search by name, tag, model, department" targetFilterPlaceholder="Search by name, tag, model, department"
                                    onSourceFilterChange={(e) => setNewWsFilterDebouncedValue(e.value)} sourceFilterValue={newWsFilterInputValue} />
                                <Row>
                                    <Col>
                                        {newWsRecordCount > RETRIEVE_BLOCK &&
                                            <span className="text-muted fs-7">Showing {RETRIEVE_BLOCK} of {newWsRecordCount} records. Refine your search to see more.</span>
                                        }
                                        <Button size='sm' color="secondary" className='mt-2' onClick={handleRefreshServiceNowButton}>Get Updates From ServiceNow</Button>
                                    </Col>
                                    <Col className='text-end'>
                                        <span className="text-muted">Count selected: {newWsCountSelected}</span>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                                {oldWsCountSelected !== newWsCountSelected &&
                                    <Alert color="warning">Old Workstations and New Workstations count must be equal</Alert>
                                }
                                <Row>
                                    <Col>
                                        <OrderList dataKey="id" value={assignmentOldWs} onChange={assignmentOldWsOnChangeHandler} itemTemplate={assignmentOldWsItemTemplate} header="Old Workstations" dragdrop></OrderList>
                                    </Col>
                                    <Col>
                                        <OrderList dataKey="id" value={assignmentNewWs} onChange={assignmentNewWsOnChangeHandler} itemTemplate={assignmentNewWsItemTemplate} header="New Workstations" dragdrop></OrderList>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                        <ul className="pager wizard twitter-bs-wizard-pager-link pt-2">
                            <li className='previous'>
                                <Button color="primary" onClick={() => { toggleTab(activeTab - 1); }} disabled={activeTab === 1} >Previous</Button>
                            </li>
                            <li className={['next ms-3', activeTab !== 3 && 'disabled'].filter(Boolean).join(' ')}>
                                <Button color="primary" onClick={handleAddToDuplicateButton} disabled={activeTab !== 3 || oldWsCountSelected !== newWsCountSelected} >Add to Duplicate</Button>
                            </li>
                            <li className='next'>
                                <Button color="primary" onClick={() => { toggleTab(activeTab + 1); }} disabled={activeTab === 3} >Next</Button>
                            </li>
                        </ul>
                    </div>
                </CardBody>
            </Card>

            <ConfirmDialog visible={addToDuplicateDialogState}
                onHide={addToDuplicateDialogHide}
                message={
                    <p>Are you sure you want to Add to Duplication Queue?</p>
                }
                header='Add to Duplication Queue'
                rejectLabel='Cancel'
                acceptLabel='Confirm'
                accept={addToDuplicatePerform} reject={addToDuplicateDialogHide} />

        </Fragment>
    );
}

export default DuplicationFromServiceNow;
