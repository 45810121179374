import { createAction } from "@reduxjs/toolkit";

// user action types
export const GET_USER = '[User] Getting user info...';
export const GET_USER_SUCCESS = '[User] user info recover with success!';
export const GET_USER_PERMISSIONS = '[User] Getting user permissions...';
export const GET_USER_PERMISSIONS_SUCCESS = '[User] user permissions recover with success!';
export const UPDATE_USER_SUCCESS = '[Users] user updated!';
export const PATCH_USER_SUCCESS = '[Users] user patched!';
export const RESET_USER_STATE = '[Users] Reset user state';
export const CREATE_USER = '[Users] user creating...!';
export const CREATE_USER_SUCCESS = '[Users] user create with success!';
export const UPDATE_USER_PROFILE = '[Users] updating user profile...';
export const UPDATE_USER_PROFILE_SUCCESS = '[Users] updating user profile success!';
export const RESET_PASSWORD = '[User] sending email to reset password';
export const RESET_PASSWORD_SUCCESS = '[User] Email to reset password send!';
export const RESET_PASSWORD_CONFIRMATION = '[User] resetting password...';
export const RESET_PASSWORD_CONFIRMATION_SUCCESS = '[User] resetting password success!'

// user actions
export const getUser = createAction(GET_USER);
export const getUserSuccess = createAction(GET_USER_SUCCESS);
export const getUserPermissions = createAction(GET_USER_PERMISSIONS);
export const getUserPermissionsSuccess = createAction(GET_USER_PERMISSIONS_SUCCESS);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const patchUserSuccess = createAction(PATCH_USER_SUCCESS);
export const resetUserState = createAction(RESET_USER_STATE);

export const createUser = createAction(CREATE_USER);
export const createUserSuccess = createAction(CREATE_USER_SUCCESS);
export const updateUserProfile = createAction(UPDATE_USER_PROFILE);
export const updateUserProfileSuccess = createAction(UPDATE_USER_PROFILE_SUCCESS);

export const resetPassword = createAction(RESET_PASSWORD);
export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);

export const resetPasswordConfirmation = createAction(RESET_PASSWORD_CONFIRMATION);
export const resetPasswordConfirmationSuccess = createAction(RESET_PASSWORD_CONFIRMATION_SUCCESS);
