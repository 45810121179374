import { Tag } from 'primereact/tag';
import React from 'react';

import { ASSET_TDR_STATUS_NOT_PASSED, ASSET_TDR_STATUS_PASSED } from 'assets/static_data/common_constants';

export const TdrStatusIndicator = ({ tdrStatus }) => {
    return (
        <p className='fs-5 mb-0'>
            {tdrStatus ?
                <Tag severity="success" value={ASSET_TDR_STATUS_PASSED}></Tag>
                :
                <Tag severity="warning" value={ASSET_TDR_STATUS_NOT_PASSED}></Tag>
            }
        </p>
    )
}

export default TdrStatusIndicator;
