import axios from 'axios';
import { LOGIN, TOKEN_INVALIDATE, USER_CHANGE_PASSWORD } from './API_CONSTANTS';

export const userLogin = ({ username, password }) => {
    const credentials = { username: username, password: password };
    return new Promise((resolve, reject) => {
        try {
            axios.post(LOGIN, credentials)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Auth error: ', err);
                    reject('Sign-in error!');
                });
        } catch (error) {
            console.error('Sign-in error!', error);
            reject('Sign-in error!');
        }
    });
};

export const userSignOut = (refreshToken) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(TOKEN_INVALIDATE, { refresh: refreshToken })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Sign-out error: ', err);
                    reject('Sign-out error!');
                });
        } catch (error) {
            console.error('Sign-out error!', error);
            reject('Sign-out error!');
        }
    });
};


export const userChangePassword = (changePasswordData) => {
    return new Promise((resolve, reject) => {
        try {
            axios.patch(USER_CHANGE_PASSWORD, changePasswordData)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Change password error: ', err);
                    reject('Change password error!');
                });
        } catch (error) {
            console.error('Change password error!', error);
            reject('Change password error!');
        }
    });
};
