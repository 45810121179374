import {
    API_ASSETS,
    API_ASSETS_TO_FOLLOW_UP,
    API_ASSET_OWNERS,
    API_ASSET_TYPES,
    API_ASSET_TYPE_CHOICES,
    API_BUILDINGS,
    API_CITIES,
    API_CLINIC_SUMMARY,
    API_CREDIT_CARD_READERS,
    API_DEPARTMENTS,
    API_DUPLICATIONS,
    API_EPIC_DEPARTMENTS,
    API_EPIC_WORKSTATIONS,
    API_FUNCTIONAL_TYPES,
    API_GAP_REPORT,
    API_LEASED_ASSETS,
    API_LEGACY_DEPARTMENTS,
    API_OUS,
    API_PRINTER_TRAY_CONTENTS,
    API_PRINTER_TYPES,
    API_SELECT_CREDIT_CARD_READERS,
    API_SELECT_DEPARTMENTS,
    API_SELECT_PRINTERS,
    API_SPECIALTIES,
    API_STATES,
    API_WAP_ASSETS,
    API_WORKFLOWS,
    API_WORKFLOW_DEFAULT_REQS,
    API_WORKFLOW_REQS,
    API_WORKFLOW_REQ_CATEGORIES,
    API_WORKSTATIONS,
    API_WORKSTATIONS_TDR_NOT_PASSED,
    API_WORKSTATIONS_WO_PRIMARY_USE,
    API_WORKSTATION_TYPES,
} from "services/API_CONSTANTS";

export const ENTITY_FUNCTIONAL_TYPE = 'functional_type';
export const ENTITY_EPIC_WORKSTATION = 'epic_workstation';
export const ENTITY_DUPLICATION = 'duplication';
export const ENTITY_STATE = 'state';
export const ENTITY_CITY = 'city';
export const ENTITY_BUILDING = 'building';
export const ENTITY_SPECIALTY = 'specialty';
export const ENTITY_OU = 'ou';
export const ENTITY_LEGACY_DEPARTMENT = 'legacy_department';
export const ENTITY_DEPARTMENT = 'department';
export const ENTITY_SELECT_DEPARTMENT = 'select_department';
export const ENTITY_EPIC_DEPARTMENT = 'epic_department';
export const ENTITY_WORKFLOW = 'workflow';
export const ENTITY_WORKFLOW_REQ = 'workflow_req';
export const ENTITY_WORKFLOW_DEFAULT_REQ = 'workflow_default_req';
export const ENTITY_WORKFLOW_REQ_CATEGORIES = 'workflow_req_categories';
export const ENTITY_GAP_REPORT = 'gap_report';
export const ENTITY_CLINIC_SUMMARY = 'clinic_summary';
export const ENTITY_ASSET_TYPE_CHOICE = 'asset_type_choice';
export const ENTITY_ASSET_TYPE = 'asset_type';
export const ENTITY_WORKSTATION_TYPE = 'workstation_types';
export const ENTITY_PRINTER_TYPE = 'printer_types';
export const ENTITY_PRINTER_TRAY_CONTENT = 'printer_tray_content';
export const ENTITY_ASSET = 'asset';
export const ENTITY_WAP_ASSET = 'wap_asset';
export const ENTITY_WORKSTATION = 'workstation';
export const ENTITY_CREDIT_CARD_READER = 'credit_card_reader';
export const ENTITY_LEASED_ASSET = 'leased_asset';
export const ENTITY_ASSET_OWNER = 'asset_owner';

export const ENTITY_SELECT_CREDIT_CARD_READER = 'select_credit_card_reader';
export const ENTITY_SELECT_PRINTER = 'select_printer';

export const ENTITY_WORKSTATION_WO_PRIMARY_USE = 'workstation_wo_primary_use';
export const ENTITY_WORKSTATION_TDR_NOT_PASSES = 'workstation_tdr_not_passed';
export const ENTITY_ASSETS_TO_FOLLOW_UP = 'assets_to_follow_up';


export const INITIAL_ROWS_PER_PAGE = 20;


export const ENTITIES_CONFIG = {
    [ENTITY_FUNCTIONAL_TYPE]: {
        nameSingular: 'Functional Type',
        namePlural: 'Functional Types',
        apiEndpoint: API_FUNCTIONAL_TYPES,
        labelField: 'functional_type',
    },
    [ENTITY_EPIC_WORKSTATION]: {
        nameSingular: 'EPIC Workstation',
        namePlural: 'EPIC Workstations',
        apiEndpoint: API_EPIC_WORKSTATIONS,
        labelField: 'ws_identifier',
    },
    [ENTITY_DUPLICATION]: {
        nameSingular: 'Duplication',
        namePlural: 'Duplications',
        apiEndpoint: API_DUPLICATIONS,
        labelField: 'new_workstation',
    },
    [ENTITY_STATE]: {
        nameSingular: 'State',
        namePlural: 'States',
        apiEndpoint: API_STATES,
        labelField: 'state_name',
    },
    [ENTITY_CITY]: {
        nameSingular: 'City',
        namePlural: 'Cities',
        apiEndpoint: API_CITIES,
        labelField: 'city_name',
    },
    [ENTITY_BUILDING]: {
        nameSingular: 'Building',
        namePlural: 'Buildings',
        apiEndpoint: API_BUILDINGS,
        labelField: 'description',
    },
    [ENTITY_SPECIALTY]: {
        nameSingular: 'Specialty',
        namePlural: 'Specialties',
        apiEndpoint: API_SPECIALTIES,
        labelField: 'specialty_name',
    },
    [ENTITY_OU]: {
        nameSingular: 'School',
        namePlural: 'Schools',
        apiEndpoint: API_OUS,
        labelField: 'ou_name',
    },
    [ENTITY_LEGACY_DEPARTMENT]: {
        nameSingular: 'Legacy Department',
        namePlural: 'Legacy Departments',
        apiEndpoint: API_LEGACY_DEPARTMENTS,
        labelField: 'legacy_department_name',
    },
    [ENTITY_DEPARTMENT]: {
        nameSingular: 'Department',
        namePlural: 'Departments',
        apiEndpoint: API_DEPARTMENTS,
        labelField: 'department_name',
    },
    [ENTITY_SELECT_DEPARTMENT]: {
        nameSingular: 'Department',
        namePlural: 'Departments',
        apiEndpoint: API_SELECT_DEPARTMENTS,
        labelField: 'department_name',
    },
    [ENTITY_EPIC_DEPARTMENT]: {
        nameSingular: 'EPIC Department',
        namePlural: 'EPIC Departments',
        apiEndpoint: API_EPIC_DEPARTMENTS,
        labelField: 'epic_department_name',
    },
    [ENTITY_WORKFLOW]: {
        nameSingular: 'Primary Use',
        namePlural: 'Primary Uses',
        apiEndpoint: API_WORKFLOWS,
        labelField: 'workflow_name',
    },
    [ENTITY_WORKFLOW_REQ]: {
        nameSingular: 'Primary Use Requirement',
        namePlural: 'Primary Use Requirements',
        apiEndpoint: API_WORKFLOW_REQS,
        labelField: 'workflow.workflow_name',
    },
    [ENTITY_WORKFLOW_DEFAULT_REQ]: {
        nameSingular: 'Primary Use Default Requirement',
        namePlural: 'Primary Use Default Requirements',
        apiEndpoint: API_WORKFLOW_DEFAULT_REQS,
        labelField: 'workflow.workflow_name',
    },
    [ENTITY_WORKFLOW_REQ_CATEGORIES]: {
        nameSingular: 'Requirement Category',
        namePlural: 'Requirement Categories',
        apiEndpoint: API_WORKFLOW_REQ_CATEGORIES,
        labelField: 'workflow_req_category_name',
    },
    [ENTITY_GAP_REPORT]: {
        nameSingular: 'Gap Report',
        namePlural: 'Gap Report',
        apiEndpoint: API_GAP_REPORT,
        labelField: 'workflow_req_category_name',
    },
    [ENTITY_CLINIC_SUMMARY]: {
        nameSingular: 'Clinic Summary',
        namePlural: 'Clinic Summary',
        apiEndpoint: API_CLINIC_SUMMARY,
        labelField: 'workflow_req_category_name',
    },
    [ENTITY_ASSET_TYPE_CHOICE]: {
        nameSingular: 'Device Make/Model',
        namePlural: 'Device Makes and Models',
        apiEndpoint: API_ASSET_TYPE_CHOICES,
        labelField: 'choice',
    },
    [ENTITY_ASSET_TYPE]: {
        nameSingular: 'Asset Type',
        namePlural: 'Asset Types',
        apiEndpoint: API_ASSET_TYPES,
        labelField: 'asset_type_name',
    },
    [ENTITY_ASSET]: {
        nameSingular: 'Asset',
        namePlural: 'Assets',
        apiEndpoint: API_ASSETS,
        labelField: 'asset_name',
    },
    [ENTITY_WAP_ASSET]: {
        nameSingular: 'Workstation and Printer Asset',
        namePlural: 'Workstation and Printer Assets',
        apiEndpoint: API_WAP_ASSETS,
        labelField: 'id',
    },
    [ENTITY_WORKSTATION]: {
        nameSingular: 'Workstation',
        namePlural: 'Workstations',
        apiEndpoint: API_WORKSTATIONS,
        labelField: 'id',
    },
    [ENTITY_WORKSTATION_WO_PRIMARY_USE]: {
        nameSingular: 'Workstation without Primary Use',
        namePlural: 'Workstations without Primary Use',
        apiEndpoint: API_WORKSTATIONS_WO_PRIMARY_USE,
        labelField: 'id',
    },
    [ENTITY_WORKSTATION_TDR_NOT_PASSES]: {
        nameSingular: 'Workstation with TDR Not Passed',
        namePlural: 'Workstations with TDR Not Passed',
        apiEndpoint: API_WORKSTATIONS_TDR_NOT_PASSED,
        labelField: 'id',
    },
    [ENTITY_ASSETS_TO_FOLLOW_UP]: {
        nameSingular: 'Asset to Follow Up',
        namePlural: 'Assets to Follow Up',
        apiEndpoint: API_ASSETS_TO_FOLLOW_UP,
        labelField: 'id',
    },
    [ENTITY_CREDIT_CARD_READER]: {
        nameSingular: 'Credit Card Reader',
        namePlural: 'Credit Card Readers',
        apiEndpoint: API_CREDIT_CARD_READERS,
        labelField: 'asset.asset_name',
    },
    [ENTITY_LEASED_ASSET]: {
        nameSingular: 'Leased Asset',
        namePlural: 'Leased Assets',
        apiEndpoint: API_LEASED_ASSETS,
        labelField: 'asset.asset_name',
    },
    [ENTITY_ASSET_OWNER]: {
        nameSingular: 'Asset Owner',
        namePlural: 'Asset Owners',
        apiEndpoint: API_ASSET_OWNERS,
        labelField: 'asset.asset_name',
    },
    [ENTITY_WORKSTATION_TYPE]: {
        nameSingular: 'Workstation Type',
        namePlural: 'Workstation Types',
        apiEndpoint: API_WORKSTATION_TYPES,
        labelField: 'asset_type_name',
    },
    [ENTITY_PRINTER_TYPE]: {
        nameSingular: 'Printer Type',
        namePlural: 'Printer Types',
        apiEndpoint: API_PRINTER_TYPES,
        labelField: 'asset_type_name',
    },
    [ENTITY_PRINTER_TRAY_CONTENT]: {
        nameSingular: 'Printer Tray Content',
        namePlural: 'Printer Tray Contents',
        apiEndpoint: API_PRINTER_TRAY_CONTENTS,
        labelField: 'tray_content',
    },
    [ENTITY_SELECT_CREDIT_CARD_READER]: {
        nameSingular: 'Default Credit Card Reader',
        namePlural: 'Default Credit Card Readers',
        apiEndpoint: API_SELECT_CREDIT_CARD_READERS,
        labelField: 'asset_name',
    },
    [ENTITY_SELECT_PRINTER]: {
        nameSingular: 'Default Printer',
        namePlural: 'Default Printers',
        apiEndpoint: API_SELECT_PRINTERS,
        labelField: 'asset_name',
    },
};
