import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { Col, Progress, Row } from "reactstrap";

import { CheckMailResetPasswordComponent } from "components/onboarding/CheckMailResetPasswordComponent";
import { ConfirmResetPasswordComponent } from "components/onboarding/ConfirmResetPasswordComponent";
import ChangeOrganizationButton from 'components/ui/ChangeOrganizationButton';
import { LOGIN_FORM } from 'navigation/ROUTE_CONSTANTS';
import { logoutSuccess } from 'redux/actions/auth.actions';
import { resetPassword as resetPasswordAction, resetPasswordConfirmation, resetUserState } from "redux/actions/users.actions";
import { OnBoardingLayout } from './OnBoardingLayout';

const STEP_1 = 'Step 1';
const STEP_2 = 'Step 2';

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const [stepper, setStepper] = useState({ step: STEP_1, progress: 30 });
    const [token, setToken] = useState('');
    let resetPasswordStepForm;
    let location = useLocation();

    function resetPasswordCheck(data) {
        if (stepper.step === STEP_1) {
            const email = { email: data.email };
            dispatch(resetPasswordAction(email));
        }
        if (stepper.step === STEP_2) {
            const credentials = { password: data.new_password, token: token };
            dispatch(resetPasswordConfirmation(credentials));
        }
    }

    useEffect(() => {
        dispatch(logoutSuccess());
        dispatch(resetUserState());
    }, [dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const resetToken = searchParams.get('token');
        if (resetToken) {
            setToken(resetToken);
            setStepper({ step: STEP_2, progress: 60 });
        }
    }, [location.search])

    if (stepper.step === STEP_1) {
        resetPasswordStepForm = <CheckMailResetPasswordComponent onSubmit={(data) => resetPasswordCheck(data)} />
    }

    if (stepper.step === STEP_2) {
        resetPasswordStepForm = <ConfirmResetPasswordComponent onSubmit={(data) => resetPasswordCheck(data)} />
    }

    return (
        <OnBoardingLayout contentTitle="Reset password">
            <Row className='mt-3'>
                <Col className="text-center">
                    <h4>{stepper.step}</h4>
                </Col>
            </Row>

            <Row className='my-2'>
                <Col>
                    <Progress color="danger" className="w-25 mx-auto" style={{ height: "5px" }} value={stepper.progress} />
                </Col>
            </Row>

            {resetPasswordStepForm}

            <Row className='mt-5'>
                <Col className="text-center">
                    <Link className="sign-up-label font-weight-bold" to={LOGIN_FORM}>Back to Login</Link>
                </Col>
            </Row>

            {process.env.REACT_APP_MULTI_TENANT === 'true' && activeTenant.name &&
                <Row className='mt-5'>
                    <Col className="text-center sign-up-label">
                        Organization: {activeTenant.name} <ChangeOrganizationButton />
                    </Col>
                </Row>
            }

        </OnBoardingLayout>
    )
}

export default ResetPassword;
