import { createAction } from "@reduxjs/toolkit";

// user admin action types
export const GET_USERS_LIST_BY_ADMIN = '[Users] Getting list users by admin...';
export const GET_USERS_LIST_BY_ADMIN_SUCCESS = '[Users] Getting list users by admin with success...';
export const RESET_ADMIN_USERS_STATE = '[Users] Reset admin users state...';
export const CREATE_USER_BY_ADMIN = '[Users] user creating by admin...';
export const CREATE_USER_BY_ADMIN_SUCCESS = '[Users] user create by admin with success';
export const UPDATE_USER_BY_ADMIN = '[Users] updating user by admin...';
export const UPDATE_USER_BY_ADMIN_SUCCESS = '[Users] updating user by admin with success';
export const REMOVE_USER_BY_ADMIN = '[Users] remove user by admin...';
export const REMOVE_USER_BY_ADMIN_SUCCESS = '[Users] remove user by admin with success';


// user admin actions
export const getUsersListByAdmin = createAction(GET_USERS_LIST_BY_ADMIN);
export const getUsersListByAdminSuccess = createAction(GET_USERS_LIST_BY_ADMIN_SUCCESS);
export const resetAdminUsersState = createAction(RESET_ADMIN_USERS_STATE);

export const createUserByAdmin = createAction(CREATE_USER_BY_ADMIN);
export const createUserByAdminSuccess = createAction(CREATE_USER_BY_ADMIN_SUCCESS);
export const updateUserByAdmin = createAction(UPDATE_USER_BY_ADMIN);
export const updateUserByAdminSuccess = createAction(UPDATE_USER_BY_ADMIN_SUCCESS);
export const removeUserByAdmin = createAction(REMOVE_USER_BY_ADMIN);
export const removeUserByAdminSuccess = createAction(REMOVE_USER_BY_ADMIN_SUCCESS);
