import { Dropdown } from 'primereact/dropdown';
import { Fragment, useEffect, useRef, useState } from 'react';
import { FormFeedback } from 'reactstrap';

import { ENTITIES_CONFIG, ENTITY_ASSET, ENTITY_ASSET_OWNER, ENTITY_ASSET_TYPE, ENTITY_ASSET_TYPE_CHOICE, ENTITY_BUILDING, ENTITY_CITY, ENTITY_SELECT_DEPARTMENT, ENTITY_EPIC_DEPARTMENT, ENTITY_LEGACY_DEPARTMENT, ENTITY_OU, ENTITY_PRINTER_TRAY_CONTENT, ENTITY_PRINTER_TYPE, ENTITY_SELECT_CREDIT_CARD_READER, ENTITY_SELECT_PRINTER, ENTITY_SPECIALTY, ENTITY_STATE, ENTITY_WORKFLOW, ENTITY_WORKFLOW_REQ_CATEGORIES, ENTITY_WORKSTATION_TYPE } from 'redux/entities_config';
import { crudBaseGetList } from 'services/crudBaseService';
import { buildPeripheralDescription, buildPrinterDescription, userFullName } from 'utils';

import 'styles/p_dropdown.scss';

const lazyLoadRows = 10;
const lazyLoadTimeout = 2500;
const searchMinLength = 3;

const dropdownOptionMappers = {
    [ENTITY_STATE]: (results) => results.map((row) => {
        return {
            label: row.state_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_CITY]: (results) => {
        let groupedData = [];
        results.forEach((row) => {
            let groupIndex = groupedData.findIndex((group) => group.value === row.state_id);
            if (groupIndex < 0) {
                groupIndex = groupedData.push({
                    label: row.state.state_name,
                    value: row.state.id,
                    items: []
                });
                groupIndex--;
            }

            groupedData[groupIndex].items.push({
                label: row.city_name + (row.active ? '' : ' [Inactive]'),
                value: row.id
            });
        });

        return groupedData;
    },

    [ENTITY_SELECT_DEPARTMENT]: (results) => results.map((row) => {
        let label = row.department_name;
        if (row.suite_num) {
            label += ' - Suite ' + row.suite_num;
        }
        if (!row.active) {
            label += ' [Inactive]';
        }
        if (row.department_number) {
            label = row.department_number + ' - ' + label;
        }
        return {
            label: label,
            value: row.id,
            suite_num: row.suite_num,
        }
    }),

    [ENTITY_LEGACY_DEPARTMENT]: (results) => results.map((row) => {
        return {
            label: row.legacy_department_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_EPIC_DEPARTMENT]: (results) => results.map((row) => {
        return {
            label: row.epic_department_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_OU]: (results) => results.map((row) => {
        return {
            label: row.ou_name + ' - ' + row.ou_description + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_BUILDING]: (results) => results.map((row) => {
        return {
            label: `${row.description} [${row.code}]` + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_ASSET_TYPE]: (results) => results.map((row) => {
        return {
            label: row.asset_type_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_ASSET_TYPE_CHOICE]: (results) => results.map((row) => {
        return {
            label: row.choice + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_WORKSTATION_TYPE]: (results) => results.map((row) => {
        return {
            label: row.workstation_type_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_PRINTER_TYPE]: (results) => results.map((row) => {
        return {
            label: row.printer_type_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_ASSET_OWNER]: (results) => results.map((row) => {
        return {
            label: userFullName(row) + ' - ' + row.email + (row.is_active ? '' : ' [Inactive]'),
            value: row.email,
        }
    }),

    [ENTITY_ASSET]: (results) => results.map((row) => {
        let label = buildPeripheralDescription(row);
        if (row.parent_asset_id) {
            // label = <Fragment>
            //     <Link to={ASSET_EDIT.replace(':recordId', row.parent_asset_id)} className='pe-1' title="See Parent Asset in a new tab" target="_blank" rel="noopener">
            //         <i className="ri ri-external-link-line"></i>
            //     </Link>
            //     <span>{label}</span>
            // </Fragment>;
            label = `Parent ID: ${row.parent_asset_id}, ${label}`;
        }

        return {
            label: label,
            value: row.id
        }
    }),

    [ENTITY_SPECIALTY]: (results) => results.map((row) => {
        return {
            label: row.specialty_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_WORKFLOW]: (results) => results.map((row) => {
        return {
            label: row.workflow_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_WORKFLOW_REQ_CATEGORIES]: (results) => results.map((row) => {
        return {
            label: row.workflow_req_category_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_PRINTER_TRAY_CONTENT]: (results) => results.map((row) => {
        return {
            label: row.printer_tray_content_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),

    [ENTITY_SELECT_CREDIT_CARD_READER]: (results) => results.map((row) => {
        let ipsn = row.ipsn || 'n/a';
        let serialNum = row.serial_num || 'n/a';

        let label = `ID: ${row.id}, IPSN: ${ipsn}, Serial #: ${serialNum}`;
        if (row.mist_tag_num) {
            label += `, MSIT Tag: ${row.mist_tag_num}`;
        }
        if (row.cam_tag_num) {
            label += `, CAM Tag: ${row.cam_tag_num}`;
        }
        if (row.name) {
            label += `, Name: ${row.name}`;
        }

        return {
            label: label,
            value: row.id
        }
    }),

    [ENTITY_SELECT_PRINTER]: (results) => results.map((row) => {
        let label = buildPrinterDescription(row);
        return {
            label: label,
            value: row.id
        }
    }),
};

// TODO: Add support for lazy loading
export const AppEntityDropdown = ({
    entityCode, field, form, filterSet = null, optionsData = null, lazyLoad = false,
    placeholderEntity = null, clearDropdownOptions = null
}) => {

    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [groupedAttributes, setGroupedAttributes] = useState({});
    const [loadingState, setLoadingState] = useState(null);
    const [dropdownPlaceHolder, setDropdownLocalPlaceHolder] = useState('');
    const [filterPlaceHolder, setFilterPlaceHolder] = useState(null);
    const [optionsCount, setOptionsCount] = useState(null);

    const [fieldErrorMessage, setFieldErrorMessage] = useState(null);

    const loadLazyTimeout = useRef(null);

    useEffect(() => {
        if (
            !lazyLoad &&
            entityCode &&
            // If the entity is asset_type_choice, then it requires a filterSet to be passed in
            (![ENTITY_ASSET_TYPE_CHOICE, ENTITY_ASSET].includes(entityCode) || filterSet)
        ) {
            let paginationParams = null;
            if (filterSet) {
                paginationParams = {
                    filterSet
                };
            }

            if (!(entityCode in dropdownOptionMappers)) {
                console.error(`No dropdownOptionMapper found for ${entityCode}`);
                return;
            }

            setDropdownOptions([]);
            if (optionsData) {
                let dropdownOptions = dropdownOptionMappers[entityCode](optionsData);
                setDropdownOptions(dropdownOptions);
            } else {
                setLoadingState('loading');
                crudBaseGetList(ENTITIES_CONFIG[entityCode].apiEndpoint, paginationParams)
                    .then((data) => {
                        let dropdownOptions = dropdownOptionMappers[entityCode](data.results);
                        setDropdownOptions(dropdownOptions);
                        setOptionsCount(data.count);
                        setLoadingState(null);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoadingState(null);
                        setOptionsCount(0);
                    });
            }

            if (entityCode === ENTITY_CITY) {
                setGroupedAttributes({
                    optionGroupLabel: 'label',
                    optionGroupChildren: 'items',
                });
            }
        }
    }, [entityCode, filterSet, lazyLoad, optionsData]);

    useEffect(() => {
        if (clearDropdownOptions) {
            setDropdownOptions([]);
            setOptionsCount(null);
            setLoadingState(null);
        }
    }, [clearDropdownOptions]);

    useEffect(() => {
        let placeholder = 'Select the '
        let filterPlaceholder = null;

        if (lazyLoad) {
            filterPlaceholder = `Type at least ${searchMinLength} characters to search`;
        }

        if (placeholderEntity) {
            placeholder += placeholderEntity;
        } else {
            placeholder += ENTITIES_CONFIG[entityCode].nameSingular;
        }

        if (optionsCount !== null) {
            placeholder += ` (${optionsCount})`;
        }

        setDropdownLocalPlaceHolder(placeholder);
        setFilterPlaceHolder(filterPlaceholder);
    }, [entityCode, lazyLoad, optionsCount, placeholderEntity]);

    useEffect(() => {
        let localFieldErrorMessage = null;
        let fieldName = field.name;
        let apiErrors = form.status?.apiErrors || {};

        if (form.touched[fieldName]) {
            if (fieldName in apiErrors) {
                localFieldErrorMessage = apiErrors[fieldName];
            } else if (fieldName in form.errors) {
                localFieldErrorMessage = form.errors[fieldName];
            }
        }

        setFieldErrorMessage(localFieldErrorMessage);
    }, [field.name, form.touched, form.errors, form.status?.apiErrors]);

    const onFilter = (e) => {
        if (!lazyLoad) {
            return;
        }

        if (e.filter.length < searchMinLength) {
            return;
        }

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            let paginationParams = {
                page: 1,
                limit: lazyLoadRows,
                search: e.filter,
            };

            crudBaseGetList(ENTITIES_CONFIG[entityCode].apiEndpoint, paginationParams)
                .then((data) => {
                    let dropdownOptions = dropdownOptionMappers[entityCode](data.results);
                    setDropdownOptions(dropdownOptions);
                })
                .catch((error) => {
                    console.log(error);
                });

        }, lazyLoadTimeout);
    }

    return (
        <Fragment>
            <Dropdown name={field.name}
                value={field.value}
                onChange={(e) => {
                    let optionValue = e.value || null;
                    form.setFieldValue(field.name, optionValue);
                    if (entityCode === ENTITY_CITY) {
                        let stateOption = dropdownOptions.find((row) => row.items.find((item) => item.value === optionValue)) || null;
                        let stateId = null;
                        if (stateOption) {
                            stateId = stateOption.value;
                        }
                        form.setFieldValue('state_id', stateId);
                    } else if (entityCode === ENTITY_SELECT_DEPARTMENT) {
                        let department = dropdownOptions.find((row) => row.value === optionValue) || {suite_num: ''};
                        form.setFieldValue('suite_num', department.suite_num);
                    }
                }}
                onBlur={field.onBlur}
                options={dropdownOptions}
                placeholder={dropdownPlaceHolder}
                filterPlaceholder={filterPlaceHolder}
                loading={loadingState}
                onFilter={onFilter}
                filter
                showClear
                showFilterClear
                className={[
                    fieldErrorMessage && 'is-invalid',
                ].filter(Boolean).join(' ')}
                {...groupedAttributes}
            />
            {fieldErrorMessage &&
                <FormFeedback>
                    {fieldErrorMessage}
                </FormFeedback>
            }
        </Fragment>
    )
}

export default AppEntityDropdown;
