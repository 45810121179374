import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR } from 'redux/reducers/ui.reducer';
import { API_WORKFLOWS } from 'services/API_CONSTANTS';
import { crudBaseGetOne } from 'services/crudBaseService';
import WorkflowReqDefaultForm from './WorkflowReqDefaultForm';
import WorkflowReqExceptionForm from './WorkflowReqExceptionForm';


export const WorkflowReqForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [schoolName, setSchoolName] = useState('');
    const [primaryUseName, setPrimaryUseName] = useState('');

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        dispatch(enableOverlay('Loading default needs...'));
        if (recordId) {
            crudBaseGetOne(API_WORKFLOWS, recordId).then((data) => {
                setSchoolName(data.ou.ou_name);
                setPrimaryUseName(data.workflow_name);
            }).catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error when retrieving workflow data',
                    detail: Object.values(err.response.data).map((value, index) => (
                        value
                    )).join('. '),
                }));
            }).finally(() => {
                dispatch(disableOverlay());
            });
        }
    }, [dispatch, recordId]);

    return (
        <Fragment>
            <Row>
                <Col><h3>School: {schoolName} / Primary Use: {primaryUseName}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <WorkflowReqDefaultForm recordId={recordId} close={close} />
                </Col>
                <Col xs={12} md={6}>
                    <WorkflowReqExceptionForm recordId={recordId} />
                </Col>
            </Row>
        </Fragment>
    )
}

export default WorkflowReqForm;
