import { createAction } from "@reduxjs/toolkit";

// AssetType action types
export const GET_ASSET_TYPES = '[ASSET_TYPES] Getting asset types';
export const GET_ASSET_TYPES_SUCCESS = '[ASSET_TYPES] Getting asset types success';
export const RESET_ASSET_TYPE_STATE = '[ASSET_TYPES] Reset asset type state';

// AssetType actions
export const getAssetTypes = createAction(GET_ASSET_TYPES);
export const getAssetTypesSuccess = createAction(GET_ASSET_TYPES_SUCCESS);
export const resetAssetTypeState = createAction(RESET_ASSET_TYPE_STATE);
