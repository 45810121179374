import { ASSET_STATUSES, ASSET_TYPE, LOGIN_STRATEGIES_LABELS } from 'assets/static_data/backend_exports';
import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_WAP_ASSET } from 'redux/entities_config';
import { convertArrayToObject, yesNoToBoolean } from 'utils';

/* Based on requested on testing */
const importExcelRequiredColumns = [
    'Asset Name',
    'MSIT tag',
    'Asset Id',
    'Serial #',
    'Workstation Type',
    'Primary Use',
    'Make',
    'Model',
    'OS',
    'School Code',
    'Department',
    'Location',
    'Suite #',
    'Room #(*)',
    'Welcome Kiosk(*)',
    'Login Strategy(*)',
    'Wall Mount(*)',
    'Rover(*)',
    'BCA PC(*)',
];

const exportExcelColumnInfo = [
    { wch: 24 }, // Asset Name
    { wch: 12 }, // MSIT tag
    { wch: 18 }, // Asset Id
    { wch: 20 }, // Serial #
    { wch: 40 }, // Workstation Type
    { wch: 60 }, // Primary Use
    { wch: 50 }, // Make
    { wch: 50 }, // Model
    { wch: 40 }, // OS
    { wch: 20 }, // School Code
    { wch: 40 }, // Department
    { wch: 40 }, // Location
    { wch: 10 }, // Suite #
    { wch: 10 }, // Room #
    { wch: 12 }, // Welcome Kiosk
    { wch: 20 }, // Login Strategy
    { wch: 12 }, // Wall Mount
    { wch: 12 }, // Rover
    { wch: 12 }, // BCA PC
];


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let loginStrategy = null;
    for (let [key, value] of Object.entries(LOGIN_STRATEGIES_LABELS)) {
        if (typeof rowData['Login Strategy'] === 'string' && value.toLowerCase() === rowData['Login Strategy'].toLowerCase()) {
            loginStrategy = key;
            break;
        }
    }

    let apiData = {
        'asset_status': ASSET_STATUSES.ACTIVE,
        'asset_type_name': ASSET_TYPE.ASSET_TYPE_WORKSTATION,
        'asset_name': rowData['Asset Name'] || null,
        'msit_tag_num': rowData['MSIT tag'] || null,
        'id': rowData['Asset Id'] || null,
        'serial_num': rowData['Serial #'] || null,
        'workstation_details': {
            'workstation_type_name': rowData['Workstation Type'] || null,
            'primary_use_name': rowData['Primary Use'] || null,
        },
        // 'printer_details': {
        //     'printer_type_name': rowData['Printer Type'] || null,
        //     'ip_address': rowData['Printer IP'] || null,
        //     'printer_queue_name': rowData['Printer Queue Name'] || null,
        //     'zeno_num': rowData['Zeno number'] || null,
        // },
        'the_make_choice': rowData['Make'] || null,
        'the_model_choice': rowData['Model'] || null,
        'the_os_choice': rowData['OS'] || null,
        'ou_name': rowData['School Code'] || null,
        'department_name': rowData['Department'] || null,
        'location': rowData['Location'] || null,
        'suite_num': rowData['Suite #'] || null,
    };

    // Optional fields
    if (rowData['Welcome Kiosk(*)']) {
        apiData.workstation_details.welcome_kiosk = yesNoToBoolean(rowData['Welcome Kiosk(*)']);
    }
    if (loginStrategy) {
        apiData.workstation_details.login_strategy = loginStrategy;
    }
    if (rowData['Wall Mount(*)']) {
        apiData.workstation_details.is_proximity_unit = yesNoToBoolean(rowData['Wall Mount(*)']);
    }
    if (rowData['Rover(*)']) {
        apiData.workstation_details.is_rover = yesNoToBoolean(rowData['Rover(*)']);
    }
    if (rowData['Room #(*)']) {
        apiData.room_num = rowData['Room #(*)'];
    }
    if (rowData['BCA PC(*)']) {
        apiData.workstation_details.bca_pc = yesNoToBoolean(rowData['BCA PC(*)']);
    }

    return apiData;
};

const apiToExcelColumn = {
    'asset_status': 'Asset Status',
    'asset_type_name': 'Asset Type',
    'asset_name': 'Asset Name',
    'msit_tag': 'MSIT tag',
    'id': 'Asset Id',
    'serial_num': 'Serial #',
    'workstation_details': {
        'workstation_type_name': 'Workstation Type',
        'primary_use_name': 'Primary Use',
        'welcome_kiosk': 'Welcome Kiosk',
        'login_strategy': 'Login Strategy',
        'is_proximity_unit': 'Wall Mount',
        'is_rover': 'Rover',
        'bca_pc': 'BCA PC',
    },
    // 'printer_details': {
    //     'printer_type_name': 'Printer Type',
    //     'ip_address': 'Printer IP',
    //     'printer_queue_name': 'Printer Queue Name',
    //     'zeno_num': 'Zeno number',
    // },
    'the_make_choice': 'Make',
    'the_model_choice': 'Model',
    'the_os_choice': 'OS',
    'ou_name': 'School Code',
    'department_name': 'Department',
    'location': 'Location',
    'suite_num': 'Suite #',
    'room_num': 'Room #',
};

const foreignKeyColumns = [
    'Workstation Type',
    'Primary Use',
    'Make',
    'Model',
    'OS',
    'School Code',
    'Department',
];

export const WorkstationAndPrinterAssetsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_WAP_ASSET}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            exportExcelColumnInfo={exportExcelColumnInfo}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default WorkstationAndPrinterAssetsBulkUpdate;
