import { ASSET_TYPE } from "assets/static_data/backend_exports";

export const ASSET_TYPE_OTHER = 'Other';

export const getAssetTypeSelect = (assetTypeName) => {
     if (assetTypeName === ASSET_TYPE.ASSET_TYPE_WORKSTATION || assetTypeName === ASSET_TYPE.ASSET_TYPE_PRINTER) {
        return assetTypeName;
    } else {
        return ASSET_TYPE_OTHER;
    }
}