import { GET_USERS_LIST_BY_ADMIN_SUCCESS, RESET_ADMIN_USERS_STATE } from "redux/actions/admin.users.actions";


const initialUsersByAdminState = {
    usersList: [],
    next: null,
    previous: null,
    count: 0,
    rolesFound: [],
};


export const UsersByAdminReducer = (state = initialUsersByAdminState, action) => {
    switch (action.type) {
        case GET_USERS_LIST_BY_ADMIN_SUCCESS:
            return {
                ...state,
                usersList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            };
        case RESET_ADMIN_USERS_STATE:
            return initialUsersByAdminState;
        default:
            return state;
    }
}
