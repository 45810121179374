import { createAction } from "@reduxjs/toolkit";

// Workflow Req Category action types
export const GET_WORKFLOW_REQ_CATEGORY = '[WORKFLOW_REQ_CATEGORY] Getting workflow req categories';
export const GET_WORKFLOW_REQ_CATEGORY_SUCCESS = '[WORKFLOW_REQ_CATEGORY] Get workflow req categories success';
export const RESET_WORKFLOW_REQ_CATEGORY_STATE = '[WORKFLOW_REQ_CATEGORY] Reset workflow req category state';

// Workflow Req Category actions
export const getWorkflowReqCategory = createAction(GET_WORKFLOW_REQ_CATEGORY);
export const getWorkflowReqCategorySuccess = createAction(GET_WORKFLOW_REQ_CATEGORY_SUCCESS);
export const resetWorkflowReqCategoryState = createAction(RESET_WORKFLOW_REQ_CATEGORY_STATE);
