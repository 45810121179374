import { Accordion, AccordionTab } from 'primereact/accordion';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Alert, Badge, Card, CardBody, CardHeader, Col, Container, Progress, Row } from 'reactstrap';
import * as XLSX from 'xlsx';

import { disableOverlay, enableOverlay } from 'redux/actions/ui.actions';
import { ENTITIES_CONFIG, INITIAL_ROWS_PER_PAGE } from 'redux/entities_config';
import { entityDataImport } from 'services/bulkUpdateService';
import { saveAsExcelFile, strToLowercaseUnderscore } from 'utils';

import 'styles/datatables.scss';
import 'styles/list_components.scss';
import BulkUpdateErrors from 'components/ui/BulkUpdateErrors';

const IMPORT_BLOCK_SIZE = 25;
export const IMPORT_MULTIPLE_ENTITY_SEPARATOR = ';';
export const TEMPLATE_SELECTOR_COLUMN = 'Template';

const IMPORT_STATUS_IN_QUEUE = 'In Queue';
const IMPORT_STATUS_PROCESSING = 'Processing';
const IMPORT_STATUS_COMPLETED = 'Completed';
const IMPORT_STATUS_FAILED = 'Failed';

/*
Example of multipleTemplateSettings:
multipleTemplateSettings: {
    [TemplateKey]: {
        'selectorColumnValue': 'Workstation',
        'endPoint': API_ASSETS,
        'foreignKeyColumns': foreignKeyColumns[TEMPLATE_WORKSTATION],
        'excelRowToApiData': excelRowToApiData[TEMPLATE_WORKSTATION],
        'exportExcelColumnInfo': exportExcelColumnInfo[TEMPLATE_WORKSTATION],
        'importExcelRequiredColumns': importExcelRequiredColumns[TEMPLATE_WORKSTATION],
        'apiToExcelColumn': apiToExcelColumn,
        'userHelpText': userHelpTestFloorplanMapCode,
        'multipleEntityColumns': null,
    }
}
*/

export const BaseBulkUpdate = ({
    multipleTemplateSettings = null,
    entityCode = null, excelRowToApiData = null, importExcelRequiredColumns = null, apiToExcelColumn,
    foreignKeyColumns = null, multipleEntityColumns = null, mainTitle = null, exportExcelColumnInfo = null,
    userHelpText = null,
}) => {
    const dispatch = useDispatch();

    const [headerTitle, setHeaderTitle] = useState('');
    const [bulkUpdateEndPoint, setBulkUpdateEndPoint] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);

    const [tableRowsData, setTableRowsData] = useState([]);
    const [xlsTotalRows, setXlsTotalRows] = useState(null);
    const [xlsLoadedRows, setXlsLoadedRows] = useState(null);
    const [importRowsData, setImportRowsData] = useState([]);
    const [fileValidationMessage, setFileValidationMessage] = useState(null);
    const [currentProcessingIndex, setCurrentProcessingIndex] = useState(null);
    const [xlsProcessedRows, setXlsProcessedRows] = useState(null);
    const [isProcessingQueue, setIsProcessingQueue] = useState(false);
    const [isAllRowsProcessed, setIsAllRowsProcessed] = useState(false);
    const [rowsStatusFailedExist, setRowsStatusFailedExist] = useState(false);
    const [countPerStatus, setCountPerStatus] = useState({});
    const [countPerAction, setCountPerAction] = useState({});

    const [nameSingular, setNameSingular] = useState(null);
    const [autoDetectedTemplateCode, setAutoDetectedTemplateCode] = useState(null);
    const [fromApiToExcelColumn, setFromApiToExcelColumn] = useState(apiToExcelColumn);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 400);

    useEffect(() => {
        let entityConfig = null;
        if (entityCode) {
            entityConfig = ENTITIES_CONFIG[entityCode];
            setBulkUpdateEndPoint(entityConfig.apiEndpoint);
            setNameSingular(entityConfig.nameSingular);
        }

        if (mainTitle) {
            setHeaderTitle(mainTitle);
        } else if (entityConfig) {
            setHeaderTitle(entityConfig.namePlural + ' Bulk Add/Update');
        }
    }, [entityCode, mainTitle]);

    useEffect(() => {
        setFilters({
            ...filters,
            global: {
                ...filters.global,
                value: globalFilterDebouncedValue
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilterDebouncedValue]);

    useEffect(() => {
        if (isProcessingQueue && currentProcessingIndex != null) {
            let localCountPerStatus = {
                [IMPORT_STATUS_FAILED]: 0,
                [IMPORT_STATUS_COMPLETED]: 0,
                ...countPerStatus,
            };
            let localCountPerAction = {
                ...countPerAction,
            };
            let tableRowsDataCopy = [...tableRowsData];

            let apiData = [];
            const importRowsDataCount = importRowsData.length;
            let lastIndex = importRowsDataCount;
            for (let i = currentProcessingIndex; i < importRowsDataCount; i++) {
                if (apiData.length >= IMPORT_BLOCK_SIZE) {
                    break;
                }
                lastIndex = i;
                if (![IMPORT_STATUS_IN_QUEUE, IMPORT_STATUS_FAILED].includes(tableRowsDataCopy[i].import_status)) {
                    continue;
                }

                let rowApiData = {};
                if (excelRowToApiData) {
                    rowApiData = excelRowToApiData(importRowsData[i]);
                } else if (multipleTemplateSettings) {
                    rowApiData = multipleTemplateSettings[autoDetectedTemplateCode].excelRowToApiData(importRowsData[i]);
                }
                rowApiData.queue_item_identifier = tableRowsDataCopy[i].queue_item_identifier;
                apiData.push(rowApiData);

                tableRowsDataCopy[i].import_status = IMPORT_STATUS_PROCESSING;
                tableRowsDataCopy[i].import_errors = null;
                tableRowsDataCopy[i].import_action = '';
            }

            setTableRowsData(tableRowsDataCopy);

            entityDataImport(bulkUpdateEndPoint, apiData)
                .then((res) => {
                    let tableRowsDataCopy = [...tableRowsData];

                    for (let i = 0; i < res.length; i++) {
                        let row = res[i];
                        let rowIndex = tableRowsDataCopy.findIndex((item) => item.queue_item_identifier === row.queue_item_identifier);

                        if (rowIndex === -1) {
                            continue;
                        }

                        if (row.queue_action in localCountPerAction) {
                            localCountPerAction[row.queue_action]++;
                        } else {
                            localCountPerAction[row.queue_action] = 1;
                        }

                        tableRowsDataCopy[rowIndex].import_action = row.queue_action;
                        if (row.queue_success === 'ok') {
                            tableRowsDataCopy[rowIndex].import_status = IMPORT_STATUS_COMPLETED;
                            localCountPerStatus[IMPORT_STATUS_COMPLETED]++;
                        } else {
                            tableRowsDataCopy[rowIndex].import_status = IMPORT_STATUS_FAILED;
                            delete row.queue_success;
                            delete row.queue_action;
                            delete row.queue_item_identifier
                            tableRowsDataCopy[rowIndex].import_errors = { ...row };
                            setRowsStatusFailedExist(true);
                            localCountPerStatus[IMPORT_STATUS_FAILED]++;
                        }
                    }

                    setTableRowsData(tableRowsDataCopy);
                    if (lastIndex >= (importRowsData.length - 1)) {
                        setIsProcessingQueue(false);
                        setCurrentProcessingIndex(null);
                        setIsAllRowsProcessed(true);
                        setXlsProcessedRows(tableRowsDataCopy.length);
                    } else {
                        setCurrentProcessingIndex(lastIndex + 1);
                        setXlsProcessedRows(lastIndex + 1);
                    }
                    setCountPerStatus(localCountPerStatus);
                    setCountPerAction(localCountPerAction);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProcessingIndex, isProcessingQueue]);

    const onFileUpload = ({ files, options }) => {
        const file = files[0];
        const reader = new FileReader();

        let fileRowsData = [];
        let toImportRowsData = [];
        dispatch(enableOverlay('Loading xls file ...'));

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary', dense: true });
            const wsName = wb.SheetNames[0];
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            let localRequiredColumns = importExcelRequiredColumns;

            // Validate first row must contain all required columns and on the same order
            let xlsFirstRow = data[0].map((item) => item.toString().trim().toLowerCase());

            if (multipleTemplateSettings) {
                let templateColumnIndex = xlsFirstRow.indexOf(TEMPLATE_SELECTOR_COLUMN.toLowerCase());
                if (templateColumnIndex !== 0) {
                    setFileValidationMessage((
                        <p>The file you uploaded must contains a column named <b>{TEMPLATE_SELECTOR_COLUMN}</b> as the first column.</p>
                    ));
                    options.clear();
                    dispatch(disableOverlay());
                    return false;
                } else {
                    // Get the value of 2nd row 1st column
                    let templateValue = data[1][0];
                    let templateCode = null;
                    if (typeof templateValue === 'string' && templateValue.length > 0) {
                        let templateEntry = Object.entries(multipleTemplateSettings).find(([key, value]) => value.selectorColumnValue.toLowerCase() === templateValue.toLowerCase());
                        if (templateEntry) {
                            templateCode = templateEntry[0];
                        }
                    }
                    if (templateCode) {
                        localRequiredColumns = multipleTemplateSettings[templateCode].importExcelRequiredColumns;
                        setAutoDetectedTemplateCode(templateCode);
                        setBulkUpdateEndPoint(multipleTemplateSettings[templateCode].endPoint);
                        setFromApiToExcelColumn(multipleTemplateSettings[templateCode].apiToExcelColumn);
                    } else {
                        setFileValidationMessage((
                            <p>The file you uploaded contains an unknown template value <b>{templateValue}</b> on 1st row.</p>
                        ));
                        options.clear();
                        dispatch(disableOverlay());
                        return false;
                    }
                }
            }

            let missedColumns = false;
            for (let i = 0; i < localRequiredColumns.length; i++) {
                if (xlsFirstRow[i] !== localRequiredColumns[i].toLowerCase()) {
                    missedColumns = true;
                    break;
                }
            }

            // Detect which columns are missed
            let missedRequiredColumns = localRequiredColumns.filter(column => !xlsFirstRow.includes(column.toLowerCase()));

            if (missedColumns) {
                setFileValidationMessage((
                    <Fragment>
                        <p>The file you uploaded is missing one or more required columns, or the columns are not in the correct order.</p>
                        {missedRequiredColumns.length > 0 &&
                            <p>Missing columns: <b>{missedRequiredColumns.join(', ')}</b></p>
                        }
                    </Fragment>
                ));
                options.clear();
                dispatch(disableOverlay());
                return false;
            } else {
                setFileValidationMessage(null);
            }

            setXlsTotalRows(data.length - 1); // -1 for the header row

            let firstRow = true;
            let queueItemIdentifier = 2; // Start from 2 to align with the row number in excel file
            data.forEach((row) => {
                if (firstRow) {
                    firstRow = false;
                    return;
                }
                fileRowsData.push({
                    import_status: IMPORT_STATUS_IN_QUEUE,
                    row_label: row.slice(0, 4).join(', ') + ' ...',
                    import_errors: null,
                    import_action: '',
                    queue_item_identifier: queueItemIdentifier
                });
                queueItemIdentifier++;

                toImportRowsData.push(row);
                // TODO: this event handler block the UI thread, it needs to be refactored
                // if (toImportRowsData.length >= IMPORT_BLOCK_SIZE) {
                //     setXlsLoadedRows(toImportRowsData.length);
                // }
            });

            setTableRowsData(fileRowsData);
            setImportRowsData(toImportRowsData);
            setXlsLoadedRows(toImportRowsData.length);
            dispatch(disableOverlay());
            options.clear();
        };

        reader.readAsBinaryString(file);

        return true;
    };

    const exportTemplateExcel = (templateSetting, e) => {
        e.preventDefault();
        e.stopPropagation();
        import('xlsx').then((xlsx) => {
            let dataRowsForExport;

            // If activeRecord is an object, then we are editing a record, so we export that record
            // If activeRecord is null, then we are adding a new record, so we export an empty row
            let emptyRow = {};
            templateSetting.importExcelRequiredColumns.forEach((col) => {
                emptyRow[col] = '';

                if (col.toLowerCase() === TEMPLATE_SELECTOR_COLUMN.toLowerCase()) {
                    emptyRow[col] = templateSetting.selectorColumnValue;
                }
            });
            dataRowsForExport = [emptyRow];

            const worksheet = xlsx.utils.json_to_sheet(dataRowsForExport, { dense: true });

            if (templateSetting.exportExcelColumnInfo) {
                worksheet['!cols'] = templateSetting.exportExcelColumnInfo;
            }

            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            let exportFilename = strToLowercaseUnderscore(templateSetting.selectorColumnValue) + '_template';
            saveAsExcelFile(exportFilename, excelBuffer);
        });
    };

    const tabHeaderTemplate = (templateSetting) => (
        <Fragment>
            <span style={{ top: '0.8rem', position: 'relative' }}>{templateSetting.selectorColumnValue} Template Requirements</span>
            <Button label='XLS Template' icon='ri-download-2-line' severity='secondary' className='ms-2 float-end' onClick={(e) => exportTemplateExcel(templateSetting, e)} />
        </Fragment>
    );

    const dynamicTemplateTab = (tabSettings) => {
        return Object.entries(tabSettings).map(([key, templateSetting]) => (
            <AccordionTab key={key} header={tabHeaderTemplate(templateSetting)}>
                <p className="m-0">
                    Upload Excel File with a header row containing columns:
                </p>
                <p className='fw-bold ms-2'>
                    {templateSetting.importExcelRequiredColumns.join(', ')}
                </p>
                {multipleTemplateSettings && templateSetting.selectorColumnValue &&
                    <p>
                        The column <b>{TEMPLATE_SELECTOR_COLUMN}</b> must contain value <b>{templateSetting.selectorColumnValue}</b> for each row.
                    </p>
                }
                {templateSetting.foreignKeyColumns && templateSetting.foreignKeyColumns.length > 0 &&
                    <Fragment>
                        <p className="m-0">
                            The entities listed in these columns should be pre-existing:<br />
                        </p>
                        <p className='fw-bold ms-2'>
                            {templateSetting.foreignKeyColumns.join(', ')}
                        </p>
                    </Fragment>
                }
                {templateSetting.multipleEntityColumns && templateSetting.multipleEntityColumns.length > 0 &&
                    <Fragment>
                        <p className="m-0">
                            Below columns can contain multiple entities separated by character <b>&quot;{IMPORT_MULTIPLE_ENTITY_SEPARATOR}&quot;</b>:
                        </p>
                        <p className='fw-bold ms-2'>
                            {templateSetting.multipleEntityColumns.join(', ')}
                        </p>
                    </Fragment>
                }

                {templateSetting.userHelpText &&
                    <Fragment>
                        <p className="m-0">Observations:</p>
                        <p className='ms-2'>
                            {templateSetting.userHelpText}
                        </p>
                    </Fragment>
                }
            </AccordionTab>
        ));
    };

    const paginatorLeft = () => {
        return (
            <Fragment>
                <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                <span className="p-input-icon-left">
                    <i className="ri-search-line" style={{ marginTop: -10 }} />
                    <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                </span>
            </Fragment>
        );
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: INITIAL_ROWS_PER_PAGE, value: INITIAL_ROWS_PER_PAGE },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />
            );
        },
        CurrentPageReport: (options) => {
            return (
                <Fragment>
                    <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                        <span className="me-2">Rows: {options.totalRecords} |</span>
                        <span className="page-number-button">Page: {options.currentPage}</span>
                        <span className="page-of-button"> of </span>
                        <span className="page-number-button">{options.totalPages}</span>
                    </span>

                </Fragment>
            );
        },
    };

    const handleStartProcessQueue = () => {
        setIsProcessingQueue(true);
        setRowsStatusFailedExist(false);
        if (currentProcessingIndex == null) {
            setCurrentProcessingIndex(0);
        }
    };

    const handleStopProcessQueue = () => {
        setIsProcessingQueue(false);
    };

    return (
        <Fragment>
            <Prompt when={isProcessingQueue} message="The bulk import is still in progress. Are you sure you want to leave this page?" />
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">{headerTitle}</h4>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row className="datatable-crud-base">
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col className='pe-3'>
                                        {fileValidationMessage &&
                                            <Alert color="warning">{fileValidationMessage}</Alert>
                                        }
                                        {tableRowsData.length === 0 &&
                                            <FileUpload
                                                name="bulk_update_file"
                                                customUpload
                                                auto
                                                uploadHandler={onFileUpload}
                                                accept=".xls,.xlsx"
                                                maxFileSize={2000000}
                                                emptyTemplate={
                                                    <Fragment>
                                                        <p className="m-0">
                                                            Drag and drop the file here to upload. {multipleTemplateSettings && 'The system will automatically identify the template from the first column\'s data.'}
                                                        </p>
                                                    </Fragment>
                                                }
                                            />
                                        }
                                        {xlsTotalRows &&
                                            <Progress color="success" className="mb-2"
                                                value={xlsLoadedRows ? (Math.round(xlsLoadedRows / xlsTotalRows * 100)) : 0}
                                                style={{ height: '2rem' }}
                                            ><span className="ms-2">{xlsLoadedRows} Rows Loaded</span>
                                            </Progress>
                                        }
                                        {tableRowsData.length > 0 &&
                                            <Fragment>
                                                <p>File successfully added to import queue.<br />
                                                    Press <b>Process Queue</b> to start sending the rows for processing. <br />
                                                    Press <b>Clear</b> to remove current rows from the queue.
                                                </p>
                                                <Progress color="success" className="animated-progress mb-3"
                                                    value={xlsProcessedRows ? (Math.round(xlsProcessedRows / tableRowsData.length * 100)) : 0}
                                                    style={{ height: '2rem' }}
                                                ><span className="ms-2">{xlsProcessedRows} Rows Processed</span>
                                                </Progress>
                                                {xlsProcessedRows &&
                                                    <Row className='mb-3'>
                                                        <Col sm={4}>
                                                            <Panel header="Status">
                                                                <Badge color="success" className='m-1 fs-6'>{IMPORT_STATUS_COMPLETED}: {countPerStatus[IMPORT_STATUS_COMPLETED] || 0}</Badge>
                                                                <Badge color="danger" className='m-1 fs-6'>{IMPORT_STATUS_FAILED}: {countPerStatus[IMPORT_STATUS_FAILED] || 0}</Badge>
                                                            </Panel>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <Panel header="Actions">
                                                                {Object.keys(countPerAction).map((key) => {
                                                                    return (
                                                                        <Badge key={key} color="info" className='m-1 fs-6'>{key}: {countPerAction[key]}</Badge>
                                                                    );
                                                                }
                                                                )}
                                                            </Panel>
                                                        </Col>

                                                    </Row>
                                                }
                                                {isAllRowsProcessed && rowsStatusFailedExist &&
                                                    <Alert color="warning">
                                                        There are rows in status <b>{IMPORT_STATUS_FAILED}</b>.
                                                        Please check the errors and retry them by pressing <b>Process Queue</b> again.
                                                        To start over press <b>Clear</b> and upload a new file.
                                                    </Alert>
                                                }
                                            </Fragment>
                                        }
                                        {multipleTemplateSettings &&
                                            <Accordion className='mt-2'>{dynamicTemplateTab(multipleTemplateSettings)}</Accordion>
                                        }
                                        {!multipleTemplateSettings &&
                                            <Accordion className='mt-2'>{dynamicTemplateTab({
                                                [entityCode]: {
                                                    selectorColumnValue: nameSingular,
                                                    importExcelRequiredColumns,
                                                    foreignKeyColumns,
                                                    exportExcelColumnInfo,
                                                    multipleEntityColumns,
                                                    userHelpText,
                                                }
                                            })}</Accordion>
                                        }
                                    </Col>
                                    <Col md={3} className='ps-3 border-start'>
                                        <div className="d-grid gap-3">
                                            <Button severity='primary'
                                                label={currentProcessingIndex == null ? 'Process Queue' : 'Continue'} icon="ri-play-line"
                                                onClick={handleStartProcessQueue} disabled={isProcessingQueue || tableRowsData.length === 0 || isAllRowsProcessed}
                                                loading={isProcessingQueue}
                                            />
                                            <Button severity='secondary'
                                                label="Stop" icon="ri-stop-line"
                                                onClick={handleStopProcessQueue} disabled={!isProcessingQueue}
                                            />
                                            <Button severity='secondary'
                                                label="Clear" icon="ri-delete-bin-line"
                                                onClick={() => {
                                                    setTableRowsData([]);
                                                    setImportRowsData([]);
                                                    setIsAllRowsProcessed(false);
                                                    setIsProcessingQueue(false);
                                                    setCurrentProcessingIndex(null);
                                                    setXlsProcessedRows(null);
                                                    setRowsStatusFailedExist(false);
                                                    setXlsTotalRows(null);
                                                    setXlsLoadedRows(null);
                                                    setCountPerStatus({});
                                                    setCountPerAction({});
                                                }}
                                                disabled={isProcessingQueue || tableRowsData.length === 0}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Divider align="left">
                                    <b>Bulk Process Queue</b>
                                </Divider>

                                <DataTable value={tableRowsData}
                                    dataKey="queue_item_identifier"
                                    rows={rowsPerPage}
                                    paginator
                                    paginatorPosition='top'
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorLeft={paginatorLeft}
                                    totalRecords={tableRowsData.length}
                                    onFilter={(e) => setFilters(e.filters)}
                                    emptyMessage="No rows in queue"
                                    filters={filters}
                                    globalFilterFields={['import_status', 'row_label']}
                                    sortField="import_status" sortOrder={-1}
                                >
                                    <Column field="queue_item_identifier" header="#" sortable style={{ width: '2rem' }} headerTooltip="Row number from the excel file" />
                                    <Column field="import_status" header="Status" sortable />
                                    <Column field="row_label" header="Row" sortable />
                                    <Column field="import_action" header="Action" sortable />
                                    <Column field="import_errors" header="Errors" body={(rowData) =>
                                        <BulkUpdateErrors backendErrors={rowData.import_errors} fromApiToExcelColumn={fromApiToExcelColumn} />
                                    } />
                                </DataTable>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default BaseBulkUpdate;
