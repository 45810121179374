import React, { useEffect } from 'react';
import { Alert, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setLastKnownVersion, setNewVersionAvailable } from 'redux/actions/ui.actions';

const VersionVerification = () => {
    const dispatch = useDispatch();
    const { lastKnownVersion, newVersionAvailable } = useSelector((state) => state.ui);

    useEffect(() => {
        const checkVersion = () => {
            fetch('/ui_version.json?r=' + new Date().getTime()) // Add timestamp to avoid caching
                .then(response => response.json())
                .then(data => {
                    if (data.commitHash !== lastKnownVersion.commitHash) {
                        dispatch(setNewVersionAvailable(true));
                        dispatch(setLastKnownVersion(data));
                    }
                })
                .catch(error => {
                    console.error('Error when checking for new version:', error);
                });
        };

        // Check version every 5 minutes
        const intervalId = setInterval(() => checkVersion(), 5 * 60 * 1000);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(intervalId);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastKnownVersion.commitHash]);

    if (newVersionAvailable) {
        return (
            <Alert color="warning" className='py-2 mx-3'>
                A new version is available. Please refresh the page.
                <Button size='sm' className='ms-2' onClick={() => window.location.reload()}>
                    <i className="mdi mdi-refresh"></i> Refresh
                </Button>
            </Alert>
        );
    }

    return null;
};

export default VersionVerification;