import { DROPDOWN_TYPES_LABELS } from 'assets/static_data/backend_exports';
import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_ASSET_TYPE_CHOICE } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './DropdownsList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let dropdownType = null;
    for (let [key, value] of Object.entries(DROPDOWN_TYPES_LABELS)) {
        if (typeof rowData['Dropdown Type'] === 'string' && value.toLowerCase() === rowData['Dropdown Type'].toLowerCase()) {
            dropdownType = key;
            break;
        }
    }

    return {
        'asset_type_name': rowData['Asset Type'],
        'parent_asset_type_choice_selector': rowData['Parent Choice'] || null,
        'dropdown_type': dropdownType,
        'choice': rowData['Dropdown Choice'],
    }
};

const apiToExcelColumn = {
    'asset_type_name': 'Asset Type',
    'parent_asset_type_choice_selector': 'Parent Choice',
    'dropdown_type': 'Dropdown Type',
    'choice': 'Dropdown Choice',
};

const foreignKeyColumns = [
    'Asset Type',
    'Parent Choice',
];

export const DropdownsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_ASSET_TYPE_CHOICE}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default DropdownsBulkUpdate;
