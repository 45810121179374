import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { setSiteTheme } from 'redux/actions/layout.actions';
import { LAYOUT_THEME_DARK, LAYOUT_THEME_LIGHT } from 'redux/reducers/layout.reducer';

export const ThemeSwitch = () => {
    const dispatch = useDispatch();
    const siteTheme = useSelector(state => state.layout.siteTheme);
    const [selectorLabel, setSelectorLabel] = useState('');

    useEffect(() => {
        if (siteTheme === LAYOUT_THEME_DARK) {
            setSelectorLabel('Dark theme');
        } else {
            setSelectorLabel('Light theme');
        }
    }, [siteTheme]);

    const toggleSiteTheme = () => {
        if (siteTheme === LAYOUT_THEME_DARK) {
            dispatch(setSiteTheme(LAYOUT_THEME_LIGHT));
        } else {
            dispatch(setSiteTheme(LAYOUT_THEME_DARK));
        }
    }

    return (
        <div className="form-check form-switch">
            <Input type="checkbox" className="form-check-input theme-choice" id="checkbox_theme"
                checked={siteTheme === LAYOUT_THEME_DARK}
                onChange={() => toggleSiteTheme()} />
            <Label className="form-check-label text-secondary" htmlFor="checkbox_theme">{selectorLabel}</Label>
        </div>
    );
}

export default ThemeSwitch;
