import { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import logo from 'assets/images/infinity_app_logo.png';
import { OnBoardingCarousel } from 'components/onboarding/OnBoardingCarouselComponent';
import { ThemeSwitch } from 'components/ui/ThemeSwitch';
import UniversityHeader from 'components/ui/UniversityHeader';
import Footer from 'theme/components/layout/Footer';

import '../../styles/OnBoarding.css';

const universityHeaderFooter = process.env.REACT_APP_UNIVERSITY_HEADER_FOOTER === 'true';

export const OnBoardingLayout = ({ contentTitle, children }) => {
    return (
        <Fragment>
            {universityHeaderFooter &&
                <UniversityHeader />
            }
            <Container fluid className={universityHeaderFooter ? 'university-container-padding' : 'custom-container-padding'}>
                <Card className="w-100 mb-0">
                    <CardBody>
                        <Row>
                            <Col xs="12" md="8" xl="9">
                                <div className="row justify-content-center on-boarding-login-section mb-3">
                                    <img className='rounded' src={logo} alt={process.env.REACT_APP_SITE_TITLE} />
                                </div>
                                <Row>
                                    <Col className="text-center">
                                        <h4>{contentTitle}</h4>
                                    </Col>
                                </Row>

                                {children}

                            </Col>
                            <Col xs="6" md="4" xl="3" className='d-none d-md-block'>
                                <Row>
                                    <Col>
                                        <OnBoardingCarousel />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <ThemeSwitch />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <footer className="on-boarding-footer">
                <Footer />
            </footer>
        </Fragment>
    )
};

export default OnBoardingLayout;
