import { LOGIN_STRATEGIES_LABELS } from 'assets/static_data/backend_exports';
import { CrudBaseList } from 'components/base/CrudBaseList';
import AssetNotes from 'components/ui/AssetNotes';
import AssetTags from 'components/ui/AssetTags';
import { ENTITY_WORKSTATION_WO_PRIMARY_USE } from 'redux/entities_config';
import { booleanToYesNo, buildPrinterDescription } from 'utils';
import { AssetForm } from '../all_assets/AssetForm';


const dataTableColumns = [
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'building.street',
        header: 'Street',
        sortable: true,
    },
    {
        field: 'suite_num',
        header: 'Suite #',
        sortable: true,
    },
    {
        field: 'location',
        header: 'Location',
        sortable: true,
    },
    {
        field: 'room',
        header: 'Room',
        sortable: true,
    },
    {
        field: 'id',
        header: 'Asset ID',
        sortable: true,
    },
    /*
    {
        field: 'asset_type',
        header: 'Asset Type',
        sortable: true,
    },
    */
    {
        field: 'workstation.workstation_type.workstation_type_name',
        header: 'Workstation Type',
        sortable: true,
    },
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        header: 'Tags',
        body: (rowData) => (
            <AssetTags asset={rowData} />
        ),
    },
    {
        field: 'the_make.choice',
        header: 'Make',
        sortable: true,
    },
    {
        field: 'the_model.choice',
        header: 'Model',
        sortable: true,
    },
    {
        field: 'the_os.choice',
        header: 'OS',
        sortable: true,
    },
    {
        field: 'workstation.ip_address',
        header: 'Workstation IP',
        sortable: true,
    },
    {
        field: 'workstation.rover',
        header: 'Rover',
        sortable: true,
        body: (rowData) => (
            rowData.workstation ? booleanToYesNo(rowData.workstation.rover) : ''
        ),
    },
    {
        field: 'workstation.proximity_unit',
        header: 'Wall Mount',
        sortable: true,
        body: (rowData) => (
            rowData.workstation ? booleanToYesNo(rowData.workstation.proximity_unit) : ''
        ),
    },
    {
        field: 'workstation.bca_pc',
        header: 'BCA PC',
        sortable: true,
        body: (rowData) => (
            rowData.workstation ? booleanToYesNo(rowData.workstation.bca_pc) : ''
        ),
    },
    {
        field: 'workstation.welcome_kiosk',
        header: 'Welcome Kiosk',
        sortable: true,
        body: (rowData) => (
            rowData.workstation ? booleanToYesNo(rowData.workstation.welcome_kiosk) : ''
        ),
    },
];

const dataTableExpansionDefinition = [
    {
        field: 'workstation.default_printer_plain_paper.asset_name',
        header: 'Default Plain Paper Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_plain_paper) : ''
        ),
    },
    {
        field: 'workstation.default_printer_wristbands.asset_name',
        header: 'Default Wristbands Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_wristbands) : ''
        ),
    },
    {
        field: 'workstation.default_printer_baby_wristbands.asset_name',
        header: 'Default Baby Wristbands Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_baby_wristbands) : ''
        ),
    },
    {
        field: 'workstation.default_printer_pharm_labels.asset_name',
        header: 'Default Pharmacy Labels Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_pharm_labels) : ''
        ),
    },
    {
        field: 'workstation.default_printer_labels.asset_name',
        header: 'Default Labels Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_labels) : ''
        ),
    },
    {
        field: 'workstation.default_printer_scripts.asset_name',
        header: 'Default Scripts Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_scripts) : ''
        ),
    },
    {
        field: 'workstation.default_printer_insurance_form.asset_name',
        header: 'Default Insurance Form Printer',
        body: (rowData) => (
            rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_insurance_form) : ''
        ),
    },
    {
        field: 'workstation.login_strategy',
        header: 'Login Strategy',
        body: (rowData) => (
            rowData.workstation ? LOGIN_STRATEGIES_LABELS[rowData.workstation.login_strategy] : ''
        ),
    },
    {
        field: 'notes',
        header: 'Notes',
        body: (rowData) => (
            <AssetNotes notes={rowData.notes} />
        ),
    },
];

const exportExcelFieldMap = (rowData) => {
    return {
        'School': rowData.ou.ou_name,
        'Department': rowData.department ? rowData.department.department_name : '',
        'Street': rowData.building.street ? rowData.building.street : '',
        'Suite #': rowData.suite_num,
        'Location': rowData.location,
        'Room': rowData.room,
        'Asset Id': rowData.id,
        'Asset Type': rowData.asset_type,
        'Workstation Type': rowData.workstation?.workstation_type?.workstation_type_name,
        'Name': rowData.asset_name,
        'Serial #': rowData.serial_num,
        'CAM Tag': rowData.cam_tag,
        'MSIT Tag': rowData.msit_tag,
        'Make': rowData.the_make.choice,
        'Model': rowData.the_model.choice,
        'OS': rowData.the_os,
        'Workstation IP': rowData.workstation?.ip_address,
        'Rover': booleanToYesNo(rowData.workstation?.rover),
        'Wall Mount': booleanToYesNo(rowData.workstation?.proximity_unit),
        'BCA PC': booleanToYesNo(rowData.workstation?.bca_pc),
        'Welcome Kiosk': booleanToYesNo(rowData.workstation?.welcome_kiosk),
        'Login Strategy': rowData.workstation ? LOGIN_STRATEGIES_LABELS[rowData.workstation.login_strategy] : '',
        'Default Plain Paper Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_plain_paper) : '',
        'Default Wristbands Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_wristbands) : '',
        'Default Baby Wristbands Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_baby_wristbands) : '',
        'Default Pharmacy Labels Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_pharm_labels) : '',
        'Default Labels Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_labels) : '',
        'Default Scripts Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_scripts) : '',
        'Default Insurance Form Printer': rowData.workstation ? buildPrinterDescription(rowData.workstation.default_printer_insurance_form) : '',
        'Notes': rowData.notes,
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 10 }, // School
    { wch: 50 }, // Department
    { wch: 40 }, // Street
    { wch: 10 }, // Suite #
    { wch: 10 }, // Location
    { wch: 10 }, // Room
    { wch: 15 }, // Asset Id
    { wch: 15 }, // Asset Type
    { wch: 15 }, // Workstation Type
    { wch: 40 }, // Name
    { wch: 15 }, // Serial #
    { wch: 15 }, // CAM Tag
    { wch: 15 }, // MSIT Tag
    { wch: 20 }, // Make
    { wch: 30 }, // Model
    { wch: 25 }, // OS
    { wch: 20 }, // Workstation IP
    { wch: 15 }, // Rover
    { wch: 15 }, // Wall Mount
    { wch: 15 }, // BCA PC
    { wch: 15 }, // Welcome Kiosk
    { wch: 15 }, // Login Strategy
    { wch: 40 }, // Default Plain Paper Printer
    { wch: 40 }, // Default Wristbands Printer
    { wch: 40 }, // Default Baby Wristbands Printer
    { wch: 40 }, // Default Pharmacy Labels Printer
    { wch: 40 }, // Default Labels Printer
    { wch: 40 }, // Default Scripts Printer
    { wch: 40 }, // Default Insurance Form Printer
    { wch: 40 }, // Notes
];


const WorkstationsWOPrimaryUseList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_WORKSTATION_WO_PRIMARY_USE}
            CrudBaseForm={AssetForm}
            dataTableColumns={dataTableColumns}
            dataTableExpansionDefinition={dataTableExpansionDefinition}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="Workstations without Primary Use"
            customEntityLabel="Workstation"
            hasCreateButton={false}
        />
    );
};

export default WorkstationsWOPrimaryUseList;
