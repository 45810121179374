import { ASSET_STATUSES, ASSET_TYPE, LOGIN_STRATEGIES_LABELS, PRINTER_TYPE_NETWORK } from 'assets/static_data/backend_exports';
import { userHelpOptionalColumns } from 'assets/static_data/common_constants';
import { BaseBulkUpdate, TEMPLATE_SELECTOR_COLUMN } from 'components/base/BaseBulkUpdate';
import { API_ASSETS } from 'services/API_CONSTANTS';
import { convertArrayToObject, yesNoToBoolean } from 'utils';

const TEMPLATE_WORKSTATION = 'workstation';
const TEMPLATE_PRINTER = 'printer';
const TEMPLATE_OTHER_ASSET = 'other_asset';

const importExcelRequiredColumns = {
    [TEMPLATE_WORKSTATION]: [
        TEMPLATE_SELECTOR_COLUMN,
        'Asset Name',
        'MSIT tag',
        'Asset Id',
        'Serial #',
        'Workstation Type',
        'Primary Use',
        'Make',
        'Model',
        'Department',
        'Location',
        'Suite #',
        'BCA Workstation',
        'DEP Number(*)',
        'School Code(*)',
        'OS(*)',
        'Welcome Kiosk(*)',
        'Login Strategy(*)',
        'Wall Mount(*)',
        'Rover(*)',
        'Room #(*)',
    ],
    [TEMPLATE_PRINTER]: [
        TEMPLATE_SELECTOR_COLUMN,
        'Asset Name',
        'MSIT tag',
        'Serial #',
        'Make',
        'Model',
        'Department',
        'Location',
        'Suite #',
        'BCA Printer',
        'Parent MSIT tag(*)',
        'Zeno number(*)',
        'School Code(*)',
        'Room #(*)',
        'Asset Id(*)',
    ],
    [TEMPLATE_OTHER_ASSET]: [
        TEMPLATE_SELECTOR_COLUMN,
        'Asset type',
        'MSIT tag',
        'Serial #',
        'Make',
        'Model',
        'Department',
        'Location',
        'Suite #',
        'Asset Name(*)',
        'Parent MSIT tag(*)',
        'School Code(*)',
    ],
};

const excelRowToApiData = {
    [TEMPLATE_WORKSTATION]: (rowDataArray) => {
        let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns[TEMPLATE_WORKSTATION]);

        let loginStrategy = null;
        for (let [key, value] of Object.entries(LOGIN_STRATEGIES_LABELS)) {
            if (typeof rowData['Login Strategy'] === 'string' && value.toLowerCase() === rowData['Login Strategy'].toLowerCase()) {
                loginStrategy = key;
                break;
            }
        }

        let apiData = {
            'asset_status': ASSET_STATUSES.ACTIVE,
            'asset_type_name': ASSET_TYPE.ASSET_TYPE_WORKSTATION,
            'asset_name': rowData['Asset Name'] || null,
            'msit_tag_num': rowData['MSIT tag'] || null,
            'id': rowData['Asset Id'] || null,
            'serial_num': rowData['Serial #'] || null,
            'workstation_details': {
                'workstation_type_name': rowData['Workstation Type'] || null,
                'primary_use_name': rowData['Primary Use'] || null,
                'bca_pc': yesNoToBoolean(rowData['BCA Workstation']),
            },
            'the_make_choice': rowData['Make'] || null,
            'the_model_choice': rowData['Model'] || null,
            'department_name': rowData['Department'] || null,
            'location': rowData['Location'] || null,
            'suite_num': rowData['Suite #'] || null,
        };

        // Optional fields
        if (rowData['DEP Number(*)']) {
            apiData.department_number = rowData['DEP Number(*)'];
        }
        if (rowData['School Code(*)']) {
            apiData.ou_name = rowData['School Code(*)'];
        }
        if (rowData['OS(*)']) {
            apiData.the_os_choice = rowData['OS(*)'];
        }
        if (rowData['Welcome Kiosk(*)']) {
            apiData.workstation_details.welcome_kiosk = yesNoToBoolean(rowData['Welcome Kiosk(*)']);
        }
        if (loginStrategy) {
            apiData.workstation_details.login_strategy = loginStrategy;
        }
        if (rowData['Wall Mount(*)']) {
            apiData.workstation_details.is_proximity_unit = yesNoToBoolean(rowData['Wall Mount(*)']);
        }
        if (rowData['Rover(*)']) {
            apiData.workstation_details.is_rover = yesNoToBoolean(rowData['Rover(*)']);
        }
        if (rowData['Room #(*)']) {
            apiData.room_num = rowData['Room #(*)'];
        }

        return apiData;
    },
    [TEMPLATE_PRINTER]: (rowDataArray) => {
        let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns[TEMPLATE_PRINTER]);

        let apiData = {
            'asset_status': ASSET_STATUSES.ACTIVE,
            'asset_type_name': ASSET_TYPE.ASSET_TYPE_PRINTER,
            'asset_name': rowData['Asset Name'] || null,
            'msit_tag_num': rowData['MSIT tag'] || null,
            'serial_num': rowData['Serial #'] || null,
            'the_make_choice': rowData['Make'] || null,
            'the_model_choice': rowData['Model'] || null,
            'department_name': rowData['Department'] || null,
            'location': rowData['Location'] || null,
            'suite_num': rowData['Suite #'] || null,
            'printer_details': {
                'printer_type_name': PRINTER_TYPE_NETWORK,
                'bca_printer': yesNoToBoolean(rowData['BCA Printer']),
            },
        };

        // Optional fields
        if (rowData['Parent MSIT tag(*)']) {
            apiData.parent_asset_msit_tag = rowData['Parent MSIT tag(*)'];
        }
        if (rowData['Zeno number(*)']) {
            apiData.printer_details.zeno_number = rowData['Zeno number(*)'];
        }
        if (rowData['School Code(*)']) {
            apiData.ou_name = rowData['School Code(*)'];
        }
        if (rowData['Room #(*)']) {
            apiData.room_num = rowData['Room #(*)'];
        }
        if (rowData['Asset Id(*)']) {
            apiData.id = rowData['Asset Id(*)'];
        }

        return apiData;
    },
    [TEMPLATE_OTHER_ASSET]: (rowDataArray) => {
        let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns[TEMPLATE_OTHER_ASSET]);

        let apiData = {
            'asset_status': ASSET_STATUSES.ACTIVE,
            'asset_type_name': rowData['Asset type'] || null,
            'msit_tag_num': rowData['MSIT tag'] || null,
            'serial_num': rowData['Serial #'] || null,
            'the_make_choice': rowData['Make'] || null,
            'the_model_choice': rowData['Model'] || null,
            'department_name': rowData['Department'] || null,
            'location': rowData['Location'] || null,
            'suite_num': rowData['Suite #'] || null,
        };

        // Optional fields
        if (rowData['Asset Name(*)']) {
            apiData.asset_name = rowData['Asset Name(*)'];
        }
        if (rowData['Parent MSIT tag(*)']) {
            apiData.parent_asset_msit_tag = rowData['Parent MSIT tag(*)'];
        }
        if (rowData['School Code(*)']) {
            apiData.ou_name = rowData['School Code(*)'];
        }

        return apiData;
    },
};

const apiToExcelColumn = {
    'asset_name': 'Asset Name',
    'msit_tag': 'MSIT tag',
    'id': 'Asset Id',
    'serial_num': 'Serial #',
    'workstation_details': {
        'workstation_type_name': 'Workstation Type',
        'primary_use_name': 'Primary Use',
        'bca_pc': 'BCA Workstation',
        'welcome_kiosk': 'Welcome Kiosk',
        'is_proximity_unit': 'Wall Mount',
        'is_rover': 'Rover',
        'login_strategy': 'Login Strategy',
    },
    'the_make_choice': 'Make',
    'the_model_choice': 'Model',
    'the_os_choice': 'OS',
    'ou_name': 'School Code',
    'department_name': 'Department',
    'department_number': 'DEP Number',
    'location': 'Location',
    'suite_num': 'Suite #',
    'room_num': 'Room #',
};

const foreignKeyColumns = {
    [TEMPLATE_WORKSTATION]: [
        'Workstation Type',
        'Primary Use',
        'Make',
        'Model',
        'OS',
        'Department',
        'School Code',
    ],
    [TEMPLATE_PRINTER]: [
        'Workstation Type',
        'Primary Use',
        'Make',
        'Model',
        'OS',
        'Department',
        'School Code',
    ],
    [TEMPLATE_OTHER_ASSET]: [
        'Make',
        'Model',
        'Department',
        'School Code',
    ],
};

/*
        TEMPLATE_SELECTOR_COLUMN,
        'Asset Name',
        'MSIT tag',
        'Asset Id',
        'Serial #',
        'Workstation Type',
        'Primary Use',
        'Make',
        'Model',
        'Department',
        'Location',
        'Suite #',
        'BCA Workstation',
        'School',
        'OS',
        'Welcome Kiosk',
        'Login Strategy',
        'Wall Mount',
        'Rover',
        'Room #',
*/

const exportExcelColumnInfo = {
    [TEMPLATE_WORKSTATION]: [
        { wch: 18 }, // Template
        { wch: 24 }, // Asset Name
        { wch: 12 }, // MSIT tag
        { wch: 18 }, // Asset Id
        { wch: 20 }, // Serial #
        { wch: 40 }, // Workstation Type
        { wch: 60 }, // Primary Use
        { wch: 50 }, // Make
        { wch: 50 }, // Model
        { wch: 40 }, // Department
        { wch: 40 }, // Location
        { wch: 10 }, // Suite #
        { wch: 12 }, // BCA Workstation
        { wch: 20 }, // School
        { wch: 40 }, // OS
        { wch: 12 }, // Welcome Kiosk
        { wch: 20 }, // Login Strategy
        { wch: 12 }, // Wall Mount
        { wch: 12 }, // Rover
        { wch: 10 }, // Room #
    ],
    [TEMPLATE_PRINTER]: [
        { wch: 18 }, // Template
        { wch: 24 }, // Asset Name
        { wch: 12 }, // MSIT tag
        { wch: 18 }, // Asset Id
        { wch: 20 }, // Serial #
        { wch: 40 }, // Workstation Type
        { wch: 60 }, // Primary Use
        { wch: 50 }, // Make
        { wch: 50 }, // Model
        { wch: 40 }, // Department
        { wch: 40 }, // Location
        { wch: 10 }, // Suite #
        { wch: 12 }, // BCA Workstation
        { wch: 20 }, // School
        { wch: 40 }, // OS
        { wch: 12 }, // Welcome Kiosk
        { wch: 20 }, // Login Strategy
        { wch: 12 }, // Wall Mount
        { wch: 12 }, // Rover
        { wch: 10 }, // Room #
    ],
    [TEMPLATE_OTHER_ASSET]: [
        { wch: 18 }, // Template
        { wch: 24 }, // Asset Name
        { wch: 12 }, // MSIT tag
        { wch: 18 }, // Asset Id
        { wch: 20 }, // Serial #
        { wch: 40 }, // Workstation Type
        { wch: 60 }, // Primary Use
        { wch: 50 }, // Make
        { wch: 50 }, // Model
        { wch: 40 }, // Department
        { wch: 40 }, // Location
        { wch: 10 }, // Suite #
        { wch: 12 }, // BCA Workstation
        { wch: 20 }, // School
        { wch: 40 }, // OS
        { wch: 12 }, // Welcome Kiosk
        { wch: 20 }, // Login Strategy
        { wch: 12 }, // Wall Mount
        { wch: 12 }, // Rover
        { wch: 10 }, // Room #
    ],
};

export const WorkstationPrinterOtherAssetsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            multipleTemplateSettings={{
                [TEMPLATE_WORKSTATION]: {
                    'selectorColumnValue': 'Workstation',
                    'endPoint': API_ASSETS,
                    'foreignKeyColumns': foreignKeyColumns[TEMPLATE_WORKSTATION],
                    'excelRowToApiData': excelRowToApiData[TEMPLATE_WORKSTATION],
                    'exportExcelColumnInfo': exportExcelColumnInfo[TEMPLATE_WORKSTATION],
                    'importExcelRequiredColumns': importExcelRequiredColumns[TEMPLATE_WORKSTATION],
                    'apiToExcelColumn': apiToExcelColumn,
                    'userHelpText': userHelpOptionalColumns,
                    'multipleEntityColumns': null,
                },
                [TEMPLATE_PRINTER]: {
                    'selectorColumnValue': 'Printer',
                    'endPoint': API_ASSETS,
                    'foreignKeyColumns': foreignKeyColumns[TEMPLATE_PRINTER],
                    'excelRowToApiData': excelRowToApiData[TEMPLATE_PRINTER],
                    'exportExcelColumnInfo': exportExcelColumnInfo[TEMPLATE_PRINTER],
                    'importExcelRequiredColumns': importExcelRequiredColumns[TEMPLATE_PRINTER],
                    'apiToExcelColumn': apiToExcelColumn,
                    'userHelpText': userHelpOptionalColumns,
                    'multipleEntityColumns': null,
                },
                [TEMPLATE_OTHER_ASSET]: {
                    'selectorColumnValue': 'Other Asset',
                    'endPoint': API_ASSETS,
                    'foreignKeyColumns': foreignKeyColumns[TEMPLATE_OTHER_ASSET],
                    'excelRowToApiData': excelRowToApiData[TEMPLATE_OTHER_ASSET],
                    'exportExcelColumnInfo': exportExcelColumnInfo[TEMPLATE_OTHER_ASSET],
                    'importExcelRequiredColumns': importExcelRequiredColumns[TEMPLATE_OTHER_ASSET],
                    'apiToExcelColumn': apiToExcelColumn,
                    'userHelpText': userHelpOptionalColumns,
                    'multipleEntityColumns': null,
                },
            }}
            mainTitle={'Workstations, Printers, and Other Assets Bulk Add/Update'}
        />
    );
};

export default WorkstationPrinterOtherAssetsBulkUpdate;
