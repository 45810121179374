import React from 'react';

export const AssetNotes = ({ notes }) => {
    let notesArray = [];

    if (notes) {
        notesArray = notes.split('|')
    }

    return (
        <ul>
            {notesArray && notesArray.map((note, index) => {
                return <li key={index}>{note}</li>
            })}
        </ul>
    )
}

export default AssetNotes;
