import { MultiSelect } from 'primereact/multiselect';
import { Fragment, useEffect, useState } from 'react';
import { Button, FormFeedback } from 'reactstrap';

import { ENTITIES_CONFIG, ENTITY_SPECIALTY } from 'redux/entities_config';
import { crudBaseGetList } from 'services/crudBaseService';

const multiselectOptionMappers = {
    [ENTITY_SPECIALTY]: (results) => results.map((row) => {
        return {
            label: row.specialty_name + (row.active ? '' : ' [Inactive]'),
            value: row.id
        }
    }),
};


export const AppEntityMultiselect = ({ entityCode, field, form }) => {

    const [multiselectOptions, setMultiselectOptions] = useState([]);
    const [groupedAttributes] = useState({});

    useEffect(() => {
        if (entityCode) {
            crudBaseGetList(ENTITIES_CONFIG[entityCode].apiEndpoint)
                .then((data) => {
                    let multiselectOptions = multiselectOptionMappers[entityCode](data.results);
                    setMultiselectOptions(multiselectOptions);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [entityCode]);

    const panelFooterTemplate = (props, hide) => (
        <Button onClick={hide} type='button'>Done</Button>
    )

    return (
        <Fragment>
            <MultiSelect name={field.name}
                value={field.value}
                onChange={(e) => {
                    form.setFieldValue(field.name, e.value);
                }}
                onBlur={field.onBlur}
                options={multiselectOptions}
                placeholder={`Select a ${ENTITIES_CONFIG[entityCode].nameSingular}`}
                filter
                display='chip'
                panelFooterTemplate={panelFooterTemplate}
                className={[
                    form.touched[field.name] && form.errors[field.name] && 'is-invalid',
                ].filter(Boolean).join(' ')}
                {...groupedAttributes}
            />
            {form.touched[field.name] && form.errors[field.name] &&
                <FormFeedback>
                    {form.errors[field.name]}
                </FormFeedback>
            }
        </Fragment>
    )
}

export default AppEntityMultiselect;
