import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import { DUPLICATION_SUCCESSFULLY_FALSE, DUPLICATION_SUCCESSFULLY_TRUE } from 'assets/static_data/common_constants';
import { Dropdown } from 'primereact/dropdown';
import { getDuplicationsPerMonth, getDuplicationsPerStatus, getLastDuplicationDatePerDepartment } from 'services/statService';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';

const availableCharts = [
    { label: 'Duplications per Status', value: 'duplicationsPerStatus' },
    { label: 'Duplications per Month', value: 'duplicationsPerMonth' },
    { label: 'Last Duplication Date per Department', value: 'lastDuplicationDatePerDepartment' }
];

const wsPerDuplicationsStatusPieDefaultOptions = {
    title: {
        text: 'Duplications Status',
        align: 'center',
    },
    plotOptions: {
        pie: {
            donut: {
                size: '75%'
            }
        }
    },
    dataLabels: {
        enabled: true
    },
    legend: {
        show: true,
    },
};

const duplicationsPerMonthBareDefaultOptions = {
    title: {
        text: 'Duplications per Month',
        align: 'center',
    },
    chart: {
        toolbar: {
            show: false,
            columnWidth: '5%',
            endingShape: 'rounded'
        }
    },
    plotOptions: {
        bar: {
            barHeight: '90%',
            // distributed: true,
            // horizontal: true,
            // dataLabels: {
            //     position: 'bottom'
            // },
        }
    },
    dataLabels: {
        // enabled: true,
        // textAnchor: 'start',
        // style: {
        //     colors: ['#fff']
        // },
    },
    // legend: {
    //     show: true,
    // },
    grid: {
        // borderColor: '#f1f1f1',
    },
    // colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],
    xaxis: {
        // categories: [],
    }
};

export const WorkstationDashboard = () => {

    const [duplicationsPerStatusPieSeries, setDuplicationsPerStatusPieSeries] = useState(null);
    const [duplicationsPerPieOptions, setDuplicationsPerStatusPieOptions] = useState(null);

    const [duplicationsPerMonthBarSeries, setDuplicationsPerMonthBarSeries] = useState(null);
    const [duplicationsPerMonthBarOptions, setDuplicationsPerMonthBarOptions] = useState(null);

    const [lastDuplicationDatePerDepartment, setLastDuplicationDatePerDepartment] = useState([]);

    const [selectedChart, setSelectedChart] = useState(null);

    useEffect(() => {
        if (!selectedChart) {
            return;
        }

        switch (selectedChart) {
            case 'duplicationsPerStatus':
                // Retrieve data for Duplication per status
                getDuplicationsPerStatus()
                    .then((responseData) => {
                        let pieLabels = [];
                        let pieSeries = [];
                        for (let result of responseData) {
                            pieLabels.push(result.successfully ? DUPLICATION_SUCCESSFULLY_TRUE : DUPLICATION_SUCCESSFULLY_FALSE);
                            pieSeries.push(result.count);
                        }

                        setDuplicationsPerStatusPieSeries(pieSeries);
                        setDuplicationsPerStatusPieOptions({
                            ...wsPerDuplicationsStatusPieDefaultOptions,
                            labels: pieLabels
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting duplications per status:', error);
                    });
                break;

            case 'duplicationsPerMonth':
                // Retrieve data for Duplications per month
                getDuplicationsPerMonth()
                    .then((responseData) => {
                        let barLabels = [];
                        let barSeries = [];
                        for (let result of responseData) {
                            barLabels.push(`${result.month}/${result.year}`);
                            barSeries.push(result.count);
                        }

                        setDuplicationsPerMonthBarSeries([{
                            name: 'Duplications',
                            data: barSeries
                        }]);
                        setDuplicationsPerMonthBarOptions({
                            ...duplicationsPerMonthBareDefaultOptions,
                            xaxis: {
                                ...duplicationsPerMonthBareDefaultOptions.xaxis,
                                categories: barLabels
                            }
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting duplications per month:', error);
                    });
                break;

            case 'lastDuplicationDatePerDepartment':
                // Retrieve data for Duplications per month
                getLastDuplicationDatePerDepartment()
                    .then((responseData) => {
                        let tableRows = [];
                        for (let result of responseData) {
                            tableRows.push({
                                department: result.department,
                                lastDuplication: result.latest_duplication_date ? isoStringToFormatted(result.latest_duplication_date, FORMAT_DATETIME_SHORT) : ''
                            });
                        }

                        setLastDuplicationDatePerDepartment(tableRows);
                    })
                    .catch((error) => {
                        console.error('Error getting duplications per month:', error);
                    });
                break;
            default:
                break;
        }
    }, [selectedChart])

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">Workstation Dashboard</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Dropdown value={selectedChart} onChange={(e) => setSelectedChart(e.value)} options={availableCharts} placeholder="Select a chart" className="w-full md:w-14rem" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {duplicationsPerStatusPieSeries && selectedChart === 'duplicationsPerStatus' &&
                                        <ReactApexChart options={duplicationsPerPieOptions} series={duplicationsPerStatusPieSeries} type="donut" height='270' />
                                    }
                                    {duplicationsPerMonthBarSeries && selectedChart === 'duplicationsPerMonth' &&
                                        <ReactApexChart options={duplicationsPerMonthBarOptions} series={duplicationsPerMonthBarSeries} type="bar" height='270' />
                                    }
                                    {lastDuplicationDatePerDepartment && selectedChart === 'lastDuplicationDatePerDepartment' &&
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>Department</th>
                                                    <th>Last duplication</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lastDuplicationDatePerDepartment.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{row.department}</td>
                                                        <td>{row.lastDuplication}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default WorkstationDashboard;
