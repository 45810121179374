import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DASHBOARD, SELECT_ORGANIZATION } from 'navigation/ROUTE_CONSTANTS';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR } from 'redux/reducers/ui.reducer';
import { getSamlAcsFinish } from 'services/userService';
import { OnBoardingLayout } from './OnBoardingLayout';

import { loginSuccess } from 'redux/actions/auth.actions';
import '../../styles/OnBoarding.css';

export const SamlAuthFinish = () => {
    const dispatch = useDispatch();
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const redirectAfterLogin = useSelector(state => state.ui.redirectAfterLogin);


    useEffect(() => {
        dispatch(setNavigateTo(null));
        if (process.env.REACT_APP_MULTI_TENANT === 'true' && !activeTenant.code) {
            dispatch(setNavigateTo(SELECT_ORGANIZATION));
        }

        if (activeTenant.code) {
            dispatch(enableOverlay('Login into your account...'));
            getSamlAcsFinish(activeTenant)
                .then(data => {
                    if (data.access) {
                        dispatch(loginSuccess({
                            access: data.access,
                            refresh: data.refresh,
                            activeTenantCode: activeTenant.code
                        }));
                    }
                    if (redirectAfterLogin) {
                        dispatch(setNavigateTo(redirectAfterLogin));
                    } else {
                        dispatch(setNavigateTo(DASHBOARD));
                    }
                })
                .catch((err) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'SAML ACS Finish Error',
                        detail: err.message,
                    }));
                })
                .finally(() => {
                    dispatch(disableOverlay());
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OnBoardingLayout contentTitle="Login">
        </OnBoardingLayout>
    )
};

export default SamlAuthFinish;
