import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_ADMIN_SETTINGS_VALUE, UPDATE_ADMIN_SETTINGS_VALUE, getAdminSettingsValueSuccess, updateAdminSettingsValueSuccess } from "redux/actions/admin.settings.actions";
import { genericError } from "redux/actions/error.actions";
import { disableOverlay, enableOverlay } from "redux/actions/ui.actions";
import { getAdminSettingsValue, updateAdminSettingsValue } from "services/admin.settings.services";

/**
 * getAdminSettingsValueEffect
 */
function* getAdminSettingsValueEffect({ payload }) {
    try {
        const setting = yield call(getAdminSettingsValue, payload);
        yield put(getAdminSettingsValueSuccess(setting));
    } catch (err) {
        yield put(genericError(JSON.stringify(err)));
        yield put(disableOverlay());
    }
}

/**
 * updateAdminSettingsValueEffect
 */
function* updateAdminSettingsValueEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating admin settings...'));
        const setting = yield call(updateAdminSettingsValue, payload);
        if (setting) {
            yield put(updateAdminSettingsValueSuccess(setting));
            yield put(disableOverlay());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
        yield put(disableOverlay());
    }
}

export function* watchGetAdminSettingsValue() {
    yield takeEvery(GET_ADMIN_SETTINGS_VALUE, getAdminSettingsValueEffect);
}

export function* watchUpdateAdminSettingsValue() {
    yield takeEvery(UPDATE_ADMIN_SETTINGS_VALUE, updateAdminSettingsValueEffect);
}

function* adminSettingsSaga() {
    yield all([
        fork(watchGetAdminSettingsValue),
        fork(watchUpdateAdminSettingsValue),
    ]);
}

export default adminSettingsSaga;
