import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_WORKSTATION } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './WorkstationDefaultPrintersList';

const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'id': rowData['Workstation Asset Id'] || null,
        'default_printer_plain_paper_name': rowData['Default Plain Paper Printer'] || null,
        'default_printer_wristbands_name': rowData['Default Wristbands Printer'] || null,
        'default_printer_baby_wristbands_name': rowData['Default Baby Wristbands Printer'] || null,
        'default_printer_pharm_labels_name': rowData['Default Pharmacy Labels Printer'] || null,
        'default_printer_labels_name': rowData['Default Labels Printer'] || null,
        'default_printer_scripts_name': rowData['Default Scripts Printer'] || null,
        'default_printer_insurance_form_name': rowData['Default Insurance Form Printer'] || null,
    }
};

const apiToExcelColumn = {
    'id': 'Workstation Asset Id',
    'default_printer_plain_paper_name': 'Default Plain Paper Printer',
    'default_printer_wristbands_name': 'Default Wristbands Printer',
    'default_printer_baby_wristbands_name': 'Default Baby Wristbands Printer',
    'default_printer_pharm_labels_name': 'Default Pharmacy Labels Printer',
    'default_printer_labels_name': 'Default Labels Printer',
    'default_printer_scripts_name': 'Default Scripts Printer',
    'default_printer_insurance_form_name': 'Default Insurance Form Printer',
};

const foreignKeyColumns = [
    'Default Plain Paper Printer',
    'Default Wristbands Printer',
    'Default Baby Wristbands Printer',
    'Default Pharmacy Labels Printer',
    'Default Labels Printer',
    'Default Scripts Printer',
    'Default Insurance Form Printer',
];

export const WorkstationDefaultPrintersBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_WORKSTATION}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
            mainTitle="Default Printers Bulk Update"
        />
    );
};

export default WorkstationDefaultPrintersBulkUpdate;
