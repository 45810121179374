import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Progress, Row } from "reactstrap";

import { CreateUserCredentialsComponent } from "components/onboarding/CreateUserCredentialsComponent";
import { CreateUserDetailsComponent } from "components/onboarding/CreateUserDetailsComponent";
import { LOGIN_FORM } from "navigation/ROUTE_CONSTANTS";
import { logoutSuccess } from 'redux/actions/auth.actions';
import {
    createUser,
    createUserSuccess,
    resetUserState,
    updateUserProfile
} from "redux/actions/users.actions";
import { OnBoardingLayout } from './OnBoardingLayout';

const STEP_1 = 'Step 1';
const STEP_2 = 'Step 2';

export const CreateAccount = () => {
    const dispatch = useDispatch();
    const checkRegistration = useSelector(state => state.users.emailRegister);
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const [stepper, setStepper] = useState({ step: STEP_1, progress: 35 });
    let stepForm;
    let stepTitle;

    useEffect(() => {
        dispatch(logoutSuccess());
        dispatch(resetUserState());
        dispatch(createUserSuccess(false));
        return () => {
            dispatch(createUserSuccess(false));
        }
    }, [dispatch])

    function createNewUser(data) {
        const newUserData = {
            username: data.username,
            email: data.email,
            password: data.password,
            tenant: activeTenant,
        }
        if (stepper.step === STEP_1) {
            dispatch(createUser(newUserData));
        }
        if (stepper.step === STEP_2) {
            data = {
                ...data,
                isRegisterFlow: true,
            };
            dispatch(updateUserProfile(data));
        }
    }

    useEffect(() => {
        if (checkRegistration) {
            setStepper({ step: STEP_2, progress: 70 });
        }
    }, [checkRegistration])


    if (stepper.step === STEP_1) {
        stepTitle = 'New Account';
        stepForm = <CreateUserCredentialsComponent onSubmit={(data) => createNewUser(data)} />
    }

    if (stepper.step === STEP_2) {
        stepTitle = 'Profile Setup';
        stepForm = <CreateUserDetailsComponent onSubmit={(data) => createNewUser(data)} />
    }

    return (
        <OnBoardingLayout contentTitle={stepTitle}>
            <Row className='mt-3'>
                <Col className="text-center">
                    <h4>{stepper.step}</h4>
                </Col>
            </Row>

            <Row className='mt-3 mb-5'>
                <Col>
                    <Progress color="danger" className="w-25 mx-auto" style={{ height: "5px" }}
                        value={stepper.progress} />
                </Col>
            </Row>

            {stepForm}

            <Row className='mt-5'>
                <Col className="text-center">
                    <span className="sign-up-label">Already have an account? &nbsp;</span>
                    <Link className="sign-up-label font-weight-bold" to={LOGIN_FORM}>Login</Link>
                </Col>
            </Row>

        </OnBoardingLayout>
    )
}

export default CreateAccount;
