import { CrudBaseList } from 'components/base/CrudBaseList';
import BulkUpdateErrors from 'components/ui/BulkUpdateErrors';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { ENTITY_DUPLICATION } from 'redux/entities_config';
import { booleanToYesNo, userFullName } from 'utils';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import { apiToExcelColumn } from '../workstations/WorkstationsDuplicate';
import DuplicationHistoryToolbar from './DuplicationHistoryToolbar.js';


const dataTableColumns = [
    {
        field: 'old_workstation',
        header: 'Old Workstation',
        sortable: true,
    },
    {
        field: 'new_workstation',
        header: 'New Workstation',
        sortable: true,
    },
    {
        field: 'successfully',
        header: 'Successfully',
        sortable: true,
        align: 'center',
        body: (rowData) => (
            <StateFieldIndicator state={rowData.successfully} />
        ),
    },
    {
        field: 'batch.epic_ticket_name',
        header: 'EPIC Ticket',
        sortable: false,
    },
    {
        field: 'duplicated_by.username',
        header: 'Duplicated By',
        sortable: true,
        body: (rowData) => (
            userFullName(rowData.duplicated_by)
        ),
    },
    {
        field: 'duplication_date',
        header: 'Duplication Date',
        sortable: true,
        body: (rowData) => (
            rowData.duplication_date ? isoStringToFormatted(rowData.duplication_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
];

const dataTableExpansionDefinition = [
    {
        header: 'Errors',
        body: (rowData) => (
            <BulkUpdateErrors backendErrors={rowData.errors} fromApiToExcelColumn={apiToExcelColumn} />
        ),
    },
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Old Workstation': rowData.old_workstation,
        'New Workstation': rowData.new_workstation,
        'Successfully': booleanToYesNo(rowData.successfully),
        'Duplicated By': userFullName(rowData.duplicated_by),
        'Duplication Date': rowData.duplication_date ? isoStringToFormatted(rowData.duplication_date, FORMAT_DATETIME_SHORT) : '',
        'Errors': rowData.errors,
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 30 }, // Old Workstation
    { wch: 30 }, // New Workstation
    { wch: 20 }, // Successfully
    { wch: 30 }, // Duplicated By
    { wch: 30 }, // Duplication Date
    { wch: 100 }, // Errors
];

const DuplicationHistoryList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_DUPLICATION}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            dataTableExpansionDefinition={dataTableExpansionDefinition}
            StartToolbarContent={DuplicationHistoryToolbar}
            hasCreateButton={false}
            hasEditButton={false}
            hasDeleteButton={false}
            mainTitle='Workstation Duplication History'
        />
    );
};

export default DuplicationHistoryList;
