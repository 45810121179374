import { Field, Form } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { createRole, updateRole } from 'redux/actions/roles.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { getOneRole } from 'services/roleService';
import { groupedPermissions } from 'utils/index';

import 'styles/p_multiselect.scss';


const RoleForm = ({ close, roleId }) => {
    const dispatch = useDispatch();
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);

    const [groupedPermissionsData, setGroupedPermissionsData] = useState([]);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState({
        name: '',
        description: '',
        permissions: [],
    });

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (roleId) {
            getOneRole(roleId).then((data) => {
                setInitialFormValues({
                    name: data.name,
                    description: data.description,
                    permissions: data.permissions,
                });
            })
        }
        setGroupedPermissionsData(groupedPermissions(activeTenant));
    }, [activeTenant, dispatch, roleId]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    const groupedItemTemplate = (option) => {
        return (
            <span>
                <i className='me-2 ri-shield-cross-line' style={{ verticalAlign: 'middle' }} />
                {option.label}
            </span>
        );
    };

    const panelFooterTemplate = (props, hide) => (
        <Button onClick={hide} type='button'>Done</Button>
    )

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                name: Yup.string()
                    .required('Please enter a Role name'),
                description: Yup.string()
                    .nullable()
                    .notRequired(),
                permissions: Yup.array()
            })}
            serverFieldMap={{
                name: 'name',
                description: 'description',
                permissions: 'permissions',
            }}
            onSubmit={(values) => {
                let formData = {
                    name: values.name,
                    description: values.description,
                    permissions: values.permissions,
                }

                if (roleId) {
                    formData.id = roleId;
                    dispatch(updateRole(formData));
                } else {
                    dispatch(createRole(formData));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col md={6} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="name"
                                inputPlaceHolder="Enter Role name"
                                inputLabel="Role Name"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="textarea"
                                fieldName="description"
                                inputPlaceHolder="Enter Role description"
                                inputLabel="Role Description"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ 'maxWidth': '75vw' }}>
                        <FormGroup>
                            <Label for='field_permissions'>Permissions</Label>
                            <Field name="permissions" id='field_permissions'>{
                                ({ field, form, meta }) => (
                                    <Fragment>
                                        <MultiSelect name={field.name} id='field_permissions' options={groupedPermissionsData} optionLabel='label'
                                            optionGroupLabel='label' optionGroupChildren='items' optionGroupTemplate={groupedItemTemplate}
                                            placeholder='Select Permissions' display='chip' panelFooterTemplate={panelFooterTemplate}
                                            className={[
                                                'w-full md:w-20rem',
                                                meta.touched && meta.error && 'is-invalid',
                                            ].filter(Boolean).join(' ')}
                                            filter
                                            value={field.value || []}
                                            onChange={(e) => {
                                                form.setFieldValue(field.name, e.value);
                                            }} />
                                        {meta.touched && meta.error &&
                                            <FormFeedback>
                                                {meta.error}
                                            </FormFeedback>
                                        }
                                    </Fragment>
                                )
                            }
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
            }
        </AppFormik>
    )
}

export default RoleForm;
