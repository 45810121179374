import React from "react";
import { Col, Container, Row } from "reactstrap";

import AppDetails from "components/ui/AppDetails";
import UniversityFooter from "components/ui/UniversityFooter";

const universityHeaderFooter = process.env.REACT_APP_UNIVERSITY_HEADER_FOOTER === 'true';

export const Footer = () => {
  return (
    <Container fluid>
      {universityHeaderFooter &&
        <UniversityFooter />
      }
      {!universityHeaderFooter &&
        <Row className="fs-7 mt-2">
          <Col>
            <AppDetails />
          </Col>
        </Row>
      }
    </Container>
  );
};

export default Footer;
