import { Form } from 'formik';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import 'styles/OnBoarding.css';
import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';

export const ConfirmResetPasswordComponent = ({ onSubmit }) => {

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={{
                new_password: '',
                confirm_new_password: '',
            }}
            validationSchema={Yup.object({
                new_password: Yup.string()
                    .min(8, 'New password must be at least 8 characters length')
                    .max(128, 'New password must be smaller than 128 characters length.')
                    .required('Enter new password'),
                confirm_new_password: Yup.string()
                    .test('passwords-match', 'Passwords must match', function (value) {
                        return this.parent.new_password === value
                    })
            })}
            initialErrors={{
                new_password: 'Enter new password',
            }}
            serverFieldMap={{
                password: 'new_password',
            }}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ isValid }) => (
                <Form className="mt-5 justify-content-center">
                    <FormGroup className="row justify-content-center">
                        <AppInputFieldComponent
                            fieldType="password"
                            fieldName="new_password"
                            inputPlaceHolder="New password"
                            tooltipText="Please enter your new password"
                            inputProps={{ autoComplete: 'current-password' }}
                        />
                    </FormGroup>
                    <FormGroup className="row justify-content-center">
                        <AppInputFieldComponent
                            fieldType="password"
                            fieldName="confirm_new_password"
                            inputPlaceHolder="Confirm new password"
                            tooltipText="Please confirm your new password"
                            inputProps={{ autoComplete: 'current-password' }}
                        />
                    </FormGroup>
                    <div className="row justify-content-center">
                        <button className="w-100 btn btn-infinity-primary mw-333"
                            disabled={!isValid}
                            type="submit"
                        >
                            Save
                        </button>
                    </div>
                </Form>
            )}
        </AppFormik>
    )
}

export default ConfirmResetPasswordComponent;
