import { LOGIN_STRATEGIES_LABELS } from 'assets/static_data/backend_exports';
import { userHelpAllAssetBulkUpdate } from 'assets/static_data/common_constants';
import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_ASSET } from 'redux/entities_config';
import { convertArrayToObject, yesNoToBoolean } from 'utils';
import { importExcelRequiredColumns } from './AllAssetsList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let loginStrategy = null;
    for (let [key, value] of Object.entries(LOGIN_STRATEGIES_LABELS)) {
        if (typeof rowData['Login Strategy'] === 'string' && value.toLowerCase() === rowData['Login Strategy'].toLowerCase()) {
            loginStrategy = key;
            break;
        }
    }

    return {
        'ou_name': rowData['School Code'] || null,
        'department_name': rowData['Department'] || null,
        'suite_num': rowData['Suite #'] || null,
        'location': rowData['Location'] || null,
        'room_num': rowData['Room'] || null,
        'parent_asset_id': rowData['Main Asset Id'] || null,
        'id': rowData['Asset Id'] || null,
        'asset_type_name': rowData['Asset Type'] || null,
        'asset_name': rowData['Asset Name'] || null,
        'serial_num': rowData['Serial #'] || null,
        'cam_tag_num': rowData['CAM tag'] || null,
        'msit_tag_num': rowData['MSIT tag'] || null,
        'the_make_choice': rowData['Make'] || null,
        'the_model_choice': rowData['Model'] || null,
        'the_os_choice': rowData['OS'] || null,
        'the_size_choice': rowData['Size'] || null,
        'the_resolution_choice': rowData['Resolution'] || null,
        'the_screen_orientation_choice': rowData['Screen Orientation'] || null,
        'new_notes': rowData['New Notes'] || null,
        'update_notes': rowData['Update Notes'] || null,
        'workstation_details': {
            'workstation_type_name': rowData['Workstation Type'] || null,
            'primary_use_name': rowData['Primary Use'] || null,
            'ip_address': rowData['Workstation IP'] || null,
            'is_rover': yesNoToBoolean(rowData['Rover']),
            'is_proximity_unit': yesNoToBoolean(rowData['Wall Mount']),
            'bca_pc': yesNoToBoolean(rowData['BCA PC']),
            'welcome_kiosk': yesNoToBoolean(rowData['Welcome Kiosk']),
            'login_strategy': loginStrategy,
        },
        'printer_details': {
            'printer_type_name': rowData['Printer Type'] || null,
            'printer_queue_name': rowData['Printer Queue Name'] || null,
            'ip_address': rowData['Printer IP'] || null,
            'hostname': rowData['Printer Hostname'] || null,
            'tray_1_content': rowData['Printer Tray 1 Content'] || null,
            'tray_2_content': rowData['Printer Tray 2 Content'] || null,
            'tray_3_content': rowData['Printer Tray 3 Content'] || null,
            'tray_4_content': rowData['Printer Tray 4 Content'] || null,
            'tray_5_content': rowData['Printer Tray 5 Content'] || null,
            'tray_6_content': rowData['Printer Tray 6 Content'] || null,
            'manual_tray_content': rowData['Printer Manual Tray Content'] || null,
            'comments': rowData['Printer Comments'] || null,
            'zeno_num': rowData['Zeno number'] || null,
            'bca_printer': yesNoToBoolean(rowData['BCA Printer']),
        },
    }
};

const apiToExcelColumn = {
    'ou_name': 'School Code',
    'department_name': 'Department',
    'suite_num': 'Suite #',
    'location': 'Location',
    'room_num': 'Room',
    'parent_asset_id': 'Main Asset Id',
    'id': 'Asset Id',
    'asset_type_name': 'Asset Type',
    'asset_name': 'Asset Name',
    'serial_num': 'Serial #',
    'cam_tag_num': 'CAM Tag',
    'msit_tag_num': 'MSIT Tag',
    'the_make_choice': 'Make',
    'the_model_choice': 'Model',
    'the_os': 'OS',
    'the_size': 'Size',
    'the_resolution': 'Resolution',
    'the_screen_orientation': 'Screen Orientation',
    'new_notes': 'New Notes',
    'update_notes': 'Update Notes',
    'workstation_details': {
        'workstation_type_name': 'Workstation Type',
        'primary_use_name': 'Primary Use',
        'ip_address': 'Workstation IP',
        'is_rover': 'Rover',
        'is_proximity_unit': 'Wall Mount',
        'bca_pc': 'BCA PC',
        'welcome_kiosk': 'Welcome Kiosk',
        'login_strategy': 'Login Strategy',
    },
    'printer_details': {
        'printer_type_name': 'Printer Type',
        'printer_queue_name': 'Printer Queue Name',
        'ip_address': 'Printer IP',
        'hostname': 'Printer Hostname',
        'tray_1_content': 'Printer Tray 1 Content',
        'tray_2_content': 'Printer Tray 2 Content',
        'tray_3_content': 'Printer Tray 3 Content',
        'tray_4_content': 'Printer Tray 4 Content',
        'tray_5_content': 'Printer Tray 5 Content',
        'tray_6_content': 'Printer Tray 6 Content',
        'manual_tray_content': 'Printer Manual Tray Content',
        'comments': 'Printer Comments',
        'zeno_num': 'Zeno number',
        'bca_printer': 'BCA Printer',
    },
};

const foreignKeyColumns = [
    'School Code',
    'Department',
    'Asset Type',
    'Primary Use',
    'Make',
    'Model',
    'OS',
    'Size',
    'Resolution',
    'Screen Orientation',
    'Workstation Type',
    'Primary Use',
    'Printer Type',
];

export const AllAssetsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_ASSET}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
            userHelpText={userHelpAllAssetBulkUpdate}
            mainTitle = 'All Assets Bulk Add/Update'
        />
    );
};

export default AllAssetsBulkUpdate;
