import { all, call, debounce, fork, put, takeEvery } from 'redux-saga/effects';
import { genericError } from "redux/actions/error.actions";
import {
    CREATE_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    GET_ROLES_MAP,
    SEARCH_ROLES,
    UPDATE_ROLE,
    getRoles as actionGetRoles,
    createRoleSuccess,
    getRolesMapSuccess,
    getRolesSuccess,
    resetRoleState,
    searchRolesSuccess,
    updateRoleSuccess
} from 'redux/actions/roles.actions';
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import {
    deleteRole,
    getRoles,
    patchRole,
    postRole,
} from 'services/roleService';


/**
 * getRolesEffect
 */
function* getRolesEffect({ payload }) {
    try {
        yield put(enableOverlay('Loading roles...'));
        const rolesList = yield call(getRoles, payload);
        if (rolesList) {
            yield put(getRolesSuccess(rolesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * getRolesEffect
 */
function* getRolesMapEffect() {
    try {
        const rolesList = yield call(getRoles);
        if (rolesList) {
            yield put(getRolesMapSuccess(rolesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}

/**
 * searchRolesEffect
 */
function* searchRolesEffect({ payload }) {
    try {
        yield put(enableOverlay('Searching roles...'));
        const rolesFound = yield call(getRoles, payload);
        if (rolesFound) {
            yield put(searchRolesSuccess(rolesFound));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * createRoleEffect
 */
function* createRoleEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating new role...'));
        const role = yield call(postRole, payload);
        if (role) {
            yield put(createRoleSuccess(role));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'Role successfully created!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * updateRoleEffect
 */
function* updateRoleEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating role...'));
        const role = yield call(patchRole, payload);
        if (role) {
            yield put(updateRoleSuccess(role));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'Role successfully updated!',
            }));
            yield put(resetRoleState());
            yield put(actionGetRoles());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * deleteRoleEffect
 */
function* deleteRoleEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting role...'));
        yield call(deleteRole, payload);
        yield put(setToast({
            severity: TOAST_SEVERITY_SUCCESS,
            detail: 'Role successfully deleted!',
        }));
        yield put(resetRoleState());
        yield put(actionGetRoles());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * Watchers
 */
export function* watchGetRoles() {
    yield takeEvery(GET_ROLES, getRolesEffect);
}

export function* watchSearchRoles() {
    yield debounce(600, SEARCH_ROLES, searchRolesEffect);
}

export function* watchCreateRole() {
    yield takeEvery(CREATE_ROLE, createRoleEffect);
}

export function* watchUpdateRole() {
    yield takeEvery(UPDATE_ROLE, updateRoleEffect);
}

export function* watchDeleteRole() {
    yield takeEvery(DELETE_ROLE, deleteRoleEffect);
}

export function* watchGetRolesMap() {
    yield takeEvery(GET_ROLES_MAP, getRolesMapEffect);
}

function* rolesSaga() {
    yield all([
        fork(watchGetRoles),
        fork(watchSearchRoles),
        fork(watchCreateRole),
        fork(watchUpdateRole),
        fork(watchDeleteRole),
        fork(watchGetRolesMap),
    ]);
}

export default rolesSaga;
