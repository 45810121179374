import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_OU } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './OusList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'ou_name': rowData['Code'],
        'ou_description': rowData['Name'],
        'active': rowData['Status'] === 'Active' ? true : false,
    }
};

const apiToExcelColumn = {
    'ou_name': 'Code',
    'ou_description': 'Name',
    'active': 'Status',
};

const foreignKeyColumns = [
];

export const OusBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_OU}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default OusBulkUpdate;
