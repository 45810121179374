import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import { DROPDOWN_TYPES_LABELS } from 'assets/static_data/backend_exports';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { DROPDOWNS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { entityBulkUpdatePermissions } from 'navigation/action_permissions';
import { crudBaseDeleteStart, crudBaseGetListStart, crudBaseGetOneReset, crudBaseResetState } from 'redux/actions/crud_base.actions';
import { ENTITIES_CONFIG, ENTITY_ASSET_TYPE_CHOICE, INITIAL_ROWS_PER_PAGE } from 'redux/entities_config';
import { saveAsExcelFile, strToLowercaseUnderscore } from 'utils';
import DropdownForm from './DropdownForm';

import 'styles/datatables.scss';
import 'styles/list_components.scss';

const entityCode = ENTITY_ASSET_TYPE_CHOICE;
const bulkUpdateRoute = DROPDOWNS_BULK_UPDATE;


const dataTableColumns = [
    {
        field: 'asset_type.asset_type_name',
        header: 'Asset Type',
        sortable: true,
    },
    {
        field: 'dropdown_type',
        header: 'Dropdown Type',
        sortable: true,
        body: (rowData) => (
            DROPDOWN_TYPES_LABELS[rowData.dropdown_type]
        ),
    },
    {
        field: 'choice',
        header: 'Choice',
        sortable: true,
    },
    {
        field: 'parent_asset_type_choice.choice',
        header: 'Parent Choice',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
    {
        field: 'approved_replacement',
        header: 'Approved Replacement?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.approved_replacement} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Asset Type',
    'Parent Choice',
    'Dropdown Type',
    'Dropdown Choice',
];

const exportExcelFieldMap = (rowData) => {
    let parentChoice = '';
    if (rowData.parent_asset_type_choice) {
        parentChoice = DROPDOWN_TYPES_LABELS[rowData.parent_asset_type_choice.dropdown_type] + ' = ' + rowData.parent_asset_type_choice.choice;
    }
    return {
        'Asset Type': rowData.asset_type.asset_type_name,
        'Parent Choice': parentChoice,
        'Dropdown Type': DROPDOWN_TYPES_LABELS[rowData.dropdown_type],
        'Dropdown Choice': rowData.choice,
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 30 }, // Asset Type
    { wch: 40 }, // Parent Choice
    { wch: 30 }, // Dropdown Type
    { wch: 100 }, // Dropdown Choice
];

/*
Basic export that follows columns in dataTableColumns
const exportBasicExcelFieldMap = (rowData) => {
    return {
        'Asset Type': rowData.asset_type.asset_type_name,
        'Dropdown Type': DROPDOWN_TYPES_LABELS[rowData.dropdown_type],
        'Choice': rowData.choice,
        'Parent Choice': rowData.parent_asset_type_choice.choice,
        'Is Active?': rowData.active,
        'Approved Replacement?': rowData.approved_replacement,
    }
};
*/

const DropdownsList = () => {
    const dispatch = useDispatch();
    const dt = useRef(null);
    const history = useHistory();

    const [entityConfig] = useState(ENTITIES_CONFIG[entityCode]);

    const tableRowsData = useSelector(state => state.crudBase.list);
    const tableRowsCount = useSelector(state => state.crudBase.count);
    const userPermissions = useSelector(state => state.users.userPermissions);

    const [selectedTableRows, setSelectedTableRows] = useState(null);
    const [createEditFormDialogState, setCreateEditFormDialogState] = useState(false);
    const [deleteRecordDialogState, setDeleteRecordDialogState] = useState(false);
    const [deleteRecordMessage, setDeleteRecordMessage] = useState('');

    const [isCreateForm, setIsCreateForm] = useState(false);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);

    const [currentPaginationParams, setCurrentPaginationParams] = useState({
        filterSet: null,
    });


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 400);


    // const [tableState, setTableState] = useState({
    //     first: 0,
    //     limit: rowsPerPage,
    //     page: 1,
    //     lastPageLoaded: 1,
    //     sortField: null,
    //     sortOrder: null,
    //     filter: []
    // });

    useEffect(() => {
        setFilters({
            ...filters,
            global: {
                ...filters.global,
                value: globalFilterDebouncedValue
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilterDebouncedValue]);

    // const onPage = (event) => {
    //     setTableState({
    //         ...tableState,
    //         page: event.page + 1,
    //     });
    // };

    // const onSort = (event) => {
    //     setTableState({
    //         ...tableState,
    //         sortField: event.sortField,
    //         sortOrder: event.sortOrder,
    //     });
    // };

    const performDeleteRecord = () => {
        dispatch(crudBaseDeleteStart({
            entityCode: entityCode,
            recordId: selectedTableRows.id,
        }));
        setDeleteRecordDialogState(false);
        setSelectedTableRows([]);
    };

    const hideDeleteRowDialog = () => {
        setDeleteRecordDialogState(false);
    };

    const openCreateDialog = useCallback(() => {
        setCreateEditFormDialogState(true);
        setIsCreateForm(true);
    }, []);

    const openEditDialog = () => {
        setCreateEditFormDialogState(true);
        setIsCreateForm(false);
    };

    const closeCreateEditForm = () => {
        setCreateEditFormDialogState(false);
        setCurrentPaginationParams({
            ...currentPaginationParams,
            filterSet: null,
        });
        dispatch(crudBaseGetOneReset());
    };

    const refreshRowsData = () => {
        dispatch(crudBaseResetState());
        dispatch(crudBaseGetListStart({
            entityCode: entityCode,
            paginationParams: currentPaginationParams,
        }));
    };

    useEffect(() => {
        if (formSubmittedSuccess) {
            refreshRowsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, formSubmittedSuccess]);

    const updateFilterSet = (filterSet, clear = false) => {
        let newFilterSet = null;
        if (!clear && currentPaginationParams.filterSet) {
            newFilterSet = {
                ...currentPaginationParams.filterSet
            };
        }

        if (filterSet) {
            // Loop over filterSet attributes
            Object.keys(filterSet).forEach((key) => {
                // If attribute is null, delete it from filterSet and remove from newFilterSet if exists
                if (filterSet[key] === null) {
                    delete filterSet[key];
                    if (newFilterSet && newFilterSet[key]) {
                        delete newFilterSet[key];
                    }
                } else {
                    if (!newFilterSet) {
                        newFilterSet = {};
                    }
                    newFilterSet[key] = filterSet[key];
                }
            });
        }
        setCurrentPaginationParams({
            ...currentPaginationParams,
            filterSet: newFilterSet,
        });
    }

    useEffect(() => {
        refreshRowsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPaginationParams]);

    const handleRefreshButton = () => {
        refreshRowsData();
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV();
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            let dataRowsForExport = tableRowsData.map(exportExcelFieldMap);

            // Sort dataRowsForExport by 'Parent Choice' to make xls file suitable for Bulk import
            dataRowsForExport.sort((a, b) => {
                if (a['Parent Choice'] < b['Parent Choice']) {
                    return -1;
                }
                if (a['Parent Choice'] > b['Parent Choice']) {
                    return 1;
                }
                return 0;
            });

            const worksheet = xlsx.utils.json_to_sheet(dataRowsForExport);

            if (exportExcelColumnInfo) {
                worksheet['!cols'] = exportExcelColumnInfo;
            }

            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            let exportFilename = dt.current.props.exportFilename;
            saveAsExcelFile(exportFilename, excelBuffer);
        });
    };

    const handleBulkUpdateButton = () => {
        history.push(bulkUpdateRoute);
    };

    const startToolbarTemplate = () => {
    };

    const endToolbarTemplate = () => (
        <Fragment>
            {bulkUpdateRoute && userPermissions && userPermissions.includes(entityBulkUpdatePermissions[entityCode]) &&
                <Button label="Bulk Add/Update" icon="ri-upload-2-line" className="record-action-button ms-2" onClick={handleBulkUpdateButton} />
            }
            <Button label="CSV" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportCSV} />
            <Button label="XLS" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportExcel} />
        </Fragment>
    );

    const onClickButtonDelete = () => {
        setDeleteRecordMessage(`Are you sure you want to delete ${entityConfig.nameSingular}: "${DROPDOWN_TYPES_LABELS[selectedTableRows.dropdown_type]} ${selectedTableRows[entityConfig.labelField]}" ?`);
        setDeleteRecordDialogState(true);
    };

    const paginatorLeft = () => {
        return (
            <Fragment>
                <Button label="Edit" icon="ri-pencil-fill" className="record-action-button me-3"
                    onClick={openEditDialog}
                    disabled={!selectedTableRows}
                />
                <Button label="Delete" icon="ri-delete-bin-line" className="record-action-button me-3"
                    onClick={() => onClickButtonDelete()}
                    disabled={!selectedTableRows}
                />

                <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                <span className="p-input-icon-left">
                    <i className="ri-search-line" style={{ marginTop: -10 }} />
                    <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                </span>
            </Fragment>
        );
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: INITIAL_ROWS_PER_PAGE, value: INITIAL_ROWS_PER_PAGE },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <Button icon="ri-refresh-line" severity='secondary' outlined size='small' className="me-2" onClick={handleRefreshButton} />
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />
                </Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <span className="me-2">Rows: {options.totalRecords} |</span>
                    <span className="page-number-button">Page: {options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">Manage {entityConfig.namePlural}</h4>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button label={`New ${entityConfig.nameSingular}`}
                                            className="add-record-button"
                                            onClick={openCreateDialog} />
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                {createEditFormDialogState &&
                    <Row className='block-fade-in'>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <DropdownForm
                                        close={closeCreateEditForm} updateFilterSet={updateFilterSet}
                                        recordId={(!isCreateForm && selectedTableRows) ? selectedTableRows.id : null}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row className="datatable-crud-base">
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Toolbar className="list-component-toolbar" start={startToolbarTemplate} end={endToolbarTemplate} />
                                <DataTable value={tableRowsData}
                                    size='small'
                                    dataKey="id"
                                    rows={rowsPerPage}
                                    paginator
                                    paginatorPosition='top'
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorLeft={paginatorLeft}
                                    totalRecords={tableRowsCount}
                                    // onPage={onPage}
                                    // onSort={onSort}
                                    onFilter={(e) => setFilters(e.filters)}
                                    // sortField={tableState.sortField}
                                    // sortOrder={tableState.sortOrder}
                                    // first={tableState.first}
                                    emptyMessage={`No ${entityConfig.namePlural} found.`}
                                    selection={selectedTableRows}
                                    onSelectionChange={(e) => setSelectedTableRows(e.value)}
                                    filters={filters}
                                    globalFilterFields={['asset_type.asset_type_name', 'dropdown_type', 'choice', 'parent_asset_type_choice.choice']}
                                    ref={dt}
                                    exportFilename={strToLowercaseUnderscore(entityConfig.namePlural)}
                                    stateStorage='local' stateKey={'dt-l-state-' + entityCode}
                                >
                                    <Column selectionMode='single' headerStyle={{ width: '3rem' }}></Column>
                                    {dataTableColumns.map((column, index) => (
                                        <Column key={index} {...column} />
                                    ))}
                                </DataTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ConfirmDialog visible={deleteRecordDialogState}
                onHide={hideDeleteRowDialog}
                className='delete-confirm-dialog'
                message={deleteRecordMessage}
                header={`Delete ${entityConfig.nameSingular}`}
                acceptLabel={`Delete ${entityConfig.nameSingular}`}
                rejectLabel='Cancel'
                accept={performDeleteRecord} reject={hideDeleteRowDialog} />
        </Fragment>
    );
};

export default DropdownsList;
