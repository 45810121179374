import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_CITY } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './CitiesList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'city_name': rowData['Name'],
        'state_name': rowData['State'],
        'active': rowData['Status'] === 'Active' ? true : false,
    }
};

const apiToExcelColumn = {
    'city_name': 'Name',
    'state_name': 'State',
    'active': 'Status',
};

const foreignKeyColumns = [
    'State',
];

export const CitiesBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_CITY}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default CitiesBulkUpdate;
