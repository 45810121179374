import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    CRUD_BASE_BULK_DELETE_START,
    CRUD_BASE_CREATE_START,
    CRUD_BASE_DELETE_START,
    CRUD_BASE_GET_LIST_START,
    CRUD_BASE_GET_ONE_START,
    CRUD_BASE_UPDATE_START,
    crudBaseDeleteSuccess,
    crudBaseGetListFailed,
    crudBaseGetListSuccess,
    crudBaseGetOneSuccess
} from 'redux/actions/crud_base.actions';
import { genericError } from "redux/actions/error.actions";
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from 'redux/actions/ui.actions';
import { ENTITIES_CONFIG } from 'redux/entities_config';
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { crudBaseBulkDelete, crudBaseDeleteOne, crudBaseGetList, crudBaseGetOne, crudBasePatch, crudBasePost } from 'services/crudBaseService';


/**
 * getCrudBaseListEffect
 */
function* getCrudBaseListEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        const entityDataList = yield call(crudBaseGetList, entityConfig.apiEndpoint, payload.paginationParams);
        if (entityDataList) {
            yield put(crudBaseGetListSuccess(entityDataList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
        yield put(crudBaseGetListFailed());
    }
}

/**
 * getOneCrudBasesEffect
 */
function* getOneCrudBaseEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        yield put(enableOverlay(`Loading ${entityConfig.nameSingular}...`));
        const entityData = yield call(crudBaseGetOne, entityConfig.apiEndpoint, payload.recordId);
        if (entityData) {
            yield put(crudBaseGetOneSuccess(entityData));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
    }
    yield put(disableOverlay());
}

/**
 * createCrudBaseEffect
 */
function* createCrudBaseEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        yield put(enableOverlay(`Creating new ${entityConfig.nameSingular}...`));
        const recordData = yield call(crudBasePost, entityConfig.apiEndpoint, payload.recordData);
        if (recordData) {
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: `${entityConfig.nameSingular} successfully created!`,
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
    }
    yield put(disableOverlay());
}

/**
 * updateCrudBaseEffect
 */
function* updateCrudBaseEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        yield put(enableOverlay(`Updating ${entityConfig.nameSingular}...`));
        const recordData = yield call(crudBasePatch, entityConfig.apiEndpoint, payload.recordData);
        if (recordData) {
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: `${entityConfig.nameSingular} successfully updated!`,
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
    }
    yield put(disableOverlay());
}

/**
 * deleteCrudBaseEffect
 */
function* deleteCrudBaseEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        yield put(enableOverlay(`Deleting ${entityConfig.nameSingular}...`));
        yield call(crudBaseDeleteOne, entityConfig.apiEndpoint, payload.recordId);
        yield put(setToast({
            severity: TOAST_SEVERITY_SUCCESS,
            detail: `${entityConfig.nameSingular} successfully deleted!`,
        }));
        yield put(crudBaseDeleteSuccess());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
    }
    yield put(disableOverlay());
}

/**
 * bulkDeleteCrudBaseEffect
 */
function* bulkDeleteCrudBaseEffect({ payload }) {
    let entityConfig = ENTITIES_CONFIG[payload.entityCode];
    try {
        yield put(enableOverlay(`Bulk deletion of ${entityConfig.namePlural}...`));
        yield call(crudBaseBulkDelete, entityConfig.apiEndpoint, payload.recordIds);
        yield put(setToast({
            severity: TOAST_SEVERITY_SUCCESS,
            detail: `${entityConfig.namePlural} successfully deleted!`,
        }));
        yield put(crudBaseDeleteSuccess());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify({
            message: err.message,
            code: err.code,
            url: err.config.url,
        })));
    }
    yield put(disableOverlay());
}

/**
 * Watchers
 */
export function* watchGetCrudBases() {
    yield takeEvery(CRUD_BASE_GET_LIST_START, getCrudBaseListEffect);
}

export function* watchGetOneCrudBase() {
    yield takeEvery(CRUD_BASE_GET_ONE_START, getOneCrudBaseEffect);
}

export function* watchCreateCrudBase() {
    yield takeEvery(CRUD_BASE_CREATE_START, createCrudBaseEffect);
}

export function* watchUpdateCrudBase() {
    yield takeEvery(CRUD_BASE_UPDATE_START, updateCrudBaseEffect);
}

export function* watchDeleteCrudBase() {
    yield takeEvery(CRUD_BASE_DELETE_START, deleteCrudBaseEffect);
}

export function* watchBulkDeleteCrudBase() {
    yield takeEvery(CRUD_BASE_BULK_DELETE_START, bulkDeleteCrudBaseEffect);
}


function* crudBaseSaga() {
    yield all([
        fork(watchGetCrudBases),
        fork(watchGetOneCrudBase),
        fork(watchCreateCrudBase),
        fork(watchUpdateCrudBase),
        fork(watchDeleteCrudBase),
        fork(watchBulkDeleteCrudBase),
    ]);
}

export default crudBaseSaga;
