import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { genericError } from "redux/actions/error.actions";
import { REFRESH_ACTIVE_TENANT, setActiveTenant } from 'redux/actions/ui.actions';
import { getOneTenant } from 'services/tenantService';

/**
 * refreshActiveTenantEffect
 */
function* refreshActiveTenantEffect() {
    // get current tenant from reducer
    const activeTenant = yield select(state => state.ui.activeTenant);
    if (activeTenant.code) {
        try {
            const tenant = yield call(getOneTenant, activeTenant.code);
            if (tenant) {
                yield put(setActiveTenant(tenant));
            }
        } catch (err) {
            console.log('error:', err);
            yield put(genericError(JSON.stringify(err)));
        }
    } else {
        console.error('No active tenant');
        yield put(genericError('No active tenant'));
    }
}

/**
 * Watchers
 */
export function* watchRefreshActiveTenant() {
    yield takeEvery(REFRESH_ACTIVE_TENANT, refreshActiveTenantEffect);
}

function* uiSaga() {
    yield all([
        fork(watchRefreshActiveTenant),
    ]);
}

export default uiSaga;
