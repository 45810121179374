import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_BUILDING, ENTITY_CITY } from 'redux/entities_config';


const BuildingForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        description: '',
        code: '',
        street: '',
        city_id: null,
        state_id: null,
        zip: '',
        site_code: '',
        active: true,
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_BUILDING, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            setInitialFormValues({
                description: activeRecord.description,
                code: activeRecord.code,
                street: activeRecord.street,
                city_id: activeRecord.city_id,
                state_id: activeRecord.state_id,
                zip: activeRecord.zip,
                site_code: activeRecord.site_code,
                active: activeRecord.active,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                description: Yup.string()
                    .required('Please enter the description'),
                code: Yup.string()
                    .required('Please enter the code'),
                street: Yup.string(),
                city_id: Yup.number()
                    .required('Please select a city'),
                zip: Yup.string(),
                site_code: Yup.string(),
                active: Yup.boolean()
            })}
            serverFieldMap={{
                description: 'description',
                code: 'code',
                street: 'street',
                city_id: 'city_id',
                state_id: 'city_id',
                zip: 'zip',
                site_code: 'site_code',
                active: 'active',
            }}
            onSubmit={(values) => {
                let formData = {
                    description: values.description,
                    code: values.code,
                    street: values.street,
                    city_id: values.city_id,
                    state_id: values.state_id,
                    zip: values.zip,
                    site_code: values.site_code,
                    active: values.active,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_BUILDING, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_BUILDING, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <FormGroup>
                    <Row>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="description"
                                inputPlaceHolder="Enter the description"
                                inputLabel="Description"
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="code"
                                inputLabel="Code"
                            />
                        </Col>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="site_code"
                                inputPlaceHolder="Enter the site code"
                                inputLabel="Site Code"
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="street"
                                inputLabel="Street"
                            />
                        </Col>
                        <Col>
                            <Label for='field_city'>City</Label>
                            <Field name="city_id" id='field_city' entityCode={ENTITY_CITY} component={AppEntityDropdown} />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="zip"
                                inputPlaceHolder="Enter the zip"
                                inputLabel="Zip"
                            />
                        </Col>
                        <Col>
                            <AppInputFieldComponent
                                fieldType="checkbox"
                                fieldName="active"
                                inputLabel="Is Active?"
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form >
        )
            }
        </AppFormik>
    )
}

export default BuildingForm;
