import {createAction} from "@reduxjs/toolkit";
// Action types
export const GET_ADMIN_SETTINGS_VALUE = '[ADMIN SETTINGS] Getting admin settings value';
export const GET_ADMIN_SETTINGS_VALUE_SUCCESS = '[ADMIN SETTINGS] Get admin settings value success!'
export const UPDATE_ADMIN_SETTINGS_VALUE = '[ADMIN SETTINGS] Updating admin settings value ...';
export const UPDATE_ADMIN_SETTINGS_VALUE_SUCCESS = '[ADMIN SETTINGS] Updating admin settings value success!';

// Actions
export const getAdminSettingsValue = createAction(GET_ADMIN_SETTINGS_VALUE);
export const getAdminSettingsValueSuccess = createAction(GET_ADMIN_SETTINGS_VALUE_SUCCESS);
export const updateAdminSettingsValue = createAction(UPDATE_ADMIN_SETTINGS_VALUE);
export const updateAdminSettingsValueSuccess = createAction(UPDATE_ADMIN_SETTINGS_VALUE_SUCCESS);
