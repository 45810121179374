import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { DEPARTMENT_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { AppCalendarComponent } from 'components/ui/AppCalendarComponent';
import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppEntityMultiselect } from 'components/ui/AppEntityMultiselect';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { AppOptionSelect } from 'components/ui/AppOptionSelect';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_BUILDING, ENTITY_DEPARTMENT, ENTITY_OU, ENTITY_SPECIALTY } from 'redux/entities_config';
import { departmentScopeToUsedByEpic, usedByEpicToDepartmentScope } from 'utils';
import { convertToDateTime, convertToJSDate, dateTimeToAPIDate, parseToDateTime } from 'utils/date_time';


export const DepartmentForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        department_name: '',
        department_number: '',
        ou_id: null,
        active: true,
        status: '',
        building_id: null,
        suite_num: '',
        used_by_epic: '', // department_scope
        specialty_ids: [],
        tdr_date: '',
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_DEPARTMENT, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            let tdrDate = null;
            if (activeRecord.tdr_date) {
                tdrDate = parseToDateTime(activeRecord.tdr_date);
                tdrDate = convertToJSDate(tdrDate);
            }

            let usedByEpic = departmentScopeToUsedByEpic(activeRecord.department_scope);

            setInitialFormValues({
                department_name: activeRecord.department_name,
                department_number: activeRecord.department_number || '',
                ou_id: activeRecord.ou_id,
                active: activeRecord.active,
                status: activeRecord.status,
                building_id: activeRecord.building_id,
                suite_num: activeRecord.suite_num,
                used_by_epic: usedByEpic,
                specialty_ids: activeRecord.specialty_ids,
                tdr_date: tdrDate,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                department_name: Yup.string()
                    .required('Please enter the department name'),
                department_number: Yup.number()
                    .notRequired(),
                ou_id: Yup.number()
                    .required('Please select the School'),
                active: Yup.boolean(),
                status: Yup.string()
                    .required('Please select the status'),
                building_id: Yup.number()
                    .required('Please select the building'),
                suite_num: Yup.string()
                    .notRequired(),
                used_by_epic: Yup.boolean(),
                specialty_ids: Yup.array()
                    .notRequired(),
                tdr_date: Yup.date()
                    .notRequired(),
            })}
            serverFieldMap={{
                department_name: 'department_name',
                department_number: 'department_number',
                ou_id: 'ou_id',
                active: 'active',
                status: 'status',
                building_id: 'building_id',
                suite_num: 'suite_num',
                department_scope: 'used_by_epic', // this only attribute is mapped differently
                specialty_ids: 'specialty_ids',
                tdr_date: 'tdr_date',
            }}
            onSubmit={(values) => {
                let tdrDate = values.tdr_date ? convertToDateTime(values.tdr_date) : null;

                let departmentScope = usedByEpicToDepartmentScope(values.used_by_epic);

                let formData = {
                    department_name: values.department_name,
                    department_number: values.department_number || null,
                    ou_id: values.ou_id,
                    active: values.active,
                    status: values.status,
                    building_id: values.building_id,
                    suite_num: values.suite_num || null,
                    department_scope: departmentScope,
                    specialty_ids: values.specialty_ids,
                    tdr_date: tdrDate ? dateTimeToAPIDate(tdrDate) : null,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_DEPARTMENT, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_DEPARTMENT, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col xs={12} md={4} className='mb-3'>
                        <AppInputFieldComponent
                            fieldType="text"
                            fieldName="department_name"
                            inputPlaceHolder="Enter the department name"
                            inputLabel="Department Name"
                        />
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <AppInputFieldComponent
                            fieldType="text"
                            fieldName="department_number"
                            inputPlaceHolder="Enter the department number"
                            inputLabel="Department Number (DEP)"
                        />
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <AppCalendarComponent
                            fieldName="tdr_date"
                            inputPlaceHolder="Enter the TDR date"
                            inputLabel="TDR Date"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} className='mb-3'>
                        <Label for='field_ou_id'>School</Label>
                        <Field name="ou_id" id='field_ou_id' entityCode={ENTITY_OU} component={AppEntityDropdown} />
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <Label for='field_building_id'>Building</Label>
                        <Field name="building_id" id='field_building_id' entityCode={ENTITY_BUILDING} component={AppEntityDropdown} />
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <AppInputFieldComponent
                            fieldType="text"
                            fieldName="suite_num"
                            inputPlaceHolder="Enter the suite number"
                            inputLabel="Suite Number"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-3'>
                        <Label for='field_specialty_ids'>Specialties</Label>
                        <Field name="specialty_ids" id='field_specialty_ids' entityCode={ENTITY_SPECIALTY} component={AppEntityMultiselect} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={4} className='mb-3'>
                        <AppInputFieldComponent
                            fieldType="checkbox"
                            fieldName="used_by_epic"
                            inputLabel="Used by EPIC?"
                        />
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <AppOptionSelect fieldName="status" fieldLabel="Status" selectOptions={DEPARTMENT_STATUSES_LABELS} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={4} className='mb-3'>
                        <AppInputFieldComponent
                            fieldType="checkbox"
                            fieldName="active"
                            inputLabel="In Use?"
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} xs={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} xs={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default DepartmentForm;
