import axios from "axios";

import {
    API_USERS_ADMIN
} from "./API_CONSTANTS";

export const getUsersByAdmin = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_USERS_ADMIN)
                .then((res) => {
                    console.log('Users loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get users error: ', err);
                    reject('Get users error');
                });
        } catch (error) {
            console.error('Get users error: ', error);
            reject('Get users error');
        }
    });
};

export const postUserByAdmin = (user) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_USERS_ADMIN, user)
                .then((res) => {
                    console.log("user created: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user creation error: ", err);
                    reject("user creation error!");
                });
        } catch (error) {
            reject("user creation error!");
        }
    });
}

export const getUserByAdmin = (userId) => {
    const url = API_USERS_ADMIN + '/' + userId;
    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("User loaded: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get user error: ", err);
                    reject("Get user error!");
                });
        } catch (error) {
            reject("Get user error!");
        }
    });
}

export const updateUserByAdmin = (user) => {
    const url = API_USERS_ADMIN + '/' + user.id;
    return new Promise((resolve, reject) => {
        try {
            axios.put(url, user, {
                headers: {
                    "content-type": "application/json"
                }
            })
                .then((res) => {
                    console.log("user data updated: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user update error: ", err);
                    reject("user update error!");
                });
        } catch (error) {
            reject("user update error!");
        }
    });
}


export const removeUserByAdmin = (recordId) => {
    const url = API_USERS_ADMIN + '/' + recordId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('User removed by admin: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove user by admin error: ', err);
                    reject('Remove user by admin error');
                });
        } catch (error) {
            console.error('Remove user by admin error: ', error);
            reject('Remove user by admin error');
        }
    });
}
