import { } from "redux/actions/roles.actions";
import { GET_WORKFLOW_REQ_CATEGORY_SUCCESS, RESET_WORKFLOW_REQ_CATEGORY_STATE } from "redux/actions/workflow_req_categories.actions";

// It only contains records valid for default workflow requirements (for-default-reqs: true)
const initialWorkflowReqCategoryState = {
    workflowReqCategoriesList: [],
    next: null,
    previous: null,
    count: 0,
    workflowReqCategoriesMap: {},
};

const getWorkflowReqCategoriesMap = (results) => {
    let workflowReqCategoriesMap = {};
    for (let category of results) {
        workflowReqCategoriesMap[category.id] = category.workflow_req_category_name;
    }
    return workflowReqCategoriesMap;
}

export const WorkflowReqCategoriesReducer = (state = initialWorkflowReqCategoryState, action) => {
    switch (action.type) {
        case GET_WORKFLOW_REQ_CATEGORY_SUCCESS:
            return {
                ...state,
                workflowReqCategoriesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                workflowReqCategoriesMap: getWorkflowReqCategoriesMap(action.payload.results),
            };
        case RESET_WORKFLOW_REQ_CATEGORY_STATE:
            return initialWorkflowReqCategoryState;
        default:
            return state;
    }
}