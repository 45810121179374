import { Fragment } from 'react';
import { Badge } from 'reactstrap';

import { CrudBaseList } from 'components/base/CrudBaseList';
import { WORKSTATION_DEFAULT_PRINTERS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_WORKSTATION } from 'redux/entities_config';
import { AssetForm } from '../all_assets/AssetForm';

const defaultPrinterBody = (rowData, field) => {
    let defaultPrinterAsset = rowData[field];
    if (!defaultPrinterAsset) {
        return '';
    }
    let assetName = defaultPrinterAsset.asset_name;
    let printerType = null
    if (defaultPrinterAsset.printer_type) {
        printerType = (<p className='mb-0'><Badge color="secondary">{defaultPrinterAsset.printer_type.printer_type_name}</Badge></p>)
    }

    return (<Fragment>{printerType} {assetName}</Fragment>);
};

const dataTableColumns = [
    {
        field: 'id',
        header: 'ID',
        sortable: true,
    },
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'default_printer_plain_paper.asset_name',
        header: 'Default Plain Paper Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_plain_paper'),
    },
    {
        field: 'default_printer_wristbands.asset_name',
        header: 'Default Wristbands Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_wristbands'),
    },
    {
        field: 'default_printer_baby_wristbands.asset_name',
        header: 'Default Baby Wristbands Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_baby_wristbands'),
    },
    {
        field: 'default_printer_pharm_labels.asset_name',
        header: 'Default Pharmacy Labels Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_pharm_labels'),
    },
    {
        field: 'default_printer_labels.asset_name',
        header: 'Default Labels Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_labels'),
    },
    {
        field: 'default_printer_scripts.asset_name',
        header: 'Default Scripts Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_scripts'),
    },
    {
        field: 'default_printer_insurance_form.asset_name',
        header: 'Default Insurance Form Printer',
        sortable: true,
        body: (rowData) => defaultPrinterBody(rowData, 'default_printer_insurance_form'),
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Workstation Asset Id',
    'Default Plain Paper Printer',
    'Default Wristbands Printer',
    'Default Baby Wristbands Printer',
    'Default Pharmacy Labels Printer',
    'Default Labels Printer',
    'Default Scripts Printer',
    'Default Insurance Form Printer',
];


const exportExcelFieldMap = (rowData) => {
    return {
        'School Code': rowData.ou.ou_name,
        'Dept': rowData.department ? rowData.department.department_name : '',
        'Street': rowData.building.street ? rowData.building.street : '',
        'Suite #': rowData.suite_num,
        'Workstation Asset Id': rowData.id,
        'Default Plain Paper Printer': rowData.default_printer_plain_paper ? rowData.default_printer_plain_paper.asset_name : '',
        'Default Wristbands Printer': rowData.default_printer_wristbands ? rowData.default_printer_wristbands.asset_name : '',
        'Default Baby Wristbands Printer': rowData.default_printer_baby_wristbands ? rowData.default_printer_baby_wristbands.asset_name : '',
        'Default Pharmacy Labels Printer': rowData.default_printer_pharm_labels ? rowData.default_printer_pharm_labels.asset_name : '',
        'Default Labels Printer': rowData.default_printer_labels ? rowData.default_printer_labels.asset_name : '',
        'Default Scripts Printer': rowData.default_printer_scripts ? rowData.default_printer_scripts.asset_name : '',
        'Default Insurance Form Printer': rowData.default_printer_insurance_form ? rowData.default_printer_insurance_form.asset_name : '',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 10 }, // School Code
    { wch: 50 }, // Dept
    { wch: 40 }, // Street
    { wch: 10 }, // Suite #
    { wch: 15 }, // Workstation Asset Id
    { wch: 40 }, // Default Plain Paper Printer
    { wch: 40 }, // Default Wristbands Printer
    { wch: 40 }, // Default Baby Wristbands Printer
    { wch: 40 }, // Default Pharmacy Labels Printer
    { wch: 40 }, // Default Labels Printer
    { wch: 40 }, // Default Scripts Printer
    { wch: 40 }, // Default Insurance Form Printer
];


const WorkstationDefaultPrintersList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_WORKSTATION}
            CrudBaseForm={AssetForm}
            bulkUpdateRoute={WORKSTATION_DEFAULT_PRINTERS_BULK_UPDATE}
            bulkUpdateSupportAdd={false}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="All Default Printers"
            customEntityLabel="Asset"
        />
    );
};

export default WorkstationDefaultPrintersList;
