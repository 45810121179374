import { MENU_SECTIONS, USER_PERMISSIONS } from "assets/static_data/backend_exports";
import { ADMIN_ROLES, ADMIN_USERS, ALL_ASSETS_LIST, ASSETS_TO_FOLLOW_UP_LIST, ASSET_ADD, BUILDINGS_LIST, CITIES_LIST, CLINIC_SUMMARY_LIST, CREDIT_CARD_READER_LIST, DASHBOARD, DEPARTMENTS_LIST, DROPDOWNS_LIST, DUPLICATE_WORKSTATIONS, DUPLICATION_HISTORY, FUNCTIONAL_TYPES, GAP_REPORT_LIST, LEASED_ASSETS_LIST, ORGANIZATION_SETTINGS, OUS_LIST, SPECIALTIES_LIST, STATES_LIST, WAP_ASSETS_LIST, WORKFLOWS_LIST, WORKFLOW_DEFAULT_REQS_LIST, WORKSTATIONS_LIST, WORKSTATIONS_TDR_NOT_PASSED, WORKSTATIONS_WO_PRIMARY_USE, WORKSTATION_DEFAULT_PRINTERS_LIST } from "./ROUTE_CONSTANTS";

// Define menu options with icons and links as an object
// First level is the main section,
// Second level could be final menu option or a group of menu option
// Third level is the final menu option and is optional
export const menuOptions = {
    [MENU_SECTIONS.WORKSTATION]: [
        {
            name: 'Admin Tools',
            icon: 'ri-tools-fill',
            subMenu: [
                {
                    name: 'Functional Types',
                    link: FUNCTIONAL_TYPES,
                    availableForPermissions: [
                        USER_PERMISSIONS.FUNCTIONAL_TYPES_MGT,
                    ],
                },
            ],
        },
        {
            name: 'Reports',
            icon: 'ri-list-check',
            subMenu: [
                {
                    name: 'EPIC Workstations',
                    link: WORKSTATIONS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKSTATIONS_LIST,
                    ],
                },
                {
                    name: 'Duplication History',
                    link: DUPLICATION_HISTORY,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKSTATIONS_DUP_HISTORY
                    ],
                },
            ],
        },
        {
            name: 'Tools',
            icon: 'ri-mac-line',
            subMenu: [
                {
                    name: 'Duplicate EPIC Workstations',
                    link: DUPLICATE_WORKSTATIONS,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKSTATIONS_DUPLICATE,
                    ],
                },
            ],
        },
    ],
    [MENU_SECTIONS.INVENTORY]: [
        {
            name: 'Admin Tools',
            icon: 'ri-tools-fill',
            subMenu: [
                {
                    name: 'Manage Device Makes and Models',
                    link: DROPDOWNS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.DROPDOWNS_MGT,
                    ],
                },
                /*
                {
                    name: 'Manage Legacy Departments',
                    link: LEGACY_DEPARTMENTS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.LEGACY_DEPARTMENTS_MGT
                    ],
                },
                {
                    name: 'Manage EPIC Departments',
                    link: EPIC_DEPARTMENTS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.EPIC_DEPARTMENTS_MGT
                    ],
                },
                */
                {
                    name: 'Manage Schools',
                    link: OUS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.OUS_MGT
                    ],
                },
                {
                    name: 'Manage Buildings',
                    link: BUILDINGS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.BUILDINGS_MGT
                    ],
                },
                {
                    name: 'Manage States',
                    link: STATES_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.STATES_MGT
                    ],
                },
                {
                    name: 'Manage Cities',
                    link: CITIES_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.CITIES_MGT
                    ],
                },
                {
                    name: 'Manage Specialties',
                    link: SPECIALTIES_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.SPECIALTIES_MGT
                    ],
                },
                {
                    name: 'Manage Departments',
                    link: DEPARTMENTS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.DEPARTMENTS_MGT,
                    ],
                },
                {
                    name: 'Manage Primary Uses',
                    link: WORKFLOWS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKFLOWS_MGT
                    ],
                },
            ],
        },
        {
            name: 'Assets Reports',
            icon: 'ri-list-check',
            subMenu: [
                {
                    name: 'Add an Asset',
                    link: ASSET_ADD,
                    availableForPermissions: [
                        USER_PERMISSIONS.ASSETS_ADD,
                    ],
                },
                {
                    name: 'All Workstation & Printer Assets',
                    link: WAP_ASSETS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKSTATION_AND_PRINTER_ASSETS_MGT,
                    ],
                },
                {
                    name: 'All Assets View',
                    link: ALL_ASSETS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.ALL_ASSETS_MGT,
                    ],
                },
                {
                    name: 'All CC Readers',
                    link: CREDIT_CARD_READER_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.ALL_ASSETS_MGT,
                    ],
                },
                {
                    name: 'Clinic Inventory Summary',
                    link: CLINIC_SUMMARY_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.CLINIC_SUMMARY_REPORT,
                    ],
                },
                {
                    name: 'Assets to Follow-up',
                    link: ASSETS_TO_FOLLOW_UP_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.ASSETS_TO_FOLLOW_UP_REPORT,
                    ],
                },
                {
                    name: 'Workstations Without Primary Use',
                    link: WORKSTATIONS_WO_PRIMARY_USE,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKSTATIONS_WO_PRIMARY_USE_REPORT,
                    ],
                },
                {
                    name: 'All Leased Assets',
                    link: LEASED_ASSETS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.LEASED_ASSETS_MGT,
                    ],
                },
                {
                    name: 'All Documents',
                    link: '/to-dev/all-documents',
                },
                {
                    name: 'All Default Printers',
                    link: WORKSTATION_DEFAULT_PRINTERS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.ALL_ASSETS_MGT,
                    ],
                },
            ]
        },
        {
            name: 'EPIC Reports',
            icon: 'ri-list-check',
            subMenu: [
                {
                    name: 'Workstations with TDR Status Not Passed',
                    link: WORKSTATIONS_TDR_NOT_PASSED,
                    availableForPermissions: [
                        USER_PERMISSIONS.ASSETS_TDR_NOT_PASSED_REPORT,
                    ],
                },
                /*
                {
                    name: 'All Counts',
                    link: '/to-dev/all-counts',
                },
                */
                {
                    name: 'Gap Report',
                    link: GAP_REPORT_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.GAP_REPORT,
                    ],
                },
                /* Removed, these 3 are included into Gap Report
                {
                    name: 'OU Walkthrough',
                    link: '/to-dev/ou-walkthrough',
                },
                {
                    name: 'Peripherals by Primary Use',
                    link: '/to-dev/peripherals-by-primary-use',
                },
                {
                    name: 'Peripherals Needed Report',
                    link: '/to-dev/peripherals-needed-report',
                },
                */
                {
                    name: 'UTHealthConnect LWS Report',
                    link: '/to-dev/uthealthconnect-lws-report',
                },
                {
                    name: 'UTHealthConnect EPR Report',
                    link: '/to-dev/uthealthconnect-epr-report',
                },
                {
                    name: 'LWS Changes Report',
                    link: '/to-dev/lws-changes-report',
                },
                {
                    name: 'View Primary Uses Requirements',
                    link: WORKFLOW_DEFAULT_REQS_LIST,
                    availableForPermissions: [
                        USER_PERMISSIONS.WORKFLOW_REQS_REPORT,
                    ],
                },
            ]
        },
    ],
    [MENU_SECTIONS.COMMON]: [
        {
            name: 'Administration',
            icon: 'ri-shield-user-line',
            subMenu: [
                {
                    name: 'User Roles',
                    link: ADMIN_ROLES,
                    availableForPermissions: [
                        USER_PERMISSIONS.USERS_ROLES,
                    ],
                },
                {
                    name: 'Users',
                    link: ADMIN_USERS,
                    availableForPermissions: [
                        USER_PERMISSIONS.USERS_MGT,
                    ],
                },
                {
                    name: 'Organization Settings',
                    link: ORGANIZATION_SETTINGS,
                    availableForPermissions: [
                        USER_PERMISSIONS.SETTINGS_MGT,
                    ],
                },
            ],
        },
        {
            name: 'Help',
            icon: 'ri-question-line',
            subMenu: [
                {
                    name: 'About',
                    link: '/to-dev/about',
                    availableForPermissions: false,
                },
                {
                    name: 'Contact Us',
                    link: '/to-dev/contact-us',
                    availableForPermissions: false,
                },
                {
                    name: 'FAQ',
                    link: '/to-dev/faq',
                    availableForPermissions: false,
                },
                {
                    name: 'Workflow Documents',
                    link: '/to-dev/workflow-documents',
                    availableForPermissions: false,
                },
                {
                    name: 'Training Materials',
                    link: '/to-dev/training-materials',
                    availableForPermissions: false,
                },
            ],
        },
        {
            name: 'Notifications',
            icon: 'ri-notification-2-line',
            link: DASHBOARD,
            availableForPermissions: false,
        },
    ],
};

// Map menuOptions to a flat array of menu options by link => availableForPermissions
export const menuOptionsPermissionsMap = Object.keys(menuOptions).reduce((acc, section) => {
    menuOptions[section].forEach(menuOption => {
        if (menuOption.subMenu) {
            menuOption.subMenu.forEach(subMenuOption => {
                acc[subMenuOption.link] = subMenuOption.availableForPermissions;
            });
        } else {
            acc[menuOption.link] = menuOption.availableForPermissions;
        }
    });
    return acc;
}, {});
