import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup } from "reactstrap";

import { MENU_SECTIONS } from "assets/static_data/backend_exports";
import MenuSwitcher from "components/ui/MenuSwitcher";
import UniversityHeader from "components/ui/UniversityHeader";
import { ROOT } from "navigation/ROUTE_CONSTANTS";
import { Badge } from "primereact/badge";
import ProfileMenu from "theme/components/commonForBoth/ProfileMenu";

import logo from "theme/assets/images/infinity_app_header.png";

const universityHeaderFooter = process.env.REACT_APP_UNIVERSITY_HEADER_FOOTER === 'true';

export const Header = () => {
    const userLogged = useSelector(state => state.users.userLogged);
    const activeMenuSection = useSelector(state => state.ui.activeMenuSection);
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const [isSearch, setIsSearch] = useState(false);
    const [displaySwitcher, setDisplaySwitcher] = useState(false);

    function toggleSidebar(e) {
        e.preventDefault();

        if (!document.body) {
            return true;
        }
        document.body.classList.toggle('sidebar-enable');
        document.body.classList.toggle('vertical-collpsed');
    }

    useEffect(() => {
        setDisplaySwitcher((activeTenant.workstation_enabled && activeTenant.inventory_enabled));
    }, [activeTenant.workstation_enabled, activeTenant.inventory_enabled]);

    const logoImg = (widthSize) => (
        <img src={logo} alt={process.env.REACT_APP_SITE_TITLE} style={{ width: widthSize }} />
    )

    return (
        <Fragment>
            <header id="page-topbar">

                {universityHeaderFooter &&
                    <UniversityHeader />
                }

                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box text-center">
                            <Link to={ROOT} className="logo logo-dark">
                                <span className="logo-sm">
                                    {logoImg('4rem')}
                                </span>
                                <span className="logo-lg">
                                    {logoImg('5rem')}
                                </span>
                            </Link>

                            <Link to={ROOT} className="logo logo-light">
                                <span className="logo-sm">
                                    {logoImg('4rem')}
                                </span>
                                <span className="logo-lg">
                                    {logoImg('5rem')}
                                </span>
                            </Link>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" onClick={toggleSidebar}>
                            <span className="visually-hidden">Toggle Sidebar</span>
                            <i className="ri-menu-2-line align-middle"></i>
                        </button>

                        {displaySwitcher &&
                            <MenuSwitcher />
                        }

                        {/* <Nav pills id="nav-menu-switcher-outline">
                            {Object.entries(MENU_SECTIONS_LABELS).map(([menuSectionCode, menuSectionLabel]) => (
                                <NavItem className="mx-3" key={menuSectionCode}>
                                    <Button color={menuSectionCode}
                                        size="sm"
                                        outline
                                        className="mt-3 px-4"
                                        active={activeMenuSection === menuSectionCode}
                                        onClick={() => dispatch(setActiveMenuSection(menuSectionCode))}
                                    >
                                        {menuSectionLabel}
                                    </Button>
                                </NavItem>
                            ))}
                        </Nav> */}

                        {activeMenuSection === MENU_SECTIONS.WORKSTATION &&
                            <Form className="app-search d-none d-lg-block mx-4">
                                <div className="position-relative">
                                    <label htmlFor="workstation-search" className="visually-hidden">Search workstation</label>
                                    <Input type="text" className="form-control" id="workstation-search" placeholder={'Search workstation'} />
                                    <span className="ri-search-line"></span>
                                </div>
                            </Form>
                        }

                        {activeMenuSection === MENU_SECTIONS.INVENTORY &&
                            <Form className="app-search d-none d-lg-block mx-4">
                                <div className="position-relative">
                                    <label htmlFor="inventory-search" className="visually-hidden">Search inventory</label>
                                    <Input type="text" className="form-control" id="inventory-search" placeholder={'Search inventory'} />
                                    <span className="ri-search-line"></span>
                                </div>
                            </Form>
                        }

                        {activeMenuSection === MENU_SECTIONS.WORKSTATION &&
                            <div className="dropdown d-inline-block d-lg-none mx-3">
                                <button type="button" onClick={() => { setIsSearch(!isSearch) }} className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                    <span className="visually-hidden">Search workstation</span>
                                    <i className="ri-search-line"></i>
                                </button>
                                <div
                                    className={"dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" + (isSearch === true ? " show" : "")}
                                    aria-labelledby="page-header-search-dropdown">

                                    <Form className="p-3">
                                        <FormGroup className="m-0">
                                            <InputGroup>
                                                <label htmlFor="md-workstation-search" className="visually-hidden">Search workstation</label>
                                                <Input type="text" className="form-control" id="md-workstation-search" placeholder={'Search workstation'} />
                                                <Button color="primary" type="submit">
                                                    <span className="visually-hidden">Search workstation</span>
                                                    <i className="ri-search-line"></i>
                                                </Button>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        }

                        {activeMenuSection === MENU_SECTIONS.INVENTORY &&
                            <div className="dropdown d-inline-block d-lg-none mx-3">
                                <button type="button" onClick={() => { setIsSearch(!isSearch) }} className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                    <span className="visually-hidden">Search inventory</span>
                                    <i className="ri-search-line"></i>
                                </button>
                                <div
                                    className={"dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" + (isSearch === true ? " show" : "")}
                                    aria-labelledby="page-header-search-dropdown">

                                    <Form className="p-3">
                                        <FormGroup className="m-0">
                                            <InputGroup>
                                                <label htmlFor="md-inventory-search" className="visually-hidden">Search inventory</label>
                                                <Input type="text" className="form-control" id="md-inventory-search" placeholder={'Search inventory'} />
                                                <Button color="primary" type="submit">
                                                    <span className="visually-hidden">Search inventory</span>
                                                    <i className="ri-search-line"></i>
                                                </Button>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        }

                    </div>


                    <div className="d-flex">
                        {process.env.REACT_APP_MULTI_TENANT === 'true' && activeTenant.name &&
                            <div className="me-2 my-auto">
                                <Badge className="me-1" value={activeTenant.name} severity='secondary' />
                            </div>
                        }
                        {userLogged &&
                            <ProfileMenu />
                        }
                    </div>
                </div>
            </header>
        </Fragment>
    );
}
export default Header;
