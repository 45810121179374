import { createAction } from "@reduxjs/toolkit";

// Auth action types
export const LOGIN = '[Auth] Login in progress...';
export const LOGIN_WITH_HASH = '[Auth] Login with hash in progress...';
export const LOGIN_SUCCESS = '[Auth] Login success!';
export const SET_TOKENS = '[Auth] Set tokens';
export const CONFIRM_LOGOUT = '[Auth] Logout confirmation in progress...';
export const LOGOUT = '[Auth] Logout in progress...';
export const LOGOUT_SUCCESS = '[Auth] Logout success!';
export const REFRESH_TOKEN_SUCCESS = '[Auth] Refreshing token success!';
export const AUTOMATED_LOGOUT = '[Auth] Automated logout received';


// Auth actions
export const login = createAction(LOGIN);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginWithHash = createAction(LOGIN_WITH_HASH);
export const setTokens = createAction(SET_TOKENS);
export const confirmLogout = createAction(CONFIRM_LOGOUT);
export const logout = createAction(LOGOUT);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);
export const automatedLogout = createAction(AUTOMATED_LOGOUT);
