import { Field, Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import AppEntityDropdown from 'components/ui/AppEntityDropdown';
import { ENTITY_BUILDING, ENTITY_SELECT_DEPARTMENT } from 'redux/entities_config';


const ObserverFormOnChange = ({ updateFilterSet }) => {
    const { values: formikValues, setFieldValue } = useFormikContext();
    const [lastFieldValues, setLastFieldValues] = useState(null);

    useEffect(() => {
        if (lastFieldValues) {
            let filterSet = {};
            let performUpdate = false;
            if (formikValues.building_id !== lastFieldValues.building_id) {
                filterSet.building_id = formikValues.building_id;
                setFieldValue('department_id', null);
                performUpdate = true;
            }

            if (formikValues.department_id !== lastFieldValues.department_id) {
                filterSet.department_id = formikValues.department_id;
                performUpdate = true;
            }

            if (performUpdate) {
                updateFilterSet(filterSet, formikValues);
            }
        }
        setLastFieldValues(formikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikValues.building_id, formikValues.department_id]);

    return null;
}

export const ClinicSummaryToolbar = ({ updateFilterSet }) => {

    const [dropdownDepartmentFilterSet, setDropdownDepartmentFilterSet] = useState();

    const handleFilterSet = useCallback((filterSet, formikValues) => {
        if ('building_id' in filterSet) {
            if (filterSet.building_id === null) {
                setDropdownDepartmentFilterSet(null);
            } else {
                setDropdownDepartmentFilterSet({
                    building_id: filterSet.building_id,
                });
            }
        }
        let crudListFilterSet = null;
        if (formikValues.department_id) {
            crudListFilterSet = {
                department_id: formikValues.department_id,
            };
        } else if (formikValues.building_id) {
            crudListFilterSet = {
                building_id: formikValues.building_id,
            };
        }
        updateFilterSet(crudListFilterSet);
    }, [updateFilterSet]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                building_id: null,
                department_id: null,
            }}
        >{() => (
            <Form>
                <ObserverFormOnChange updateFilterSet={handleFilterSet} />
                <Row>
                    <Col xs={12} lg={6} className='mb-1'>
                        <Field name="building_id" id="field_building_id" entityCode={ENTITY_BUILDING} component={AppEntityDropdown} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6} className='mb-1'>
                        <Field name="department_id" id="field_department_id" entityCode={ENTITY_SELECT_DEPARTMENT} component={AppEntityDropdown} filterSet={dropdownDepartmentFilterSet} />
                    </Col>
                </Row>
            </Form>
        )}
        </Formik>
    )
}

export default ClinicSummaryToolbar;
