import { SELECT_ORGANIZATION } from 'navigation/ROUTE_CONSTANTS';
import { Link } from 'react-router-dom';

const ChangeOrganizationButton = () => {

    return (
        <Link className="font-weight-bold text-secondary align-middle" to={SELECT_ORGANIZATION} title='Change organization'><i className="ri-logout-box-r-line" /></Link>
    );
};

export default ChangeOrganizationButton;
