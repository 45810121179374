import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { getLastRefreshTimeStart, refreshWorkstationsStart } from 'redux/actions/workstations.actions';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';

export const WorkstationsToolbar = ({ updateFilterSet }) => {
    const dispatch = useDispatch();
    const workstations = useSelector(state => state.workstations);

    const [refreshListDialogState, setRefreshListDialogState] = useState(false);

    const handleRefreshListButton = () => {
        setRefreshListDialogState(true);
    }

    const refreshListPerform = () => {
        dispatch(refreshWorkstationsStart());
        setRefreshListDialogState(false);
    }

    const refreshListHide = () => {
        setRefreshListDialogState(false);
    }

    useEffect(() => {
        dispatch(getLastRefreshTimeStart());
    }, [dispatch]);

    useEffect(() => {
        if (workstations.triggerRefreshDataTable) {
            updateFilterSet({ refreshList: true });
            dispatch(getLastRefreshTimeStart());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workstations.triggerRefreshDataTable]);

    return (
        <Fragment>

            <Row className='mb-1' style={{ minWidth: '25rem' }}>
                <Col>
                    <Button label='Refresh List' icon='ri-refresh-line' severity='secondary' className='ms-2' onClick={handleRefreshListButton} loading={workstations.epicRefreshing} />
                </Col>
                <Col>
                    <div className='text-end text-muted'>
                        <p className='mb-0'>Last Refresh</p>
                        <p className='mb-0'>{workstations.lastRefreshTime ? isoStringToFormatted(workstations.lastRefreshTime, FORMAT_DATETIME_SHORT) : ''}</p>
                    </div>
                </Col>
            </Row >

            <ConfirmDialog visible={refreshListDialogState}
                onHide={refreshListHide}
                message={
                    <p>Are you sure you want to REFRESH this list?</p>
                }
                header='Refresh List'
                acceptLabel='Start Refresh'
                rejectLabel='Cancel'
                accept={refreshListPerform} reject={refreshListHide} />
        </Fragment>
    )
}

export default WorkstationsToolbar;
