import { LOGIN_SUCCESS, LOGOUT_SUCCESS, REFRESH_TOKEN_SUCCESS, SET_TOKENS } from "redux/actions/auth.actions";
import { cleanSession } from "utils";

const initialAuthState = {
    accessToken: null,
    refreshToken: null,
    activeTenantCode: null,
};


export const AuthReducer = (state = initialAuthState, action) => {
    let activeTenantCode = null;
    switch (action.type) {
        case LOGIN_SUCCESS:
            activeTenantCode = action.payload.activeTenantCode;

            localStorage.setItem('in-access-' + activeTenantCode, action.payload.access);
            localStorage.setItem('in-refresh-' + activeTenantCode, action.payload.refresh);

            return {
                ...state,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
                activeTenantCode,
            };
        case SET_TOKENS:
            return {
                ...state,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
                activeTenantCode: action.payload.tenantCode,
            };
        case REFRESH_TOKEN_SUCCESS:
            // Refresh the access token
            activeTenantCode = state.activeTenantCode;
            localStorage.setItem('in-access-' + activeTenantCode, action.payload);

            return {
                ...state,
                accessToken: action.payload,
            };
        case LOGOUT_SUCCESS:
            if (state.activeTenantCode) {
                cleanSession(state.activeTenantCode);
            }

            return {
                ...state, // Keep the activeTenantCode
                accessToken: null,
                refreshToken: null,
            };
        default:
            return state;
    }
}
