import axios from "axios";

import { buildPaginationUrlParams } from "utils";
import { PARAM_BULK_DELETE } from "./API_CONSTANTS";

export const crudBaseGetList = (apiEndpoint, paginationParams = null) => {
    paginationParams = paginationParams ? paginationParams : {
        limit: null,
    };
    let url = apiEndpoint + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        } catch (error) {
            reject(error);
        }
    });
};

export const crudBaseGetOne = (apiEndpoint, recordId) => {
    let url = apiEndpoint + '/' + recordId;

    return new Promise((resolve, reject) => {
        axios.get(url)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const crudBasePost = (apiEndpoint, recordData) => {
    return new Promise((resolve, reject) => {
        axios.post(apiEndpoint, recordData)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const crudBasePatch = (apiEndpoint, recordData) => {
    return new Promise((resolve, reject) => {
        axios.patch(apiEndpoint + '/' + recordData.id, recordData)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const crudBaseDeleteOne = (apiEndpoint, recordId) => {
    return new Promise((resolve, reject) => {
        axios.delete(apiEndpoint + '/' + recordId)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

export const crudBaseBulkDelete = (apiEndpoint, recordIds) => {
    let postData = {
        ids: recordIds
    };
    return new Promise((resolve, reject) => {
        axios.post(apiEndpoint + '/' + PARAM_BULK_DELETE, postData)
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}