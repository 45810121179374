import { ASSET_TYPE } from "assets/static_data/backend_exports";
import {
    GET_ASSET_TYPES_SUCCESS,
    RESET_ASSET_TYPE_STATE
} from "redux/actions/asset_types.actions";

const initialAssetTypesState = {
    assetTypesList: [],
    next: null,
    previous: null,
    count: 0,
    assetTypesMap: {},
    mainAssetTypes: {
        workstation_asset_type_id: null,
        printer_asset_type_id: null,
        credit_card_reader_asset_type_id: null,
        monitor_asset_type_id: null,
    }
};

export const AssetTypesReducer = (state = initialAssetTypesState, action) => {
    let assetTypesMap = {},
        workstation_asset_type_id = null,
        printer_asset_type_id = null,
        credit_card_reader_asset_type_id = null,
        monitor_asset_type_id = null;

    switch (action.type) {
        case GET_ASSET_TYPES_SUCCESS:

            for (let assetType of action.payload.results) {
                assetTypesMap[assetType.id] = assetType.asset_type_name;

                switch (assetType.asset_type_name) {
                    case ASSET_TYPE.ASSET_TYPE_WORKSTATION:
                        workstation_asset_type_id = assetType.id;
                        break;
                    case ASSET_TYPE.ASSET_TYPE_PRINTER:
                        printer_asset_type_id = assetType.id;
                        break;
                    case ASSET_TYPE.ASSET_TYPE_CREDIT_CARD_READER:
                        credit_card_reader_asset_type_id = assetType.id;
                        break;
                    case ASSET_TYPE.ASSET_TYPE_MONITOR:
                        monitor_asset_type_id = assetType.id;
                        break;
                    default:
                        break;
                }
            }

            return {
                ...state,
                assetTypesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                assetTypesMap: assetTypesMap,
                mainAssetTypes: {
                    workstation_asset_type_id: workstation_asset_type_id,
                    printer_asset_type_id: printer_asset_type_id,
                    credit_card_reader_asset_type_id: credit_card_reader_asset_type_id,
                    monitor_asset_type_id: monitor_asset_type_id
                }
            };
        case RESET_ASSET_TYPE_STATE:
            return initialAssetTypesState;
        default:
            return state;
    }
}
