import {
    API_ORGANIZATIONS,
} from "./API_CONSTANTS";

const organizationsEndpoint = process.env.REACT_APP_API_PROTOCOL + '://' + process.env.REACT_APP_API_BASE_URL + API_ORGANIZATIONS;

export const getTenants = () => {
    return new Promise((resolve, reject) => {
        try {
            fetch(organizationsEndpoint)
                .then(response => response.json())
                .then(data => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export const getOneTenant = (tenantCode) => {
    return new Promise((resolve, reject) => {
        try {
            fetch(organizationsEndpoint + '/' + tenantCode)
                .then(response => response.json())
                .then(data => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });
}
