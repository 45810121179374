import { USER_PERMISSIONS } from "assets/static_data/backend_exports";
import {
    ENTITY_ASSET,
    ENTITY_ASSET_TYPE_CHOICE, ENTITY_BUILDING, ENTITY_CITY, ENTITY_CREDIT_CARD_READER, ENTITY_DEPARTMENT, ENTITY_EPIC_DEPARTMENT,
    ENTITY_FUNCTIONAL_TYPE,
    ENTITY_LEASED_ASSET,
    ENTITY_LEGACY_DEPARTMENT, ENTITY_OU, ENTITY_SPECIALTY, ENTITY_STATE, ENTITY_WAP_ASSET, ENTITY_WORKFLOW, ENTITY_WORKFLOW_DEFAULT_REQ, ENTITY_WORKSTATION
} from "redux/entities_config";


export const entityBulkUpdatePermissions = {
    [ENTITY_STATE]: USER_PERMISSIONS.STATES_BULK_UPDATE,
    [ENTITY_CITY]: USER_PERMISSIONS.CITIES_BULK_UPDATE,
    [ENTITY_BUILDING]: USER_PERMISSIONS.BUILDINGS_BULK_UPDATE,
    [ENTITY_SPECIALTY]: USER_PERMISSIONS.SPECIALTIES_BULK_UPDATE,
    [ENTITY_OU]: USER_PERMISSIONS.OUS_BULK_UPDATE,
    [ENTITY_LEGACY_DEPARTMENT]: USER_PERMISSIONS.LEGACY_DEPARTMENTS_BULK_UPDATE,
    [ENTITY_DEPARTMENT]: USER_PERMISSIONS.DEPARTMENTS_BULK_UPDATE,
    [ENTITY_EPIC_DEPARTMENT]: USER_PERMISSIONS.EPIC_DEPARTMENTS_BULK_UPDATE,
    [ENTITY_WORKFLOW]: USER_PERMISSIONS.WORKFLOWS_BULK_UPDATE,
    [ENTITY_WORKFLOW_DEFAULT_REQ]: USER_PERMISSIONS.WORKFLOW_DEFAULT_REQS_BULK_UPDATE,
    [ENTITY_ASSET_TYPE_CHOICE]: USER_PERMISSIONS.DROPDOWNS_BULK_UPDATE,
    [ENTITY_ASSET]: USER_PERMISSIONS.ASSETS_ALL_BULK_UPDATE,
    [ENTITY_WAP_ASSET]: USER_PERMISSIONS.ASSETS_ALL_BULK_UPDATE,
    [ENTITY_WORKSTATION]: USER_PERMISSIONS.WORKSTATIONS_BULK_UPDATE,
    [ENTITY_CREDIT_CARD_READER]: USER_PERMISSIONS.ASSETS_ALL_BULK_UPDATE,
    [ENTITY_LEASED_ASSET]: USER_PERMISSIONS.LEASED_ASSETS_BULK_UPDATE,
    [ENTITY_FUNCTIONAL_TYPE]: USER_PERMISSIONS.FUNCTIONAL_TYPES_BULK_UPDATE,
}