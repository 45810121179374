import { createAction } from "@reduxjs/toolkit";

// Role action types
export const GET_ROLES = '[ROLES] Getting roles';
export const GET_ROLES_SUCCESS = '[ROLES] Getting roles success';
export const SEARCH_ROLES = '[ROLES] Searching roles';
export const SEARCH_ROLES_SUCCESS = '[ROLES] Searching roles success';
export const CREATE_ROLE = '[ROLES] Creating new role';
export const CREATE_ROLE_SUCCESS = '[ROLES] New role created with success';
export const DELETE_ROLE = '[ROLES] Deleting role';
export const UPDATE_ROLE = '[ROLES] Updating role';
export const UPDATE_ROLE_SUCCESS = '[ROLES] Role updated with success';
export const RESET_ROLE_STATE = '[ROLES] Reset role state'
export const RESET_ROLES_FOUND = '[ROLES] Reset roles found'
export const GET_ROLES_MAP = '[ROLES] Getting roles map';
export const GET_ROLES_MAP_SUCCESS = '[ROLES] Getting roles map success';

// Role actions
export const getRoles = createAction(GET_ROLES);
export const getRolesSuccess = createAction(GET_ROLES_SUCCESS);
export const searchRoles = createAction(SEARCH_ROLES);
export const searchRolesSuccess = createAction(SEARCH_ROLES_SUCCESS);
export const createRoleSuccess = createAction(CREATE_ROLE_SUCCESS);
export const updateRoleSuccess = createAction(UPDATE_ROLE_SUCCESS);
export const resetRoleState = createAction(RESET_ROLE_STATE);
export const resetRolesFound = createAction(RESET_ROLES_FOUND);
export const getRolesMap = createAction(GET_ROLES_MAP);
export const getRolesMapSuccess = createAction(GET_ROLES_MAP_SUCCESS);

export const createRole = createAction(CREATE_ROLE);
export const updateRole = createAction(UPDATE_ROLE);
export const deleteRole = createAction(DELETE_ROLE);
