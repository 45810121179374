import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_BUILDING } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './BuildingsList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'description': rowData['Name'],
        'code': rowData['Code'],
        'street': rowData['Street'],
        'city_name': rowData['City'],
        'state_name': rowData['State'],
        'zip': rowData['Zip Code'],
        'site_code': rowData['Site Code'],
        'active': rowData['Status'] === 'Active' ? true : false,
    }
};

const apiToExcelColumn = {
    'description': 'Name',
    'code': 'Code',
    'street': 'Street',
    'city_name': 'City',
    'state_name': 'State',
    'zip': 'Zip Code',
    'site_code': 'Site Code',
    'active': 'Status',
};

const foreignKeyColumns = [
    'City',
    'State',
];

export const BuildingsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_BUILDING}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default BuildingsBulkUpdate;
