import AppFormik from 'components/ui/AppFormik';
import { Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import AppInputFieldComponent from 'components/ui/AppInputFieldComponent';
import { USER_PROFILE } from 'navigation/ROUTE_CONSTANTS';
import { Link } from 'react-router-dom';
import { setNavigateTo, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { updateUser } from 'services/userService';


const EditProfile = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">Edit Profile</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>

                            <AppFormik
                                enableReinitialize={true}
                                initialValues={{
                                    first_name: userLogged?.first_name ? userLogged.first_name : '',
                                    last_name: userLogged?.last_name ? userLogged.last_name : '',
                                }}
                                validationSchema={Yup.object({
                                    first_name: Yup.string()
                                        .required('First Name is required'),
                                    last_name: Yup.string()
                                        .required('Last Name is required'),
                                })}
                                serverFieldMap={{
                                    first_name: 'fist_name',
                                    last_name: 'last_name',
                                }}
                                onSubmit={(values) => {
                                    updateUser({
                                        first_name: values.first_name,
                                        last_name: values.last_name,
                                    })
                                        .then((response) => {
                                            if (response.username) {
                                                dispatch(setToast({
                                                    severity: TOAST_SEVERITY_SUCCESS,
                                                    summary: 'Success',
                                                    detail: 'Profile updated successfully',
                                                }));
                                                dispatch(setNavigateTo(USER_PROFILE));
                                            }
                                        })
                                        .catch((err) => {
                                        });
                                }}
                            >
                                {() => (
                                    <Form className="justify-content-center text-center">
                                        <FormGroup className="row justify-content-center">
                                            <AppInputFieldComponent
                                                inputLabel="First Name"
                                                fieldName="first_name"
                                                inputPlaceHolder="First Name"
                                            />
                                        </FormGroup>
                                        <FormGroup className="row justify-content-center">
                                            <AppInputFieldComponent
                                                inputLabel="Last Name"
                                                fieldName="last_name"
                                                inputPlaceHolder="Last Name"
                                            />
                                        </FormGroup>
                                        <div className="text-center mt-5">
                                            <Link to={USER_PROFILE} className="btn btn-secondary w-100 mw-333 me-4">Cancel</Link>
                                            <Button type='submit' color='primary' className='w-100 mw-333'>Save</Button>
                                        </div>

                                    </Form>
                                )}
                            </AppFormik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}

export default EditProfile;