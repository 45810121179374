import { Fragment, useEffect, useState } from "react";

export const BulkUpdateErrors = ({ backendErrors, fromApiToExcelColumn }) => {
    const [isObject, setIsObject] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        if (!backendErrors) {
            setIsObject(null);

        } else if (typeof backendErrors === 'object') {
            setIsObject(true);

            // Loop over all keys and display the error messages per key. Convert key to column name by using fromApiToExcelColumn
            let localErrorMessages = {};

            const flatErrorMessage = (resolveColumnName, importErrors) => {
                if (typeof resolveColumnName === 'object') {
                    Object.keys(importErrors).forEach((errorKey) => {
                        if (errorKey in resolveColumnName) {
                            let columnName = flatErrorMessage(resolveColumnName[errorKey], importErrors[errorKey]);
                            if (columnName) {
                                localErrorMessages[columnName] = importErrors[errorKey];
                            }
                        } else {
                            localErrorMessages[errorKey] = importErrors[errorKey];
                        }
                    });
                    return null;
                } else {
                    return resolveColumnName;
                }
            };

            flatErrorMessage(fromApiToExcelColumn, backendErrors);
            setErrorMessages(localErrorMessages);
        } else {
            setIsObject(false);
        }
    }, [backendErrors, fromApiToExcelColumn]);

    return (
        <Fragment>
            {isObject &&
                <ul className="list-unstyled">
                    {Object.keys(errorMessages).map((key) => {
                        return (
                            <li key={key}>
                                <b>{key}:</b> {errorMessages[key].join(', ')}
                            </li>
                        );
                    })}
                </ul>
            }
            {isObject === false && <pre>{JSON.stringify(backendErrors, null, 2)}</pre>}
        </Fragment>
    );
}

export default BulkUpdateErrors;
