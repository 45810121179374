import { Field, Form } from 'formik';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';

import { WORKFLOW_REQ_CATEGORY_MONITOR } from 'assets/static_data/backend_exports';
import { AppFormik } from 'components/ui/AppFormik';
import { serverValidationReset } from 'redux/actions/server_validations.actions';
import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { getWorkflowReqCategory } from 'redux/actions/workflow_req_categories.actions';
import { TOAST_SEVERITY_ERROR } from 'redux/reducers/ui.reducer';
import { API_WORKFLOW_DEFAULT_REQS, API_WORKFLOW_REQS } from 'services/API_CONSTANTS';
import { crudBaseGetOne, crudBasePatch, crudBasePost } from 'services/crudBaseService';


export const WorkflowReqDefaultForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        needs_workflow_req_category: {},
        monitor_assets_needed: '1',
    });

    const workflowReqCategoriesMap = useSelector(state => state.workflowReqCategories.workflowReqCategoriesMap);

    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const [activeRecordWorkflowReqs, setActiveRecordWorkflowReqs] = useState([]);
    const [saveWorkflowReqs, setSaveWorkflowReqs] = useState([]);

    useEffect(() => {
        dispatch(enableOverlay('Loading default needs...'));
        if (Object.keys(workflowReqCategoriesMap).length === 0) {
            dispatch(getWorkflowReqCategory());
        }
        if (recordId) {
            crudBaseGetOne(API_WORKFLOW_DEFAULT_REQS, recordId).then((data) => {
                let needsWorkflowReqCategory = {};
                let monitorAssetsNeeded = '1';
                data.workflowreq_set.forEach((item) => {
                    needsWorkflowReqCategory['id_' + item.workflow_req_category.id] = item.num_assets_needed > 0;
                    if (item.workflow_req_category.workflow_req_category_name === WORKFLOW_REQ_CATEGORY_MONITOR) {
                        monitorAssetsNeeded = item.num_assets_needed > 1 ? '2' : '1';
                    }
                });
                setInitialFormValues({
                    needs_workflow_req_category: needsWorkflowReqCategory,
                    monitor_assets_needed: monitorAssetsNeeded,
                });

                let activeRecordWorkflowReqs = data.workflowreq_set.map((item) => ({
                    id: item.id,
                    workflow_req_category_id: item.workflow_req_category.id,
                    num_assets_needed: item.num_assets_needed,
                }));
                setActiveRecordWorkflowReqs(activeRecordWorkflowReqs);

            }).catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error when retrieving default needs',
                    detail: Object.values(err.response.data).map((value, index) => (
                        value
                    )).join('. '),
                }));
            }).finally(() => {
                dispatch(disableOverlay());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    useEffect(() => {
        if (formSubmitStarted) {
            if (saveWorkflowReqs.length > 0) {
                dispatch(enableOverlay('Saving default needs...'));
                let serviceMethod;
                let workflowReq = saveWorkflowReqs[0];
                if (workflowReq.id) {
                    serviceMethod = crudBasePatch;
                } else {
                    serviceMethod = crudBasePost;
                }

                serviceMethod(API_WORKFLOW_REQS, workflowReq).then((data) => {
                    if (saveWorkflowReqs.length > 0) {
                        setSaveWorkflowReqs(saveWorkflowReqs.slice(1));
                    } else {
                        setSaveWorkflowReqs([]);
                    }
                }).catch((err) => {
                    setFormSubmitStarted(false);
                    dispatch(disableOverlay());
                    dispatch(serverValidationReset());
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Error when saving default needs',
                        detail: Object.values(err.response.data).map((value, index) => (
                            value
                        )).join('. '),
                    }));
                });
            } else {
                dispatch(disableOverlay());
                close({ refreshList: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveWorkflowReqs]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            serverFieldMap={{
            }}
            onSubmit={(values) => {
                setFormSubmitStarted(true);
                // Loop over values.needs_workflow_req_category checking if the record exists in activeRecordWorkflowReqs matching workflow_req_category_id with id_{number}
                // If it exists, check if the value is true, if not, assign zero to num_assets_needed
                // If it doesn't exist, create a new record with workflow_id, workflow_req_category_id, and num_assets_needed
                let saveWorkflowReqs = [];
                for (let key in values.needs_workflow_req_category) {
                    let categoryId = key.split('_')[1];
                    let formAssetsNeeded = values.needs_workflow_req_category[key] ? 1 : 0;

                    let isCategoryMonitor = (workflowReqCategoriesMap[categoryId] === WORKFLOW_REQ_CATEGORY_MONITOR);
                    // If the category is Monitor and is checked
                    if (formAssetsNeeded && isCategoryMonitor) {
                        formAssetsNeeded = parseInt(values.monitor_assets_needed);
                    }

                    let activeRecordWorkflowReq = activeRecordWorkflowReqs.find(item => item.workflow_req_category_id === parseInt(categoryId));
                    if (activeRecordWorkflowReq) {
                        if (formAssetsNeeded !== activeRecordWorkflowReq.num_assets_needed) {
                            saveWorkflowReqs.push({
                                id: activeRecordWorkflowReq.id,
                                num_assets_needed: formAssetsNeeded,
                            });
                        }
                    } else {
                        if (formAssetsNeeded) {
                            saveWorkflowReqs.push({
                                workflow_id: recordId,
                                workflow_req_category_id: categoryId,
                                num_assets_needed: formAssetsNeeded,
                            });
                        }
                    }
                }

                setSaveWorkflowReqs(saveWorkflowReqs);
            }}
        >{({ values }) => (
            <Form>
                <Card title="Needs by default">
                    <Row>
                        {Object.entries(workflowReqCategoriesMap).map(([categoryId, categoryName]) => {
                            let showMonitorAssetsNeeded = (categoryName === WORKFLOW_REQ_CATEGORY_MONITOR);
                            let disableMonitorAssetsNeeded = false;
                            if (showMonitorAssetsNeeded) {
                                disableMonitorAssetsNeeded = !values['needs_workflow_req_category']['id_' + categoryId];
                            }
                            return (
                                <Col xs={12} md={6} key={categoryId} className='mb-3'>
                                    <Field inputId={'needs_workflow_req_category_' + categoryId} name={'needs_workflow_req_category.id_' + categoryId} type="checkbox" as={Checkbox} />
                                    <Label for={'needs_workflow_req_category_' + categoryId} className='ms-2 my-0'>{categoryName}</Label>

                                    {showMonitorAssetsNeeded &&
                                        <Fragment>
                                            <Label className='ms-3 my-0'>
                                                <Field name='monitor_assets_needed' value='1' type="radio" className='me-1' disabled={disableMonitorAssetsNeeded} />
                                                x1
                                            </Label>
                                            <Label className='ms-3 my-0'>
                                                <Field name='monitor_assets_needed' value='2' type="radio" className='me-1' disabled={disableMonitorAssetsNeeded} />
                                                x2
                                            </Label>
                                        </Fragment>
                                    }
                                </Col>
                            );
                        })}
                    </Row>
                </Card>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit'>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default WorkflowReqDefaultForm;
