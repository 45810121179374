import { GET_ADMIN_SETTINGS_VALUE_SUCCESS } from "redux/actions/admin.settings.actions";

const initialAdminSettingsState = {
};

export const AdminSettingsReducer = (state = initialAdminSettingsState, action) => {
    switch (action.type) {
        case GET_ADMIN_SETTINGS_VALUE_SUCCESS:
            state[action.payload.key] = action.payload.value;
            return state;
        default:
            return state;
    }
}
