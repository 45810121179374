import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import { entityBulkUpdatePermissions } from 'navigation/action_permissions';
import { crudBaseBulkDeleteStart, crudBaseDeleteStart, crudBaseGetListStart, crudBaseGetOneReset, crudBaseResetState } from 'redux/actions/crud_base.actions';
import { disableOverlay, enableOverlay } from 'redux/actions/ui.actions';
import { ENTITIES_CONFIG, INITIAL_ROWS_PER_PAGE } from 'redux/entities_config';
import { crudBaseGetList } from 'services/crudBaseService';
import { getObjectValueFromPath, saveAsExcelFile, strToLowercaseUnderscore } from 'utils';

import 'styles/datatables.scss';
import 'styles/list_components.scss';


export const CrudBaseList = ({
    entityCode, CrudBaseForm = null, dataTableColumns,
    exportExcelFieldMap, exportExcelColumnInfo = null, bulkUpdateRoute = null,
    mainTitle = null, bulkUpdateSupportAdd = true, customEntityLabel = null,
    hasCreateButton = true, hasEditButton = true, hasDeleteButton = true,
    hasGlobalSearchField = true, initialRowsPerPage = null, StartToolbarContent = null,
    dataTableExpansionDefinition = null,
}) => {
    const dispatch = useDispatch();
    const dt = useRef(null);
    const history = useHistory();

    const [entityConfig] = useState(ENTITIES_CONFIG[entityCode]);
    const [headerTitle] = useState(mainTitle ? mainTitle : 'Manage ' + entityConfig.namePlural);

    const tableRowsData = useSelector(state => state.crudBase.list);
    const tableRowsCount = useSelector(state => state.crudBase.count);
    const tableLoading = useSelector(state => state.crudBase.loading);
    const refreshList = useSelector(state => state.crudBase.refreshList);
    const userPermissions = useSelector(state => state.users.userPermissions);

    const [selectedTableRows, setSelectedTableRows] = useState([]);
    const [createEditFormDialogState, setCreateEditFormDialogState] = useState(false);
    const [deleteRecordDialogState, setDeleteRecordDialogState] = useState(false);
    const [deleteRecordMessage, setDeleteRecordMessage] = useState('');
    const [entityLabel, setEntityLabel] = useState('');
    const [componentInstanceSlug, setComponentInstanceSlug] = useState('');

    const [formModalTitle, setFormModalTitle] = useState('');
    const [isCreateForm, setIsCreateForm] = useState(false);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || INITIAL_ROWS_PER_PAGE);

    const [expandedRows, setExpandedRows] = useState(null);

    const [paginationState, setPaginationState] = useState({
        limit: null,
        page: 1,
        sortField: null,
        search: null,
        filterSet: null,
    });

    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 500);

    const [tableState, setTableState] = useState({
        first: 0,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const refreshRowsData = useCallback(() => {
        dispatch(crudBaseGetListStart({
            entityCode,
            paginationParams: paginationState
        }));
        setSelectedTableRows([]);
    }, [dispatch, entityCode, paginationState]);

    useEffect(() => {
        return () => {
            dispatch(crudBaseResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (paginationState.limit) {
            // Trigger refresh only after the initial limit is set, to prevent double refresh at component mount
            refreshRowsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshRowsData]);

    useEffect(() => {
        if (customEntityLabel) {
            setEntityLabel(customEntityLabel);
        } else {
            setEntityLabel(entityConfig.nameSingular);
        }

        let localComponentInstanceSlug = mainTitle ? strToLowercaseUnderscore(mainTitle) : strToLowercaseUnderscore(entityConfig.namePlural);
        setComponentInstanceSlug(localComponentInstanceSlug);
    }, [customEntityLabel, entityConfig.namePlural, entityConfig.nameSingular, mainTitle]);

    useEffect(() => {
        setPaginationState((state) => ({
            ...state,
            limit: rowsPerPage,
        }));
    }, [rowsPerPage]);

    useEffect(() => {
        setPaginationState((state) => ({
            ...state,
            search: globalFilterDebouncedValue,
        }));
    }, [globalFilterDebouncedValue]);

    useEffect(() => {
        if (refreshList) {
            refreshRowsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshList]);

    const onPage = (event) => {
        setTableState(event);
        setPaginationState((state) => ({
            ...state,
            page: event.page + 1,
        }));
    };

    const onSort = (event) => {
        setTableState(event);
        setPaginationState((state) => ({
            ...state,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
        }));
    };

    const updateFilterSet = useCallback((newFilterSet) => {
        setPaginationState((state) => ({
            ...state,
            filterSet: newFilterSet,
        }));
    }, []);

    const performDeleteRecord = () => {
        if (selectedTableRows.length === 1) {
            dispatch(crudBaseDeleteStart({
                entityCode: entityCode,
                recordId: selectedTableRows[0].id,
            }));
            setDeleteRecordDialogState(false);
        } else {
            let recordIds = selectedTableRows.map((row) => row.id);
            dispatch(crudBaseBulkDeleteStart({
                entityCode: entityCode,
                recordIds,
            }));
            setDeleteRecordDialogState(false);
        }
    };

    const hideDeleteRowDialog = () => {
        setDeleteRecordDialogState(false);
    };

    const openCreateDialog = () => {
        setFormModalTitle(`New ${entityLabel}`);
        setIsCreateForm(true);
        setCreateEditFormDialogState(true);
    };

    const openEditDialog = () => {
        setFormModalTitle(`Edit ${entityLabel}`);
        setIsCreateForm(false);
        setCreateEditFormDialogState(true);
    };

    const closeCreateEditForm = (options = {}) => {
        setCreateEditFormDialogState(false);
        if (formSubmittedSuccess || options.refreshList) {
            refreshRowsData();
        }
        dispatch(crudBaseGetOneReset());
    };

    const handleRefreshButton = () => {
        refreshRowsData();
    };

    const exportCSV = () => {
        dispatch(enableOverlay(`Exporting ${entityConfig.namePlural} to csv (${tableRowsCount} rows)... `));
        crudBaseGetList(
            entityConfig.apiEndpoint,
            {
                ...paginationState,
                limit: 99999,
                page: 1,
            }
        ).then((response) => {
            if ('results' in response && response.results.length >= 0) {

                let fileName = dt.current.props.exportFilename;

                let dataRowsForExport = response.results.map(exportExcelFieldMap);

                // Extract column headers
                let columnHeaders = Object.keys(dataRowsForExport[0]);

                // Extract data rows
                let csvContent = dataRowsForExport.map((row) => {
                    row = Object.values(row);
                    return row
                        .map(String)
                        .map(v => v.replaceAll('"', '""'))
                        .map(v => `"${v}"`)
                        .join(',')
                });

                csvContent.unshift(columnHeaders.join(','));
                csvContent = csvContent.join('\r\n');

                // Create a blob
                var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);

                // Create a link to download it
                var csvLink = document.createElement('a');
                csvLink.href = url;
                csvLink.setAttribute('download', fileName);
                csvLink.click();
            }
        }).finally(() => {
            dispatch(disableOverlay());
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            dispatch(enableOverlay(`Exporting ${entityConfig.namePlural} to xlsx (${tableRowsCount} rows)... `));
            crudBaseGetList(
                entityConfig.apiEndpoint,
                {
                    ...paginationState,
                    limit: 99999,
                    page: 1,
                }
            ).then((response) => {
                if ('results' in response && response.results.length >= 0) {
                    let dataRowsForExport = response.results.map(exportExcelFieldMap);

                    const worksheet = xlsx.utils.json_to_sheet(dataRowsForExport, { dense: true });

                    if (exportExcelColumnInfo) {
                        worksheet['!cols'] = exportExcelColumnInfo;
                    }

                    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                    const excelBuffer = xlsx.write(workbook, {
                        bookType: 'xlsx',
                        type: 'array'
                    });

                    let exportFilename = dt.current.props.exportFilename;
                    saveAsExcelFile(exportFilename, excelBuffer);
                }
            }).finally(() => {
                dispatch(disableOverlay());
            });
        });
    };

    const handleBulkUpdateButton = () => {
        history.push(bulkUpdateRoute);
    };

    const startToolbarTemplate = () => StartToolbarContent ? <StartToolbarContent updateFilterSet={updateFilterSet} /> : null;

    const endToolbarTemplate = () => (
        <Fragment>
            {bulkUpdateRoute && userPermissions && userPermissions.includes(entityBulkUpdatePermissions[entityCode]) &&
                <Button label={bulkUpdateSupportAdd ? 'Bulk Add/Update' : 'Bulk Update'} icon="ri-upload-2-line" className="record-action-button ms-2" onClick={handleBulkUpdateButton} />
            }
            <Button label="CSV" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportCSV} />
            <Button label="XLS" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportExcel} />
        </Fragment>
    );

    const onClickButtonDelete = () => {
        if (selectedTableRows.length === 1) {
            setDeleteRecordMessage(`Are you sure you want to delete ${entityLabel}: "${selectedTableRows[0][entityConfig.labelField]}" ?`);
        } else {
            setDeleteRecordMessage(`Are you sure you want to delete ${selectedTableRows.length} ${entityConfig.namePlural} ?`);
        }
        setDeleteRecordDialogState(true);
    };

    const paginatorLeft = () => {
        return (
            <Fragment>
                {hasEditButton &&
                    <Button label="Edit" icon="ri-pencil-fill" className="record-action-button me-3"
                        onClick={openEditDialog}
                        disabled={!selectedTableRows || selectedTableRows.length !== 1}
                    />
                }
                {hasDeleteButton &&
                    <Button label="Delete" icon="ri-delete-bin-line" className="record-action-button me-3"
                        onClick={() => onClickButtonDelete()}
                        disabled={!selectedTableRows || selectedTableRows.length === 0}
                    />
                }
                {hasGlobalSearchField &&
                    <Fragment>
                        <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                        <span className="p-input-icon-left">
                            <i className="ri-search-line" style={{ marginTop: -10 }} />
                            <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                        </span>
                    </Fragment>
                }
            </Fragment>
        );
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: INITIAL_ROWS_PER_PAGE, value: INITIAL_ROWS_PER_PAGE },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <Button icon="ri-refresh-line" severity='secondary' outlined size='small' className="me-2" onClick={handleRefreshButton} />
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />
                </Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <span className="me-2">Rows: {options.totalRecords} |</span>
                    <span className="page-number-button">Page: {options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    const rowExpansionTemplate = useCallback((rowData) => {
        return (
            <Card>
                <CardBody>
                    <Row>
                        {dataTableExpansionDefinition.map((column, index) => {
                            let body = null;
                            if (column.body) {
                                body = column.body(rowData);
                            } else {
                                body = getObjectValueFromPath(rowData, column.field);
                            }
                            return (
                                <Col xs={12} md={4} key={index}>
                                    <b className='text-muted'>{column.header}</b>: {body}
                                </Col>
                            )
                        })}
                    </Row>
                </CardBody>
            </Card>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">{headerTitle}</h4>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        {hasCreateButton &&
                                            <Button label={`New ${entityLabel}`} className="add-record-button" onClick={openCreateDialog} />
                                        }
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row className="datatable-crud-base">
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Toolbar className="list-component-toolbar" start={startToolbarTemplate} end={endToolbarTemplate} />
                                <DataTable value={tableRowsData}
                                    size='small'
                                    dataKey="id"
                                    rows={rowsPerPage}
                                    scrollable scrollHeight="56vh"
                                    paginator
                                    paginatorPosition='top'
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorLeft={paginatorLeft}
                                    totalRecords={tableRowsCount}

                                    lazy
                                    loading={tableLoading}
                                    onPage={onPage}
                                    onSort={onSort}
                                    sortField={tableState.sortField}
                                    sortOrder={tableState.sortOrder}
                                    first={tableState.first}

                                    emptyMessage={`No ${entityConfig.namePlural} found.`}
                                    selection={selectedTableRows}
                                    onSelectionChange={(e) => setSelectedTableRows(e.value)}
                                    ref={dt}
                                    exportFilename={componentInstanceSlug}
                                    // stateStorage='session' stateKey={'dt-l-state-' + componentInstanceSlug}

                                    rowExpansionTemplate={rowExpansionTemplate}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                    resizableColumns
                                    columnResizeMode='expand'
                                >
                                    {CrudBaseForm &&
                                        <Column selectionMode='multiple' />
                                    }

                                    {dataTableExpansionDefinition &&
                                        <Column expander={true} />
                                    }

                                    {dataTableColumns.map((column, index) => (
                                        <Column key={index} {...column} />
                                    ))}

                                </DataTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {CrudBaseForm &&
                <Dialog visible={createEditFormDialogState} header={formModalTitle} modal onHide={closeCreateEditForm} className="p-fluid" maximizable>
                    <CrudBaseForm close={closeCreateEditForm} recordId={(!isCreateForm && selectedTableRows.length > 0) ? selectedTableRows[0].id : null} />
                </Dialog>
            }

            <ConfirmDialog visible={deleteRecordDialogState}
                onHide={hideDeleteRowDialog}
                className='delete-confirm-dialog'
                message={deleteRecordMessage}
                header={`Delete ${entityLabel}`}
                acceptLabel={`Delete ${entityLabel}`}
                rejectLabel='Cancel'
                accept={performDeleteRecord} reject={hideDeleteRowDialog} />

        </Fragment>
    );
};

export default CrudBaseList;
