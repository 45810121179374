import { Fragment } from "react";
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

export const Sidebar = (props) => {

    return (
        <Fragment>
            <div className='vertical-menu'>
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent />
                        </SimpleBar>
                    ) : <SidebarContent />}
                </div>
            </div>
        </Fragment>
    );
}

export default Sidebar;
