import { CrudBaseList } from 'components/base/CrudBaseList';
import AssetNotes from 'components/ui/AssetNotes';
import AssetTags from 'components/ui/AssetTags';
import { ENTITY_ASSETS_TO_FOLLOW_UP } from 'redux/entities_config';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import { AssetForm } from '../all_assets/AssetForm';


const dataTableColumns = [
    {
        field: 'asset_type.asset_type_name',
        header: 'Asset Type',
        sortable: true,
    },
    {
        field: 'id',
        header: 'Asset ID',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'notes',
        header: 'Notes',
        body: (rowData) => (
            <AssetNotes notes={rowData.notes} />
        ),
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        sortable: true,
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => (
            rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : ''
        ),
    }
];

const dataTableExpansionDefinition = [
    {
        field: 'the_make.choice',
        header: 'Make',
    },
    {
        field: 'the_model.choice',
        header: 'Model',
    },
    {
        field: 'building.street',
        header: 'Street',
    },
    {
        field: 'suite_num',
        header: 'Suite #',
    },
    {
        field: 'location',
        header: 'Location',
    },
    {
        field: 'room',
        header: 'Room',
    },
    {
        header: 'Tags',
        body: (rowData) => (
            <AssetTags asset={rowData} />
        ),
    },
    {
        field: 'notes',
        header: 'Notes',
        body: (rowData) => (
            <AssetNotes notes={rowData.notes} />
        ),
    },
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Asset Type': rowData.asset_type.asset_type_name,
        'Asset Id': rowData.id,
        'Serial #': rowData.serial_num,
        'MSIT Tag': rowData.msit_tag,
        'Name': rowData.asset_name,
        'Notes': rowData.notes,
        'Make': rowData.the_make.choice,
        'Model': rowData.the_model.choice,
        'School': rowData.ou.ou_name,
        'Department': rowData.department ? rowData.department.department_name : '',
        'Street': rowData.building.street ? rowData.building.street : '',
        'Suite #': rowData.suite_num,
        'Location': rowData.location,
        'Room': rowData.room,
        'Last Modified By': rowData.last_modified_by.username,
        'Last Modified Date': rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 15 }, // Asset Type
    { wch: 15 }, // Asset Id
    { wch: 15 }, // Serial #
    { wch: 15 }, // MSIT Tag
    { wch: 40 }, // Name
    { wch: 40 }, // Notes
    { wch: 20 }, // Make
    { wch: 30 }, // Model
    { wch: 10 }, // School
    { wch: 50 }, // Department
    { wch: 40 }, // Street
    { wch: 10 }, // Suite #
    { wch: 10 }, // Location
    { wch: 10 }, // Room
    { wch: 20 }, // Last Modified By
    { wch: 20 }, // Last Modified Date
];


const AssetsToFollowUpList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_ASSETS_TO_FOLLOW_UP}
            CrudBaseForm={AssetForm}
            dataTableColumns={dataTableColumns}
            dataTableExpansionDefinition={dataTableExpansionDefinition}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            hasCreateButton={false}
        />
    );
};

export default AssetsToFollowUpList;
