export const USERS_API = '/users';
export const USERS_REGISTER = USERS_API + '/register';
export const LOGIN = USERS_API + '/token/create';
export const TOKEN_REFRESH = USERS_API + '/token/refresh';
export const TOKEN_INVALIDATE = USERS_API + '/token/invalidate';
export const TOKEN_LOGOUT_ALL = USERS_API + '/token/logout_all';
export const USER_CHANGE_PASSWORD = USERS_API + '/change-password';
export const USER_UPDATE = '/update';
export const PASSWORD_RESET_BY_EMAIL = '/password_reset';
export const PASSWORD_RESET = '/password_reset/confirm';

// Common
export const API_ROLES = '/roles';
export const API_ORGANIZATION_SETTINGS = '/organization_settings';
export const API_USERS_PERMISSIONS = USERS_API + '/permissions';
export const API_USERS_ADMIN = USERS_API + '/admin';
export const API_ASSET_OWNERS = USERS_API + '/asset_owners';

// Workstation
export const API_FUNCTIONAL_TYPES = '/functional_types';
export const API_EPIC_WORKSTATIONS = '/epic_workstations';
export const API_SERVICE_NOW_WORKSTATIONS = '/service_now_workstations';
export const API_UPDATE_SERVICE_NOW_WORKSTATIONS = '/update_servicenow_workstations';
export const API_DUPLICATIONS = '/duplications';
export const API_REFRESH_EPIC_WORKSTATIONS = '/refresh_epic_workstations';
export const API_DUPLICATE_EPIC_WORKSTATIONS = '/duplicate_epic_workstations';
export const API_CREATE_EPIC_TICKET = '/create_epic_ticket';
export const API_STAT_DUPLICATIONS_PER_STATUS = '/workstation_stats/duplications_per_status';
export const API_STAT_DUPLICATIONS_PER_MONTH = '/workstation_stats/duplications_per_month';
export const API_STAT_LAST_DUPLICATION_PER_DEPARTMENT = '/workstation_stats/last_duplication_per_department';

// Inventory
export const API_STATES = '/states';
export const API_CITIES = '/cities';
export const API_BUILDINGS = '/buildings';
export const API_SPECIALTIES = '/specialties';
export const API_OUS = '/ous';
export const API_LEGACY_DEPARTMENTS = '/legacy_departments';
export const API_DEPARTMENTS = '/departments';
export const API_SELECT_DEPARTMENTS = '/select_departments';
export const API_EPIC_DEPARTMENTS = '/epic_departments';
export const API_WORKFLOWS = '/workflows';
export const API_WORKFLOW_REQS = '/workflow_reqs';
export const API_WORKFLOW_DEFAULT_REQS = '/workflow_default_reqs';
export const API_WORKFLOW_REQ_CATEGORIES = '/workflow_req_categories';
export const API_GAP_REPORT = '/gap_report';
export const API_CLINIC_SUMMARY = '/clinic_summary';
export const API_ASSET_TYPE_CHOICES = '/asset_type_choices';
export const API_ASSET_TYPES = '/asset_types';
export const API_WORKSTATION_TYPES = '/workstation_types';
export const API_PRINTER_TYPES = '/printer_types';
export const API_PRINTER_TRAY_CONTENTS = '/printer_tray_contents';
export const API_ASSETS = '/assets';
export const API_ASSETS_SET_PARENT = API_ASSETS + '/set_parent';
export const API_WORKSTATIONS = '/workstations';
export const API_WORKSTATIONS_WO_PRIMARY_USE = '/workstations_wo_primary_use';
export const API_WORKSTATIONS_TDR_NOT_PASSED = '/workstations_tdr_not_passed';
export const API_ASSETS_TO_FOLLOW_UP = '/assets_to_follow_up';
export const API_WAP_ASSETS = '/wap_assets';
export const API_CREDIT_CARD_READERS = '/credit_card_readers';
export const API_LEASED_ASSETS = '/leased_assets';
export const API_SELECT_CREDIT_CARD_READERS = '/select_credit_card_readers';
export const API_SELECT_PRINTERS = '/select_printers';
export const API_ORGANIZATIONS = '/organizations'; // This endpoint operates solely on the provider API
export const API_STAT_WORKSTATIONS_PER_TDR_STATUS = 'inventory_stats/workstations_per_tdr_status';
export const API_STAT_WORKSTATIONS_PER_INFORMATION_STATUS = 'inventory_stats/workstations_per_information_status';
export const API_STAT_WORKSTATIONS_PER_TYPE = 'inventory_stats/workstations_per_type';
export const API_STAT_WORKSTATIONS_PER_DEPARTMENT = 'inventory_stats/workstations_per_department';
export const API_STAT_WORKSTATIONS_PER_BUILDING = 'inventory_stats/workstations_per_building';

// Generic params
export const URL_PARAM_DATA_IMPORT = 'data_import';
export const PARAM_BULK_DELETE = 'bulk_delete';