import { Tag } from 'primereact/tag';
import React from 'react';

export const AssetTags = ({ asset }) => {
    return (
        <span className='fs-5'>
            {asset.printer?.zeno_num && <Tag className='me-2' severity="success" value={'Zeno:' + asset.printer.zeno_num} />}
            {asset.cam_tag_num && <Tag className='me-2' severity="success" value={'CAM:' + asset.cam_tag_num} />}
            {asset.msit_tag_num && <Tag className='me-2' severity="success" value={'MSIT:' + asset.msit_tag_num} />}
        </span>
    )
}

export default AssetTags;
