import { Field, Form } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { createUserByAdmin, updateUserByAdmin } from 'redux/actions/admin.users.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { getUserByAdmin } from 'services/adminUserService';

import 'styles/p_multiselect.scss';


const UserForm = ({ close, userId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        is_active: true,
        roles: [],
        new_password: '',
        confirm_new_password: '',
    });
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const rolesList = useSelector(state => state.roles.rolesList);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (userId) {
            getUserByAdmin(userId).then((data) => {
                setInitialFormValues({
                    username: data.username,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    is_active: data.is_active,
                    roles: data.roles,
                });
            })
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    const panelFooterTemplate = (props, hide) => (
        <Button onClick={hide} type='button'>Done</Button>
    )

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                username: Yup.string()
                    .required('Please enter an username'),
                first_name: Yup.string()
                    .required('Please enter a first name'),
                last_name: Yup.string()
                    .required('Please enter a last name'),
                email: Yup.string()
                    .email('Please enter a valid email address')
                    .required('Please enter an email address'),
                roles: Yup.array(),
                new_password: Yup.string()
                    .notRequired(),
                confirm_new_password: Yup.string()
                    .test('passwords-match', 'Passwords must match', function (value) {
                        return this.parent.new_password === value
                    })
            })}
            serverFieldMap={{
                username: 'username',
                first_name: 'first_name',
                last_name: 'last_name',
                email: 'email',
                is_active: 'is_active',
                roles: 'roles',
                password: 'new_password',
            }}
            onSubmit={(values) => {
                let formData = {
                    username: values.username,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    is_active: values.is_active,
                    roles: values.roles,
                    password: values.new_password,
                }

                if (userId) {
                    formData.id = userId;
                    dispatch(updateUserByAdmin(formData));
                } else {
                    dispatch(createUserByAdmin(formData));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col md={6} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="first_name"
                                inputPlaceHolder="Enter First Name"
                                inputLabel="First Name"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="last_name"
                                inputPlaceHolder="Enter Last Name"
                                inputLabel="Last Name"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="username"
                                inputPlaceHolder="Enter User ID"
                                inputLabel="User ID"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="email"
                                fieldName="email"
                                inputPlaceHolder="Enter Email"
                                inputLabel="Email"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="checkbox"
                                fieldName="is_active"
                                inputPlaceHolder="Enabled?"
                                inputLabel="Enabled?"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {!userId &&
                    <Row>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <AppInputFieldComponent
                                    fieldType="password"
                                    fieldName="new_password"
                                    inputPlaceHolder="Password"
                                    inputLabel="Password"
                                    inputProps={{ autoComplete: 'new-password' }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <AppInputFieldComponent
                                    fieldType="password"
                                    fieldName="confirm_new_password"
                                    inputPlaceHolder="Confirm Password"
                                    inputLabel="Confirm Password"
                                    inputProps={{ autoComplete: 'new-password' }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='field_roles'>Roles</Label>
                            <Field name="roles" id='field_roles'>{
                                ({ field, form, meta }) => (
                                    <Fragment>
                                        <MultiSelect name={field.name} id='field_roles' options={rolesList}
                                            optionLabel='name' optionValue='id'
                                            placeholder='Select Roles' display='chip' panelFooterTemplate={panelFooterTemplate}
                                            className={[
                                                'w-full md:w-20rem',
                                                meta.touched && meta.error && 'is-invalid',
                                            ].filter(Boolean).join(' ')}
                                            filter
                                            value={field.value || []}
                                            onChange={(e) => {
                                                form.setFieldValue(field.name, e.value);
                                            }} />
                                        {meta.touched && meta.error &&
                                            <FormFeedback>
                                                {meta.error}
                                            </FormFeedback>
                                        }
                                    </Fragment>
                                )
                            }
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default UserForm;
