import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { SPECIALTIES_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_SPECIALTY } from 'redux/entities_config';
import { SpecialtyForm } from './SpecialtyForm';


const dataTableColumns = [
    {
        field: 'specialty_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Name',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.specialty_name,
        'Status': rowData.active ? 'Active' : 'Inactive',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 100 }, // Name
    { wch: 10 }, // Status
];

const SpecialtiesList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_SPECIALTY}
            CrudBaseForm={SpecialtyForm}
            bulkUpdateRoute={SPECIALTIES_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default SpecialtiesList;
