import axios from "axios";

import { buildPaginationUrlParams } from "utils";
import { API_FUNCTIONAL_TYPES } from "./API_CONSTANTS";

export const getFunctionalTypes = (paginationParams) => {
    paginationParams = paginationParams ? paginationParams : {
        page: 1,
        limit: null,
    };
    let url = API_FUNCTIONAL_TYPES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Functional Types loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get functional types error: ", err);
                    reject("Get functional types error");
                });
        } catch (error) {
            console.error("Get functional types error", error);
            reject("Get functional types error");
        }
    });
};

export const getOneFunctionalType = (functionalTypeId) => {
    let url = API_FUNCTIONAL_TYPES + '/' + functionalTypeId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Functional Type loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get functional type error: ", err);
                    reject("Get functional type error");
                });
        } catch (error) {
            console.error("Get functional type error", error);
            reject("Get functional type error");
        }
    });
};

export const postFunctionalType = (functionalType) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_FUNCTIONAL_TYPES, functionalType)
                .then((res) => {
                    console.log("Functional Type added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post functional types error: ", err);
                    reject("Post functional types error");
                });
        } catch (error) {
            console.error("Post functional type error", error);
            reject("Post functional type error");
        }
    });
};

export const patchFunctionalType = (functionalType) => {
    return new Promise((resolve, reject) => {
        try {
            axios.patch(API_FUNCTIONAL_TYPES + '/' + functionalType.id, functionalType)
                .then((res) => {
                    console.log("Functional Type updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Patch functional types error: ", err);
                    reject("patch functional types error");
                });
        } catch (error) {
            console.error("Patch functional type error", error);
            reject("Patch functional type error");
        }
    });
};

export const deleteFunctionalType = (functionalTypeId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.delete(API_FUNCTIONAL_TYPES + '/' + functionalTypeId)
                .then((res) => {
                    console.log("Functional Type deleted: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Functional Type delete error: ", err);
                    reject("Functional Type delete error");
                });
        } catch (error) {
            console.error("Functional Type delete error", error);
            reject("Functional Type delete error");
        }
    });
};
