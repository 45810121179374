import { Button, DialogActions, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { CheckBox, Error, Info } from '@mui/icons-material';
import React, { Fragment } from 'react';
import '../../styles/NotifationModal.css';
import { closeNotificationModal } from 'redux/actions/ui.actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { logout } from 'redux/actions/auth.actions';


export const ICON_ERROR = 'error';
export const ICON_SUCCESS = 'success';
export const ICON_INFO = 'info';

const NotificationModal = () => {
    const dispatch = useDispatch();
    const notificationModal = useSelector(state => state.ui.notificationModal);

    const handleClose = () => {
        dispatch(closeNotificationModal());
    };

    const handleOnAccept = () => {
        if (notificationModal.handleAccept === 'logout') {
            dispatch(logout());
            dispatch(closeNotificationModal());
        }
    };

    const iconDisplay = (iconType) => {
        let iconDisplay;
        switch (iconType) {
            case ICON_ERROR:
                iconDisplay = <Error />
                break;
            case ICON_SUCCESS:
                iconDisplay = <CheckBox />
                break;
            case ICON_INFO:
            default:
                iconDisplay = <Info />
        }
        return iconDisplay;
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={notificationModal.isOpen}>
            <DialogTitle className="font-weight-bold" id="simple-dialog-title">
                <div className="row justify-content-center text-center p-0">
                    {iconDisplay(notificationModal.iconType)}
                </div>
                <div className="row justify-content-center text-center ">
                    {notificationModal.title}
                </div>
            </DialogTitle>
            <DialogContent className="pt-0">
                <p className="notification-modal-content">
                    {notificationModal.message}
                </p>
            </DialogContent>
            <DialogActions>
                {notificationModal.buttonResponse ?
                    <Fragment>
                        <Button color="secondary" onClick={handleOnAccept} >
                            {notificationModal.buttonAcceptText}
                        </Button>
                        <Button color="success" onClick={(e) => handleClose(e)} >
                            Cancel
                        </Button>
                    </Fragment>
                    :
                    <Button color="primary" onClick={handleClose} >
                        Accept
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default NotificationModal;
