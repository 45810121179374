import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import AppEntityDropdown from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_OU, ENTITY_WORKFLOW } from 'redux/entities_config';
import { booleanToActiveInactive } from 'utils';


export const WorkflowForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        workflow_name: '',
        workflow_desc: '',
        ou_id: null,
        active: true,
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_WORKFLOW, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            setInitialFormValues({
                workflow_name: activeRecord.workflow_name,
                workflow_desc: activeRecord.workflow_desc,
                ou_id: activeRecord.ou_id,
                active: activeRecord.active,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                workflow_name: Yup.string()
                    .required('Please enter the primary use name'),
                workflow_desc: Yup.string()
                    .required('Please enter the primary use description'),
                ou_id: Yup.number()
                    .notRequired(),
                active: Yup.boolean()
            })}
            serverFieldMap={{
                workflow_name: 'workflow_name',
                workflow_desc: 'workflow_desc',
                ou_id: 'ou_id',
                active: 'active',
            }}
            onSubmit={(values) => {
                let formData = {
                    workflow_name: values.workflow_name,
                    workflow_desc: values.workflow_desc,
                    ou_id: values.ou_id,
                    active: values.active,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_WORKFLOW, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_WORKFLOW, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="workflow_name"
                                inputPlaceHolder="Enter Primary Use Name"
                                inputLabel="Primary Use Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="workflow_desc"
                                inputPlaceHolder="Enter Primary Use description"
                                inputLabel="Primary Use description"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='field_ou_id'>School</Label>
                            <Field name="ou_id" id='field_ou_id' entityCode={ENTITY_OU} component={AppEntityDropdown} />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="checkbox"
                                fieldName="active"
                                inputLabel="Status"
                                inputProps={{ onLabel: booleanToActiveInactive(true), offLabel: booleanToActiveInactive(false) }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default WorkflowForm;
