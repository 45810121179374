import { Fragment } from 'react';

import { ASSET_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import TdrStatusIndicator from './TdrStatusIndicator';

export const AssetStatus = ({ asset }) => {
    return (
        <Fragment>
            <p className='mb-0'>
                {ASSET_STATUSES_LABELS[asset.asset_status]}
            </p>
            <TdrStatusIndicator tdrStatus={asset.tdr_status} />
        </Fragment>
    )
}

export default AssetStatus;
