import { Fragment, useEffect, useState } from 'react';
import { Badge } from 'reactstrap';

export const BadgeItems = ({ items }) => {
    const [toDisplayItems, setToDisplayItems] = useState([]);
    const [truncated, setTruncated] = useState(false);

    useEffect(() => {
        let toDisplayItems = items;
        let truncated = false;
        if (toDisplayItems.length > 5) {
            toDisplayItems = toDisplayItems.slice(0, 5);
            truncated = true;
        }
        setToDisplayItems(toDisplayItems);
        setTruncated(truncated);
    }, [items]);

    return (
        <Fragment>
            {toDisplayItems.map((item, key) => (
                <Badge color='secondary' key={key} className="m-1">{item}</Badge>
            ))}
            {truncated && '...'}
        </Fragment>
    )
}

export default BadgeItems;
