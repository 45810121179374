import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { genericError } from "redux/actions/error.actions";
import { disableOverlay, enableOverlay } from 'redux/actions/ui.actions';
import {
    REFRESH_WORKSTATIONS_START,
    WORKSTATIONS_GET_LAST_REFRESH_TIME_START,
    getLastRefreshTimeSuccess,
    refreshWorkstationsFailed,
    refreshWorkstationsSuccess
} from 'redux/actions/workstations.actions';
import { ENTITIES_CONFIG, ENTITY_EPIC_WORKSTATION } from 'redux/entities_config';
import { crudBaseGetList } from 'services/crudBaseService';
import { refreshEpicWorkstations } from 'services/workstationService';

/**
 * refreshWorkstationsStartEffect
 */
function* refreshWorkstationsStartEffect() {
    try {
        yield put(enableOverlay('Refreshing workstations list from EPIC (It may take a few minutes)...'));
        const refreshResult = yield call(refreshEpicWorkstations);
        if (refreshResult) {
            yield put(refreshWorkstationsSuccess());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(refreshWorkstationsFailed());
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * getLastRefreshTimeEffect
 */
function* getLastRefreshTimeEffect() {
    let entityConfig = ENTITIES_CONFIG[ENTITY_EPIC_WORKSTATION];
    try {
        const lastWorkstation = yield call(crudBaseGetList, entityConfig.apiEndpoint, {
            limit: 1,
            ordering: '-last_modified_date',
        });

        if (lastWorkstation && lastWorkstation.results && lastWorkstation.results.length > 0) {
            let lastRefreshTime = lastWorkstation.results[0].last_modified_date;
            yield put(getLastRefreshTimeSuccess(lastRefreshTime));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}

/**
 * watchRefreshWorkstationsStart
 */
function* watchRefreshWorkstationsStart() {
    yield takeEvery(REFRESH_WORKSTATIONS_START, refreshWorkstationsStartEffect);
}

/**
 * watchWorkstationsGetLastRefreshTimeStart
 */
function* watchWorkstationsGetLastRefreshTimeStart() {
    yield takeEvery(WORKSTATIONS_GET_LAST_REFRESH_TIME_START, getLastRefreshTimeEffect);
}

/**
 * workstationsSaga
 */
function* workstationsSaga() {
    yield all([
        fork(watchRefreshWorkstationsStart),
        fork(watchWorkstationsGetLastRefreshTimeStart),
    ]);
}

export default workstationsSaga;
