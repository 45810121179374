import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import BadgeItems from 'components/ui/BadgeItems';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { getUsersListByAdmin, removeUserByAdmin, resetAdminUsersState } from 'redux/actions/admin.users.actions';
import { getRoles } from 'redux/actions/roles.actions';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import { booleanToYesNo, permissionName, saveAsExcelFile, userFullName } from 'utils/index';
import UserForm from './UserForm';

import 'styles/datatables.scss';
import 'styles/list_components.scss';

const initialRowsPerPage = 20;

const UsersList = () => {
    const dispatch = useDispatch();
    const dt = useRef(null);

    const tableRowsData = useSelector(state => state.usersByAdmin.usersList);
    const tableRowsCount = useSelector(state => state.usersByAdmin.count);
    const rolesMap = useSelector(state => state.roles.rolesMap);

    const [selectedTableRows, setSelectedTableRows] = useState(null);
    const [createFormDialogState, setCreateFormDialogState] = useState(false);
    const [editFormDialogState, setEditFormDialogState] = useState(false);
    const [deleteRecordDialogState, setDeleteRecordDialogState] = useState(false);

    const [formModalTitle, setFormModalTitle] = useState('');
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 400);


    // const [tableState, setTableState] = useState({
    //     first: 0,
    //     limit: rowsPerPage,
    //     page: 1,
    //     lastPageLoaded: 1,
    //     sortField: null,
    //     sortOrder: null,
    //     filter: []
    // });

    useEffect(() => {
        dispatch(getRoles());
        dispatch(resetAdminUsersState());
        dispatch(getUsersListByAdmin());
    }, [dispatch]);

    useEffect(() => {
        setFilters({
            ...filters,
            global: {
                ...filters.global,
                value: globalFilterDebouncedValue
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilterDebouncedValue]);

    // const onPage = (event) => {
    //     setTableState({
    //         ...tableState,
    //         page: event.page + 1,
    //     });
    // };

    // const onSort = (event) => {
    //     setTableState({
    //         ...tableState,
    //         sortField: event.sortField,
    //         sortOrder: event.sortOrder,
    //     });
    // };

    const performDeleteRecord = () => {
        dispatch(removeUserByAdmin(selectedTableRows.id));
        setDeleteRecordDialogState(false);
        setSelectedTableRows([]);
    };

    const hideDeleteRowDialog = () => {
        setDeleteRecordDialogState(false);
    };

    const openCreateDialog = () => {
        setFormModalTitle('New User');
        setCreateFormDialogState(true);
    };

    const openEditDialog = () => {
        setFormModalTitle('Edit User');
        setEditFormDialogState(true);
    };

    const closeCreateEditForm = () => {
        setCreateFormDialogState(false);
        setEditFormDialogState(false);
        if (formSubmittedSuccess) {
            dispatch(resetAdminUsersState());
            dispatch(getUsersListByAdmin());
        }
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV();
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            let usersListForExport = tableRowsData.map((user) => {
                return {
                    'User ID': user.username,
                    'First Name': user.first_name,
                    'Last Name': user.last_name,
                    'Email': user.email,
                    'Enabled?': booleanToYesNo(user.is_active),
                    'Roles': user.roles.map((permission) => permissionName(permission)).join(' / '),
                    'Last Modified By': userFullName(user.last_modified_by),
                    'Last Modified Date': user.updated_at ? isoStringToFormatted(user.updated_at, FORMAT_DATETIME_SHORT) : '',
                }
            });

            const worksheet = xlsx.utils.json_to_sheet(usersListForExport);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            let exportFilename = dt.current.props.exportFilename;
            saveAsExcelFile(exportFilename, excelBuffer);
        });
    };

    const startToolbarTemplate = () => {
    };

    const endToolbarTemplate = () => (
        <Fragment>
            <Button label="CSV" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportCSV} />
            <Button label="XLS" icon="ri-download-2-line" className="record-action-button ms-2" onClick={exportExcel} />
        </Fragment>
    );

    const paginatorLeft = () => {
        return (
            <Fragment>
                <Button label="Edit" icon="ri-pencil-fill" className="record-action-button me-3"
                    onClick={openEditDialog}
                    disabled={!selectedTableRows}
                />
                <Button label="Delete" icon="ri-delete-bin-line" className="record-action-button me-3"
                    onClick={() => setDeleteRecordDialogState(true)}
                    disabled={!selectedTableRows}
                />

                <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                <span className="p-input-icon-left">
                    <i className="ri-search-line" style={{ marginTop: -10 }} />
                    <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                </span>
            </Fragment>
        );
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: initialRowsPerPage, value: initialRowsPerPage },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <span className="me-2">Rows: {options.totalRecords} |</span>
                    <span className="page-number-button">Page: {options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">Users</h4>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button label="New User"
                                            className="add-record-button"
                                            onClick={openCreateDialog} />
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row className="datatable-crud-base">
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Toolbar className="list-component-toolbar" start={startToolbarTemplate} end={endToolbarTemplate} />
                                <DataTable value={tableRowsData}
                                    size='small'
                                    dataKey="id"
                                    rows={rowsPerPage}
                                    paginator
                                    paginatorPosition='top'
                                    paginatorTemplate={paginatorTemplate}
                                    paginatorLeft={paginatorLeft}
                                    totalRecords={tableRowsCount}
                                    // onPage={onPage}
                                    // onSort={onSort}
                                    onFilter={(e) => setFilters(e.filters)}
                                    // sortField={tableState.sortField}
                                    // sortOrder={tableState.sortOrder}
                                    // first={tableState.first}
                                    emptyMessage="No users found."
                                    selection={selectedTableRows}
                                    onSelectionChange={(e) => setSelectedTableRows(e.value)}
                                    filters={filters}
                                    globalFilterFields={['name', 'description']}
                                    ref={dt}
                                    exportFilename="users"
                                >
                                    <Column selectionMode='single' headerStyle={{ width: '3rem' }}></Column>
                                    <Column field="username" header="User ID" sortable />
                                    <Column field="first_name" header="First Name" sortable />
                                    <Column field="last_name" header="Last Name" sortable />
                                    <Column field="email" header="Email" sortable />
                                    <Column field="is_active" header="Enabled?" sortable body={(rowData) =>
                                        <StateFieldIndicator state={rowData.is_active} />
                                    } align='center' />
                                    <Column field="roles" header="Roles" headerStyle={{ width: '12rem' }} body={(rowData) =>
                                        <BadgeItems items={rowData.roles.map((role) => (role in rolesMap) ? rolesMap[role] : role)} />
                                    } />
                                    <Column field="last_modified_by.username" header="Last Modified By" sortable body={(rowData) => userFullName(rowData.last_modified_by)} />
                                    <Column field="updated_at" header="Last Modified Date" sortable body={
                                        (rowData) => rowData.updated_at ? isoStringToFormatted(rowData.updated_at, FORMAT_DATETIME_SHORT) : ''
                                    } />
                                </DataTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Dialog visible={createFormDialogState} header={formModalTitle} modal maximizable position='top' onHide={closeCreateEditForm} className="p-fluid">
                <UserForm close={closeCreateEditForm} userId={null} />
            </Dialog>

            <Dialog visible={editFormDialogState} header={formModalTitle} modal maximizable position='top' onHide={closeCreateEditForm} className="p-fluid">
                <UserForm close={closeCreateEditForm} userId={selectedTableRows ? selectedTableRows.id : null} />
            </Dialog>

            <ConfirmDialog visible={deleteRecordDialogState}
                onHide={hideDeleteRowDialog}
                className='delete-confirm-dialog'
                message="Are you sure you want to delete this user?"
                header="Delete User"
                acceptLabel="Delete User"
                rejectLabel="Cancel"
                accept={performDeleteRecord} reject={hideDeleteRowDialog} />

        </Fragment>
    );
};

export default UsersList;
