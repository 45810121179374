import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { getWorkflowReqCategory } from 'redux/actions/workflow_req_categories.actions';
import { ENTITY_WORKFLOW_DEFAULT_REQ } from 'redux/entities_config';
import WorkflowReqForm from './WorkflowReqForm';

const fixedDataTableColumns = [
    {
        field: 'workflow_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'School Code',
    'Name',
    'Description',
    'Status',
];

const WorkflowReqsList = () => {
    const dispatch = useDispatch();
    const [dataTableColumns, setDataTableColumns] = useState([]);
    const [exportExcelColumnInfo, setExportExcelColumnInfo] = useState([]);
    const workflowReqCategoriesMap = useSelector(state => state.workflowReqCategories.workflowReqCategoriesMap);

    const exportExcelFieldMap = useCallback((rowData) => {
        let schema = {
            'Primary Use': rowData.workflow_name,
            'School Code': rowData.ou.ou_name,
        };

        for (let key in workflowReqCategoriesMap) {
            let keyInt = parseInt(key);
            let workflowReqCategory = rowData.workflowreq_set.find(item => item.workflow_req_category.id === keyInt);
            if (workflowReqCategory) {
                schema[workflowReqCategoriesMap[key]] = workflowReqCategory.num_assets_needed > 0 ? 'x' : '';
            } else {
                schema[workflowReqCategoriesMap[key]] = '';
            }
        }

        return schema;
    }, [workflowReqCategoriesMap]);

    useEffect(() => {
        if (Object.keys(workflowReqCategoriesMap).length > 0) {
            let reqCategoryTableColumns = [];
            let localExportExcelColumnInfo = [];
            for (let key in workflowReqCategoriesMap) {
                let keyInt = parseInt(key);
                reqCategoryTableColumns.push({
                    header: workflowReqCategoriesMap[key],
                    sortable: false,
                    align: 'center',
                    body: (rowData) => {
                        let workflowReqCategory = rowData.workflowreq_set.find(item => item.workflow_req_category.id === keyInt);
                        if (workflowReqCategory && workflowReqCategory.num_assets_needed > 0) {
                            return <StateFieldIndicator state={true} />;
                        } else {
                            return '';
                        }
                    },
                });
                localExportExcelColumnInfo.push({ wch: 25 });
            }

            setDataTableColumns([
                ...fixedDataTableColumns,
                ...reqCategoryTableColumns,
            ]);

            setExportExcelColumnInfo([
                { wch: 60 }, // School
                { wch: 20 }, // Primary Use
                ...localExportExcelColumnInfo,
            ]);
        } else {
            dispatch(getWorkflowReqCategory());
        }
    }, [dispatch, workflowReqCategoriesMap]);

    return (
        <CrudBaseList
            entityCode={ENTITY_WORKFLOW_DEFAULT_REQ}
            CrudBaseForm={WorkflowReqForm}
            // bulkUpdateRoute={WORKFLOW_DEFAULT_REQS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle={'View Primary Uses Requirements'}
            hasCreateButton={false}
            hasDeleteButton={false}
        />
    );
};

export default WorkflowReqsList;
