import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import BadgeItems from 'components/ui/BadgeItems';
import { getUser } from 'redux/actions/users.actions';
import { EDIT_PROFILE, USER_CHANGE_PASSWORD } from 'navigation/ROUTE_CONSTANTS';
import { Link } from 'react-router-dom';

export const Profile = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">Profile</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Table className='border-dark border-opacity-25 text-nowrap' bordered>
                                <tbody>
                                    <tr>
                                        <td className='border-start-0 fw-bold'>Username</td>
                                        <td className='border-end-0'>{userLogged.username}</td>
                                    </tr>
                                    <tr>
                                        <td className='border-start-0 fw-bold'>Name</td>
                                        <td className='border-end-0'>{userLogged.first_name} {userLogged.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='border-start-0 fw-bold'>Role</td>
                                        <td className='border-end-0'>
                                            <BadgeItems items={userLogged.role_names.map((role) => role)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-start-0 fw-bold'>Email</td>
                                        <td className='border-end-0'>{userLogged.email}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Link to={USER_CHANGE_PASSWORD} className='btn btn-primary btn-sm me-3'>
                                Change Password
                            </Link>
                            <Link to={EDIT_PROFILE} className='btn btn-primary btn-sm'>
                                Edit Profile
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile;
