import { FRONT_END_SAML_FINISH_URL } from "assets/static_data/backend_exports";

export const ROOT = '/';
export const ON_BOARDING = '/on-boarding';

export const LOGIN_FORM = '/login';
export const RESET_PASSWORD = ON_BOARDING + '/reset-password';
export const CREATE_ACCOUNT = ON_BOARDING + '/create-account';
export const USER_PROFILE = '/profile';
export const USER_CHANGE_PASSWORD = '/change-password';
export const EDIT_PROFILE = '/edit-profile';
export const DASHBOARD = '/dashboard';
export const LOGOUT = '/logout';


// Common
export const BASE_PATH_COMMON = '/admin';
export const ADMIN_ROLES = BASE_PATH_COMMON + '/roles';
export const ADMIN_USERS = BASE_PATH_COMMON + '/users';
export const ORGANIZATION_SETTINGS = BASE_PATH_COMMON + '/organization_settings';

// Inventory
// Admin - Tools
export const BASE_PATH_INVENTORY = '/inventory';
export const INVENTORY_DASHBOARD = BASE_PATH_INVENTORY + '/dashboard';
export const DEPARTMENTS_LIST = BASE_PATH_INVENTORY + '/manage-departments';
export const LEGACY_DEPARTMENTS_LIST = BASE_PATH_INVENTORY + '/manage-legacy-departments';
export const EPIC_DEPARTMENTS_LIST = BASE_PATH_INVENTORY + '/manage-epic-departments';
export const OUS_LIST = BASE_PATH_INVENTORY + '/manage-ous';
export const SPECIALTIES_LIST = BASE_PATH_INVENTORY + '/manage-specialties';
export const STATES_LIST = BASE_PATH_INVENTORY + '/manage-states';
export const CITIES_LIST = BASE_PATH_INVENTORY + '/manage-cities';
export const BUILDINGS_LIST = BASE_PATH_INVENTORY + '/manage-buildings';
export const WORKFLOWS_LIST = BASE_PATH_INVENTORY + '/manage-workflows';
export const DROPDOWNS_LIST = BASE_PATH_INVENTORY + '/manage-dropdowns';
export const ASSET_ADD = BASE_PATH_INVENTORY + '/add-asset';
export const ASSET_EDIT = BASE_PATH_INVENTORY + '/edit-asset/:recordId';

// Admin - Bulk Update
export const DEPARTMENTS_BULK_UPDATE = BASE_PATH_INVENTORY + '/departments-bulk-update';
export const LEGACY_DEPARTMENTS_BULK_UPDATE = BASE_PATH_INVENTORY + '/legacy-departments-bulk-update';
export const BUILDINGS_BULK_UPDATE = BASE_PATH_INVENTORY + '/buildings-bulk-update';
export const OUS_BULK_UPDATE = BASE_PATH_INVENTORY + '/ous-bulk-update';
export const STATES_BULK_UPDATE = BASE_PATH_INVENTORY + '/states-bulk-update';
export const CITIES_BULK_UPDATE = BASE_PATH_INVENTORY + '/cities-bulk-update';
export const EPIC_DEPARTMENTS_BULK_UPDATE = BASE_PATH_INVENTORY + '/epic-departments-bulk-update';
export const SPECIALTIES_BULK_UPDATE = BASE_PATH_INVENTORY + '/specialties-bulk-update';
export const WORKFLOWS_BULK_UPDATE = BASE_PATH_INVENTORY + '/workflows-bulk-update';
export const DROPDOWNS_BULK_UPDATE = BASE_PATH_INVENTORY + '/dropdowns-bulk-update';

// Reports
export const WAP_ASSETS_LIST = BASE_PATH_INVENTORY + '/workstation-printer-assets';
export const ALL_ASSETS_LIST = BASE_PATH_INVENTORY + '/all-assets';
export const WORKSTATION_DEFAULT_PRINTERS_LIST = BASE_PATH_INVENTORY + '/workstation-default-printers';
export const CREDIT_CARD_READER_LIST = BASE_PATH_INVENTORY + '/credit-card-readers';
export const LEASED_ASSETS_LIST = BASE_PATH_INVENTORY + '/leased-assets';
export const WORKSTATIONS_WO_PRIMARY_USE = BASE_PATH_INVENTORY + '/workstation-wo-primary-use';
export const WORKSTATIONS_TDR_NOT_PASSED = BASE_PATH_INVENTORY + '/workstation-tdr-not-passed';
export const WORKFLOW_DEFAULT_REQS_LIST = BASE_PATH_INVENTORY + '/workflow-default-reqs';
export const ASSETS_TO_FOLLOW_UP_LIST = BASE_PATH_INVENTORY + '/assets-to-follow-up';
export const GAP_REPORT_LIST = BASE_PATH_INVENTORY + '/gap-report';
export const CLINIC_SUMMARY_LIST = BASE_PATH_INVENTORY + '/clinic-summary';

// Assets - Bulk Update
export const WAP_ASSETS_BULK_UPDATE = BASE_PATH_INVENTORY + '/workstation-printer-assets-bulk-update';
export const WPO_BULK_UPDATE = BASE_PATH_INVENTORY + '/workstations-printers-others-bulk-update';
export const ALL_ASSETS_BULK_UPDATE = BASE_PATH_INVENTORY + '/all-assets-bulk-update';
export const WORKSTATION_DEFAULT_PRINTERS_BULK_UPDATE = BASE_PATH_INVENTORY + '/workstation-default-printers-bulk-update';
export const CREDIT_CARD_READER_BULK_UPDATE = BASE_PATH_INVENTORY + '/credit-card-readers-bulk-update';
export const LEASED_ASSETS_BULK_UPDATE = BASE_PATH_INVENTORY + '/leased-assets-bulk-update';
export const WORKFLOW_DEFAULT_REQS_BULK_UPDATE = BASE_PATH_INVENTORY + '/workflow-default-reqs-bulk-update';

// Workstation
export const BASE_PATH_WORKSTATION = '/workstation';
export const WORKSTATION_DASHBOARD = BASE_PATH_WORKSTATION + '/dashboard';
export const DUPLICATE_WORKSTATIONS = BASE_PATH_WORKSTATION + '/duplicate-workstations';
export const WORKSTATIONS_LIST = BASE_PATH_WORKSTATION + '/workstations-list';
export const FUNCTIONAL_TYPES = BASE_PATH_WORKSTATION + '/functional-types';
export const DUPLICATION_HISTORY = BASE_PATH_WORKSTATION + '/duplication-history';

// Workstation - Bulk Update
export const FUNCTIONAL_TYPES_BULK_UPDATE = BASE_PATH_WORKSTATION + '/functional-types-bulk-update';

// General
export const NOTIFICATIONS = '/notifications';

// Multi-Tenancy
export const SELECT_ORGANIZATION = ON_BOARDING + '/organizations';
export const RETRIEVE_ORGANIZATION = ON_BOARDING + '/retrieve-organization/:tenantCode';

// Pages
export const PAGES = '/pages';
export const PAGES_NOT_AUTHORIZED = PAGES + '/not-authorized';

// SAML
export const PROCESS_SAML_FINISH = FRONT_END_SAML_FINISH_URL;
