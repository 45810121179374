import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_THEME,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_PRELOADER,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME,
    SET_SITE_THEME
} from "redux/actions/layout.actions";

export const LAYOUT_THEME_DARK = "dark";
export const LAYOUT_THEME_LIGHT = "light";

const siteTheme = localStorage.getItem('siteTheme') || LAYOUT_THEME_LIGHT;

const INIT_STATE = {
    layoutTheme: siteTheme, // light | dark
    leftSideBarTheme: siteTheme, // light | dark
    layoutType: "horizontal",
    leftSideBarType: "default",
    layoutWidth: "fluid",
    topbarTheme: "light",
    isPreloader: false,
    isMobile: false,
    siteTheme: siteTheme, // light | dark
};

export const LayoutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload
            };
        case CHANGE_PRELOADER:
            return {
                ...state,
                isPreloader: action.payload
            };

        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload.width
            };
        case CHANGE_LAYOUT_THEME:
            return {
                ...state,
                layoutTheme: action.payload
            };
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: action.payload.theme
            };
        case CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: action.payload.sidebarType
            };
        case CHANGE_TOPBAR_THEME:
            return {
                ...state,
                topbarTheme: action.payload
            };
        case SET_SITE_THEME:
            localStorage.setItem('siteTheme', action.payload);

            return {
                ...state,
                layoutTheme: action.payload,
                leftSideBarTheme: action.payload,
                siteTheme: action.payload,
            };
        default:
            return state;
    }
};
