import { createAction } from "@reduxjs/toolkit";

/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_LAYOUT_THEME = 'CHANGE_LAYOUT_THEME';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

export const SET_SITE_THEME = 'SET_SITE_THEME';

// topbar
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';
// Preloader
export const CHANGE_PRELOADER = 'CHANGE_PRELOADER';

export const changeLayout = layout => ({
    type: CHANGE_LAYOUT,
    payload: layout
});

export const changePreloader = layout => ({
    type: CHANGE_PRELOADER,
    payload: layout
});

export const changeLayoutWidth = (width, layoutType) => ({
    type: CHANGE_LAYOUT_WIDTH,
    payload: {width, layoutType}
});

export const changeLayoutTheme = theme => ({
    type: CHANGE_LAYOUT_THEME,
    payload: theme
});

export const changeSidebarTheme = (theme, layoutType) => ({
    type: CHANGE_SIDEBAR_THEME,
    payload: {theme, layoutType}
});

export const changeSidebarType = (sidebarType, isMobile) => {
    return {
        type: CHANGE_SIDEBAR_TYPE,
        payload: {sidebarType, isMobile}
    };
};

export const changeTopbarTheme = topbarTheme => ({
    type: CHANGE_TOPBAR_THEME,
    payload: topbarTheme
});


export const setSiteTheme = createAction(SET_SITE_THEME);
