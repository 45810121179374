import { DEPARTMENT_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { IMPORT_MULTIPLE_ENTITY_SEPARATOR } from 'components/base/BaseBulkUpdate';
import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { DEPARTMENTS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { Badge } from 'reactstrap';
import { ENTITY_DEPARTMENT } from 'redux/entities_config';
import { booleanToYesNo, departmentScopeToUsedByEpic, userFullName } from 'utils';
import { FORMAT_DATETIME_SHORT, FORMAT_DATE_SHORT, isoStringToFormatted } from 'utils/date_time';
import { DepartmentForm } from './DepartmentForm';


const dataTableColumns = [
    {
        field: 'department_name',
        header: 'Department name',
        sortable: true,
    },
    {
        field: 'department_number',
        header: 'DEP number',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School name',
        sortable: true,
    },
    {
        field: 'active',
        header: 'In Use?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
    {
        field: 'status',
        header: 'Status',
        body: (rowData) => (
            DEPARTMENT_STATUSES_LABELS[rowData.status]
        ),
        sortable: true,
    },
    {
        field: 'building.code',
        header: 'Building',
        sortable: true,
    },
    {
        field: 'suite_num',
        header: 'Suite Number',
        sortable: true,
    },
    {
        field: 'department_scope',
        header: 'Used by EPIC',
        body: (rowData) => (
            <StateFieldIndicator state={departmentScopeToUsedByEpic(rowData.department_scope)} />
        ),
        sortable: true,
        align: 'center'
    },
    {
        field: 'specialties',
        header: 'Specialties',
        body: (rowData) => (
            rowData.specialties ?
                (rowData.specialties.map((specialty, key) => <Badge color='secondary' key={key} className="m-1">{specialty.specialty_name}</Badge>)) :
                ''
        ),
    },
    {
        field: 'tdr_date',
        header: 'TDR Date',
        sortable: true,
        body: (rowData) => (
            rowData.tdr_date ? isoStringToFormatted(rowData.tdr_date, FORMAT_DATE_SHORT) : ''
        ),
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        sortable: true,
        body: (rowData) => (
            userFullName(rowData.last_modified_by)
        ),
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => (
            rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'ID',
    'Department name',
    'DEP number',
    'School Name',
    'Specialties',
    'Building Street',
    'Building Description',
    'Building Code',
    'Suite Num',
    'In Use',
    'Used By EPIC',
    'Status',
    'TDR Date',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'ID': rowData.id,
        'Department name': rowData.department_name,
        'DEP number': rowData.department_number,
        'School Name': rowData.ou ? rowData.ou.ou_description : '',
        'Specialties': rowData.specialties ? rowData.specialties.map((specialty) => specialty.specialty_name).join(IMPORT_MULTIPLE_ENTITY_SEPARATOR) : '',
        'Building Street': rowData.building ? rowData.building.street : '',
        'Building Description': rowData.building ? rowData.building.description : '',
        'Building Code': rowData.building ? rowData.building.code : '',
        'Suite Num': rowData.suite_num,
        'In Use': booleanToYesNo(rowData.active),
        'Used By EPIC': booleanToYesNo(departmentScopeToUsedByEpic(rowData.department_scope)),
        'Status': DEPARTMENT_STATUSES_LABELS[rowData.status],
        'TDR Date': rowData.tdr_date ? isoStringToFormatted(rowData.tdr_date, FORMAT_DATE_SHORT) : '',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 8 }, // ID
    { wch: 100 }, // Department name
    { wch: 16 }, // DEP number
    { wch: 100 }, // School Name
    { wch: 100 }, // Specialties
    { wch: 60 }, // Building Street
    { wch: 60 }, // Building Description
    { wch: 8 }, // Building Code
    { wch: 8 }, // Suite Num
    { wch: 10 }, // In Use
    { wch: 15 }, // Used By EPIC
    { wch: 10 }, // Status
    { wch: 12 }, // TDR Date
];

/*
Basic export that follows columns in dataTableColumns
const exportBasicExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.department_name,
        'Number': rowData.department_number,
        'School': rowData.ou.ou_name,
        'Is Active?': rowData.active,
        'Status': DEPARTMENT_STATUSES_LABELS[rowData.status],
        'Building': rowData.building.code,
        'Suite Number': rowData.suite_num,
        'Department Scope': DEPARTMENT_SCOPES_LABELS[rowData.department_scope],
        'Specialties': rowData.specialties ? rowData.specialties.join(',') : '',
        'TDR Date': rowData.tdr_date ? isoStringToFormatted(rowData.tdr_date, FORMAT_DATE_SHORT) : '',
        'Last Modified By': userFullName(rowData.last_modified_by),
        'Last Modified Date': rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    }
};
*/

const DepartmentsList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_DEPARTMENT}
            CrudBaseForm={DepartmentForm}
            bulkUpdateRoute={DEPARTMENTS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default DepartmentsList;
