import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { DROPDOWN_TYPES, DROPDOWN_TYPES_LABELS } from 'assets/static_data/backend_exports';
import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { AppOptionSelect } from 'components/ui/AppOptionSelect';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_ASSET_TYPE, ENTITY_ASSET_TYPE_CHOICE } from 'redux/entities_config';
import { serverValidationReset } from 'redux/actions/server_validations.actions';


const ApplyFiltersOnChange = ({ updateFilterSet }) => {
    const { values: formikValues } = useFormikContext();
    const [lastFieldValues, setLastFieldValues] = useState(null);

    useEffect(() => {
        if (lastFieldValues) {
            let filterSet = {};
            let performUpdate = false;
            if (formikValues.asset_type_id !== lastFieldValues.asset_type_id) {
                filterSet.asset_type_id = formikValues.asset_type_id;
                filterSet.parent_asset_type_choice_id = null;
                performUpdate = true;
            }
            if (formikValues.dropdown_type !== lastFieldValues.dropdown_type) {
                filterSet.dropdown_type = formikValues.dropdown_type;
                filterSet.parent_asset_type_choice_id = null;
                performUpdate = true;
            }
            if (formikValues.parent_asset_type_choice_id !== lastFieldValues.parent_asset_type_choice_id) {
                filterSet.parent_asset_type_choice_id = formikValues.parent_asset_type_choice_id;
                performUpdate = true;
            }

            if (performUpdate) {
                updateFilterSet(filterSet);
            }
        }
        setLastFieldValues(formikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikValues.asset_type_id, formikValues.dropdown_type, formikValues.parent_asset_type_choice_id]);

    return null;
}


const ResetFieldChoice = ({ shouldReset }) => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (shouldReset) {
            setFieldValue('choice', '', false);
        }
    }, [setFieldValue, shouldReset]);

    return null;
}


export const DropdownForm = ({ close, recordId, updateFilterSet }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        asset_type_id: null,
        dropdown_type: '',
        choice: '',
        parent_asset_type_choice_id: null,
        active: true,
        approved_replacement: true,
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const [saveButtonLabel, setSaveButtonLabel] = useState('');
    const [dropdownFilterSet, setDropdownFilterSet] = useState(null);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            setSaveButtonLabel('Update');
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_ASSET_TYPE_CHOICE, recordId }));
        } else {
            setSaveButtonLabel('Create');
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            setInitialFormValues({
                asset_type_id: activeRecord.asset_type_id,
                dropdown_type: activeRecord.dropdown_type,
                choice: activeRecord.choice,
                parent_asset_type_choice_id: activeRecord.parent_asset_type_choice_id,
                active: activeRecord.active,
                approved_replacement: activeRecord.approved_replacement,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            // If update, close the form after successful update
            if (recordId) {
                close();
            } else {
                // If create, reset the form and allow another create
                setFormSubmitStarted(false);
                dispatch(setFormSubmittedSuccess(false));
                dispatch(serverValidationReset());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    const handleFilterSet = (filterSet) => {
        updateFilterSet(filterSet);

        if (filterSet.asset_type_id) {
            setDropdownFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.MAKE,
            });
        }
    }

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                asset_type_id: Yup.number()
                    .required('Please select the asset type'),
                dropdown_type: Yup.string()
                    .required('Please select the dropdown type'),
                choice: Yup.string()
                    .required('Please enter the choice'),
                active: Yup.boolean(),
                approved_replacement: Yup.boolean(),
            })}
            serverFieldMap={{
                asset_type_id: 'asset_type_id',
                dropdown_type: 'dropdown_type',
                choice: 'choice',
                parent_asset_type_choice_id: 'parent_asset_type_choice_id',
                active: 'active',
                approved_replacement: 'approved_replacement',
            }}
            onSubmit={(values) => {
                let formData = {
                    asset_type_id: values.asset_type_id,
                    dropdown_type: values.dropdown_type,
                    choice: values.choice,
                    parent_asset_type_choice_id: values.parent_asset_type_choice_id,
                    active: values.active,
                    approved_replacement: values.approved_replacement,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_ASSET_TYPE_CHOICE, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_ASSET_TYPE_CHOICE, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid, values }) => (
            <Form className='p-fluid'>
                <ApplyFiltersOnChange updateFilterSet={handleFilterSet} />
                <ResetFieldChoice shouldReset={(formSubmittedSuccess && formSubmitStarted)} />
                <Row>
                    <Col xs={12} lg={10} className='mb-2'>
                        <Row>
                            <Col xs={12} md={4} className='mb-3'>
                                <Label for='field_asset_type_id'>Asset Type</Label>
                                <Field name="asset_type_id" id='field_asset_type_id' entityCode={ENTITY_ASSET_TYPE} component={AppEntityDropdown} />
                            </Col>
                            <Col xs={12} md={4} className='mb-3'>
                                <AppOptionSelect fieldName="dropdown_type" fieldLabel="Dropdown List For" selectOptions={DROPDOWN_TYPES_LABELS} />
                            </Col>
                            {values.dropdown_type && values.dropdown_type === DROPDOWN_TYPES.MODEL &&
                                <Col xs={12} md={4} className='mb-3'>
                                    <Label for='field_parent_asset_type_choice_id'>Select Make</Label>
                                    <Field name="parent_asset_type_choice_id" id='field_parent_asset_type_choice_id' component={AppEntityDropdown}
                                        entityCode={ENTITY_ASSET_TYPE_CHOICE}
                                        filterSet={dropdownFilterSet}
                                    />
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col xs={12} md={4} className='mb-3'>
                                <AppInputFieldComponent
                                    fieldType="text"
                                    fieldName="choice"
                                    inputPlaceHolder="Enter Choice"
                                    inputLabel="Choice"
                                />
                            </Col>
                            <Col xs={12} md={4} className='mb-3'>
                                <AppInputFieldComponent
                                    fieldType="checkbox"
                                    fieldName="active"
                                    inputLabel="Is Active?"
                                />
                            </Col>
                            <Col xs={12} md={4} className='mb-3'>
                                <AppInputFieldComponent
                                    fieldType="checkbox"
                                    fieldName="approved_replacement"
                                    inputLabel="Is valid replacement?"
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} lg={2} className='mb-2'>
                        <Row>
                            <Col xs={6} md={12} className='mb-3'>
                                <Button color='success' className='w-100' type='submit' disabled={!isValid}>
                                    {saveButtonLabel}
                                </Button>
                            </Col>
                            <Col xs={6} md={12} className='mb-3'>
                                <Button color='secondary' className='w-100' type='reset' onClick={close}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Form >
        )}
        </AppFormik >
    )
}

export default DropdownForm;
