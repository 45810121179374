import { CREATE_USER_SUCCESS, GET_USER_PERMISSIONS_SUCCESS, GET_USER_SUCCESS, RESET_USER_STATE } from "redux/actions/users.actions";

const initialUsersState = {
    emailRegister: false,
    userLogged: null,
    userPermissions: null,
};

export const UsersReducer = (state = initialUsersState, action) => {
    switch (action.type) {
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                emailRegister: action.payload
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                userLogged: action.payload,
                userPermissions: action.payload.permissions,
            };
        case GET_USER_PERMISSIONS_SUCCESS:
            return {
                ...state,
                userPermissions: action.payload.userPermissions
            };
        case RESET_USER_STATE:
            return {
                ...initialUsersState
            };
        default:
            return state;
    }
}
