import { Container } from "reactstrap";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import VersionVerification from "components/ui/VersionVerification";

export const Layout = (props) => {

  // const layoutSetting = useSelector(state => state.layout)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  /*  toggleMenuCallback = () => {
      if (this.props.leftSideBarType === "default") {
        this.props.changeSidebarType("condensed", this.state.isMobile);
      } else if (this.props.leftSideBarType === "condensed") {
        this.props.changeSidebarType("default", this.state.isMobile);
      }
    };*/

  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar
        theme="light"
        type="logo"
        isMobile={isMobile}
      />
      <div className="main-content">
        <div className="page-content">
          <VersionVerification />
          <Container fluid>
            {props.children}
          </Container>
        </div>
        <footer className="footer">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default Layout;