import { createAction } from "@reduxjs/toolkit";

// Workstation action types
export const WORKSTATIONS_GET_LAST_REFRESH_TIME_START = '[WORKSTATIONS] Get last refresh time start!';
export const WORKSTATIONS_GET_LAST_REFRESH_TIME_SUCCESS = '[WORKSTATIONS] Get last refresh time success!';
export const REFRESH_WORKSTATIONS_START = '[WORKSTATIONS] Refreshing workstations start!';
export const REFRESH_WORKSTATIONS_SUCCESS = '[WORKSTATIONS] Workstations refreshed with success!';
export const REFRESH_WORKSTATIONS_FAILED = '[WORKSTATIONS] Workstations refresh failed!';
export const DUPLICATE_WORKSTATION = '[WORKSTATIONS] Duplicating workstation';
export const DUPLICATE_WORKSTATION_SUCCESS = '[WORKSTATIONS] Workstation duplicated with success';

export const RESET_WORKSTATION_STATE = '[WORKSTATIONS] Reset workstation state'

// Workstation actions
export const getLastRefreshTimeStart = createAction(WORKSTATIONS_GET_LAST_REFRESH_TIME_START);
export const getLastRefreshTimeSuccess = createAction(WORKSTATIONS_GET_LAST_REFRESH_TIME_SUCCESS);
export const refreshWorkstationsStart = createAction(REFRESH_WORKSTATIONS_START);
export const refreshWorkstationsSuccess = createAction(REFRESH_WORKSTATIONS_SUCCESS);
export const refreshWorkstationsFailed = createAction(REFRESH_WORKSTATIONS_FAILED);
export const duplicateWorkstation = createAction(DUPLICATE_WORKSTATION);
export const duplicateWorkstationSuccess = createAction(DUPLICATE_WORKSTATION_SUCCESS);

export const resetWorkstationState = createAction(RESET_WORKSTATION_STATE);
