import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { MENU_SECTIONS, MENU_SECTIONS_LABELS } from "assets/static_data/backend_exports";
import { INVENTORY_DASHBOARD, WORKSTATION_DASHBOARD } from "navigation/ROUTE_CONSTANTS";
import { setActiveMenuSection, setNavigateTo } from "redux/actions/ui.actions";

const MenuSwitcher = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const activeMenuSection = useSelector(state => state.ui.activeMenuSection);

    if (!activeMenuSection) {
        return null;
    }

    const handleSwitchOnClick = (menuSection) => {
        dispatch(setActiveMenuSection(menuSection));
        if (location.pathname === INVENTORY_DASHBOARD && menuSection === MENU_SECTIONS.WORKSTATION) {
            dispatch(setNavigateTo(WORKSTATION_DASHBOARD));
        } else if (location.pathname === WORKSTATION_DASHBOARD && menuSection === MENU_SECTIONS.INVENTORY) {
            dispatch(setNavigateTo(INVENTORY_DASHBOARD));
        }
    }

    return (
        <div className={[
            'menu-switcher my-auto',
            (activeMenuSection === MENU_SECTIONS.INVENTORY) && 'checked',
        ].filter(Boolean).join(' ')}
        >
            <span
                className={[
                    (activeMenuSection === MENU_SECTIONS.WORKSTATION) && 'active',
                ].filter(Boolean).join(' ')}
                onClick={() => handleSwitchOnClick(MENU_SECTIONS.WORKSTATION)}
            >
                {MENU_SECTIONS_LABELS[MENU_SECTIONS.WORKSTATION]}
            </span>

            <span
                className={[
                    (activeMenuSection === MENU_SECTIONS.INVENTORY) && 'active',
                ].filter(Boolean).join(' ')}
                onClick={() => handleSwitchOnClick(MENU_SECTIONS.INVENTORY)}
            >
                {MENU_SECTIONS_LABELS[MENU_SECTIONS.INVENTORY]}
            </span>
        </div>
    );
}

export default MenuSwitcher;