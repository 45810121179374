import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_WORKFLOW } from 'redux/entities_config';
import { activeInactiveToBoolean, convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './WorkflowsList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'ou_name': rowData['School Code'],
        'workflow_name': rowData['Name'],
        'workflow_desc': rowData['Description'],
        'active': activeInactiveToBoolean(rowData['Status']),
    }
};

const apiToExcelColumn = {
    'ou_name': 'School Code',
    'workflow_name': 'Name',
    'workflow_desc': 'Description',
    'active': 'Status',
};

const foreignKeyColumns = [
    'School Code',
];

export const WorkflowsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_WORKFLOW}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default WorkflowsBulkUpdate;
