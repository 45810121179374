import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { SAML_START_LOGIN_URL } from 'assets/static_data/backend_exports';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import ChangeOrganizationButton from 'components/ui/ChangeOrganizationButton';
import { CREATE_ACCOUNT, DASHBOARD, RESET_PASSWORD, SELECT_ORGANIZATION } from 'navigation/ROUTE_CONSTANTS';
import { login } from 'redux/actions/auth.actions';
import { setNavigateTo, setRedirectAfterLogin } from 'redux/actions/ui.actions';
import { getTenantBaseUrl } from 'utils';
import { OnBoardingLayout } from './OnBoardingLayout';

import '../../styles/OnBoarding.css';


export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLogged = useSelector(state => state.users.userLogged);
    const activeTenant = useSelector(state => state.ui.activeTenant);
    const redirectAfterLogin = useSelector(state => state.ui.redirectAfterLogin);

    const [samlLoginUrl, setSamlLoginUrl] = useState('#');

    useEffect(() => {
        dispatch(setNavigateTo(null));
        if (process.env.REACT_APP_MULTI_TENANT === 'true' && !activeTenant.code) {
            dispatch(setNavigateTo(SELECT_ORGANIZATION));
        }

        if (activeTenant) {
            let localSamlLoginUrl = new URL(SAML_START_LOGIN_URL, getTenantBaseUrl(activeTenant));
            setSamlLoginUrl(localSamlLoginUrl.href);
        } else {
            setSamlLoginUrl('#');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTenant.code]);

    useEffect(() => {
        if (userLogged) {
            dispatch(setNavigateTo(null));
            dispatch(setRedirectAfterLogin(null));
            if (redirectAfterLogin) {
                history.push(redirectAfterLogin);
            } else {
                history.push(DASHBOARD);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLogged]);

    return (
        <OnBoardingLayout contentTitle="Login">
            <Row className='mt-4'>
                <Col>&nbsp;</Col>
            </Row>
            {activeTenant.is_saml_configured &&
                <Fragment>
                    <Row>
                        <Col className="text-center">
                            <p className='mb-1'>Login using SSO</p>
                            <a href={samlLoginUrl} className='w-100 btn btn-infinity-primary mw-333'>SSO Login</a>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col className="text-center">
                            <p className='mb-1'>Or with your username and password</p>
                        </Col>
                    </Row>
                </Fragment>
            }
            <Formik
                enableReinitialize={true}
                initialValues={{
                    username: '',
                    password: '',
                }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .required('Please enter your username or email address'),
                    password: Yup.string()
                        .required('Please enter your password'),
                })}
                onSubmit={(values) => {
                    dispatch(login({
                        ...values,
                        tenant: activeTenant,
                    }));
                }}
            >
                {() => (
                    <Form className="justify-content-center">
                        <FormGroup className="row justify-content-center">
                            <AppInputFieldComponent
                                fieldName="username"
                                inputPlaceHolder="Enter username or email address"
                                inputProps={{ autoComplete: 'username' }}
                            />
                        </FormGroup>

                        <FormGroup className="row justify-content-center">
                            <AppInputFieldComponent
                                fieldType="password"
                                fieldName="password"
                                inputPlaceHolder="Password"
                                inputProps={{ autoComplete: 'current-password' }}
                            />
                        </FormGroup>

                        <Row className="justify-content-center">
                            <button type='submit' className='w-100 btn btn-infinity-primary mw-333'>Login</button>
                        </Row>
                    </Form>
                )}
            </Formik>

            <Row className='mt-4'>
                <Col className="text-center">
                    <Link className="forgot-password" to={RESET_PASSWORD}>I forgot my password</Link>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className="text-center">
                    <span className="sign-up-label">Don&apos;t have an account? &nbsp;</span>
                    <Link className="sign-up-label font-weight-bold" to={CREATE_ACCOUNT}>Sign up</Link>
                </Col>
            </Row>
            {process.env.REACT_APP_MULTI_TENANT === 'true' && activeTenant.name &&
                <Row className='mt-5'>
                    <Col className="text-center sign-up-label">
                        Organization: {activeTenant.name} <ChangeOrganizationButton />
                    </Col>
                </Row>
            }
        </OnBoardingLayout>
    )
};

export default Login;
