import { createAction } from "@reduxjs/toolkit";

// Functional Types action types
export const GET_FUNCTIONAL_TYPES = '[FUNCTIONAL_TYPES] Getting functional types';
export const GET_FUNCTIONAL_TYPES_SUCCESS = '[FUNCTIONAL_TYPES] Getting functional types success!';
export const SEARCH_FUNCTIONAL_TYPES = '[FUNCTIONAL_TYPES] Searching functional types';
export const SEARCH_FUNCTIONAL_TYPES_SUCCESS = '[FUNCTIONAL_TYPES] Searching functional types success';
export const CREATE_FUNCTIONAL_TYPE = '[FUNCTIONAL_TYPES] Creating new functional type';
export const CREATE_FUNCTIONAL_TYPE_SUCCESS = '[FUNCTIONAL_TYPES] New functional type create with success';
export const DELETE_FUNCTIONAL_TYPE = '[FUNCTIONAL_TYPES] Deleting functional type';
export const UPDATE_FUNCTIONAL_TYPE = '[FUNCTIONAL_TYPES] Updating functional type';
export const UPDATE_FUNCTIONAL_TYPE_SUCCESS = '[FUNCTIONAL_TYPES] Functional type updated with success';
export const RESET_FUNCTIONAL_TYPE_STATE = '[FUNCTIONAL_TYPES] Reset functional type state'
export const RESET_FUNCTIONAL_TYPES_FOUND = '[FUNCTIONAL_TYPES] Reset functional types found'

// Functional Types actions
export const getFunctionalTypes = createAction(GET_FUNCTIONAL_TYPES);
export const getFunctionalTypesSuccess = createAction(GET_FUNCTIONAL_TYPES_SUCCESS);
export const searchFunctionalTypes = createAction(SEARCH_FUNCTIONAL_TYPES);
export const searchFunctionalTypesSuccess = createAction(SEARCH_FUNCTIONAL_TYPES_SUCCESS);
export const createFunctionalTypeSuccess = createAction(CREATE_FUNCTIONAL_TYPE_SUCCESS);
export const updateFunctionalTypeSuccess = createAction(UPDATE_FUNCTIONAL_TYPE_SUCCESS);
export const resetFunctionalTypeState = createAction(RESET_FUNCTIONAL_TYPE_STATE);
export const resetFunctionalTypesFound = createAction(RESET_FUNCTIONAL_TYPES_FOUND);

export const createFunctionalType = createAction(CREATE_FUNCTIONAL_TYPE);
export const updateFunctionalType = createAction(UPDATE_FUNCTIONAL_TYPE);
export const deleteFunctionalType = createAction(DELETE_FUNCTIONAL_TYPE);
