import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { STATES_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_STATE } from 'redux/entities_config';
import StateForm from './StateForm';


const dataTableColumns = [
    {
        field: 'state_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Name',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.state_name,
        'Status': rowData.active ? 'Active' : 'Inactive',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 100 }, // Name
    { wch: 10 }, // Active
];

const StatesList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_STATE}
            CrudBaseForm={StateForm}
            bulkUpdateRoute={STATES_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default StatesList;
