import { AppFormik } from 'components/ui/AppFormik';
import { Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_STATE } from 'redux/entities_config';


const StateForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        state_name: '',
        active: true,
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_STATE, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            setInitialFormValues({
                state_name: activeRecord.state_name,
                active: activeRecord.active,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                state_name: Yup.string()
                    .required('Please enter the state name'),
                active: Yup.boolean()
            })}
            serverFieldMap={{
                state_name: 'state_name',
                active: 'active',
            }}
            onSubmit={(values) => {
                let formData = {
                    state_name: values.state_name,
                    active: values.active,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_STATE, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_STATE, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="state_name"
                                inputPlaceHolder="Enter the state name"
                                inputLabel="State Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="checkbox"
                                fieldName="active"
                                inputLabel="Is Active?"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default StateForm;
