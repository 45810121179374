import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { LOGIN_FORM } from "navigation/ROUTE_CONSTANTS";
import OnBoardingLayout from "pages/on-boarding/OnBoardingLayout";

export const NotAuthorized = () => {
    return (
        <OnBoardingLayout contentTitle="Not Authorized">
            <Row>
                <Col>
                    <div className="text-center my-5">
                        <h3 className='mb-4 mt-2'>Not Authorized to access requested page.</h3>
                    </div>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className="text-center">
                    <Link className="sign-up-label font-weight-bold" to={LOGIN_FORM}>Back to Login</Link>
                </Col>
            </Row>
        </OnBoardingLayout>
    )
}

export default NotAuthorized;
