import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
    GET_ASSET_TYPES,
    getAssetTypesSuccess
} from 'redux/actions/asset_types.actions';
import { genericError } from "redux/actions/error.actions";
import { ENTITIES_CONFIG, ENTITY_ASSET_TYPE } from 'redux/entities_config';
import { crudBaseGetList } from 'services/crudBaseService';


/**
 * getAssetTypesEffect
 */
function* getAssetTypesEffect() {
    let entityConfig = ENTITIES_CONFIG[ENTITY_ASSET_TYPE];

    // Get current asset types in reducer state
    let assetTypesList = yield select(state => state.assetTypes.assetTypesList);
    if (assetTypesList.length > 0) {
        return;
    }

    try {
        const assetTypesList = yield call(crudBaseGetList, entityConfig.apiEndpoint);
        if (assetTypesList) {
            yield put(getAssetTypesSuccess(assetTypesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}


/**
 * Watchers
 */
export function* watchGetAssetTypes() {
    yield takeEvery(GET_ASSET_TYPES, getAssetTypesEffect);
}


function* assetTypesSaga() {
    yield all([
        fork(watchGetAssetTypes),
    ]);
}

export default assetTypesSaga;
