import {
    GET_FUNCTIONAL_TYPES_SUCCESS, RESET_FUNCTIONAL_TYPES_FOUND, RESET_FUNCTIONAL_TYPE_STATE, SEARCH_FUNCTIONAL_TYPES_SUCCESS
} from "redux/actions/functional_types.actions";

const initialFunctionalTypesState = {
    functionalTypesList: [],
    next: null,
    previous: null,
    count: 0,
    functionalTypesFound: [],
};

export const FunctionalTypesReducer = (state = initialFunctionalTypesState, action) => {
    switch (action.type) {
        case GET_FUNCTIONAL_TYPES_SUCCESS:
            return {
                ...state,
                // functionalTypesList: [...state.functionalTypesList, ...action.payload.results],
                functionalTypesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count
            };
        case SEARCH_FUNCTIONAL_TYPES_SUCCESS:
            return {
                ...state, functionalTypesFound: action.payload.results
            };
        case RESET_FUNCTIONAL_TYPES_FOUND:
            return {
                ...state, functionalTypesFound: []
            };
        case RESET_FUNCTIONAL_TYPE_STATE:
            return initialFunctionalTypesState;
        default:
            return state;
    }
}