import { all, call, debounce, fork, put, takeEvery } from 'redux-saga/effects';

import { genericError } from "redux/actions/error.actions";
import {
    CREATE_FUNCTIONAL_TYPE,
    DELETE_FUNCTIONAL_TYPE,
    GET_FUNCTIONAL_TYPES,
    SEARCH_FUNCTIONAL_TYPES,
    UPDATE_FUNCTIONAL_TYPE,
    getFunctionalTypes as actionGetFunctionalTypes,
    createFunctionalTypeSuccess,
    getFunctionalTypesSuccess,
    resetFunctionalTypeState,
    searchFunctionalTypesSuccess,
    updateFunctionalTypeSuccess,
} from 'redux/actions/functional_types.actions';
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import {
    deleteFunctionalType,
    getFunctionalTypes,
    patchFunctionalType,
    postFunctionalType,
} from 'services/functionalTypeService';


/**
 * getFunctionalTypesEffect
 */
function* getFunctionalTypesEffect({ payload }) {
    try {
        yield put(enableOverlay('Loading functional types...'));
        const functionalTypesList = yield call(getFunctionalTypes, payload);
        if (functionalTypesList) {
            yield put(getFunctionalTypesSuccess(functionalTypesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * searchFunctionalTypesEffect
 */
function* searchFunctionalTypesEffect({ payload }) {
    try {
        yield put(enableOverlay('Searching functional types...'));
        const functionalTypesFound = yield call(getFunctionalTypes, payload);
        if (functionalTypesFound) {
            yield put(searchFunctionalTypesSuccess(functionalTypesFound));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * createFunctionalTypeEffect
 */
function* createFunctionalTypeEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating new functional type...'));
        const functionalType = yield call(postFunctionalType, payload);
        if (functionalType) {
            yield put(createFunctionalTypeSuccess(functionalType));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'Functional type successfully created!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * updateFunctionalTypeEffect
 */
function* updateFunctionalTypeEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating functional type...'));
        const functionalType = yield call(patchFunctionalType, payload);
        if (functionalType) {
            yield put(updateFunctionalTypeSuccess(functionalType));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'Functional type successfully updated!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * deleteFunctionalTypeEffect
 */
function* deleteFunctionalTypeEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting functional type...'));
        yield call(deleteFunctionalType, payload);
        yield put(setToast({
            severity: TOAST_SEVERITY_SUCCESS,
            detail: 'Functional type successfully deleted!',
        }));
        yield put(resetFunctionalTypeState());
        yield put(actionGetFunctionalTypes());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * Watchers
 */
export function* watchGetFunctionalTypes() {
    yield takeEvery(GET_FUNCTIONAL_TYPES, getFunctionalTypesEffect);
}

export function* watchSearchFunctionalTypes() {
    yield debounce(600, SEARCH_FUNCTIONAL_TYPES, searchFunctionalTypesEffect);
}

export function* watchCreateFunctionalType() {
    yield takeEvery(CREATE_FUNCTIONAL_TYPE, createFunctionalTypeEffect);
}

export function* watchUpdateFunctionalType() {
    yield takeEvery(UPDATE_FUNCTIONAL_TYPE, updateFunctionalTypeEffect);
}

export function* watchDeleteFunctionalType() {
    yield takeEvery(DELETE_FUNCTIONAL_TYPE, deleteFunctionalTypeEffect);
}


function* functionalTypesSaga() {
    yield all([
        fork(watchGetFunctionalTypes),
        fork(watchSearchFunctionalTypes),
        fork(watchCreateFunctionalType),
        fork(watchUpdateFunctionalType),
        fork(watchDeleteFunctionalType),
    ]);
}

export default functionalTypesSaga;
