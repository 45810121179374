import { createAction } from "@reduxjs/toolkit";

// UI action types
export const ENABLE_OVERLAY = '[UI] Enable Overlay';
export const DISABLE_OVERLAY = '[UI] Disable Overlay';
export const UPDATE_MODAL_PROGRESS = '[UI] Update modal progress';
export const CLOSE_MODAL_PROGRESS = '[UI] Close modal progress';
export const NEW_NOTIFICATION_MODAL = '[UI] Opening new notification modal';
export const CLOSE_NOTIFICATION_MODAL = '[UI] Closing new notification modal';
export const SET_TOAST = '[UI] Setting toast';
export const CLEAR_TOAST = '[UI] Clearing toast';
export const SET_HEADER_DATA = '[UI] Setting header data';
export const SET_NAVIGATE_TO = '[UI] Navigating to';
export const SET_HOME_TARGET_URL = '[UI] Setting home target url';
export const SET_REDIRECT_AFTER_LOGIN = '[UI] Setting redirect after login';
export const SET_REDIRECT_AFTER_RETRIEVE_ORGANIZATION = '[UI] Setting redirect after retrieve organization';
export const SET_FORM_DIALOG_SUBMIT_SUCCESS = '[UI] Form dialog submit success set';
export const SET_FORM_SUBMITTED_SUCCESS = '[UI] Form submitted success set';
export const SET_START_LOGOUT_PROCESS = '[UI] Start logout process set';
export const SET_ACTIVE_MENU_SECTION = '[UI] Set active menu section';
export const TRIGGER_UI_ACTION = '[UI] Trigger UI action';
export const RESET_UI_ACTION = '[UI] Reset UI action';

export const SET_LAST_KNOW_VERSION = '[UI] Set last known version';
export const SET_NEW_VERSION_AVAILABLE = '[UI] Set new version available';

export const SET_ACTIVE_TENANT = '[UI] Set active tenant';
export const REFRESH_ACTIVE_TENANT = '[UI] Refresh active tenant';
export const RESET_ACTIVE_TENANT = '[UI] Reset active tenant';

// UI actions
export const enableOverlay = createAction(ENABLE_OVERLAY);
export const disableOverlay = createAction(DISABLE_OVERLAY);

export const updateModalProgress = createAction(UPDATE_MODAL_PROGRESS);
export const closeModalProgress = createAction(CLOSE_MODAL_PROGRESS);

export const openNotificationModal = createAction(NEW_NOTIFICATION_MODAL);
export const closeNotificationModal = createAction(CLOSE_NOTIFICATION_MODAL);

export const setToast = createAction(SET_TOAST);
export const clearToast = createAction(CLEAR_TOAST);

export const setHeaderData = createAction(SET_HEADER_DATA);

export const setNavigateTo = createAction(SET_NAVIGATE_TO);
export const setHomeTargetUrl = createAction(SET_HOME_TARGET_URL);
export const setRedirectAfterLogin = createAction(SET_REDIRECT_AFTER_LOGIN);
export const setRedirectAfterRetrieveOrganization = createAction(SET_REDIRECT_AFTER_RETRIEVE_ORGANIZATION);

export const setFormDialogSubmitSuccess = createAction(SET_FORM_DIALOG_SUBMIT_SUCCESS);
export const setFormSubmittedSuccess = createAction(SET_FORM_SUBMITTED_SUCCESS);
export const setStartLogoutProcess = createAction(SET_START_LOGOUT_PROCESS);

export const setActiveMenuSection = createAction(SET_ACTIVE_MENU_SECTION);

export const triggerUiAction = createAction(TRIGGER_UI_ACTION);
export const resetUiAction = createAction(RESET_UI_ACTION);

export const setLastKnownVersion = createAction(SET_LAST_KNOW_VERSION);
export const setNewVersionAvailable = createAction(SET_NEW_VERSION_AVAILABLE);

export const setActiveTenant = createAction(SET_ACTIVE_TENANT);
export const refreshActiveTenant = createAction(REFRESH_ACTIVE_TENANT);
export const resetActiveTenant = createAction(RESET_ACTIVE_TENANT);
