import { CrudBaseList } from 'components/base/CrudBaseList';
import { FUNCTIONAL_TYPES_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_FUNCTIONAL_TYPE } from 'redux/entities_config';
import { userFullName } from 'utils';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import FunctionalTypeForm from './FunctionalTypeForm';


const dataTableColumns = [
    {
        field: 'functional_type',
        header: 'Functional Type',
        sortable: true,
    },
    {
        field: 'abbrev',
        header: 'Abbreviation',
        sortable: true,
    },
    {
        field: 'email',
        header: 'Email',
        sortable: true,
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        sortable: true,
        body: (rowData) => userFullName(rowData.last_modified_by),
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Functional Type ID',
    'Functional Type',
    'Abbreviation',
    'Email',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Functional Type': rowData.functional_type,
        'Abbreviation': rowData.abbrev,
        'Email': rowData.email,
        'Last Modified By': userFullName(rowData.last_modified_by),
        'Last Modified Date': rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 40 }, // Functional Type
    { wch: 20 }, // Abbreviation
    { wch: 30 }, // Email
    { wch: 30 }, // Last Modified By
    { wch: 20 }, // Last Modified Date
];

const FunctionalTypesList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_FUNCTIONAL_TYPE}
            CrudBaseForm={FunctionalTypeForm}
            bulkUpdateRoute={FUNCTIONAL_TYPES_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default FunctionalTypesList;
