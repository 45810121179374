import { Form } from 'formik';
import { FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';

export const CreateUserCredentialsComponent = ({ onSubmit }) => {

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={{
                username: '',
                email: '',
                password: '',
                confirm_password: '',
            }}
            initialErrors={{
                username: 'Enter username',
            }}
            validationSchema={Yup.object({
                username: Yup.string()
                    .min(3, 'Username must be at least 3 characters length')
                    .max(150, 'Username must be smaller than 150 characters length.')
                    .required('Enter username'),
                email: Yup.string().email('Invalid email address')
                    .max(150, 'Email must be 150 characters or less')
                    .required('Enter email address'),
                password: Yup.string()
                    .min(8, 'Password must be at least 8 characters length')
                    .max(128, 'Password must be smaller than 128 characters length.')
                    .required('Enter password'),
                confirm_password: Yup.string()
                    .test('passwords-match', 'Passwords must match', function (value) {
                        return this.parent.password === value
                    })
            })}
            serverFieldMap={{
                username: 'username',
                email: 'email',
                password: 'password',
            }}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ isValid }) => (

                <div className="row justify-content-center">
                    <div className="col-12 col-md-9">
                        <Form>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldName="username"
                                    inputPlaceHolder="Enter username"
                                    tooltipText="Please ensure username contains only letters, digit and @/./+/-/_ characters"
                                    inputProps={{ autoComplete: 'username' }}
                                />
                            </FormGroup>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldType="email"
                                    fieldName="email"
                                    inputPlaceHolder="Enter email address"
                                    tooltipText="Please enter a unique email address"
                                    inputProps={{ autoComplete: 'username' }}
                                />
                            </FormGroup>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldType="password"
                                    fieldName="password"
                                    inputPlaceHolder="Password"
                                    tooltipText="Please enter a strong password for your account"
                                    inputProps={{ autoComplete: 'new-password' }}
                                />
                            </FormGroup>
                            <FormGroup className="row justify-content-center">
                                <AppInputFieldComponent
                                    fieldType="password"
                                    fieldName="confirm_password"
                                    inputPlaceHolder="Confirm password"
                                    tooltipText="Please enter the same password as above"
                                    inputProps={{ autoComplete: 'new-password' }}
                                />
                            </FormGroup>
                            <Row className="justify-content-center">
                                <button className="w-100 btn btn-infinity-primary mw-333" disabled={!isValid} type="submit">
                                    Next
                                </button>
                            </Row>
                        </Form>
                    </div>
                </div>
            )}
        </AppFormik>
    )
}

export default CreateUserCredentialsComponent;
