import { all, call, delay, fork, put, select, takeEvery } from "redux-saga/effects";

import { ICON_SUCCESS } from "components/ui/NotificationModal";
import { DASHBOARD, LOGIN_FORM } from "navigation/ROUTE_CONSTANTS";
import { loginSuccess } from "redux/actions/auth.actions";
import { genericError } from "redux/actions/error.actions";
import {
    closeNotificationModal,
    disableOverlay,
    enableOverlay,
    openNotificationModal,
    refreshActiveTenant,
    setNavigateTo,
    setToast
} from "redux/actions/ui.actions";
import {
    CREATE_USER,
    GET_USER,
    GET_USER_PERMISSIONS,
    GET_USER_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_CONFIRMATION,
    UPDATE_USER_PROFILE,
    createUserSuccess,
    getUser,
    getUserPermissionsSuccess,
    getUserSuccess
} from "redux/actions/users.actions";
import { TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { userLogin } from "services/authService";
import {
    getLoggedUser,
    getPermissionsLoggedUser,
    registerUser,
    resetPasswordByEmail,
    resetPasswordCredentials,
    updateUser
} from "services/userService";

/**
 * getUserEffect
 */
function* getUserEffect() {
    yield put(enableOverlay('Loading user info...'));
    try {
        const userResult = yield call(getLoggedUser);
        if (userResult) {
            yield put(getUserSuccess(userResult));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * getUserSuccessEffect
 */
function* getUserSuccessEffect() {
    // Uncomment if a fixed timezone is needed
    // const defaultTimeZone = 'America/Los_Angeles';
    try {
        // yield call(setDefaultTimeZone, defaultTimeZone);
        yield put(refreshActiveTenant());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}

/**
 * getUserPermissionsEffect
 */
function* getUserPermissionsEffect() {
    try {
        const userPermissionsResult = yield call(getPermissionsLoggedUser);
        if (userPermissionsResult) {
            const activeTenantCode = yield select(state => state.auth.activeTenantCode);
            yield put(getUserPermissionsSuccess({
                userPermissions: userPermissionsResult,
                activeTenantCode,
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}

/**
 * createUserEffect
 * @param {*} param0
 */
function* createUserEffect({ payload }) {
    try {
        let tenant = payload.tenant;
        delete payload.tenant;
        yield put(enableOverlay('Creating new account...'));
        const userData = yield call(registerUser, payload);
        if (userData) {
            let loginResult = yield call(userLogin, {
                username: payload.username,
                password: payload.password,
            });
            if (loginResult) {
                yield put(loginSuccess({
                    ...loginResult,
                    activeTenantCode: tenant.code,
                }));
                yield put(createUserSuccess(true));
            }

            yield put(disableOverlay());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
        yield put(disableOverlay());
    }
}

/**
 * updateUserProfileEffect
 */
function* updateUserProfileEffect({ payload }) {
    yield put(enableOverlay('Updating profile ...'));
    try {
        const updateProfile = yield call(updateUser, payload);
        if (updateProfile) {
            yield put(getUser());

            if (payload.isRegisterFlow) {
                yield put(setNavigateTo(DASHBOARD));
            } else {
                yield put(setToast({
                    severity: TOAST_SEVERITY_SUCCESS,
                    summary: 'Success!',
                    detail: 'You profile was updated!',
                }));
            }
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * resetPasswordEffect
 * @param {*} param0
 */
function* resetPasswordEffect({ payload }) {
    try {
        yield put(enableOverlay('Sending email...'));
        const sending = yield call(resetPasswordByEmail, payload);
        if (sending) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'A reset password email was send!',
                iconType: ICON_SUCCESS,
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            yield put(setNavigateTo(LOGIN_FORM));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
        yield put(disableOverlay());
    }
}

/**
 * resetPasswordConfirmationEffect
 * @param {*} param0
 */
function* resetPasswordConfirmationEffect({ payload }) {
    try {
        yield put(enableOverlay('Resetting password...'));
        const reset = yield call(resetPasswordCredentials, payload);
        if (reset) {
            yield put(disableOverlay());
            yield put(openNotificationModal({
                title: 'Success!',
                message: 'Your password was successfully changed!',
                iconType: ICON_SUCCESS,
            }));
            yield delay(3000);
            yield put(closeNotificationModal());
            yield put(setNavigateTo(LOGIN_FORM));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
        yield put(disableOverlay());
    }
}

/**
 * Watchers
 */
export function* watchGetUser() {
    yield takeEvery(GET_USER, getUserEffect);
}

export function* watchGetUserSuccess() {
    yield takeEvery(GET_USER_SUCCESS, getUserSuccessEffect);
}

export function* watchGetUserPermissions() {
    yield takeEvery(GET_USER_PERMISSIONS, getUserPermissionsEffect);
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUserEffect);
}

export function* watchUpdateUserProfile() {
    yield takeEvery(UPDATE_USER_PROFILE, updateUserProfileEffect);
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordEffect);
}

export function* watchResetPasswordConfirmation() {
    yield takeEvery(RESET_PASSWORD_CONFIRMATION, resetPasswordConfirmationEffect);
}

function* usersSaga() {
    yield all([
        fork(watchGetUser),
        fork(watchGetUserSuccess),
        fork(watchGetUserPermissions),
        fork(watchCreateUser),
        fork(watchUpdateUserProfile),
        fork(watchResetPassword),
        fork(watchResetPasswordConfirmation),
    ]);
}

export default usersSaga;
