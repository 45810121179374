import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { LOGIN_FORM, SELECT_ORGANIZATION } from 'navigation/ROUTE_CONSTANTS';
import { resetActiveTenant, setActiveTenant, setNavigateTo, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR } from 'redux/reducers/ui.reducer';
import { getOneTenant } from 'services/tenantService';
import { validateTenant } from 'utils';


export const RetrieveOrganization = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const redirectAfterRetrieveOrganization = useSelector(state => state.ui.redirectAfterRetrieveOrganization);

    useEffect(() => {
        dispatch(resetActiveTenant());
        dispatch(setNavigateTo(null));

        let tenantCode = params.tenantCode;
        if (!tenantCode) {
            dispatch(setToast({
                severity: TOAST_SEVERITY_ERROR,
                summary: 'No tenant code',
                detail: 'No tenant code provided',
            }));
            history.push(SELECT_ORGANIZATION);
        } else {
            getOneTenant(tenantCode)
                .then(data => {
                    let isValid = validateTenant(data);
                    if (isValid) {
                        dispatch(setActiveTenant(data));
                        if (redirectAfterRetrieveOrganization) {
                            history.push(redirectAfterRetrieveOrganization);
                        } else {
                            history.push(LOGIN_FORM);
                        }
                    } else {
                        dispatch(setToast({
                            severity: TOAST_SEVERITY_ERROR,
                            summary: 'Error fetching organization',
                            detail: JSON.stringify(data),
                        }));
                        history.push(SELECT_ORGANIZATION);
                    }
                })
                .catch((err) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Error fetching organization',
                        detail: err.message,
                    }));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default RetrieveOrganization;
