import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form, useFormikContext } from 'formik';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Label, Row } from 'reactstrap';

import { ASSET_STATUSES, DROPDOWN_TYPES } from 'assets/static_data/backend_exports';
import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { getAssetTypes } from 'redux/actions/asset_types.actions';
import { crudBaseCreateStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_ASSET, ENTITY_ASSET_TYPE, ENTITY_ASSET_TYPE_CHOICE } from 'redux/entities_config';
import { API_ASSETS } from 'services/API_CONSTANTS';
import { crudBaseGetOne } from 'services/crudBaseService';

import 'styles/p_fieldset.scss';

const emptyFormValues = {
    id: '',
    parent_asset_id: '',
    asset_type_id: null,
    serial_num: '',
    msit_tag_num: '',

    the_make_id: null,
    the_model_id: null,
    the_size_id: null,
    the_resolution_id: null,
    the_screen_orientation_id: null,

    // CC Reader Fields
    ipsn: '',
};

const serverFieldMap = {
    id: 'id',
    parent_asset_id: 'parent_asset_id',
    asset_type_id: 'asset_type_id',
    serial_num: 'serial_num',
    msit_tag_num: 'msit_tag_num',

    the_make_id: 'the_make_id',
    the_model_id: 'the_model_id',
    the_size_id: 'the_size_id',
    the_resolution_id: 'the_resolution_id',
    the_screen_orientation_id: 'the_screen_orientation_id',

    ipsn: 'ipsn',

};

const ObserverFormOnChange = ({ updateFilterSet }) => {
    const { values: formikValues, setFieldValue, dirty } = useFormikContext();
    const [lastFieldValues, setLastFieldValues] = useState(null);

    useEffect(() => {
        if (lastFieldValues && dirty) {
            let filterSet = {};
            let performUpdate = false;

            if (formikValues.asset_type_id !== lastFieldValues.asset_type_id) {
                filterSet.asset_type_id = formikValues.asset_type_id;

                setFieldValue('the_make_id', null);
                setFieldValue('the_model_id', null);
                setFieldValue('the_size_id', null);
                setFieldValue('the_resolution_id', null);
                setFieldValue('the_screen_orientation_id', null);

                performUpdate = true;
            }

            if (formikValues.the_make_id !== lastFieldValues.the_make_id) {
                filterSet.asset_type_id = formikValues.asset_type_id;
                filterSet.the_make_id = formikValues.the_make_id;
                filterSet.the_model_id = null;
                performUpdate = true;
            }

            if (performUpdate) {
                updateFilterSet(filterSet);
            }
        }
        setLastFieldValues(formikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formikValues.asset_type_id, formikValues.the_make_id,
    ]);

    return null;
}

export const PeripheralForm = ({ close, recordId, parentAssetId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState(emptyFormValues);

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const mainAssetTypes = useSelector(state => state.assetTypes.mainAssetTypes);
    const assetTypesList = useSelector(state => state.assetTypes.assetTypesList);

    const [dropdownMakesFilterSet, setDropdownMakesFilterSet] = useState(null);
    const [dropdownModelsFilterSet, setDropdownModelsFilterSet] = useState(null);
    const [dropdownSizesFilterSet, setDropdownSizesFilterSet] = useState(null);
    const [dropdownResolutionsFilterSet, setDropdownResolutionsFilterSet] = useState(null);
    const [dropdownScreenOrientationsFilterSet, setDropdownScreenOrientationsFilterSet] = useState(null);

    const handleFilterSet = useCallback((filterSet) => {
        if (filterSet.asset_type_id) {
            setDropdownMakesFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.MAKE,
            });

            if (filterSet.the_make_id) {
                setDropdownModelsFilterSet({
                    asset_type_id: filterSet.asset_type_id,
                    dropdown_type: DROPDOWN_TYPES.MODEL,
                    parent_asset_type_choice_id: filterSet.the_make_id,
                });
            }

            setDropdownSizesFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.SIZE,
            });

            setDropdownResolutionsFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.RESOLUTION,
            });

            setDropdownScreenOrientationsFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.SCREEN_ORIENTATION,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId])

    useEffect(() => {
        if (assetTypesList.length === 0) {
            dispatch(getAssetTypes());
        }
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            crudBaseGetOne(API_ASSETS, recordId)
                .then((response) => {
                    let localFormValues = {
                        ...emptyFormValues,

                        id: response.id,
                        parent_asset_id: response.parent_asset_id || '',
                        asset_type_id: response.asset_type_id,
                        serial_num: response.serial_num || '',
                        msit_tag_num: response.msit_tag_num || '',

                        the_make_id: response.the_make_id,
                        the_model_id: response.the_model_id,
                        the_size_id: response.the_size_id,
                        the_resolution_id: response.the_resolution_id,
                        the_screen_orientation_id: response.the_screen_orientation_id,

                        ipsn: response.ipsn || '',
                    };

                    setInitialFormValues(localFormValues);

                    // Trigger loading of dropdowns
                    handleFilterSet({
                        asset_type_id: response.asset_type_id,
                        the_make_id: response.the_make_id,
                    });
                });
        } else {
            let localFormValues = {
                ...emptyFormValues,
            };

            setInitialFormValues(localFormValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, recordId]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <Fragment>
            <AppFormik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={initialFormValues}
                serverFieldMap={serverFieldMap}
                onSubmit={(values) => {

                    let formData = {
                        parent_asset_id: parentAssetId,
                        asset_type_id: values.asset_type_id,
                        serial_num: values.serial_num || null,
                        msit_tag_num: values.msit_tag_num || null,
                        status: activeRecord.status,

                        building_id: activeRecord.building_id,
                        ou_id: activeRecord.ou_id,
                        department_id: activeRecord.department_id,
                        specialty_id: activeRecord.specialty_id,
                        suite_num: activeRecord.suite_num,
                        // location: activeRecord.location,
                        // room: activeRecord.room,

                        the_make_id: values.the_make_id,
                        the_model_id: values.the_model_id,
                        the_size_id: values.the_size_id,
                        the_resolution_id: values.the_resolution_id,
                        the_screen_orientation_id: values.the_screen_orientation_id,

                        asset_status: ASSET_STATUSES.ACTIVE,
                        ipsn: values.ipsn || null,
                    }

                    if (recordId) {
                        formData.id = recordId;
                        dispatch(crudBaseUpdateStart({ entityCode: ENTITY_ASSET, recordData: formData }));
                    } else {
                        // Allow users to create assets with custom IDs
                        if (values.id) {
                            formData.id = values.id;
                        }
                        dispatch(crudBaseCreateStart({ entityCode: ENTITY_ASSET, recordData: formData }));
                    }
                    setFormSubmitStarted(true);
                }}
            >{({ values, status }) => (
                <Form>
                    <ObserverFormOnChange updateFilterSet={(filterSet) => handleFilterSet(filterSet)} />
                    <Row>
                        <Col xs={6} lg={4} className='mb-3'>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="serial_num"
                                inputPlaceHolder="Enter the serial number"
                                inputLabel="Serial Number"
                            />
                        </Col>
                        <Col xs={6} lg={4} className='mb-3'>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="msit_tag_num"
                                inputPlaceHolder="Enter the MSIT tag number"
                                inputLabel="MSIT Tag"
                            />
                        </Col>
                        <Col xs={6} lg={4} className='mb-3'>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="id"
                                inputLabel="Asset ID"
                                inputProps={{ disabled: !!recordId }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6} className='mb-3'>
                            <Label for='field_asset_type_id'>Type</Label>
                            <Field name="asset_type_id" id='field_asset_type_id' entityCode={ENTITY_ASSET_TYPE} component={AppEntityDropdown} optionsData={
                                assetTypesList.filter((assetType) => assetType.is_peripheral)
                            } />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6} className='mb-3'>
                            <Label for='field_the_make_id'>Make</Label>
                            <Field name="the_make_id" id='field_the_make_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownMakesFilterSet} placeholderEntity='Make' />
                        </Col>
                        <Col xs={12} lg={6} className='mb-3'>
                            <Label for='field_the_model_id'>Model</Label>
                            <Field name="the_model_id" id='field_the_model_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownModelsFilterSet} placeholderEntity='Model' />
                        </Col>
                    </Row>

                    {values.asset_type_id && values.asset_type_id === mainAssetTypes.monitor_asset_type_id &&
                        <Row>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_the_size_id'>Size</Label>
                                <Field name="the_size_id" id='field_the_size_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownSizesFilterSet} placeholderEntity='Size' />
                            </Col>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_the_resolution_id'>Resolution</Label>
                                <Field name="the_resolution_id" id='field_the_resolution_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownResolutionsFilterSet} placeholderEntity='Resolution' />
                            </Col>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_the_screen_orientation_id'>Screen Orientation</Label>
                                <Field name="the_screen_orientation_id" id='field_the_screen_orientation_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownScreenOrientationsFilterSet} placeholderEntity='Screen Orientation' />
                            </Col>
                        </Row>
                    }

                    {values.asset_type_id && values.asset_type_id === mainAssetTypes.credit_card_reader_asset_type_id &&
                        <Row>
                            <Col xs={12} lg={6} className='mb-3'>
                                <AppInputFieldComponent
                                    fieldType="text"
                                    fieldName="ipsn"
                                    inputPlaceHolder="Enter the IPSN"
                                    inputLabel="IPSN"
                                />
                            </Col>
                        </Row>
                    }

                    <hr />

                    <Row>
                        <Col className="text-center" md={6} xs={12}>
                            <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                                Cancel
                            </Button>
                        </Col>
                        <Col className="text-center" md={6} xs={12}>
                            <Button color='success' className='mt-2 px-5' type='submit'>
                                Save
                            </Button>
                            {(Object.entries(status.apiErrors).length > 0) &&
                                <Alert color="danger" className="mt-2">
                                    Your changes were not saved. Check the error messages and submit the form again.
                                </Alert>
                            }
                        </Col>
                    </Row>
                </Form>
            )}
            </AppFormik>

        </Fragment>
    )
}

export default PeripheralForm;
