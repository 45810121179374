import { Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { createFunctionalType, updateFunctionalType } from 'redux/actions/functional_types.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { getOneFunctionalType } from 'services/functionalTypeService';


export const FunctionalTypeForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        functional_type: '',
        abbrev: '',
        email: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
    });
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            getOneFunctionalType(recordId).then((data) => {
                setInitialFormValues({
                    functional_type: data.functional_type,
                    abbrev: data.abbrev,
                    email: data.email,
                    lastModifiedBy: data.last_modified_by,
                    lastModifiedDate: data.last_modified_date,
                });
            })
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                functional_type: Yup.string()
                    .required('Please enter a Functional Type'),
                abbrev: Yup.string()
                    .required('Please enter an abbreviation'),
                email: Yup.string()
                    .required('Please enter an email')
                    .email('Invalid email address')
            })}
            serverFieldMap={{
                functional_type: 'functional_type',
                abbrev: 'abbrev',
                email: 'email',
            }}
            onSubmit={(values) => {
                let formData = {
                    functional_type: values.functional_type,
                    abbrev: values.abbrev,
                    email: values.email,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(updateFunctionalType(formData));
                } else {
                    dispatch(createFunctionalType(formData));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="functional_type"
                                inputPlaceHolder="Enter functional type name"
                                inputLabel="Functional Type"
                            />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="abbrev"
                                inputPlaceHolder="Enter abbreviation"
                                inputLabel="Abbreviation"
                            />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="email"
                                fieldName="email"
                                inputPlaceHolder="Enter email"
                                inputLabel="Email"
                            />
                        </FormGroup>

                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default FunctionalTypeForm;
