export const userHelpAllAssetBulkUpdate =
    '"New Notes" column is used to add more notes to existing notes in the import file. "Update Notes" will replace current notes for the asset, all other notes will be deleted.';

export const userHelpCreditCardReaders =
    '"New Notes" column is used to add more notes to any existing notes for an asset. Values in column "Asset Id" are optional, ' +
    'if not explicitly provided then the system will try to find the CC Reader asset by searching with "Serial #", "IPSN" or "MSIT Tag". ' +
    'Values in column "Main Asset Id", "Department", "School" and "Street" are optional. Column "Street" is used to lookup for a Building';

export const userHelpLeasedAssets = 'Values in column "Asset Id" are optional, ' +
    'if not explicitly provided then the system will try to find the Asset by searching with "Serial #". ' +
    'Values in column "Department" and "School" are not used to update the asset.';

export const userHelpOptionalColumns = 'Column where the name is ending with (*) are optional.'

export const ASSET_TDR_STATUS_PASSED = 'TDR Passed';
export const ASSET_TDR_STATUS_NOT_PASSED = 'TDR Not Passed';

export const DUPLICATION_SUCCESSFULLY_TRUE = 'Successfully';
export const DUPLICATION_SUCCESSFULLY_FALSE = 'Failed';
