import { Dialog } from 'primereact/dialog';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import TriggerActionButton from 'components/ui/TriggerActionButton';
import { WORKFLOWS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { resetUiAction } from 'redux/actions/ui.actions';
import { ENTITY_WORKFLOW } from 'redux/entities_config';
import { booleanToActiveInactive } from 'utils';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import WorkflowReqForm from '../workflow_reqs/WorkflowReqForm';
import { WorkflowForm } from './WorkflowForm';


const ACTION_EDIT_ASSETS_NEEDED = 'edit_assets_needed';


const dataTableColumns = [
    {
        field: 'workflow_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'workflow_desc',
        header: 'Description',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Status',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
    {
        header: 'Assets Needed',
        body: (rowData) => (
            <TriggerActionButton actionCode={ACTION_EDIT_ASSETS_NEEDED} actionData={rowData.id} disabled={!rowData.active} />
        ),
        align: 'center'
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        sortable: true,
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => (
            rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : ''
        ),
    }
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'School Code',
    'Name',
    'Description',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'School Code': rowData.ou.ou_name,
        'Name': rowData.workflow_name,
        'Description': rowData.workflow_desc,
        'Status': booleanToActiveInactive(rowData.active),
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 40 }, // School Code
    { wch: 80 }, // Name
    { wch: 100 }, // Description
    { wch: 10 }, // Status
];

const WorkflowsList = () => {
    const dispatch = useDispatch();

    const [createEditFormDialogState, setCreateEditFormDialogState] = useState(false);
    const uiAction = useSelector(state => state.ui.uiAction);
    const [actionDataId, setActionDataId] = useState(null);

    const closeCreateEditForm = () => {
        setCreateEditFormDialogState(false);
        dispatch(resetUiAction());
    };

    useEffect(() => {
        if (uiAction && uiAction.actionCode === ACTION_EDIT_ASSETS_NEEDED && uiAction.actionData) {
            setActionDataId(uiAction.actionData);
            setCreateEditFormDialogState(true);
        }
    }, [uiAction]);

    return (
        <Fragment>
            <CrudBaseList
                entityCode={ENTITY_WORKFLOW}
                CrudBaseForm={WorkflowForm}
                bulkUpdateRoute={WORKFLOWS_BULK_UPDATE}
                dataTableColumns={dataTableColumns}
                exportExcelFieldMap={exportExcelFieldMap}
                exportExcelColumnInfo={exportExcelColumnInfo}
            />

            <Dialog visible={createEditFormDialogState} header='Edit Primary Use Default Requirements' modal onHide={closeCreateEditForm} className="p-fluid" maximizable>
                <WorkflowReqForm close={closeCreateEditForm} recordId={actionDataId} />
            </Dialog>
        </Fragment>
    );
};

export default WorkflowsList;
