import { Form } from 'formik';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import { AppFormik } from 'components/ui/AppFormik';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';


export const CheckMailResetPasswordComponent = ({ onSubmit }) => {

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address')
                    .max(150, 'Email must be 150 characters or less')
                    .required('Enter email address'),
            })}
            initialErrors={{
                email: 'Enter email address'
            }}
            onSubmit={(values) => {
                onSubmit(values);
            }}
            serverFieldMap={{
                email: 'email',
            }}
        >
            {({ isValid }) => (
                <Form className="mt-5 justify-content-center">
                    <FormGroup className="row justify-content-center">
                        <AppInputFieldComponent
                            fieldType="email"
                            fieldName="email"
                            inputPlaceHolder="Enter email address"
                            tooltipText="Please enter your registered email address"
                        />
                    </FormGroup>
                    <div className="row justify-content-center">
                        <button className="w-100 btn btn-infinity-primary mw-333"
                            disabled={!isValid}
                            type="submit"
                        >
                            Next
                        </button>
                    </div>
                </Form>
            )}
        </AppFormik>
    )
}

export default CheckMailResetPasswordComponent;
