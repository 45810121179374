import axios from "axios";

import { buildPaginationUrlParams } from "utils";
import { API_ROLES } from "./API_CONSTANTS";

export const getRoles = (paginationParams) => {
    paginationParams = paginationParams ? paginationParams : {
        page: 1,
        limit: null,
    };
    let url = API_ROLES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Roles loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get roles error: ", err);
                    reject("Get roles error");
                });
        } catch (error) {
            console.error("Get roles error", error);
            reject("Get roles error");
        }
    });
};

export const getOneRole = (roleId) => {
    let url = API_ROLES + '/' + roleId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Role loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get role error: ", err);
                    reject("Get role error");
                });
        } catch (error) {
            console.error("Get role error", error);
            reject("Get role error");
        }
    });
};

export const postRole = (role) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(API_ROLES, role)
                .then((res) => {
                    console.log("Role added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post roles error: ", err);
                    reject("Post roles error");
                });
        } catch (error) {
            console.error("Post role error", error);
            reject("Post role error");
        }
    });
};

export const patchRole = (role) => {
    return new Promise((resolve, reject) => {
        try {
            axios.patch(API_ROLES + '/' + role.id, role)
                .then((res) => {
                    console.log("Role updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Patch roles error: ", err);
                    reject("patch roles error");
                });
        } catch (error) {
            console.error("Patch role error", error);
            reject("Patch role error");
        }
    });
};

export const deleteRole = (recordId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.delete(API_ROLES + '/' + recordId)
                .then((res) => {
                    console.log("Role deleted: ", res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log("Role delete error: ", err);
                    reject("Role delete error");
                });
        } catch (error) {
            console.error("Role delete error", error);
            reject("Role delete error");
        }
    });
};
