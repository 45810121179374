import { InfoOutlined } from '@mui/icons-material';
import { Field, useField, useFormikContext } from 'formik';
import { ToggleButton } from 'primereact/togglebutton';
import { Fragment, useEffect, useState } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupText, Label, UncontrolledTooltip } from 'reactstrap';

import '../../styles/OnBoarding.css';

export const AppInputFieldComponent = ({ fieldName, inputPlaceHolder, tooltipText = null, inputLabel = null, fieldType = 'text', inputProps = {} }) => {
    const [infoId] = useState(() => fieldName.replace('/', '-') + '_tooltip_' + Math.random().toString(36).substring(7));
    const [inputId] = useState(() => 'input_' + fieldName);
    const [formikField, meta] = useField({ name: fieldName, type: fieldType });
    const [fieldErrorMessage, setFieldErrorMessage] = useState(null);
    const { status: { apiErrors = {} } = {} } = useFormikContext();

    useEffect(() => {
        let localFieldErrorMessage = null;
        if (meta.touched) {
            if (fieldName in apiErrors) {
                localFieldErrorMessage = apiErrors[fieldName];
            } else if (meta.error) {
                localFieldErrorMessage = meta.error;
            }
        }
        setFieldErrorMessage(localFieldErrorMessage);
    }, [meta.touched, meta.error, apiErrors, fieldName]);

    return (
        <Fragment>
            {inputLabel &&
                <Label for={inputId}>{inputLabel}</Label>
            }
            <InputGroup className='infinity-input'>
                {fieldType === 'checkbox' ?
                    (<Field {...formikField} id={inputId} as={ToggleButton} {...inputProps} aria-label={fieldName} onIcon="ri-checkbox-line" offIcon="ri-checkbox-blank-line" />)
                    :
                    (<Field {...formikField} id={inputId} placeholder={inputPlaceHolder} as={Input} type={fieldType} {...inputProps} invalid={!!fieldErrorMessage} aria-label={fieldName} />)
                }
                {tooltipText &&
                    <InputGroupText>
                        <InfoOutlined fontSize="small" id={infoId} />
                        <UncontrolledTooltip placement="top" target={infoId}>
                            {tooltipText}
                        </UncontrolledTooltip>
                    </InputGroupText>
                }
                {fieldErrorMessage &&
                    <FormFeedback>
                        {fieldErrorMessage}
                    </FormFeedback>
                }
            </InputGroup>
        </Fragment>
    );
};

export default AppInputFieldComponent;
