import axios from "axios";
import { API_CREATE_EPIC_TICKET, API_REFRESH_EPIC_WORKSTATIONS, API_UPDATE_SERVICE_NOW_WORKSTATIONS } from "./API_CONSTANTS";

export const refreshEpicWorkstations = () => {
    return new Promise((resolve, reject) => {
        axios.put(API_REFRESH_EPIC_WORKSTATIONS)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const createEpicTicket = (batchId) => {
    let url = API_CREATE_EPIC_TICKET + '/' + batchId;
    return new Promise((resolve, reject) => {
        axios.put(url)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const updateServiceNowWorkstations = () => {
    return new Promise((resolve, reject) => {
        axios.put(API_UPDATE_SERVICE_NOW_WORKSTATIONS)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};
