import { Fragment } from 'react';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { DASHBOARD } from 'navigation/ROUTE_CONSTANTS';

const PlaceHolderPage = () => {
    return (
        <Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center my-5">
                                <h1 className="fw-bold text-error">Place holder page</h1>
                                <h3 className="text-uppercase">Sorry, this page is not available.</h3>
                                <div className="mt-5 text-center">
                                    <Link to={DASHBOARD} className="btn btn-primary waves-effect waves-light">Back to Dashboard</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default PlaceHolderPage;