import { Fragment } from "react";

import { gitCommitTimestampToDate } from "utils";

const gitCommitTimestamp = gitCommitTimestampToDate(process.env.REACT_APP_GIT_COMMIT_TIMESTAMP).toLocaleDateString();

const AppDetails = () => {
    return (
        <Fragment>
            {new Date().getFullYear()} © {process.env.REACT_APP_SITE_TITLE} |
            UI version sha:&nbsp;{process.env.REACT_APP_GIT_COMMIT_SHA} |
            Updated:&nbsp;{gitCommitTimestamp}
        </Fragment>
    );
}

export default AppDetails;
