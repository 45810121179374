import axios from "axios";
import { API_STAT_DUPLICATIONS_PER_MONTH, API_STAT_DUPLICATIONS_PER_STATUS, API_STAT_LAST_DUPLICATION_PER_DEPARTMENT, API_STAT_WORKSTATIONS_PER_BUILDING, API_STAT_WORKSTATIONS_PER_DEPARTMENT, API_STAT_WORKSTATIONS_PER_INFORMATION_STATUS, API_STAT_WORKSTATIONS_PER_TDR_STATUS, API_STAT_WORKSTATIONS_PER_TYPE } from "./API_CONSTANTS";

export const getWorkstationsPerTdrStatus = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_WORKSTATIONS_PER_TDR_STATUS)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getWorkstationsPerInformationStatus = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_WORKSTATIONS_PER_INFORMATION_STATUS)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getWorkstationsPerType = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_WORKSTATIONS_PER_TYPE)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getWorkstationsPerDepartment = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_WORKSTATIONS_PER_DEPARTMENT)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getWorkstationsPerBuilding = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_WORKSTATIONS_PER_BUILDING)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getDuplicationsPerStatus = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_DUPLICATIONS_PER_STATUS)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getDuplicationsPerMonth = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_DUPLICATIONS_PER_MONTH)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const getLastDuplicationDatePerDepartment = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_STAT_LAST_DUPLICATION_PER_DEPARTMENT)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};