import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
} from 'reactstrap';

import img1 from 'assets/icons/on-boarding/login-carousel1.png';
import img2 from 'assets/icons/on-boarding/login-carousel2.png';
import img3 from 'assets/icons/on-boarding/login-carousel3.png';

const items = [
    {
        src: img1,
        altText: 'Slide 1',
    },
    {
        src: img2,
        altText: 'Slide 2',
    },
    {
        src: img3,
        altText: 'Slide 3',
    },
];

export const OnBoardingCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img src={item.src} alt={item.altText} style={{ filter: "brightness(60%)" }} className='img-fluid' />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={6000}
        >
            {slides}
        </Carousel>
    );
};

export default OnBoardingCarousel;
