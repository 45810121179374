import { all } from 'redux-saga/effects';
import adminSettingsSagas from "./effects/admin.settings.saga";
import usersByAdminSaga from './effects/admin.users.saga';
import assetTypesSaga from './effects/asset_types.saga';
import authSaga from "./effects/auth.saga";
import crudBaseSaga from './effects/crud_base.saga';
import functionalTypesSaga from './effects/functional_types.saga';
import layoutSaga from "./effects/layout.saga";
import rolesSaga from './effects/roles.saga';
import uiSaga from './effects/ui.saga';
import usersSaga from "./effects/users.saga";
import workflowReqCategoriesSaga from './effects/workflow_req_categories.saga';
import workstationsSaga from './effects/workstations.saga';

export default function* appSaga() {
    yield all([
        adminSettingsSagas(),
        assetTypesSaga(),
        authSaga(),
        crudBaseSaga(),
        functionalTypesSaga(),
        layoutSaga(),
        rolesSaga(),
        uiSaga(),
        usersByAdminSaga(),
        usersSaga(),
        workflowReqCategoriesSaga(),
        workstationsSaga(),
    ])
}
