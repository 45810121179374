import { Formik } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

import { serverValidationReset } from 'redux/actions/server_validations.actions';


const setFormFieldError = (serverFieldMap, serverValidations) => {
    let nonFieldErrors = [];
    let apiErrors = [];

    if (typeof serverFieldMap === 'string') {
        let validationMessage;
        if (Array.isArray(serverValidations)) {
            validationMessage = serverValidations.join('\n');
        } else if (typeof serverValidations === 'object') {
            validationMessage = JSON.stringify(serverValidations);
        } else {
            validationMessage = serverValidations;
        }

        apiErrors.push([serverFieldMap, validationMessage]);

    } else if (typeof serverFieldMap === 'object') {
        for (const [key, value] of Object.entries(serverValidations)) {
            if (key in serverFieldMap) {
                const [recNonFieldErrors, recApiErrors] = setFormFieldError(serverFieldMap[key], value);
                nonFieldErrors = nonFieldErrors.concat(recNonFieldErrors);
                apiErrors = apiErrors.concat(recApiErrors);
            } else {
                nonFieldErrors.push(key + ': ' + value);
            }
        }
    }

    return [nonFieldErrors, apiErrors];
};

export const AppFormik = ({ serverFieldMap, children, ...props }) => {
    const dispatch = useDispatch();
    const serverValidations = useSelector(state => state.serverValidations.fields);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const formikRef = useRef(null);

    useEffect(() => {
        let nonFieldErrors = [];
        let apiErrors = [];
        if (!serverFieldMap) {
            console.warn('AppFormik.serverFieldMap is not defined');
        } else {
            [nonFieldErrors, apiErrors] = setFormFieldError(serverFieldMap, serverValidations);
        }

        const objApiErrors = {};
        apiErrors.forEach(([key, value]) => {
            objApiErrors[key] = value;
            formikRef.current?.setFieldTouched(key, true, false);
        });

        const currentStatus = formikRef.current?.status || {};
        formikRef.current?.setStatus({
            ...currentStatus,
            apiErrors: objApiErrors,
        });

        setNonFieldErrors(nonFieldErrors);

    }, [serverFieldMap, serverValidations]);

    useEffect(() => {
        dispatch(serverValidationReset());
        return () => {
            dispatch(serverValidationReset());
        }
    }, [dispatch]);

    // TODO: This component should include <Form> tag and buttons Cancel and Save. Then display a feedback to user when the form contains validation errors.
    return (
        <Formik
            innerRef={formikRef}
            initialStatus={{
                apiErrors: {},
            }}
            {...props}
        >
            {(formikProps) => (
                <Fragment>
                    {
                        nonFieldErrors.map((errorMessage, index) => (
                            <Alert color="warning" key={index}>{errorMessage}</Alert>
                        ))
                    }
                    {children(formikProps)}
                </Fragment>
            )}
        </Formik>
    );
};

export default AppFormik;
