import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { INFORMATION_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { ASSET_TDR_STATUS_NOT_PASSED, ASSET_TDR_STATUS_PASSED } from 'assets/static_data/common_constants';
import { Dropdown } from 'primereact/dropdown';
import { getWorkstationsPerBuilding, getWorkstationsPerDepartment, getWorkstationsPerInformationStatus, getWorkstationsPerTdrStatus, getWorkstationsPerType } from 'services/statService';

const availableCharts = [
    { label: 'Workstations per TDR Status', value: 'wsPerTdrStatus' },
    { label: 'Workstations per Information Status', value: 'wsPerInformationStatus' },
    { label: 'Workstations per Type', value: 'wsPerType' },
    { label: 'Workstations per Department', value: 'wsPerDepartment' },
    { label: 'Workstations per Building', value: 'wsPerBuilding' },
];

const wsPerTdrStatusPieDefaultOptions = {
    title: {
        text: 'TDR Status',
        align: 'center',
    },
    plotOptions: {
        pie: {
            donut: {
                size: '75%'
            }
        }
    },
    dataLabels: {
        enabled: true
    },
    legend: {
        show: true,
    },
};

const wsPerInformationStatusPieDefaultOptions = {
    title: {
        text: 'Information Status',
        align: 'center',
    },
    plotOptions: {
        pie: {
            donut: {
                size: '75%'
            }
        }
    },
    dataLabels: {
        enabled: true
    },
    legend: {
        show: true,
    },
};

const wsPerTypePieDefaultOptions = {
    title: {
        text: 'Workstations per type',
        align: 'center',
    },
    plotOptions: {
        pie: {
            donut: {
                size: '75%'
            }
        }
    },
    dataLabels: {
        enabled: true
    },
    legend: {
        show: true,
    },
};

const wsPerDepartmentBareDefaultOptions = {
    title: {
        text: 'Workstations per Department',
        align: 'center',
    },
    chart: {
        toolbar: {
            show: false,
            columnWidth: '5%',
            endingShape: 'rounded'
        }
    },
    plotOptions: {
        bar: {
            barHeight: '90%',
            distributed: true,
            // horizontal: true,
            // dataLabels: {
            //     position: 'bottom'
            // },
        }
    },
    dataLabels: {
        // enabled: true,
        // textAnchor: 'start',
        // style: {
        //     colors: ['#fff']
        // },
    },
    // legend: {
    //     show: true,
    // },
    grid: {
        // borderColor: '#f1f1f1',
    },
    // colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],
    xaxis: {
        // categories: [],
        labels: {
            show: false
        }
    }
};

const wsPerBuildingBareDefaultOptions = {
    title: {
        text: 'Workstations per Building',
        align: 'center',
    },
    chart: {
        toolbar: {
            show: false,
            columnWidth: '5%',
            endingShape: 'rounded'
        }
    },
    plotOptions: {
        bar: {
            barHeight: '90%',
            distributed: true,
            // horizontal: true,
            // dataLabels: {
            //     position: 'bottom'
            // },
        }
    },
    dataLabels: {
        // enabled: true,
        // textAnchor: 'start',
        // style: {
        //     colors: ['#fff']
        // },
    },
    // legend: {
    //     show: true,
    // },
    grid: {
        // borderColor: '#f1f1f1',
    },
    // colors: ['#CF3339', '#D6D3ED', '#E5E9F2', '#7D83A1'],
    xaxis: {
        // categories: [],
        labels: {
            show: false
        }
    }
};

export const InventoryDashboard = () => {

    const [wsPerTdrStatusPieSeries, setWsPerTdrStatusPieSeries] = useState(null);
    const [wsPerTdrStatusPieOptions, setWsPerTdrStatusPieOptions] = useState(null);

    const [wsPerInformationStatusPieSeries, setWsPerInformationStatusPieSeries] = useState(null);
    const [wsPerInformationStatusPieOptions, setWsPerInformationStatusPieOptions] = useState(null);

    const [wsPerTypePieSeries, setWsPerTypePieSeries] = useState(null);
    const [wsPerTypePieOptions, setWsPerTypePieOptions] = useState(null);

    const [wsPerDepartmentBarSeries, setWsPerDepartmentBarSeries] = useState(null);
    const [wsPerDepartmentBarOptions, setWsPerDepartmentBarOptions] = useState(null);

    const [wsPerBuildingBarSeries, setWsPerBuildingBarSeries] = useState(null);
    const [wsPerBuildingBarOptions, setWsPerBuildingBarOptions] = useState(null);

    const [selectedChart, setSelectedChart] = useState(null);

    useEffect(() => {
        if (!selectedChart) {
            return;
        }

        switch (selectedChart) {
            case 'wsPerTdrStatus':
                // Retrieve data for Workstation per TDR Status
                getWorkstationsPerTdrStatus()
                    .then((responseData) => {
                        let pieLabels = [];
                        let pieSeries = [];
                        for (let result of responseData) {
                            pieLabels.push(result.tdr_status ? ASSET_TDR_STATUS_PASSED : ASSET_TDR_STATUS_NOT_PASSED);
                            pieSeries.push(result.count);
                        }

                        setWsPerTdrStatusPieSeries(pieSeries);
                        setWsPerTdrStatusPieOptions({
                            ...wsPerTdrStatusPieDefaultOptions,
                            labels: pieLabels
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting workstations per TDR status:', error);
                    });
                break;

            case 'wsPerInformationStatus':
                // Retrieve data for Workstation per TDR Status
                getWorkstationsPerInformationStatus()
                    .then((responseData) => {
                        let pieLabels = [];
                        let pieSeries = [];
                        for (let result of responseData) {
                            let pieLabel = result.status in INFORMATION_STATUSES_LABELS ? INFORMATION_STATUSES_LABELS[result.status] : result.status;
                            pieLabels.push(pieLabel);
                            pieSeries.push(result.count);
                        }

                        setWsPerInformationStatusPieSeries(pieSeries);
                        setWsPerInformationStatusPieOptions({
                            ...wsPerInformationStatusPieDefaultOptions,
                            labels: pieLabels
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting workstations per information status:', error);
                    });
                break;

            case 'wsPerType':
                // Retrieve data for Workstation per type
                getWorkstationsPerType()
                    .then((responseData) => {
                        let pieLabels = [];
                        let pieSeries = [];
                        for (let result of responseData) {
                            pieLabels.push(result.workstation_type_name);
                            pieSeries.push(result.count);
                        }

                        setWsPerTypePieSeries(pieSeries);
                        setWsPerTypePieOptions({
                            ...wsPerTypePieDefaultOptions,
                            labels: pieLabels
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting workstations per type:', error);
                    });
                break;

            case 'wsPerDepartment':
                // Retrieve data for Workstation per department
                getWorkstationsPerDepartment()
                    .then((responseData) => {
                        let barLabels = [];
                        let barSeries = [];
                        for (let result of responseData) {
                            barLabels.push(result.department_name);
                            barSeries.push(result.count);
                        }

                        setWsPerDepartmentBarSeries([{
                            name: 'Workstations',
                            data: barSeries
                        }]);
                        setWsPerDepartmentBarOptions({
                            ...wsPerDepartmentBareDefaultOptions,
                            xaxis: {
                                ...wsPerDepartmentBareDefaultOptions.xaxis,
                                categories: barLabels
                            }
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting workstations per department:', error);
                    });
                break;

            case 'wsPerBuilding':
                // Retrieve data for Workstation per building
                getWorkstationsPerBuilding()
                    .then((responseData) => {
                        let barLabels = [];
                        let barSeries = [];
                        for (let result of responseData) {
                            barLabels.push(result.description);
                            barSeries.push(result.count);
                        }

                        setWsPerBuildingBarSeries([{
                            name: 'Workstations',
                            data: barSeries
                        }]);
                        setWsPerBuildingBarOptions({
                            ...wsPerBuildingBareDefaultOptions,
                            xaxis: {
                                ...wsPerBuildingBareDefaultOptions.xaxis,
                                categories: barLabels
                            }
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting workstations per building:', error);
                    });

                break;
            default:
                break;
        }
    }, [selectedChart])

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">Inventory Dashboard</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Dropdown value={selectedChart} onChange={(e) => setSelectedChart(e.value)} options={availableCharts} placeholder="Select a chart" className="w-full md:w-14rem" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {wsPerTdrStatusPieSeries && selectedChart === 'wsPerTdrStatus' &&
                                        <ReactApexChart options={wsPerTdrStatusPieOptions} series={wsPerTdrStatusPieSeries} type="donut" height='270' />
                                    }
                                    {wsPerInformationStatusPieSeries && selectedChart === 'wsPerInformationStatus' &&
                                        <ReactApexChart options={wsPerInformationStatusPieOptions} series={wsPerInformationStatusPieSeries} type="donut" height='270' />
                                    }
                                    {wsPerTypePieSeries && selectedChart === 'wsPerType' &&
                                        <ReactApexChart options={wsPerTypePieOptions} series={wsPerTypePieSeries} type="donut" height='270' />
                                    }
                                    {wsPerBuildingBarSeries && selectedChart === 'wsPerBuilding' &&
                                        <ReactApexChart options={wsPerBuildingBarOptions} series={wsPerBuildingBarSeries} type="bar" height='380' />
                                    }
                                    {wsPerDepartmentBarSeries && selectedChart === 'wsPerDepartment' &&
                                        <ReactApexChart options={wsPerDepartmentBarOptions} series={wsPerDepartmentBarSeries} type="bar" height='380'/>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default InventoryDashboard;
