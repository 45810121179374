import { DEPARTMENT_STATUSES } from 'assets/static_data/backend_exports';
import { BaseBulkUpdate, IMPORT_MULTIPLE_ENTITY_SEPARATOR } from 'components/base/BaseBulkUpdate';
import { ENTITY_DEPARTMENT } from 'redux/entities_config';
import { convertArrayToObject, usedByEpicToDepartmentScope, yesNoToBoolean } from 'utils';
import { convertToDateTime, dateTimeToAPIDate } from 'utils/date_time';
import { importExcelRequiredColumns } from './DepartmentsList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let departmentStatus = DEPARTMENT_STATUSES.ACTIVE;
    for (let [key, value] of Object.entries(DEPARTMENT_STATUSES)) {
        if (typeof rowData['Status'] === 'string' && value === rowData['Status'].toUpperCase()) {
            departmentStatus = key;
            break;
        }
    }

    // Format rowData['TDR Date'] : mm/dd/yyyy
    // Convert to ISO string
    let tdrDate = null;
    if (rowData['TDR Date']) {
        tdrDate = new Date(rowData['TDR Date']);
        tdrDate = convertToDateTime(tdrDate);
        tdrDate = dateTimeToAPIDate(tdrDate);
    }

    return {
        'id': rowData['ID'],
        'department_name': rowData['Department name'],
        'department_number': rowData['Number'] || null,
        'ou_description': rowData['School Name'],
        'specialty_names': rowData['Specialties'] ? rowData['Specialties'].split(IMPORT_MULTIPLE_ENTITY_SEPARATOR) : [],
        'building_code': rowData['Building Code'],
        'suite_num': rowData['Suite Num'],
        'active': yesNoToBoolean(rowData['In Use']),
        'department_scope': usedByEpicToDepartmentScope(yesNoToBoolean(rowData['Used by EPIC'])),
        'status': departmentStatus,
        'tdr_date': tdrDate,
    }
};

const apiToExcelColumn = {
    'id': 'ID',
    'department_name': 'Department name',
    'department_number': 'Number',
    'ou_description': 'School Name',
    'specialty_names': 'Specialties',
    'building_code': 'Building Code',
    'suite_num': 'Suite Num',
    'active': 'In Use',
    'department_scope': 'Used by EPIC',
    'status': 'Status',
    'tdr_date': 'TDR Date',
};

const foreignKeyColumns = [
    'School Name',
    'Specialties',
    'Building Code',
];

const multipleEntityColumns = [
    'Specialties',
];

export const DepartmentsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_DEPARTMENT}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
            multipleEntityColumns={multipleEntityColumns}
        />
    );
};

export default DepartmentsBulkUpdate;
