import { CrudBaseList } from 'components/base/CrudBaseList';
import { CREDIT_CARD_READER_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_CREDIT_CARD_READER } from 'redux/entities_config';
import { AssetForm } from '../all_assets/AssetForm';

const dataTableColumns = [
    {
        field: 'id',
        header: 'Asset Id',
        sortable: true,
    },
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        field: 'ipsn',
        header: 'IPSN',
        sortable: true,
    },
    {
        field: 'cam_tag_num',
        header: 'CAM Tag',
        sortable: true,
    },
    {
        field: 'msit_tag_num',
        header: 'MSIT Tag',
        sortable: true,
    },
    {
        field: 'w_p_num',
        header: 'CC #',
        sortable: true,
    },
    {
        field: 'the_make.choice',
        header: 'Make',
        sortable: true,
    },
    {
        field: 'the_model.choice',
        header: 'Model',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'suite_num',
        header: 'Suite #',
        sortable: true,
    },
    {
        field: 'parent',
        header: 'Main Asset Id',
        sortable: true,
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Asset Id',
    'Asset Name',
    'Serial #',
    'IPSN',
    'CAM Tag',
    'MSIT Tag',
    'CC #',
    'Make',
    'Model',
    'New Notes',
    'Department',
    'School Code',
    'Street',
    'Suite #',
    'Main Asset Id',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Asset Id': rowData.id,
        'Asset Name': rowData.asset_name,
        'Serial #': rowData.serial_num,
        'IPSN': rowData.ipsn,
        'CAM Tag': rowData.cam_tag_num,
        'MSIT Tag': rowData.msit_tag_num,
        'CC #': rowData.w_p_num,
        'Make': rowData.the_make.choice,
        'Model': rowData.the_model.choice,
        'Notes': rowData.notes,
        'Department': rowData.department ? rowData.department.department_name : '',
        'School Code': rowData.ou ? rowData.ou.ou_name : '',
        'Street': rowData.building ? rowData.building.street : '',
        'Suite #': rowData.suite_num,
        'Main Asset Id': rowData.parent_asset_id,
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 20 }, // Asset Id
    { wch: 50 }, // Asset Name
    { wch: 10 }, // Serial #
    { wch: 10 }, // IPSN
    { wch: 10 }, // CAM Tag
    { wch: 10 }, // MSIT Tag
    { wch: 10 }, // CC #
    { wch: 60 }, // Make
    { wch: 60 }, // Model
    { wch: 80 }, // Notes
    { wch: 50 }, // Department
    { wch: 50 }, // School
    { wch: 50 }, // Street
    { wch: 10 }, // Suite #
    { wch: 20 }, // Main Asset Id
];


const CreditCardReadersList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_CREDIT_CARD_READER}
            CrudBaseForm={AssetForm}
            bulkUpdateRoute={CREDIT_CARD_READER_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="All CC Readers"
        />
    );
};

export default CreditCardReadersList;
