import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { ENTITY_BUILDING } from 'redux/entities_config';
import BuildingForm from './BuildingForm';
import { BUILDINGS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';


const dataTableColumns = [
    {
        field: 'description',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'code',
        header: 'Code',
        sortable: true,
    },
    {
        field: 'street',
        header: 'Street',
        sortable: true,
    },
    {
        field: 'city.city_name',
        header: 'City',
        sortable: true,
    },
    {
        field: 'state.state_name',
        header: 'State',
        sortable: true,
    },
    {
        field: 'zip',
        header: 'Zip Code',
        sortable: true,
    },
    {
        field: 'site_code',
        header: 'Site Code',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Name',
    'Code',
    'Street',
    'City',
    'State',
    'Zip Code',
    'Site Code',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.description,
        'Code': rowData.code,
        'Street': rowData.street,
        'City': rowData.city.city_name,
        'State': rowData.state.state_name,
        'Zip Code': rowData.zip,
        'Site Code': rowData.site_code,
        'Status': rowData.active ? 'Active' : 'Inactive',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 100 }, // Description
    { wch: 8 }, // Code
    { wch: 80 }, // Street
    { wch: 60 }, // City
    { wch: 40 }, // State
    { wch: 8 }, // Zip Code
    { wch: 8 }, // Site Code
    { wch: 10 }, // Active
];

const BuildingsList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_BUILDING}
            CrudBaseForm={BuildingForm}
            bulkUpdateRoute={BUILDINGS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default BuildingsList;
