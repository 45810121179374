import { ConfirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { Fragment, useCallback, useState } from 'react';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';

import { SETTING_GROUPS, SETTING_GROUPS_LABELS } from 'assets/static_data/backend_exports';
import SettingGroupForm from './SettingGroupForm';

import 'styles/p_tabview.scss';

const OrganizationSettings = () => {
    const [dirtyForm, setDirtyForm] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [nextActiveIndex, setNextActiveIndex] = useState(null);

    const [discardChangesDialogState, setDiscardChangesDialogState] = useState(false);

    const tabChangeHandler = useCallback((e) => {
        if (dirtyForm) {
            showDiscardChangesDialog();
            setNextActiveIndex(e.index);
        } else {
            setActiveIndex(e.index);
        }
    }, [dirtyForm]);

    const hideDiscardChangesDialog = () => {
        setDiscardChangesDialogState(false);
    }

    const showDiscardChangesDialog = () => {
        setDiscardChangesDialogState(true);
    }

    const performDiscardChanges = () => {
        setActiveIndex(nextActiveIndex);
        hideDiscardChangesDialog();
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card className='mb-3'>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h4 className="mt-2">Organization Settings</h4>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TabView activeIndex={activeIndex} onTabChange={tabChangeHandler}>
                            {Object.values(SETTING_GROUPS).map((settingGroupCode) => (
                                <TabPanel header={SETTING_GROUPS_LABELS[settingGroupCode]} key={settingGroupCode}>
                                    <SettingGroupForm settingGroup={settingGroupCode} setDirtyForm={setDirtyForm} />
                                </TabPanel>
                            ))}
                        </TabView>
                    </Col>
                </Row>
            </Container>
            <ConfirmDialog visible={discardChangesDialogState}
                onHide={hideDiscardChangesDialog}
                message="Are you sure you want to discard changes?"
                header="Discard Changes"
                acceptLabel="Discard"
                rejectLabel="Cancel"
                accept={performDiscardChanges} reject={hideDiscardChangesDialog} />
        </Fragment>
    );
};

export default OrganizationSettings;
