import axios from "axios";
import { Toast } from 'primereact/toast';
import { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { TENANT_CODE_URL_PARAMETER } from "assets/static_data/backend_exports";
import AppModalProgress from "components/ui/AppModalProgress";
import AppSpinner from "components/ui/AppSpinner";
import NotificationModal from "components/ui/NotificationModal";
import { handleAppDataInterceptor, invalidTokenInterceptor, networkErrorInterceptor, otherErrorsInterceptor } from "interceptors/axiosInterceptors";
import { CREATE_ACCOUNT, LOGIN_FORM, LOGOUT, RESET_PASSWORD, RETRIEVE_ORGANIZATION, SELECT_ORGANIZATION } from "navigation/ROUTE_CONSTANTS";
import { RouterConfig } from "navigation/RouterConfig";
import { loginSuccess } from "redux/actions/auth.actions";
import {
    changeLayoutTheme,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme
} from "redux/actions/layout.actions";
import { clearToast, setActiveTenant, setRedirectAfterRetrieveOrganization, setToast } from "redux/actions/ui.actions";
import { getUser } from "redux/actions/users.actions";
import { store } from "redux/configureStore";
import { TOAST_SEVERITY_ERROR } from "redux/reducers/ui.reducer";
import { setAxiosBaseUrl } from "utils";

// First import the theme
import './theme/assets/scss/theme.scss';

// Then import the PrimeReact theme
import './theme/primereact.scss';
import './App.scss';

const updateAxiosRequestInterceptor = (accessToken, refreshToken) => {
    axios.interceptors.request.clear();
    if (accessToken) {
        axios.interceptors.request.use((config) => {
            config.headers.Authorization = 'Bearer ' + accessToken;
            config.userData = {
                refresh: refreshToken
            };
            return config;
        });
    }
};

axios.defaults.baseURL = null;
// Order of interceptors is important
axios.interceptors.response.use(null, (error) => invalidTokenInterceptor(error, store, updateAxiosRequestInterceptor));
axios.interceptors.response.use(null, networkErrorInterceptor);
axios.interceptors.response.use(null, otherErrorsInterceptor);
axios.interceptors.response.use(null, (error) => handleAppDataInterceptor(error, store));

const useQuery = () => new URLSearchParams(useLocation().search);

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const queryParams = useQuery();
    const { overlay, toast: toastSelector, activeTenant } = useSelector(state => state.ui);
    const themeState = useSelector(state => state.layout);
    const { accessToken, refreshToken } = useSelector(state => state.auth);
    const toast = useRef(null);

    useEffect(() => {
        if (process.env.REACT_APP_MULTI_TENANT === 'true') {
            // Multi-tenancy enabled
            let tenantCodeParameter = queryParams.get(TENANT_CODE_URL_PARAMETER);
            if (tenantCodeParameter) {
                dispatch(setRedirectAfterRetrieveOrganization(location.pathname + location.search));
                history.push(RETRIEVE_ORGANIZATION.replace(':tenantCode', tenantCodeParameter));
            } else {
                // Check if tenant is in session storage
                let tenant = JSON.parse(sessionStorage.getItem('in-tenant'));
                if (!tenant) {
                    // Check if tenant is in local storage
                    tenant = JSON.parse(localStorage.getItem('in-tenant'));
                }
                if (tenant) {
                    setAxiosBaseUrl(axios, tenant);
                    dispatch(setActiveTenant(tenant));
                } else {
                    history.push(SELECT_ORGANIZATION);
                }
            }
        } else {
            // Multi-tenancy disabled
            const tenant = JSON.parse(localStorage.getItem('in-tenant'));
            if (tenant) {
                setAxiosBaseUrl(axios, tenant);
                dispatch(setActiveTenant(tenant));
            } else {
                history.push(RETRIEVE_ORGANIZATION.replace(':tenantCode', process.env.REACT_APP_TENANT_CODE));
            }
        }

        // Set University Header and Footer height
        let root = document.documentElement;
        let universityHeaderHeight = '0px';
        let footerHeight = '40px';
        if (process.env.REACT_APP_UNIVERSITY_HEADER_FOOTER === 'true') {
            universityHeaderHeight = '31px';
            footerHeight = '9rem';
        }
        root.style.setProperty('--bs-university-header-height', universityHeaderHeight); // --bs- is the prefix for Bootstrap variables. #{$prefix}
        root.style.setProperty('--bs-footer-height', footerHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (activeTenant.code) {
            if (!activeTenant.domains || activeTenant.domains.length === 0) {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Domain not configured',
                    detail: 'Active Organization does not have a domain configured. Please contact support.'
                }));
            } else {
                setAxiosBaseUrl(axios, activeTenant);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTenant.code, activeTenant.domains]);

    useEffect(() => {
        // If activeTenant.code changed and is not in the public routes
        //  then check for refresh token in local storage
        if (activeTenant.code && ![
            SELECT_ORGANIZATION, RETRIEVE_ORGANIZATION, LOGIN_FORM, RESET_PASSWORD, CREATE_ACCOUNT, LOGOUT
        ].includes(location.pathname)) {
            const refresh = localStorage.getItem('in-refresh-' + activeTenant.code);
            const access = localStorage.getItem('in-access-' + activeTenant.code);
            if (refresh) {
                dispatch(loginSuccess({
                    access,
                    refresh,
                    activeTenantCode: activeTenant.code
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTenant.code]);

    useEffect(() => {
        updateAxiosRequestInterceptor(accessToken, refreshToken);
        if (refreshToken) {
            dispatch(getUser());
        }
    }, [accessToken, dispatch, refreshToken]);

    useEffect(() => {
        if (toastSelector && toastSelector.severity && (toastSelector.summary || toastSelector.detail)) {
            let toastOptions = {
                ...toastSelector
            };
            if (toastSelector.life) {
                toastOptions.life = toastSelector.life;
            }

            toast.current.show(toastOptions);
            dispatch(clearToast());
        }
    }, [dispatch, toastSelector]);

    useEffect(() => {
        dispatch(changeLayoutTheme(themeState.layoutTheme));
        dispatch(changeSidebarTheme(themeState.leftSideBarTheme));
        dispatch(changeLayoutWidth(themeState.layoutWidth));
        dispatch(changeSidebarType(themeState.leftSideBarType));
        dispatch(changeTopbarTheme(themeState.topbarTheme));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeState.siteTheme])

    return (
        <Fragment>
            <RouterConfig />
            <NotificationModal />
            <AppModalProgress />
            {overlay.loading && <AppSpinner message={overlay.message} />}
            <Toast position="top-right" ref={toast} />
        </Fragment>
    );
}

export default App;
