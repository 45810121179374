import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';

import { ALL_ASSETS_LIST, WPO_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { entityBulkUpdatePermissions } from 'navigation/action_permissions';
import { ENTITY_WAP_ASSET } from 'redux/entities_config';
import { AssetForm } from './AssetForm';

const AssetPageForm = () => {
    const { recordId = null } = useParams();
    const history = useHistory();

    const [headerTitle, setHeaderTitle] = useState('Add Asset');
    const userPermissions = useSelector(state => state.users.userPermissions);

    useEffect(() => {
        if (recordId) {
            setHeaderTitle('Edit Asset');
        }
    }, [recordId]);

    const handleBulkUpdateButton = () => {
        history.push(WPO_BULK_UPDATE);
    };

    const handleClose = () => {
        history.push(ALL_ASSETS_LIST);
    };

    return (
        <Container fluid>
            <Row>
                <Col xl={12}>
                    <Card className='mb-3'>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mt-2">{headerTitle}</h4>
                                </Col>
                                <Col className="text-end">
                                    {userPermissions && userPermissions.includes(entityBulkUpdatePermissions[ENTITY_WAP_ASSET]) &&
                                        <Button label="Bulk Add/Update" icon="ri-upload-2-line" severity="secondary" className="ms-2" onClick={handleBulkUpdateButton} />
                                    }
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row className="datatable-crud-base p-fluid mb-4">
                <AssetForm close={handleClose} recordId={recordId} />
            </Row>
        </Container>
    );
};

export default AssetPageForm;
