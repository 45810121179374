import { Field, useField, useFormikContext } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';


export const AppOptionSelect = ({ fieldName, selectOptions, fieldLabel }) => {
    const [inputId] = useState(() => 'input_' + fieldName);

    const [, meta] = useField({ name: fieldName });
    const [fieldErrorMessage, setFieldErrorMessage] = useState(null);
    const { status: { apiErrors = {} } = {} } = useFormikContext();

    useEffect(() => {
        let localFieldErrorMessage = null;
        if (meta.touched) {
            if (fieldName in apiErrors) {
                localFieldErrorMessage = apiErrors[fieldName];
            } else if (meta.error) {
                localFieldErrorMessage = meta.error;
            }
        }
        setFieldErrorMessage(localFieldErrorMessage);
    }, [meta.touched, meta.error, apiErrors, fieldName]);

    return (
        <Fragment>
            <Label for={inputId}>{fieldLabel}</Label>
            <Field id={inputId} name={fieldName} as="select"

                className="form-select"
            >
                <option value="">{`Select ${fieldLabel}`}</option>
                {Object.entries(selectOptions).map((role) =>
                    <option value={role[0]}
                        key={role[0]}>{role[1]}</option>)
                }
            </Field>

            {fieldErrorMessage &&
                <div className="text-danger mt-1">
                    {fieldErrorMessage}
                </div>
            }
        </Fragment>
    )
}

export default AppOptionSelect;
