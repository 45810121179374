import { CrudBaseList } from 'components/base/CrudBaseList';
import { StateFieldIndicator } from 'components/ui/StateFieldIndicator';
import { OUS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_OU } from 'redux/entities_config';
import { OuForm } from './OuForm';


const dataTableColumns = [
    {
        field: 'ou_name',
        header: 'Code',
        sortable: true,
    },
    {
        field: 'ou_description',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'active',
        header: 'Is Active?',
        sortable: true,
        body: (rowData) => (
            <StateFieldIndicator state={rowData.active} />
        ),
        align: 'center'
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'Code',
    'Name',
    'Status',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Code': rowData.ou_name,
        'Name': rowData.ou_description,
        'Status': rowData.active ? 'Active' : 'Inactive',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 20 }, // Code
    { wch: 80 }, // Name
    { wch: 10 }, // Status
];

const OusList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_OU}
            CrudBaseForm={OuForm}
            bulkUpdateRoute={OUS_BULK_UPDATE}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
        />
    );
};

export default OusList;
