import { createAction } from "@reduxjs/toolkit";

// Functional Types action types
export const CRUD_BASE_GET_LIST_START = 'crud_base_get_list_start';
export const CRUD_BASE_GET_LIST_SUCCESS = 'crud_base_get_list_success';
export const CRUD_BASE_GET_LIST_FAILED = 'crud_base_get_list_failed';
export const CRUD_BASE_GET_ONE_START = 'crud_base_get_one_start';
export const CRUD_BASE_GET_ONE_SUCCESS = 'crud_base_get_one_success';
export const CRUD_BASE_GET_ONE_RESET = 'crud_base_get_one_reset';
export const CRUD_BASE_CREATE_START = 'crud_base_create_start';
export const CRUD_BASE_UPDATE_START = 'crud_base_update_start';
export const CRUD_BASE_DELETE_START = 'crud_base_delete_start';
export const CRUD_BASE_DELETE_SUCCESS = 'crud_base_delete_success';
export const CRUD_BASE_BULK_DELETE_START = 'crud_base_bulk_delete_start';
export const CRUD_BASE_RESET_STATE = 'crud_base_reset_state';

// Functional Types actions
export const crudBaseGetListStart = createAction(CRUD_BASE_GET_LIST_START);
export const crudBaseGetListSuccess = createAction(CRUD_BASE_GET_LIST_SUCCESS);
export const crudBaseGetListFailed = createAction(CRUD_BASE_GET_LIST_FAILED);
export const crudBaseGetOneStart = createAction(CRUD_BASE_GET_ONE_START);
export const crudBaseGetOneSuccess = createAction(CRUD_BASE_GET_ONE_SUCCESS);
export const crudBaseGetOneReset = createAction(CRUD_BASE_GET_ONE_RESET);
export const crudBaseCreateStart = createAction(CRUD_BASE_CREATE_START);
export const crudBaseUpdateStart = createAction(CRUD_BASE_UPDATE_START);
export const crudBaseDeleteStart = createAction(CRUD_BASE_DELETE_START);
export const crudBaseDeleteSuccess = createAction(CRUD_BASE_DELETE_SUCCESS);
export const crudBaseBulkDeleteStart = createAction(CRUD_BASE_BULK_DELETE_START);
export const crudBaseResetState = createAction(CRUD_BASE_RESET_STATE);
