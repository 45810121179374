import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";

import { logout } from "redux/actions/auth.actions";

const Logout = () => {
    const dispatch = useDispatch();
    const refreshToken = useSelector(state => state.auth.refreshToken);

    useEffect(() => {
        if (refreshToken) {
            dispatch(logout({ refreshToken }));
        }
    }, [dispatch, refreshToken]);


    return (
        <Container className='min-vh-72'>
            <span>&nbsp;</span>
        </Container>
    );
}

export default Logout;
