import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_USER_BY_ADMIN,
    GET_USERS_LIST_BY_ADMIN,
    REMOVE_USER_BY_ADMIN,
    UPDATE_USER_BY_ADMIN,
    createUserByAdminSuccess,
    getUsersListByAdmin,
    getUsersListByAdminSuccess,
    resetAdminUsersState
} from "redux/actions/admin.users.actions";
import { genericError } from "redux/actions/error.actions";
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from "redux/actions/ui.actions";
import { TOAST_SEVERITY_SUCCESS } from "redux/reducers/ui.reducer";
import {
    getUsersByAdmin,
    postUserByAdmin,
    removeUserByAdmin,
    updateUserByAdmin
} from "services/adminUserService";

/**
 * getUsersByAdminEffect
 * @param {*} param0
 */
function* getUsersByAdminEffect() {
    yield put(enableOverlay('Loading users ...'));
    try {
        const usersList = yield call(getUsersByAdmin);
        yield put(getUsersListByAdminSuccess(usersList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * createUserByAdminEffect
 * @param {*} param0
 */
function* createUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating new account ...'));
        const createdUser = yield call(postUserByAdmin, payload);
        if (createdUser) {
            yield put(createUserByAdminSuccess(createdUser));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The user has been created!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * updateUserByAdminEffect
 * @param {*} param0
 */
function* updateUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating user ...'));
        const updateUser = yield call(updateUserByAdmin, payload);
        if (updateUser) {
            yield put(createUserByAdminSuccess(updateUser));
            yield put(setFormSubmittedSuccess(true));
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The user has been updated!',
            }));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * removeUserByAdminEffect
 */
function* removeUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting user  ...'));
        yield call(removeUserByAdmin, payload);
        yield put(setToast({
            severity: TOAST_SEVERITY_SUCCESS,
            detail: 'The user has been deleted!',
        }));
        yield put(resetAdminUsersState());
        yield put(getUsersListByAdmin());
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}


/**
 * Watchers
 */
export function* watchGetUsersByAdmin() {
    yield takeEvery(GET_USERS_LIST_BY_ADMIN, getUsersByAdminEffect);
}

export function* watchCreateUserByAdmin() {
    yield takeEvery(CREATE_USER_BY_ADMIN, createUserByAdminEffect);
}

export function* watchUpdateUserByAdmin() {
    yield takeEvery(UPDATE_USER_BY_ADMIN, updateUserByAdminEffect);
}

export function* watchRemoveUserByAdmin() {
    yield takeEvery(REMOVE_USER_BY_ADMIN, removeUserByAdminEffect);
}


function* usersByAdminSaga() {
    yield all([
        fork(watchGetUsersByAdmin),
        fork(watchCreateUserByAdmin),
        fork(watchUpdateUserByAdmin),
        fork(watchRemoveUserByAdmin),
    ]);
}

export default usersByAdminSaga;
