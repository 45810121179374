import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';
import { Col, Label, Row } from 'reactstrap';

const OPTION_ALL = '__ALL__';

const dropdownOptions = [
    { label: '-- All --', value: OPTION_ALL },
    { label: 'Successful duplications only', value: true },
    { label: 'Failed duplications only', value: false },
];


export const DuplicationHistoryToolbar = ({ updateFilterSet }) => {
    const [filterStatusValue, setFilterStatusValue] = useState(OPTION_ALL);

    const updateFilterStatus = (value) => {
        setFilterStatusValue(value);
        if (value !== OPTION_ALL) {
            updateFilterSet({ successfully: value });
        } else {
            updateFilterSet({});
        }
    }

    return (
        <Row className='mb-1' style={{ minWidth: '25rem' }}>
            <Col>
                <Label for='field_the_make_id'>Filter by Status</Label>
                <Dropdown className='ms-2' value={filterStatusValue} options={dropdownOptions} onChange={(e) => updateFilterStatus(e.target.value)} />
            </Col>
        </Row >
    )
}

export default DuplicationHistoryToolbar;
