import { SERVER_VALIDATION_RESET, SERVER_VALIDATION_SET } from "redux/actions/server_validations.actions";

const initialServerValidationState = {
    fields: {},
};

export const ServerValidationsReducer = (state = initialServerValidationState, action) => {
    switch (action.type) {
        case SERVER_VALIDATION_SET:
            return {
                ...state,
                fields: action.payload,
            };
        case SERVER_VALIDATION_RESET:
            return {
                ...initialServerValidationState
            };
        default:
            return state;
    }
};
