import { MENU_SECTIONS } from 'assets/static_data/backend_exports';
import { DASHBOARD } from 'navigation/ROUTE_CONSTANTS';
import {
    CLEAR_TOAST,
    CLOSE_MODAL_PROGRESS,
    CLOSE_NOTIFICATION_MODAL,
    DISABLE_OVERLAY,
    ENABLE_OVERLAY,
    NEW_NOTIFICATION_MODAL,
    RESET_ACTIVE_TENANT,
    RESET_UI_ACTION,
    SET_ACTIVE_MENU_SECTION,
    SET_ACTIVE_TENANT,
    SET_FORM_DIALOG_SUBMIT_SUCCESS,
    SET_FORM_SUBMITTED_SUCCESS,
    SET_HOME_TARGET_URL,
    SET_LAST_KNOW_VERSION,
    SET_NAVIGATE_TO,
    SET_NEW_VERSION_AVAILABLE,
    SET_REDIRECT_AFTER_LOGIN,
    SET_REDIRECT_AFTER_RETRIEVE_ORGANIZATION,
    SET_START_LOGOUT_PROCESS,
    SET_TOAST,
    TRIGGER_UI_ACTION,
    UPDATE_MODAL_PROGRESS,
} from 'redux/actions/ui.actions';
import { saveTenantBrowserStorage } from 'utils';

export const TOAST_SEVERITY_SUCCESS = 'success';
export const TOAST_SEVERITY_INFO = 'info';
export const TOAST_SEVERITY_WARN = 'warn';
export const TOAST_SEVERITY_ERROR = 'error';

const initialUIState = {
    overlay: { loading: false, message: null },
    modalProgress: { isOpen: false, message: null, percentage: 0 },
    notificationModal: {
        title: null,
        message: null,
        iconType: null,
        isOpen: false,
    },
    toast: {
        severity: null,
        summary: null,
        detail: null,
        life: null,
    },
    navigateTo: null,
    activeMenuSection: null,
    homeTargetUrl: DASHBOARD,
    redirectAfterLogin: null,
    redirectAfterRetrieveOrganization: null,
    formDialogSubmitSuccess: false,
    formSubmittedSuccess: false,
    startLogoutProcess: false,
    uiAction: null,
    lastKnownVersion: {
        // Must follow the signature of file public/ui_version.json. See generation on set_env_vars.sh
        commitHash: process.env.REACT_APP_GIT_COMMIT_SHA,
        timestamp: process.env.REACT_APP_GIT_COMMIT_TIMESTAMP,
    },
    newVersionAvailable: false,
    activeTenant: {
        code: null,
        name: null,
        domains: [],
        workstation_enabled: false,
        inventory_enabled: false,
        is_saml_configure: false,
    },
};

export const UIReducer = (state = initialUIState, action) => {
    let activeMenuSection;
    let activeTenant;

    switch (action.type) {
        case ENABLE_OVERLAY:
            return {
                ...state,
                overlay: { loading: true, message: action.payload },
            };
        case DISABLE_OVERLAY:
            return { ...state, overlay: { loading: false, message: null } };
        case UPDATE_MODAL_PROGRESS:
            return {
                ...state,
                modalProgress: {
                    isOpen: true,
                    message: action.payload.message,
                    percentage: action.payload.percentage,
                },
            };
        case CLOSE_MODAL_PROGRESS:
            return {
                ...state,
                modalProgress: {
                    isOpen: false,
                    message: null,
                    percentage: 0,
                },
            };
        case NEW_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: {
                    title: action.payload.title,
                    message: action.payload.message,
                    iconType: action.payload.iconType,
                    buttonResponse: action.payload.buttonResponse,
                    buttonAcceptText: action.payload.buttonAcceptText,
                    handleAccept: action.payload.handleAccept,
                    isOpen: true,
                },
            };
        case CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: {
                    title: null,
                    message: null,
                    iconType: null,
                    isOpen: false,
                },
            };
        case SET_TOAST:
            return { ...state, toast: action.payload };
        case CLEAR_TOAST:
            return { ...state, toast: initialUIState.toast };
        case SET_NAVIGATE_TO:
            return { ...state, navigateTo: action.payload };
        case SET_HOME_TARGET_URL:
            return { ...state, homeTargetUrl: action.payload };
        case SET_REDIRECT_AFTER_LOGIN:
            return { ...state, redirectAfterLogin: action.payload };
        case SET_REDIRECT_AFTER_RETRIEVE_ORGANIZATION:
            return { ...state, redirectAfterRetrieveOrganization: action.payload };
        case SET_FORM_DIALOG_SUBMIT_SUCCESS:
            return { ...state, formDialogSubmitSuccess: action.payload };
        case SET_FORM_SUBMITTED_SUCCESS:
            return { ...state, formSubmittedSuccess: action.payload };
        case SET_START_LOGOUT_PROCESS:
            return { ...state, startLogoutProcess: action.payload };
        case SET_ACTIVE_MENU_SECTION:
            activeMenuSection = null;
            // If the active tenant does not have the feature enabled, do not allow the switch
            if (MENU_SECTIONS.WORKSTATION === action.payload && state.activeTenant.workstation_enabled) {
                activeMenuSection = MENU_SECTIONS.WORKSTATION;
            } else if (MENU_SECTIONS.INVENTORY === action.payload && state.activeTenant.inventory_enabled) {
                activeMenuSection = MENU_SECTIONS.INVENTORY;
            }

            return {
                ...state,
                activeMenuSection
            };
        case TRIGGER_UI_ACTION:
            return { ...state, uiAction: action.payload };
        case RESET_UI_ACTION:
            return { ...state, uiAction: null };
        case SET_LAST_KNOW_VERSION:
            return { ...state, lastKnownVersion: action.payload };
        case SET_NEW_VERSION_AVAILABLE:
            return { ...state, newVersionAvailable: action.payload };
        case SET_ACTIVE_TENANT:
            activeTenant = action.payload;

            saveTenantBrowserStorage(activeTenant);

            if (
                (state.activeMenuSection === MENU_SECTIONS.WORKSTATION && action.payload.workstation_enabled) ||
                (state.activeMenuSection === MENU_SECTIONS.INVENTORY && action.payload.inventory_enabled)
            ) {
                activeMenuSection = state.activeMenuSection;
            } else if (action.payload.workstation_enabled) {
                activeMenuSection = MENU_SECTIONS.WORKSTATION;
            } else if (action.payload.inventory_enabled) {
                activeMenuSection = MENU_SECTIONS.INVENTORY;
            }

            return {
                ...state,
                activeTenant,
                activeMenuSection
            };
        case RESET_ACTIVE_TENANT:
            return {
                ...state,
                activeTenant: initialUIState.activeTenant,
                activeMenuSection: null,
            };
        default:
            return state;
    }
};
