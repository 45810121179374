import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { genericError } from "redux/actions/error.actions";
import { GET_WORKFLOW_REQ_CATEGORY, getWorkflowReqCategorySuccess } from 'redux/actions/workflow_req_categories.actions';
import { WorkflowReqCategoryService } from 'services/workflowReqCategoryService';

const workflowReqCategoryService = new WorkflowReqCategoryService();

/**
 * getWorkflowReqCategoriesEffect
 */
function* getWorkflowReqCategoriesEffect({ payload }) {
    try {
        const workflowReqCategoriesList = yield call([workflowReqCategoryService, workflowReqCategoryService.getAll],
            {
                ...payload,
                filterSet: { 'for-default-reqs': true }
            }
        );
        if (workflowReqCategoriesList) {
            yield put(getWorkflowReqCategorySuccess(workflowReqCategoriesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
}

export function* watchGetWorkflowReqCategories() {
    yield takeEvery(GET_WORKFLOW_REQ_CATEGORY, getWorkflowReqCategoriesEffect);
}

function* workflowReqCategoriesSaga() {
    yield all([
        fork(watchGetWorkflowReqCategories),

    ]);
}

export default workflowReqCategoriesSaga;
