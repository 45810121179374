import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import ThemeSwitch from 'components/ui/ThemeSwitch';
import { LOGOUT, USER_PROFILE } from 'navigation/ROUTE_CONSTANTS';
import avatar from 'theme/assets/images/users/avatar-2.jpg';

export const ProfileMenu = () => {
    const userLogged = useSelector(state => state.users.userLogged);
    // const activeTenant = useSelector(state => state.ui.activeTenant);
    const [menu, setMenu] = useState(false);

    function toggle() {
        setMenu(!menu);
    }

    return (
        <Dropdown isOpen={menu} toggle={() => toggle()} className="d-inline-block user-dropdown">
            <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                <img className="rounded-circle header-profile-user me-1" src={avatar} alt={'Profile pic for ' + userLogged.username} />
                <span className="d-none d-xl-inline-block ms-1 text-transform text-truncate align-text-bottom" style={{ maxWidth: '10rem' }}>{userLogged.username}</span>
                <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
                <DropdownItem to={USER_PROFILE} tag={Link}>
                    <i className="ri-user-line align-bottom me-1"></i> {'Profile'}
                </DropdownItem>
                <DropdownItem toggle={false}>

                    <ThemeSwitch />

                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-danger" to={LOGOUT} tag={Link}>
                    <i className="ri-shut-down-line align-bottom me-1 text-danger"></i> {'Logout'}
                </DropdownItem>
                { /*
                process.env.REACT_APP_MULTI_TENANT === 'true' && activeTenant &&
                    <DropdownItem to={SELECT_ORGANIZATION} tag={Link}>
                        <i className="ri-logout-box-r-line align-bottom me-1"></i> {activeTenant.name}
                    </DropdownItem>
                */ }
            </DropdownMenu>
        </Dropdown>
    );
}

export default ProfileMenu;
