import { ListBox } from 'primereact/listbox';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { LOGIN_FORM } from 'navigation/ROUTE_CONSTANTS';
import { disableOverlay, enableOverlay, resetActiveTenant, setActiveTenant, setNavigateTo, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR } from 'redux/reducers/ui.reducer';
import { getTenants } from 'services/tenantService';
import { validateTenant } from 'utils/index';
import { OnBoardingLayout } from './OnBoardingLayout';

import '../../styles/OnBoarding.css';
import '../../styles/p_listbox.scss';

export const SelectOrganization = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [organizationsList, setOrganizationsList] = useState([]);

    useEffect(() => {
        dispatch(resetActiveTenant());
        dispatch(setNavigateTo(null));

        dispatch(enableOverlay('Loading organizations...'));
        getTenants()
            .then(data => {
                setOrganizationsList(data.results);
            })
            .catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error fetching organizations',
                    detail: err.message,
                }));
            })
            .finally(() => {
                dispatch(disableOverlay());
            });
    }, [dispatch]);

    const handleSelection = (selectedTenant) => {
        if (selectedTenant) {
            let isValid = validateTenant(selectedTenant);
            if (isValid) {
                dispatch(setActiveTenant(selectedTenant));
                history.push(LOGIN_FORM);
            } else {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error selecting organization',
                    detail: JSON.stringify(selectedTenant),
                }));
            }

        }
    }

    return (
        <OnBoardingLayout contentTitle="Select your organization">
            <Row>
                <Col>
                    <ListBox
                        onChange={(e) => handleSelection(e.value)}
                        options={organizationsList} optionLabel="name"
                        className="select-organization-list mt-5" />
                </Col>
            </Row>
        </OnBoardingLayout >
    )
};

export default SelectOrganization;
