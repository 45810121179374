import { Form, Formik } from 'formik';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import { SETTING, SETTING_LABELS } from 'assets/static_data/backend_exports';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { API_ORGANIZATION_SETTINGS } from 'services/API_CONSTANTS';
import { entityDataImport } from 'services/bulkUpdateService';
import { crudBaseGetList } from 'services/crudBaseService';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';


const SettingGroupForm = ({ settingGroup, setDirtyForm }) => {
    const dispatch = useDispatch();

    const [currentGroupSettings, setCurrentGroupSettings] = useState([]);
    const [retrievedSettings, setRetrievedSettings] = useState(null);

    const [discardChangesDialogState, setDiscardChangesDialogState] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState({});

    const handleReload = useCallback(() => {
        setDirtyForm(false);
        dispatch(enableOverlay('Loading settings...'));

        crudBaseGetList(API_ORGANIZATION_SETTINGS, {
            limit: 9999,
            filterSet: {
                'setting-group': settingGroup,
            }
        }).then((data) => {
            let settings = {};
            let retrievedSettings = {};
            data.results.forEach((item) => {
                settings[item.setting] = item.value;
                retrievedSettings[item.setting] = item;
            });
            setInitialFormValues(settings);
            setRetrievedSettings(retrievedSettings);
        }).catch((err) => {
            dispatch(setToast({
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Error when retrieving settings',
                detail: Object.values(err.response.data).map((value, index) => (
                    value
                )).join('. '),
            }));
        }).finally(() => {
            dispatch(disableOverlay());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingGroup]);

    useEffect(() => {
        let currentGroupSettings = Object.values(SETTING).filter((setting) => setting.startsWith(settingGroup));
        setCurrentGroupSettings(currentGroupSettings);
        handleReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleReload]);

    const hideDiscardChangesDialog = () => {
        setDiscardChangesDialogState(false);
    }

    const showDiscardChangesDialog = () => {
        setDiscardChangesDialogState(true);
    }

    const performDiscardChanges = () => {
        hideDiscardChangesDialog();
        setInitialFormValues(null);
        handleReload();
    }

    const settingLastUpdateInfo = (settingCode) => (
        retrievedSettings[settingCode] &&
        <div className="text-muted">
            <small>Last updated:
                {retrievedSettings[settingCode].last_modified_date ? isoStringToFormatted(retrievedSettings[settingCode].last_modified_date, FORMAT_DATETIME_SHORT) : ''}
                {' by '}
                {retrievedSettings[settingCode].last_modified_by.username}
            </small>
        </div>
    );

    return (
        <Fragment>
            <Card className='mb-3'>
                <CardBody>
                    {retrievedSettings &&
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            onSubmit={(values, actions) => {

                                let bulkUpdateQueue = [];
                                // Loop through the settings
                                currentGroupSettings.forEach((settingCode) => {
                                    if (!(settingCode in retrievedSettings)) {
                                        // If the setting is not in the retrieved settings, it means it is a new setting
                                        if (values[settingCode]) {
                                            let formData = {
                                                setting: settingCode,
                                                value: values[settingCode],
                                                queue_item_identifier: settingCode,
                                            };
                                            bulkUpdateQueue.push(formData);
                                        }
                                    } else if (values[settingCode] !== retrievedSettings[settingCode].value) {
                                        // If the setting is in the retrieved settings and the value has changed
                                        let formData = {
                                            setting: settingCode,
                                            value: values[settingCode],
                                            queue_item_identifier: settingCode,
                                        }
                                        bulkUpdateQueue.push(formData);
                                    }
                                });

                                if (bulkUpdateQueue.length > 0) {
                                    entityDataImport(API_ORGANIZATION_SETTINGS, bulkUpdateQueue)
                                        .then((response) => {

                                            let isThereError = false;
                                            response.forEach((item) => {
                                                if (item.queue_success === 'error') {
                                                    isThereError = true;
                                                    let errors = [];
                                                    if (item.value) {
                                                        errors.push(item.value);
                                                    }
                                                    if (item.setting) {
                                                        errors.push(item.setting);
                                                    }
                                                    actions.setFieldError(item.queue_item_identifier, errors.join(' / '));
                                                }
                                            });

                                            if (isThereError) {
                                                dispatch(setToast({
                                                    severity: TOAST_SEVERITY_ERROR,
                                                    summary: 'Errors!',
                                                    detail: 'There are errors in the settings. Please check the fields.',
                                                }));
                                            } else {
                                                dispatch(setToast({
                                                    severity: TOAST_SEVERITY_SUCCESS,
                                                    summary: 'Success!',
                                                    detail: 'Settings saved successfully!',
                                                }));
                                                handleReload();
                                            }
                                        }).catch((err) => {
                                            console.error(err);
                                        });
                                }

                            }}
                        >{({ dirty }) => (
                            <Fragment>
                                {setDirtyForm && setDirtyForm(dirty)}
                                <Form>
                                    <Row>
                                        {currentGroupSettings.map((settingCode) => {
                                            if (settingCode === SETTING.EPIC_API_CLIENT_PRIVATE_KEY) {
                                                return (
                                                    <Col md={6} sm={12} key={settingCode}>
                                                        <FormGroup>
                                                            <AppInputFieldComponent
                                                                fieldType="textarea"
                                                                fieldName={settingCode}
                                                                tooltipText="This is a write-only field. The value is not retrieved from the server."
                                                                inputLabel={SETTING_LABELS[settingCode]}
                                                            />
                                                            {settingLastUpdateInfo(settingCode)}
                                                        </FormGroup>
                                                    </Col>
                                                )
                                            } else if ([SETTING.SERVICE_NOW_PASSWORD, SETTING.SERVICE_NOW_CLIENT_SECRET].includes(settingCode)) {
                                                return (
                                                    <Col md={4} sm={12} key={settingCode}>
                                                        <FormGroup>
                                                            <AppInputFieldComponent
                                                                fieldType="text"
                                                                fieldName={settingCode}
                                                                tooltipText="This is a write-only field. The value is not retrieved from the server."
                                                                inputLabel={SETTING_LABELS[settingCode]}
                                                            />
                                                            {settingLastUpdateInfo(settingCode)}
                                                        </FormGroup>
                                                    </Col>
                                                )
                                            } else {
                                                return (
                                                    <Col md={4} sm={12} key={settingCode}>
                                                        <FormGroup>
                                                            <AppInputFieldComponent
                                                                fieldType="text"
                                                                fieldName={settingCode}
                                                                inputPlaceHolder="Enter the setting value"
                                                                inputLabel={SETTING_LABELS[settingCode]}
                                                            />
                                                            {settingLastUpdateInfo(settingCode)}
                                                        </FormGroup>
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col className="text-center" md={6} sm={12}>
                                            <Button color='secondary' className='mt-2 px-5' onClick={showDiscardChangesDialog} disabled={!dirty}>
                                                Reload
                                            </Button>
                                        </Col>
                                        <Col className="text-center" md={6} sm={12}>
                                            <Button color='success' className='mt-2 px-5' type='submit' disabled={!dirty}>
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fragment>
                        )
                            }
                        </Formik>
                    }
                </CardBody>
            </Card>
            <ConfirmDialog visible={discardChangesDialogState}
                onHide={hideDiscardChangesDialog}
                message="Are you sure you want to discard changes?"
                header="Reload Values"
                acceptLabel="Discard"
                rejectLabel="Cancel"
                accept={performDiscardChanges} reject={hideDiscardChangesDialog} />
        </Fragment>
    )
}

export default SettingGroupForm;
