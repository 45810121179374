import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form, useFormikContext } from 'formik';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import AppEntityDropdown from 'components/ui/AppEntityDropdown';
import AppInputFieldComponent from 'components/ui/AppInputFieldComponent';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { serverValidationReset } from 'redux/actions/server_validations.actions';
import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { ENTITY_BUILDING, ENTITY_SELECT_DEPARTMENT, ENTITY_WORKFLOW_REQ_CATEGORIES } from 'redux/entities_config';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { API_WORKFLOW_REQS } from 'services/API_CONSTANTS';
import { crudBaseDeleteOne, crudBaseGetList, crudBasePost } from 'services/crudBaseService';


const ObserverFormOnChange = ({ updateFilterSet }) => {
    const { values: formikValues, setFieldValue, dirty } = useFormikContext();
    const [lastFieldValues, setLastFieldValues] = useState(null);

    useEffect(() => {
        if (lastFieldValues && dirty) {
            let filterSet = {};
            let performUpdate = false;
            if (formikValues.building_id !== lastFieldValues.building_id) {
                filterSet.building_id = formikValues.building_id;
                setFieldValue('department_id', null);
                performUpdate = true;
            }

            if (performUpdate) {
                updateFilterSet(filterSet);
            }
        }
        setLastFieldValues(formikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikValues.building_id]);

    return null;
}

export const WorkflowReqExceptionForm = ({ recordId }) => {
    const dispatch = useDispatch();

    const [dropdownDepartmentFilterSet, setDropdownDepartmentFilterSet] = useState(null);
    const [dropdownCategoryFilterSet] = useState({ 'active': 1 });
    const [refreshExceptionsList, setRefreshExceptionsList] = useState(null);
    const [workflowReqExceptions, setWorkflowReqExceptions] = useState([]);
    const [clearDropdownDepartmentOptions, setClearDropdownDepartmentOptions] = useState(null);

    const [deleteRecordDialogState, setDeleteRecordDialogState] = useState(false);
    const [deleteRecordId, setDeleteRecordId] = useState(null);

    useEffect(() => {
        if (recordId) {
            crudBaseGetList(API_WORKFLOW_REQS, {
                limit: 9999,
                filterSet: {
                    'workflow_id': recordId,
                    'only-exceptions': '1',
                }
            }).then((data) => {
                setWorkflowReqExceptions(data.results);
            }).catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error when retrieving exceptions',
                    detail: Object.values(err.response.data).map((value, index) => (
                        value
                    )).join('. '),
                }));
            });
        }
    }, [dispatch, recordId, refreshExceptionsList]);

    const handleFilterSet = useCallback((filterSet) => {
        if ('building_id' in filterSet) {
            if (filterSet.building_id) {
                setDropdownDepartmentFilterSet({
                    building_id: filterSet.building_id,
                });
            } else {
                setClearDropdownDepartmentOptions(new Date().getTime());
            }
        }
    }, [])

    const performDeleteRecord = () => {
        dispatch(enableOverlay('Removing exception...'));
        crudBaseDeleteOne(API_WORKFLOW_REQS, deleteRecordId)
            .then((data) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_SUCCESS,
                    detail: 'Exception deleted',
                }));
                setRefreshExceptionsList(new Date().getTime());
            })
            .catch((err) => {
                dispatch(setToast({
                    severity: TOAST_SEVERITY_ERROR,
                    summary: 'Error deleting exception',
                    detail: Object.values(err.response.data).map((value, index) => (
                        value
                    )).join('. '),
                }));
            })
            .finally(() => {
                dispatch(disableOverlay());
            });

        setDeleteRecordDialogState(false);
    };

    const hideDeleteRowDialog = () => {
        setDeleteRecordDialogState(false);
    };

    const exceptionsTemplate = (data) => {
        if (!data || data.length === 0) return null;

        // Sort by building, department, suite_num, workflow_req_category_name
        let sortedData = data.sort((a, b) => {
            if (a.building.description < b.building.description) return -1;
            if (a.building.description > b.building.description) return 1;
            if (a.department.department_name < b.department.department_name) return -1;
            if (a.department.department_name > b.department.department_name) return 1;
            if (a.suite_num < b.suite_num) return -1;
            if (a.suite_num > b.suite_num) return 1;
            if (a.workflow_req_category.workflow_req_category_name < b.workflow_req_category.workflow_req_category_name) return -1;
            if (a.workflow_req_category.workflow_req_category_name > b.workflow_req_category.workflow_req_category_name) return 1;
            return 0;
        });

        let lastBuildingDescription = null;
        let lastDepartmentName = null;
        let lastSuiteNum = null;
        let buildingDepartmentSuiteGroup = [];
        let groupItems = [];

        sortedData.forEach((item) => {
            if (lastBuildingDescription !== item.building.description || lastDepartmentName !== item.department.department_name || lastSuiteNum !== item.suite_num) {
                if (lastBuildingDescription !== null) {
                    buildingDepartmentSuiteGroup.push({
                        description: lastBuildingDescription,
                        department_name: lastDepartmentName,
                        suite_num: lastSuiteNum,
                        data: groupItems,
                    });
                }
                lastBuildingDescription = item.building.description;
                lastDepartmentName = item.department.department_name;
                lastSuiteNum = item.suite_num;
                groupItems = [];
            }
            groupItems.push(item);
        });

        if (lastBuildingDescription !== null) {
            buildingDepartmentSuiteGroup.push({
                description: lastBuildingDescription,
                department_name: lastDepartmentName,
                suite_num: lastSuiteNum,
                data: groupItems,
            });
        }

        return buildingDepartmentSuiteGroup.map((group, index) => (
            <Fragment key={index}>
                <hr />
                <Row>
                    <Col xs={6} className='mb-3'>
                        <div>
                            <strong>Building:</strong> {group.description}
                        </div>
                        <div>
                            <strong>Department:</strong> {group.department_name}
                        </div>
                        <div>
                            <strong>Suite:</strong> {group.suite_num}
                        </div>
                    </Col>
                    <Col xs={6} className='mb-3'>
                        {group.data.map((item, index) => (
                            <Row key={index} className='mb-2'>
                                <Col xs={6}>
                                    {item.workflow_req_category.workflow_req_category_name}
                                </Col>
                                <Col xs={2} className='text-end'>
                                    {item.num_assets_needed}
                                </Col>
                                <Col xs={4} className='text-end'>
                                    <Button color="danger" size="sm" onClick={() => {
                                        setDeleteRecordId(item.id);
                                        setDeleteRecordDialogState(true);
                                    }}>Remove</Button>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Fragment>
        ));
    }

    return (
        <Fragment>
            <AppFormik
                enableReinitialize={true}
                initialValues={{
                    building_id: null,
                    department_id: null,
                    workflow_req_category_id: null,
                    num_assets_needed: 0,
                }}
                validationSchema={Yup.object({
                    building_id: Yup.number()
                        .required('Please select the building'),
                    department_id: Yup.number()
                        .required('Please select the department'),
                    workflow_req_category_id: Yup.number()
                        .required('Please select the category'),
                    num_assets_needed: Yup.number()
                        .required('Please enter the number of assets needed')
                        .min(1, 'Number of assets needed must be at least 1'),
                })}
                serverFieldMap={{
                    building_id: 'building_id',
                    department_id: 'department_id',
                    workflow_req_category_id: 'workflow_req_category_id',
                    num_assets_needed: 'num_assets_needed',
                }}
                onSubmit={(values) => {
                    let addWorkflowReqData = {
                        workflow_id: recordId,
                        workflow_req_category_id: values.workflow_req_category_id,
                        building_id: values.building_id,
                        department_id: values.department_id,
                        num_assets_needed: values.num_assets_needed,
                    };

                    dispatch(enableOverlay('Adding exception...'));

                    crudBasePost(API_WORKFLOW_REQS, addWorkflowReqData)
                        .then((data) => {
                            dispatch(setToast({
                                severity: TOAST_SEVERITY_SUCCESS,
                                detail: 'Exception added',
                            }));
                            setRefreshExceptionsList(new Date().getTime());
                        })
                        .catch((err) => {
                            dispatch(serverValidationReset());
                            dispatch(setToast({
                                severity: TOAST_SEVERITY_ERROR,
                                summary: 'Error adding exception',
                                detail: Object.values(err.response.data).map((value, index) => (
                                    value
                                )).join('. '),
                            }));
                        })
                        .finally(() => {
                            dispatch(disableOverlay());
                        });
                }}
            >{() => (
                <Form>
                    <ObserverFormOnChange updateFilterSet={handleFilterSet} />

                    <Card title="Departmental Settings / Exceptions">
                        <Row>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_building_id'>Building</Label>
                                <Field name="building_id" id="field_building_id" entityCode={ENTITY_BUILDING} component={AppEntityDropdown} />
                            </Col>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_department_id'>Department / Suite</Label>
                                <Field name="department_id" id="field_department_id" entityCode={ENTITY_SELECT_DEPARTMENT} component={AppEntityDropdown} filterSet={dropdownDepartmentFilterSet} clearDropdownOptions={clearDropdownDepartmentOptions} />
                            </Col>
                            <Col xs={12} lg={6} className='mb-3'>
                                <Label for='field_workflow_req_category_id'>Category</Label>
                                <Field name="workflow_req_category_id" id="field_workflow_req_category_id" entityCode={ENTITY_WORKFLOW_REQ_CATEGORIES} component={AppEntityDropdown} filterSet={dropdownCategoryFilterSet} />
                            </Col>
                            <Col xs={12} lg={3} className='mb-3'>
                                <AppInputFieldComponent
                                    fieldType="number"
                                    fieldName="num_assets_needed"
                                    inputPlaceHolder="Enter the number of assets needed"
                                    inputLabel="Assets Needed"
                                />
                            </Col>
                            <Col xs={12} lg={3} className='mb-3'>
                                <Button type="submit" color="secondary" className="mt-4">Add Exception</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='mb-3'>
                                <DataView value={workflowReqExceptions} listTemplate={exceptionsTemplate} />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
            </AppFormik>

            <ConfirmDialog visible={deleteRecordDialogState}
                onHide={hideDeleteRowDialog}
                className='delete-confirm-dialog'
                message='Are you sure you want to delete this exception?'
                header='Delete Primary Use Requirement Exception'
                acceptLabel='Delete Exception'
                rejectLabel='Cancel'
                accept={performDeleteRecord} reject={hideDeleteRowDialog} />
        </Fragment>
    )
}

export default WorkflowReqExceptionForm;
