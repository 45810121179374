import axios from "axios";

import { SAML_ACS_FINISH_URL } from "assets/static_data/backend_exports";
import {
    API_USERS_PERMISSIONS,
    PASSWORD_RESET,
    PASSWORD_RESET_BY_EMAIL,
    USERS_API,
    USERS_REGISTER
} from "./API_CONSTANTS";
import { getTenantBaseUrl } from "utils";

export const getLoggedUser = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(USERS_API)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user logged error: ", err);
                    reject("user logged error!");
                });
        } catch (error) {
            reject("user logged error!");
        }
    });
}

export const getPermissionsLoggedUser = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_USERS_PERMISSIONS)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Permissions user logged error: ", err);
                    reject("Permissions user logged error");
                });
        } catch (error) {
            reject("Permissions user logged error");
        }
    });
}

export const registerUser = (user) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_REGISTER, user)
                .then((res) => {
                    console.log("user registered: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user registered error: ", err);
                    reject("user registered error!");
                });
        } catch (error) {
            reject("user registered error!");
        }
    });
}

export const updateUser = (user) => {
    return new Promise((resolve, reject) => {
        try {
            axios.put(USERS_API, user)
                .then((res) => {
                    console.log("user data updated: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user data error: ", err);
                    reject("user data error!");
                });
        } catch (error) {
            reject("user data error!");
        }
    });
}

export const resetPasswordByEmail = (email) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API + PASSWORD_RESET_BY_EMAIL, email)
                .then((res) => {
                    console.log("Sending email to reset password: ", res.data);
                    resolve(res.status);
                })
                .catch((err) => {
                    console.log("Reset password error: ", err);
                    reject("Reset password error!");
                });
        } catch (error) {
            reject("Reset password error!");
        }
    });
}

export const resetPasswordCredentials = (newCredentials) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API + PASSWORD_RESET, newCredentials)
                .then((res) => {
                    console.log("Reset password: ", res.data);
                    resolve(res.status);
                })
                .catch((err) => {
                    console.log("Reset password error: ", err);
                    reject("Reset password error!");
                });
        } catch (error) {
            reject("Reset password error!");
        }
    });
}

export const getSamlAcsFinish = (tenant) => {
    let url = new URL(SAML_ACS_FINISH_URL, getTenantBaseUrl(tenant));

    return new Promise((resolve, reject) => {
        try {
            axios.get(url.href, { withCredentials: true })
                .then(res => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });

        } catch (error) {
            reject(error);
        }
    });
}
