import axios from "axios";
import { buildPaginationUrlParams } from "utils";

export class BaseService {

    constructor(apiEndpoint) {
        this.apiEndpoint = apiEndpoint;
    }

    getAll(paginationParams = { page: 1, limit: null }) {
        let url = this.apiEndpoint + buildPaginationUrlParams(paginationParams);

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }

    getOne(id) {
        let url = this.apiEndpoint + '/' + id;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }

    post(entityData) {
        return new Promise((resolve, reject) => {
            axios.post(this.apiEndpoint, entityData)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }

    patch(entityData) {
        return new Promise((resolve, reject) => {
            axios.patch(this.apiEndpoint + '/' + entityData.id, entityData)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            axios.delete(this.apiEndpoint + '/' + id)
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    }
}