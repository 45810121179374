import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_FUNCTIONAL_TYPE } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './FunctionalTypesList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'id': rowData['Functional Type ID'],
        'functional_type': rowData['Functional Type'],
        'abbrev': rowData['Abbreviation'],
        'email': rowData['Email'] || null,
    }
};

const apiToExcelColumn = {
    'id': 'Functional Type ID',
    'functional_type': 'Functional Type',
    'abbrev': 'Abbreviation',
    'email': 'Email',
};

export const FunctionalTypesBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_FUNCTIONAL_TYPE}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
        />
    );
};

export default FunctionalTypesBulkUpdate;
