import { ASSET_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { userHelpLeasedAssets } from 'assets/static_data/common_constants';
import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_LEASED_ASSET } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { convertToDateTime, dateTimeToAPIDate } from 'utils/date_time';
import { importExcelRequiredColumns } from './LeasedAssetsList';

const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let assetStatus = null;
    for (let [key, value] of Object.entries(ASSET_STATUSES_LABELS)) {
        if (typeof rowData['Asset Status'] === 'string' && value.toLowerCase() === rowData['Asset Status'].toLowerCase()) {
            assetStatus = key;
            break;
        }
    }

    // Format rowData['Lease End Date'] : mm/dd/yyyy
    // Convert to ISO string
    let leaseEndDate = null;
    if (rowData['Lease End Date']) {
        leaseEndDate = new Date(rowData['Lease End Date']);
        leaseEndDate = convertToDateTime(leaseEndDate);
        leaseEndDate = dateTimeToAPIDate(leaseEndDate);
    }

    let apiData = {
        'asset_type_name': rowData['Asset Type'] || null,
        'asset_status': assetStatus,
        'id': rowData['Asset Id'] || null,
        'serial_num': rowData['Serial #'] || null,
        'lease_description': rowData['Lease Description'] || null,
        'lease_end_date': leaseEndDate,
        'rent': rowData['Rent'] || null,
        'lease_schedule': rowData['Lease Schedule'] || null,
        'lease_chartstring': rowData['Chartstring'] || null,
    }

    // These fields are not used for update the asset
    /*
    if (rowData['Department']) {
        apiData.department_name = rowData['Department'];
    }
    if (rowData['School']) {
        apiData.ou_name = rowData['School'];
    }
    */

    return apiData;
};

const apiToExcelColumn = {
    'id': 'Asset Id',
    'asset_status': 'Asset Status',
    'asset_type_name': 'Asset Type',
    'serial_num': 'Serial #',
    'lease_description': 'Lease Description',
    'lease_end_date': 'Lease End Date',
    'rent': 'Rent',
    'lease_schedule': 'Lease Schedule',
    'lease_chartstring': 'Chartstring',
    //'department_name': 'Department',
    //'ou_name': 'School',
};

const foreignKeyColumns = [
    'Asset Type',
    //'Department',
    //'School',
];

export const LeasedAssetsBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_LEASED_ASSET}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
            mainTitle="Leased Assets Bulk Update"
            userHelpText={userHelpLeasedAssets}
        />
    );
};

export default LeasedAssetsBulkUpdate;
