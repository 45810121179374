import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { setFormSubmittedSuccess } from 'redux/actions/ui.actions';
import { ENTITY_CITY, ENTITY_STATE } from 'redux/entities_config';


export const CityForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({
        city_name: '',
        state_id: null,
        active: true,
    });

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    useEffect(() => {
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_CITY, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {
            setInitialFormValues({
                city_name: activeRecord.city_name,
                state_id: activeRecord.state_id,
                active: activeRecord.active,
            });
        }
    }, [activeRecord]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    return (
        <AppFormik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                city_name: Yup.string()
                    .required('Please enter the city name'),
                state_id: Yup.number()
                    .required('Please select the state'),
                active: Yup.boolean()
            })}
            serverFieldMap={{
                city_name: 'city_name',
                state_id: 'state_id',
                active: 'active',
            }}
            onSubmit={(values) => {
                let formData = {
                    city_name: values.city_name,
                    state_id: values.state_id,
                    active: values.active,
                }

                if (recordId) {
                    formData.id = recordId;
                    dispatch(crudBaseUpdateStart({ entityCode: ENTITY_CITY, recordData: formData }));
                } else {
                    dispatch(crudBaseCreateStart({ entityCode: ENTITY_CITY, recordData: formData }));
                }
                setFormSubmitStarted(true);
            }}
        >{({ isValid }) => (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='field_state'>State</Label>
                            <Field name="state_id" id='field_state' entityCode={ENTITY_STATE} component={AppEntityDropdown} />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="text"
                                fieldName="city_name"
                                inputPlaceHolder="Enter the city name"
                                inputLabel="City Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <AppInputFieldComponent
                                fieldType="checkbox"
                                fieldName="active"
                                inputLabel="Is Active?"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                            Cancel
                        </Button>
                    </Col>
                    <Col className="text-center" md={6} sm={12}>
                        <Button color='success' className='mt-2 px-5' type='submit' disabled={!isValid}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}
        </AppFormik>
    )
}

export default CityForm;
