import { AppFormik } from 'components/ui/AppFormik';
import { Field, Form, useFormikContext } from 'formik';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { useDebounce } from 'primereact/hooks';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Label, Row } from 'reactstrap';

import { ASSET_STATUSES, ASSET_STATUSES_LABELS, ASSET_TYPE, DEFAULT_PRINTER_FIELD_MAP, DROPDOWN_TYPES, INFORMATION_STATUSES, INFORMATION_STATUSES_LABELS, LOGIN_STRATEGIES_LABELS, PRINTER_TRAY_CONTENT_NAMES } from 'assets/static_data/backend_exports';
import { ASSET_TDR_STATUS_PASSED } from 'assets/static_data/common_constants';
import AssetPeripheralList from 'components/asset/AssetPeripheralsList';
import { AppEntityDropdown } from 'components/ui/AppEntityDropdown';
import { AppInputFieldComponent } from 'components/ui/AppInputFieldComponent';
import { AppOptionSelect } from 'components/ui/AppOptionSelect';
import AssetNotes from 'components/ui/AssetNotes';
import { getAssetTypes } from 'redux/actions/asset_types.actions';
import { crudBaseCreateStart, crudBaseGetOneStart, crudBaseUpdateStart } from 'redux/actions/crud_base.actions';
import { disableOverlay, enableOverlay, setFormSubmittedSuccess, setToast } from 'redux/actions/ui.actions';
import { ENTITY_ASSET, ENTITY_ASSET_OWNER, ENTITY_ASSET_TYPE, ENTITY_ASSET_TYPE_CHOICE, ENTITY_BUILDING, ENTITY_OU, ENTITY_PRINTER_TRAY_CONTENT, ENTITY_PRINTER_TYPE, ENTITY_SELECT_CREDIT_CARD_READER, ENTITY_SELECT_DEPARTMENT, ENTITY_SELECT_PRINTER, ENTITY_SPECIALTY, ENTITY_WORKFLOW, ENTITY_WORKSTATION_TYPE } from 'redux/entities_config';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { API_ASSETS_SET_PARENT } from 'services/API_CONSTANTS';
import { crudBasePatch } from 'services/crudBaseService';
import { ASSET_TYPE_OTHER, getAssetTypeSelect } from "utils/entity_helper";

import 'styles/p_fieldset.scss';
import 'styles/p_paginator.scss';

const emptyFormValues = {
    id: '',
    parent_asset_id: '',
    asset_type_id: null,
    asset_name: '',
    description: '',
    serial_num: '',
    cam_tag_num: '',
    new_notes: '',
    owner: '',
    msit_tag_num: '',
    itams_id: null,
    status: INFORMATION_STATUSES.INCOMPLETE,
    building_id: null,
    location: '',
    ou_id: null,
    department_id: null,
    specialty_id: null,
    sub_department_id: null,
    room: '',
    // epic_department_id: null,

    the_make_id: null,
    the_model_id: null,
    the_os_id: null,
    the_size_id: null,
    the_resolution_id: null,
    the_screen_orientation_id: null,

    // floor_plan_map_code: '',
    suite_num: '',
    w_p_num: '',
    asset_status: ASSET_STATUSES.ACTIVE,
    followup_needed: false,
    tdr_status: false,
    asset_status_date: '', // Read-only
    lease_description: '',
    lease_start_date: '',
    lease_end_date: '',
    warranty_end_date: '',
    rent: '',
    lease_schedule: '',
    lease_chartstring: '',

    // CC Reader Fields
    ipsn: '',

    // Workstation Fields
    workstation_type_id: null,
    workstation_ip_address: '',
    login_strategy: '',
    primary_use_id: null,

    default_printer_plain_paper_id: null,
    default_printer_wristbands_id: null,
    default_printer_baby_wristbands_id: null,
    default_printer_pharm_labels_id: null,
    default_printer_labels_id: null,
    default_printer_scripts_id: null,
    default_printer_insurance_form_id: null,
    default_cc_reader_id: null,

    bca_pc: false,
    is_proximity_unit: false,
    is_rover: false,
    welcome_kiosk: false,
    epic_workstation_id: '', // Read-only - calculated on BE Serializer
    bca_sticker: false,
    bca_local_printer: false,
    bca_ups: false,

    // Printer fields
    printer_type_id: null,
    printer_queue_name: '',
    printer_ip_address: '',
    tray_1_content_id: null,
    tray_2_content_id: null,
    tray_3_content_id: null,
    tray_4_content_id: null,
    tray_5_content_id: null,
    tray_6_content_id: null,
    manual_tray_content_id: null,

    printer_comments: '',
    printer_hostname: '',
    printer_zeno_num: '',
    printer_epic_printer_name: '',
    bca_printer: false,

    // Read-only fields


    // For local usage
    asset_type_select: '',
    // owner_dropdown: '', // TODO remove references to owner_dropdown
    add_peripheral_asset_type_id: null,
    add_peripheral_asset_id: null,

    // For default printer checkbox
    display_default_printers: [],
};

const serverFieldMap = {
    id: 'id',
    parent_asset_id: 'parent_asset_id',
    asset_type_id: 'asset_type_id',
    asset_name: 'asset_name',
    description: 'description',
    serial_num: 'serial_num',
    cam_tag_num: 'cam_tag_num',
    // notes: 'notes',
    owner: 'owner',
    msit_tag_num: 'msit_tag_num',
    itams_id: 'itams_id',
    status: 'status',
    building_id: 'building_id',
    location: 'location',
    ou_id: 'ou_id',
    department_id: 'department_id',
    specialty_id: 'specialty_id',
    sub_department_id: 'sub_department_id',
    room: 'room',
    // epic_department_id: 'epic_department_id',
    the_make_id: 'the_make_id',
    the_model_id: 'the_model_id',
    the_os_id: 'the_os_id',
    the_size_id: 'the_size_id',
    the_resolution_id: 'the_resolution_id',
    the_screen_orientation_id: 'the_screen_orientation_id',
    // floor_plan_map_code: 'floor_plan_map_code',
    suite_num: 'suite_num',
    w_p_num: 'w_p_num',
    asset_status: 'asset_status',
    followup_needed: 'followup_needed',
    tdr_status: 'tdr_status',
    asset_status_date: 'asset_status_date',
    lease_description: 'lease_description',
    lease_start_date: 'lease_start_date',
    lease_end_date: 'lease_end_date',
    warranty_end_date: 'warranty_end_date',
    rent: 'rent',
    lease_schedule: 'lease_schedule',
    lease_chartstring: 'lease_chartstring',
    ipsn: 'ipsn',
    // Workstation Fields
    'workstation_details': {
        workstation_type_id: 'workstation_type_id',
        ip_address: 'workstation_ip_address',
        login_strategy: 'login_strategy',
        primary_use_id: 'primary_use_id',

        default_printer_plain_paper_id: 'default_printer_plain_paper_id',
        default_printer_wristbands_id: 'default_printer_wristbands_id',
        default_printer_baby_wristbands_id: 'default_printer_baby_wristbands_id',
        default_printer_pharm_labels_id: 'default_printer_pharm_labels_id',
        default_printer_labels_id: 'default_printer_labels_id',
        default_printer_scripts_id: 'default_printer_scripts_id',
        default_printer_insurance_form_id: 'default_printer_insurance_form_id',
        default_cc_reader_id: 'default_cc_reader_id',

        bca_pc: 'bca_pc',
        is_proximity_unit: 'is_proximity_unit',
        is_rover: 'is_rover',
        welcome_kiosk: 'welcome_kiosk',
        bca_sticker: 'bca_sticker',
        bca_local_printer: 'bca_local_printer',
        bca_ups: 'bca_ups',
    },
    // Printer fields
    'printer_details': {
        printer_type_id: 'printer_type_id',
        printer_queue_name: 'printer_queue_name',
        ip_address: 'printer_ip_address',
        tray_1_content_id: 'tray_1_content_id',
        tray_2_content_id: 'tray_2_content_id',
        tray_3_content_id: 'tray_3_content_id',
        tray_4_content_id: 'tray_4_content_id',
        tray_5_content_id: 'tray_5_content_id',
        tray_6_content_id: 'tray_6_content_id',
        manual_tray_content_id: 'manual_tray_content_id',

        comments: 'printer_comments',
        hostname: 'printer_hostname',
        zeno_num: 'printer_zeno_num',
        epic_printer_name: 'printer_epic_printer_name',
        bca_printer: 'bca_printer',
    },
};

const defaultPrinterFor = Object.entries(DEFAULT_PRINTER_FIELD_MAP).map(([key, value]) => {
    return {
        label: PRINTER_TRAY_CONTENT_NAMES[key],
        value: value,
    };
});

const ObserverFormOnChange = ({ updateFilterSet }) => {
    const { values: formikValues, setFieldValue, dirty } = useFormikContext();
    const [lastFieldValues, setLastFieldValues] = useState(null);
    const mainAssetTypes = useSelector(state => state.assetTypes.mainAssetTypes);
    const [, debouncedSuiteNum, setDebouncedSuiteNum] = useDebounce('', 500);

    useEffect(() => {
        if (lastFieldValues && dirty) {
            let filterSet = {};
            let performUpdate = false;

            if (formikValues.asset_type_select !== lastFieldValues.asset_type_select) {
                if (formikValues.asset_type_select === ASSET_TYPE.ASSET_TYPE_WORKSTATION) {
                    setFieldValue('asset_type_id', mainAssetTypes.workstation_asset_type_id);
                } else if (formikValues.asset_type_select === ASSET_TYPE.ASSET_TYPE_PRINTER) {
                    setFieldValue('asset_type_id', mainAssetTypes.printer_asset_type_id);
                } else {
                    setFieldValue('asset_type_id', null);
                }
            }

            if (formikValues.asset_type_id !== lastFieldValues.asset_type_id) {
                filterSet.asset_type_id = formikValues.asset_type_id;
                performUpdate = true;
            }

            if (
                formikValues.asset_type_select !== lastFieldValues.asset_type_select ||
                formikValues.asset_type_id !== lastFieldValues.asset_type_id
            ) {
                setFieldValue('the_make_id', null);
                setFieldValue('the_model_id', null);
                setFieldValue('the_os_id', null);
                setFieldValue('the_size_id', null);
                setFieldValue('the_resolution_id', null);
                setFieldValue('the_screen_orientation_id', null);
            }

            if (formikValues.the_make_id !== lastFieldValues.the_make_id) {
                filterSet.asset_type_id = formikValues.asset_type_id;
                filterSet.the_make_id = formikValues.the_make_id;
                filterSet.the_model_id = null;
                performUpdate = true;
            }

            // if (formikValues.owner_dropdown !== lastFieldValues.owner_dropdown) {
            //     setFieldValue('owner', formikValues.owner_dropdown);
            // }

            if (formikValues.department_id !== lastFieldValues.department_id) {
                filterSet.department_id = formikValues.department_id;
                setFieldValue('specialty_id', null);
                performUpdate = true;
            }

            // For Default Printers and CC Reader
            if (
                formikValues.building_id !== lastFieldValues.building_id ||
                debouncedSuiteNum !== lastFieldValues.suite_num
            ) {
                filterSet.building_id = formikValues.building_id;
                filterSet.suite_num = debouncedSuiteNum;

                filterSet.default_printer_plain_paper_id = formikValues.default_printer_plain_paper_id;
                filterSet.default_printer_wristbands_id = formikValues.default_printer_wristbands_id;
                filterSet.default_printer_baby_wristbands_id = formikValues.default_printer_baby_wristbands_id;
                filterSet.default_printer_pharm_labels_id = formikValues.default_printer_pharm_labels_id;
                filterSet.default_printer_labels_id = formikValues.default_printer_labels_id;
                filterSet.default_printer_scripts_id = formikValues.default_printer_scripts_id;
                filterSet.default_printer_insurance_form_id = formikValues.default_printer_insurance_form_id;

                filterSet.default_cc_reader_id = formikValues.default_cc_reader_id;

                if (formikValues.add_peripheral_asset_type_id) {
                    filterSet.add_peripheral_asset_type_id = formikValues.add_peripheral_asset_type_id;
                    setFieldValue('add_peripheral_asset_id', null);
                }

                performUpdate = true;
            }

            // For Building
            if (formikValues.ou_id !== lastFieldValues.ou_id) {
                filterSet.ou_id = formikValues.ou_id;
                setFieldValue('building_id', null);
                performUpdate = true;
            }

            // For Department
            if (
                // formikValues.ou_id !== lastFieldValues.ou_id ||
                formikValues.building_id !== lastFieldValues.building_id
            ) {
                filterSet.ou_id = formikValues.ou_id;
                filterSet.building_id = formikValues.building_id;
                setFieldValue('department_id', null);
                setFieldValue('specialty_id', null);
                setFieldValue('suite_num', '');
                performUpdate = true;
            }

            // For Add Peripheral
            if (formikValues.add_peripheral_asset_type_id !== lastFieldValues.add_peripheral_asset_type_id) {
                filterSet.add_peripheral_asset_type_id = formikValues.add_peripheral_asset_type_id;
                setFieldValue('add_peripheral_asset_id', null);
                performUpdate = true;
            }

            if (performUpdate) {
                updateFilterSet(filterSet);
            }
        }
        setLastFieldValues(formikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formikValues.asset_type_select, formikValues.asset_type_id, formikValues.the_make_id,
        // formikValues.owner_dropdown,
        formikValues.add_peripheral_asset_type_id, formikValues.ou_id,
        formikValues.department_id, formikValues.building_id, debouncedSuiteNum,
    ]);

    useEffect(() => {
        if (lastFieldValues && dirty) {
            // For Default Printers and CC Reader
            if (formikValues.suite_num !== lastFieldValues.suite_num) {
                setDebouncedSuiteNum(formikValues.suite_num);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikValues.suite_num]);

    return null;
}

const EmptyBuildingAlert = <Alert color="secondary">Select Building and Department to see the available Printers</Alert>

export const AssetForm = ({ close, recordId }) => {
    const dispatch = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState(emptyFormValues);

    const activeRecord = useSelector(state => state.crudBase.activeRecord);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [formSubmitStarted, setFormSubmitStarted] = useState(false);

    const mainAssetTypes = useSelector(state => state.assetTypes.mainAssetTypes);
    const assetTypesList = useSelector(state => state.assetTypes.assetTypesList);

    const [dropdownMakesFilterSet, setDropdownMakesFilterSet] = useState(null);
    const [dropdownModelsFilterSet, setDropdownModelsFilterSet] = useState(null);
    const [dropdownOssFilterSet, setDropdownOssFilterSet] = useState(null);
    const [dropdownSizesFilterSet, setDropdownSizesFilterSet] = useState(null);
    const [dropdownResolutionsFilterSet, setDropdownResolutionsFilterSet] = useState(null);
    const [dropdownScreenOrientationsFilterSet, setDropdownScreenOrientationsFilterSet] = useState(null);
    const [dropdownBuildingFilterSet, setDropdownBuildingFilterSet] = useState(null);
    const [dropdownDepartmentFilterSet, setDropdownDepartmentFilterSet] = useState(null);
    const [dropdownSpecialtyFilterSet, setDropdownSpecialtyFilterSet] = useState(null);

    // TODO: Create Redux/Saga code to get PrinterTrayContent to replace the following with forEach's
    const [dropdownDefaultPlainPaperFilterSet, setDropdownDefaultPlainPaperFilterSet] = useState(null);
    const [dropdownDefaultWristbandsFilterSet, setDropdownDefaultWristbandsFilterSet] = useState(null);
    const [dropdownDefaultBabyWristbandsFilterSet, setDropdownDefaultBabyWristbandsFilterSet] = useState(null);
    const [dropdownDefaultPharmLabelsFilterSet, setDropdownDefaultPharmLabelsFilterSet] = useState(null);
    const [dropdownDefaultLabelsFilterSet, setDropdownDefaultLabelsFilterSet] = useState(null);
    const [dropdownDefaultScriptsFilterSet, setDropdownDefaultScriptsFilterSet] = useState(null);
    const [dropdownDefaultInsuranceFormFilterSet, setDropdownDefaultInsuranceFormFilterSet] = useState(null);

    const [dropdownDefaultCCReadersFilterSet, setDropdownDefaultCCReadersFilterSet] = useState(null);
    const [dropdownAddPeripheralFilterSet, setDropdownAddPeripheralFilterSet] = useState(null);

    const [addPeripheralDialogState, setAddPeripheralDialogState] = useState(false);
    const [toAddPeripheralAssetId, setToAddPeripheralAssetId] = useState(null);

    // const [wPNumLabel, setWPNumLabel] = useState('W #');

    const [refreshPeripheralList, setRefreshPeripheralList] = useState(null);

    const handleFilterSet = useCallback((filterSet, formikValues) => {
        if (filterSet.asset_type_id) {
            setDropdownMakesFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.MAKE,
            });

            if (filterSet.the_make_id) {
                setDropdownModelsFilterSet({
                    asset_type_id: filterSet.asset_type_id,
                    dropdown_type: DROPDOWN_TYPES.MODEL,
                    parent_asset_type_choice_id: filterSet.the_make_id,
                });
            }

            setDropdownOssFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.OS,
            });

            setDropdownSizesFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.SIZE,
            });

            setDropdownResolutionsFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.RESOLUTION,
            });

            setDropdownScreenOrientationsFilterSet({
                asset_type_id: filterSet.asset_type_id,
                dropdown_type: DROPDOWN_TYPES.SCREEN_ORIENTATION,
            });

            /* TODO Remove if not needed
            let localWPNumLabel = '';
            switch (filterSet.asset_type_id) {
                case mainAssetTypes.workstation_asset_type_id:
                    localWPNumLabel = 'W #';
                    break;
                case mainAssetTypes.printer_asset_type_id:
                    localWPNumLabel = 'P #';
                    break;
                default:
                    localWPNumLabel = 'O #';
                    break;
            }
            setWPNumLabel(localWPNumLabel);
            */
        }

        if (filterSet.ou_id) {
            setDropdownBuildingFilterSet({
                department__ou_id: filterSet.ou_id,
                distinct: true,
            });
        }

        if (filterSet.ou_id && filterSet.building_id) {
            setDropdownDepartmentFilterSet({
                ou_id: filterSet.ou_id,
                building_id: filterSet.building_id,
            });
        }

        if (filterSet.department_id) {
            setDropdownSpecialtyFilterSet({
                department: filterSet.department_id,
            });
        }

        if (filterSet.building_id && filterSet.suite_num) {
            let localFilterSet = {
                building_id: filterSet.building_id,
                suite_num: filterSet.suite_num,
            };
            if (recordId) {
                localFilterSet.parent_id = recordId;
            }

            // TODO: Create Redux/Saga code to get PrinterTrayContent to replace the following with forEach's
            let dropdownPrinterTypeFilters;

            dropdownPrinterTypeFilters = {
                tray_content: 'Plain Paper',
            };
            if (filterSet.default_printer_plain_paper_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_plain_paper_id;
            }
            setDropdownDefaultPlainPaperFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Wrist Band',
            };
            if (filterSet.default_printer_wristbands_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_wristbands_id;
            }
            setDropdownDefaultWristbandsFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Baby Wrist Band',
            };
            if (filterSet.default_printer_baby_wristbands_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_baby_wristbands_id;
            }
            setDropdownDefaultBabyWristbandsFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Pharmacy Label',
            };
            if (filterSet.default_printer_pharm_labels_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_pharm_labels_id;
            }
            setDropdownDefaultPharmLabelsFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Label',
            };
            if (filterSet.default_printer_labels_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_labels_id;
            }
            setDropdownDefaultLabelsFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Prescription',
            };
            if (filterSet.default_printer_scripts_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_scripts_id;
            }
            setDropdownDefaultScriptsFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            dropdownPrinterTypeFilters = {
                tray_content: 'Insurance Form',
            };
            if (filterSet.default_printer_insurance_form_id) {
                dropdownPrinterTypeFilters.current_asset_id = filterSet.default_printer_insurance_form_id;
            }
            setDropdownDefaultInsuranceFormFilterSet({
                ...localFilterSet,
                ...dropdownPrinterTypeFilters,
            });

            // CC Reader
            let localFilterSetCCReader = {
                ...localFilterSet,
            }
            if (filterSet.default_cc_reader_id) {
                localFilterSetCCReader.current_asset_id = filterSet.default_cc_reader_id;
            }
            setDropdownDefaultCCReadersFilterSet(localFilterSetCCReader);

        }

        // Add Peripheral
        if (filterSet.add_peripheral_asset_type_id && formikValues.building_id && formikValues.suite_num) {
            setDropdownAddPeripheralFilterSet({
                building_id: formikValues.building_id,
                suite_num: formikValues.suite_num,
                asset_type_id: filterSet.add_peripheral_asset_type_id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId])

    useEffect(() => {
        dispatch(getAssetTypes());
        dispatch(setFormSubmittedSuccess(false));
        if (recordId) {
            dispatch(crudBaseGetOneStart({ entityCode: ENTITY_ASSET, recordId }));
        }
    }, [dispatch, recordId]);

    useEffect(() => {
        if (activeRecord) {

            let localFormValues = {
                ...emptyFormValues,

                id: activeRecord.id,
                parent_asset_id: activeRecord.parent_asset_id || '',
                asset_type_select: getAssetTypeSelect(activeRecord.asset_type.asset_type_name),
                asset_type_id: activeRecord.asset_type_id,
                asset_name: activeRecord.asset_name || '',
                description: activeRecord.description || '',
                serial_num: activeRecord.serial_num || '',
                cam_tag_num: activeRecord.cam_tag_num || '',
                // new_notes: activeRecord.new_notes,
                owner: activeRecord.owner || '',
                msit_tag_num: activeRecord.msit_tag_num || '',
                itams_id: activeRecord.itams_id,
                status: activeRecord.status || '',
                building_id: activeRecord.building_id,
                location: activeRecord.location || '',
                ou_id: activeRecord.ou_id,
                department_id: activeRecord.department_id,
                specialty_id: activeRecord.specialty_id,
                sub_department_id: activeRecord.sub_department_id,
                room: activeRecord.room || '',
                // epic_department_id: activeRecord.epic_department_id,

                the_make_id: activeRecord.the_make_id,
                the_model_id: activeRecord.the_model_id,
                the_os_id: activeRecord.the_os_id,
                the_size_id: activeRecord.the_size_id,
                the_resolution_id: activeRecord.the_resolution_id,
                the_screen_orientation_id: activeRecord.the_screen_orientation_id,

                // floor_plan_map_code: activeRecord.floor_plan_map_code || '',
                suite_num: activeRecord.suite_num || '',
                w_p_num: activeRecord.w_p_num || '',
                asset_status: activeRecord.asset_status || '',
                followup_needed: activeRecord.followup_needed,
                tdr_status: activeRecord.tdr_status,
                asset_status_date: activeRecord.asset_status_date,
                lease_description: activeRecord.lease_description || '',
                lease_start_date: activeRecord.lease_start_date || '',
                lease_end_date: activeRecord.lease_end_date || '',
                warranty_end_date: activeRecord.warranty_end_date || '',
                rent: activeRecord.rent || '',
                lease_schedule: activeRecord.lease_schedule || '',
                lease_chartstring: activeRecord.lease_chartstring || '',
                ipsn: activeRecord.ipsn || '',

                // Read-only fields
                epic_workstation_id: activeRecord.epic_workstation_id || '',
            };

            // Workstation Fields
            if (activeRecord.workstation) {
                localFormValues = {
                    ...localFormValues,
                    workstation_type_id: activeRecord.workstation.workstation_type ? activeRecord.workstation.workstation_type.id : null,
                    workstation_ip_address: activeRecord.workstation.ip_address || '',
                    login_strategy: activeRecord.workstation.login_strategy || '',
                    primary_use_id: activeRecord.workstation.primary_use ? activeRecord.workstation.primary_use.id : null,

                    default_printer_plain_paper_id: activeRecord.workstation.default_printer_plain_paper ? activeRecord.workstation.default_printer_plain_paper.id : null,
                    default_printer_wristbands_id: activeRecord.workstation.default_printer_wristbands ? activeRecord.workstation.default_printer_wristbands.id : null,
                    default_printer_baby_wristbands_id: activeRecord.workstation.default_printer_baby_wristbands ? activeRecord.workstation.default_printer_baby_wristbands.id : null,
                    default_printer_pharm_labels_id: activeRecord.workstation.default_printer_pharm_labels ? activeRecord.workstation.default_printer_pharm_labels.id : null,
                    default_printer_labels_id: activeRecord.workstation.default_printer_labels ? activeRecord.workstation.default_printer_labels.id : null,
                    default_printer_scripts_id: activeRecord.workstation.default_printer_scripts ? activeRecord.workstation.default_printer_scripts.id : null,
                    default_printer_insurance_form_id: activeRecord.workstation.default_printer_insurance_form ? activeRecord.workstation.default_printer_insurance_form.id : null,
                    default_cc_reader_id: activeRecord.workstation.default_cc_reader ? activeRecord.workstation.default_cc_reader.id : null,

                    bca_pc: activeRecord.workstation.bca_pc,
                    is_proximity_unit: activeRecord.workstation.is_proximity_unit,
                    is_rover: activeRecord.workstation.is_rover,
                    welcome_kiosk: activeRecord.workstation.welcome_kiosk,
                    bca_sticker: activeRecord.workstation.bca_sticker,
                    bca_local_printer: activeRecord.workstation.bca_local_printer,
                    bca_ups: activeRecord.workstation.bca_ups,
                };
            }

            // Printer fields

            if (activeRecord.printer) {
                localFormValues = {
                    ...localFormValues,
                    printer_type_id: activeRecord.printer.printer_type ? activeRecord.printer.printer_type.id : null,
                    printer_queue_name: activeRecord.printer.printer_queue_name || '',
                    printer_ip_address: activeRecord.printer.ip_address || '',
                    tray_1_content_id: activeRecord.printer.tray_1_content ? activeRecord.printer.tray_1_content.id : null,
                    tray_2_content_id: activeRecord.printer.tray_2_content ? activeRecord.printer.tray_2_content.id : null,
                    tray_3_content_id: activeRecord.printer.tray_3_content ? activeRecord.printer.tray_3_content.id : null,
                    tray_4_content_id: activeRecord.printer.tray_4_content ? activeRecord.printer.tray_4_content.id : null,
                    tray_5_content_id: activeRecord.printer.tray_5_content ? activeRecord.printer.tray_5_content.id : null,
                    tray_6_content_id: activeRecord.printer.tray_6_content ? activeRecord.printer.tray_6_content.id : null,
                    manual_tray_content_id: activeRecord.printer.manual_tray_content ? activeRecord.printer.manual_tray_content.id : null,

                    printer_comments: activeRecord.printer.comments || '',
                    printer_hostname: activeRecord.printer.hostname || '',
                    printer_zeno_num: activeRecord.printer.zeno_num || '',
                    printer_epic_printer_name: activeRecord.printer.epic_printer_name || '',
                    bca_printer: activeRecord.printer.bca_printer,
                };
            }

            // Initialize fields for default printer checkboxes
            localFormValues.display_default_printers = [];
            Object.values(DEFAULT_PRINTER_FIELD_MAP).forEach((value) => {
                // Fields used on the form are in the format default_printer_<tray_content>_id
                if (localFormValues[value + '_id']) {
                    localFormValues.display_default_printers.push(value);
                }
            });

            setInitialFormValues(localFormValues);

            // Trigger loading of dropdowns
            handleFilterSet({
                asset_type_id: activeRecord.asset_type_id,
                ou_id: activeRecord.ou_id,
                building_id: activeRecord.building_id,
                department_id: activeRecord.department_id,
                suite_num: activeRecord.suite_num,
                the_make_id: activeRecord.the_make_id,
            });
        } else {
            let localFormValues = {
                ...emptyFormValues,
                asset_type_id: mainAssetTypes.workstation_asset_type_id,
                asset_type_select: ASSET_TYPE.ASSET_TYPE_WORKSTATION,
            };

            setInitialFormValues(localFormValues);
            handleFilterSet({
                asset_type_id: mainAssetTypes.workstation_asset_type_id,
            });
        }

    }, [activeRecord, handleFilterSet, mainAssetTypes.workstation_asset_type_id]);

    useEffect(() => {
        if (formSubmittedSuccess && formSubmitStarted) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSubmitStarted, formSubmittedSuccess]);

    const assetTypeSelectOptions = [
        { icon: 'ri-computer-line', value: ASSET_TYPE.ASSET_TYPE_WORKSTATION },
        { icon: 'ri-printer-line', value: ASSET_TYPE.ASSET_TYPE_PRINTER },
        { icon: 'ri-keyboard-line', value: ASSET_TYPE_OTHER },
    ];

    const assetTypeSelectTemplate = (option) => {
        return (<span className='fs-6 mx-auto'>
            <i className={'me-2 align-bottom ' + option.icon} />{option.value}
        </span>);
    };

    const saveLocationToSessionStorage = (formData) => {
        let lastLocationData = {
            ou_id: formData.ou_id,
            department_id: formData.department_id,
            specialty_id: formData.sub_department_id,
            building_id: formData.building_id,
            suite_num: formData.suite_num,
            location: formData.location,
            room: formData.room,
        };

        sessionStorage.setItem('in-asset-last-location-data', JSON.stringify(lastLocationData));
    };

    const handleAddPeripheralConfirm = (childAssetId) => {
        setAddPeripheralDialogState(true);
        setToAddPeripheralAssetId(childAssetId);
    }

    const handleAddPeripheralSave = () => {
        setAddPeripheralDialogState(false);
        if (toAddPeripheralAssetId && recordId) {
            let addPeripheralData = {
                id: toAddPeripheralAssetId,
                parent_asset_id: recordId,
            };

            dispatch(enableOverlay('Adding Peripheral'));

            crudBasePatch(API_ASSETS_SET_PARENT, addPeripheralData)
                .then((data) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_SUCCESS,
                        detail: 'Peripheral added',
                    }));
                    setRefreshPeripheralList(new Date().getTime());
                })
                .catch((err) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Error adding peripheral',
                        detail: Object.values(err.response.data).map((value, index) => (
                            value
                        )).join('. '),
                    }));
                })
                .finally(() => {
                    dispatch(disableOverlay());
                });
        }
    }

    const addPeripheralHideDialog = () => {
        setAddPeripheralDialogState(false);
    }

    return (
        <Fragment>
            <AppFormik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={initialFormValues}
                /*
                -- No validation is performed on Frontend. We rely for this form on the Backend validation.
                validationSchema={Yup.object({
                    asset_type_select: Yup.string(),
                    // .required('Please select the asset type'),
                    asset_type_id: Yup.number()
                        .nullable(),
                    // .required('Please select the asset type'),
                    asset_name: Yup.string(),
                    description: Yup.string(),
                    serial_num: Yup.string(),
                    cam_tag_num: Yup.string(),
                    new_notes: Yup.string(),
                    //owner: Yup.string()
                    //    .notRequired(),
                    msit_tag_num: Yup.string(),
                    itams_id: Yup.number()
                        .nullable(),
                    status: Yup.string(),
                    //    .required('Please select the status'),
                    building_id: Yup.number()
                        .nullable(),
                    location: Yup.string(),
                    ou_id: Yup.number()
                        .nullable(),
                    department_id: Yup.number()
                        .nullable(),
                    // .required('Please select the department'),
                    specialty_id: Yup.number()
                        .nullable(),
                    sub_department_id: Yup.number()
                        .nullable(),
                    room: Yup.string(),
                    // epic_department_id: Yup.number()
                    //     .notRequired(),

                    the_make_id: Yup.number()
                        .nullable(),
                    // .required('Please select the make'),
                    the_model_id: Yup.number()
                        .nullable(),
                    // .required('Please select the model'),
                    the_os_id: Yup.number()
                        .nullable(),
                    the_size_id: Yup.number()
                        .nullable(),
                    the_resolution_id: Yup.number()
                        .nullable(),
                    the_screen_orientation_id: Yup.number()
                        .nullable(),

                    // floor_plan_map_code: Yup.string(),
                    //     .notRequired(),
                    suite_num: Yup.string(),
                    w_p_num: Yup.string(),
                    asset_status: Yup.string(),
                    //    .required('Please select the asset status'),
                    followup_needed: Yup.boolean(),
                    tdr_status: Yup.boolean(),
                    // asset_status_date: Yup.date(),
                    //     .notRequired(),
                    lease_description: Yup.string(),
                    lease_end_date: Yup.date(),
                    rent: Yup.number()
                        .nullable(),
                    lease_schedule: Yup.string(),
                    lease_chartstring: Yup.string(),
                    ipsn: Yup.string(),

                    // Workstation Fields
                    workstation_type_id: Yup.number()
                        .nullable(),
                    workstation_ip_address: Yup.string(),
                    login_strategy: Yup.string(),
                    primary_use_id: Yup.number()
                        .nullable(),

                    default_printer_plain_paper_id: Yup.number()
                        .nullable(),
                    default_printer_wristbands_id: Yup.number()
                        .nullable(),
                    default_printer_baby_wristbands_id: Yup.number()
                        .nullable(),
                    default_printer_pharm_labels_id: Yup.number()
                        .nullable(),
                    default_printer_labels_id: Yup.number()
                        .nullable(),
                    default_printer_scripts_id: Yup.number()
                        .nullable(),
                    default_printer_insurance_form_id: Yup.number()
                        .nullable(),
                    default_cc_reader_id: Yup.number()
                        .nullable(),

                    bca_pc: Yup.boolean(),
                    is_proximity_unit: Yup.boolean(),
                    is_rover: Yup.boolean(),
                    welcome_kiosk: Yup.boolean(),

                    bca_sticker: Yup.boolean(),
                    bca_local_printer: Yup.boolean()
                    bca_ups: Yup.boolean(),

                    // Printer fields
                    printer_type_id: Yup.number()
                        .nullable(),
                    printer_queue_name: Yup.string(),
                    printer_ip_address: Yup.string(),
                    tray_1_content_id: Yup.number()
                        .nullable(),
                    tray_2_content_id: Yup.number()
                        .nullable(),
                    tray_3_content_id: Yup.number()
                        .nullable(),
                    tray_4_content_id: Yup.number()
                        .nullable(),
                    tray_5_content_id: Yup.number()
                        .nullable(),
                    tray_6_content_id: Yup.number()
                        .nullable(),
                    manual_tray_content_id: Yup.number()
                        .nullable(),

                    printer_comments: Yup.string(),
                    printer_hostname: Yup.string(),
                    printer_zeno_num: Yup.string(),
                    printer_epic_printer_name: Yup.string(),
                })}
                */
                serverFieldMap={serverFieldMap}
                onSubmit={(values) => {

                    let formData = {
                        asset_type_id: values.asset_type_id,
                        asset_name: values.asset_name || null,
                        description: values.description || null,
                        serial_num: values.serial_num || null,
                        cam_tag_num: values.cam_tag_num || null,
                        new_notes: values.new_notes || null,
                        owner: values.owner || null,
                        msit_tag_num: values.msit_tag_num || null,
                        itams_id: values.itams_id || null,
                        status: values.status || null,
                        building_id: values.building_id,
                        location: values.location || null,
                        ou_id: values.ou_id,
                        department_id: values.department_id,
                        specialty_id: values.specialty_id,
                        // sub_department_id: values.sub_department_id || null,
                        room: values.room || null,
                        // epic_department_id: values.epic_department_id,

                        the_make_id: values.the_make_id,
                        the_model_id: values.the_model_id,
                        the_os_id: values.the_os_id,
                        the_size_id: values.the_size_id,
                        the_resolution_id: values.the_resolution_id,
                        the_screen_orientation_id: values.the_screen_orientation_id,

                        // floor_plan_map_code: values.floor_plan_map_code,
                        suite_num: values.suite_num || null,
                        w_p_num: values.w_p_num || null,
                        asset_status: values.asset_status || null,
                        followup_needed: values.followup_needed,
                        tdr_status: values.tdr_status,
                        // asset_status_date: values.asset_status_date,
                        lease_description: values.lease_description || null,
                        lease_start_date: values.lease_start_date || null,
                        lease_end_date: values.lease_end_date || null,
                        warranty_end_date: values.warranty_end_date || null,
                        rent: values.rent || null,
                        lease_schedule: values.lease_schedule || null,
                        lease_chartstring: values.lease_chartstring || null,
                        ipsn: values.ipsn || null,
                    }

                    // Workstation Fields
                    if (values.asset_type_select === ASSET_TYPE.ASSET_TYPE_WORKSTATION) {
                        // Default printers are set in null and assigned in next block according to selected on display_default_printers
                        formData['workstation_details'] = {
                            workstation_type_id: values.workstation_type_id,
                            ip_address: values.workstation_ip_address || null,
                            login_strategy: values.login_strategy,
                            primary_use_id: values.primary_use_id,

                            default_printer_plain_paper_id: null,
                            default_printer_wristbands_id: null,
                            default_printer_baby_wristbands_id: null,
                            default_printer_pharm_labels_id: null,
                            default_printer_labels_id: null,
                            default_printer_scripts_id: null,
                            default_printer_insurance_form_id: null,

                            default_cc_reader_id: values.default_cc_reader_id,

                            bca_pc: values.bca_pc,
                            is_proximity_unit: values.is_proximity_unit,
                            is_rover: values.is_rover,
                            welcome_kiosk: values.welcome_kiosk,
                            bca_sticker: values.bca_sticker,
                            bca_local_printer: values.bca_local_printer,
                            bca_ups: values.bca_ups,
                        };

                        // Set the default printers according to the selected ones
                        Object.values(DEFAULT_PRINTER_FIELD_MAP).forEach((value) => {
                            if (values.display_default_printers.includes(value)) {
                                let fieldName = value + '_id';
                                formData['workstation_details'][fieldName] = values[fieldName];
                            }
                        });
                    }

                    // Printer fields
                    if (values.asset_type_select === ASSET_TYPE.ASSET_TYPE_PRINTER) {
                        formData['printer_details'] = {
                            printer_type_id: values.printer_type_id,
                            printer_queue_name: values.printer_queue_name || null,
                            ip_address: values.printer_ip_address || null,
                            tray_1_content_id: values.tray_1_content_id,
                            tray_2_content_id: values.tray_2_content_id,
                            tray_3_content_id: values.tray_3_content_id,
                            tray_4_content_id: values.tray_4_content_id,
                            tray_5_content_id: values.tray_5_content_id,
                            tray_6_content_id: values.tray_6_content_id,
                            manual_tray_content_id: values.manual_tray_content_id,

                            comments: values.printer_comments || null,
                            hostname: values.printer_hostname || null,
                            zeno_num: values.printer_zeno_num || null,
                            epic_printer_name: values.printer_epic_printer_name || null,
                            bca_printer: values.bca_printer,
                        };
                    }

                    if (recordId) {
                        formData.id = recordId;
                        dispatch(crudBaseUpdateStart({ entityCode: ENTITY_ASSET, recordData: formData }));
                    } else {
                        // Allow users to create assets with custom IDs
                        if (values.id) {
                            formData.id = values.id;
                        }
                        dispatch(crudBaseCreateStart({ entityCode: ENTITY_ASSET, recordData: formData }));
                    }
                    setFormSubmitStarted(true);
                    saveLocationToSessionStorage(values);
                }}
            >{({ values, status }) => (
                <Form>
                    <ObserverFormOnChange updateFilterSet={(filterSet) => handleFilterSet(filterSet, values)} />
                    <Row>
                        <Col xs={12} md={6}>
                            <Fieldset legend="General Information" toggleable className='mb-3'>
                                <Row>
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="asset_name"
                                            inputPlaceHolder="Enter the asset name"
                                            inputLabel="Asset Name"
                                        />
                                    </Col>
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="serial_num"
                                            inputPlaceHolder="Enter the serial number"
                                            inputLabel="Serial Number"
                                        />
                                    </Col>
                                    {/*
                                    TODO: Remove we confirm that CAM tag number is not used
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="cam_tag_num"
                                            inputPlaceHolder="Enter the CAM tag number"
                                            inputLabel="CAM Tag"
                                        />
                                    </Col> */}
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="msit_tag_num"
                                            inputPlaceHolder="Enter the MSIT tag number"
                                            inputLabel="MSIT Tag"
                                        />
                                    </Col>
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="id"
                                            inputLabel="Asset ID"
                                            inputProps={{ disabled: !!recordId }}
                                        />
                                    </Col>
                                    <Col xs={6} lg={4} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="parent_asset_id"
                                            inputLabel="Parent Asset ID"
                                            inputProps={{ disabled: true }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='mb-3'>
                                        <Field name="asset_type_select" as={SelectButton}
                                            options={assetTypeSelectOptions} itemTemplate={assetTypeSelectTemplate} optionLabel="value"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {values.asset_type_select && values.asset_type_select === ASSET_TYPE_OTHER &&
                                        <Col xs={12} lg={6} className='mb-3'>
                                            <Label for='field_asset_type_id'>Type</Label>
                                            <Field name="asset_type_id" id='field_asset_type_id' entityCode={ENTITY_ASSET_TYPE} component={AppEntityDropdown} optionsData={
                                                assetTypesList.filter((assetType) => assetType.is_peripheral)
                                            } />
                                        </Col>
                                    }
                                    {/* TODO Remove when we confirm that this field is not used
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="w_p_num"
                                            inputPlaceHolder="Enter the number"
                                            inputLabel={wPNumLabel}
                                        />
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_the_make_id'>Make</Label>
                                        <Field name="the_make_id" id='field_the_make_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownMakesFilterSet} placeholderEntity='Make' />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_the_model_id'>Model</Label>
                                        <Field name="the_model_id" id='field_the_model_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownModelsFilterSet} placeholderEntity='Model' />
                                    </Col>
                                </Row>
                                {values.asset_type_select && values.asset_type_select === ASSET_TYPE.ASSET_TYPE_WORKSTATION &&
                                    <Row>
                                        <Col xs={12} lg={6} className='mb-3'>
                                            <Label for='field_the_os_id'>OS</Label>
                                            <Field name="the_os_id" id='field_the_os_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownOssFilterSet} placeholderEntity='OS' />
                                        </Col>
                                    </Row>
                                }
                                {values.asset_type_id && values.asset_type_id === mainAssetTypes.monitor_asset_type_id &&
                                    <Row>
                                        <Col xs={12} lg={6} className='mb-3'>
                                            <Label for='field_the_size_id'>Size</Label>
                                            <Field name="the_size_id" id='field_the_size_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownSizesFilterSet} placeholderEntity='Size' />
                                        </Col>
                                        <Col xs={12} lg={6} className='mb-3'>
                                            <Label for='field_the_resolution_id'>Resolution</Label>
                                            <Field name="the_resolution_id" id='field_the_resolution_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownResolutionsFilterSet} placeholderEntity='Resolution' />
                                        </Col>
                                        <Col xs={12} lg={6} className='mb-3'>
                                            <Label for='field_the_screen_orientation_id'>Screen Orientation</Label>
                                            <Field name="the_screen_orientation_id" id='field_the_screen_orientation_id' entityCode={ENTITY_ASSET_TYPE_CHOICE} component={AppEntityDropdown} filterSet={dropdownScreenOrientationsFilterSet} placeholderEntity='Screen Orientation' />
                                        </Col>
                                    </Row>
                                }
                            </Fieldset>
                            {values.asset_type_select && values.asset_type_select === ASSET_TYPE.ASSET_TYPE_WORKSTATION &&
                                <Fragment>
                                    <Fieldset legend="Workstation Information" toggleable className='mb-3'>
                                        <Row>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <Label for='field_workstation_type_id'>Workstation Type</Label>
                                                <Field name="workstation_type_id" id='field_workstation_type_id' entityCode={ENTITY_WORKSTATION_TYPE} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <Label for='field_primary_use_id'>Workstation Primary Use</Label>
                                                <Field name="primary_use_id" id='field_primary_use_id' entityCode={ENTITY_WORKFLOW} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="workstation_ip_address"
                                                    inputPlaceHolder="Enter the workstation IP"
                                                    inputLabel="Workstation IP"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppOptionSelect fieldName="login_strategy" fieldLabel="Login Strategy" selectOptions={LOGIN_STRATEGIES_LABELS} />
                                            </Col>
                                            <Col xs={6} md={4} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="checkbox"
                                                    fieldName="bca_pc"
                                                    inputLabel="BCA PC?"
                                                />
                                            </Col>
                                            <Col xs={6} md={4} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="checkbox"
                                                    fieldName="is_proximity_unit"
                                                    inputLabel="Wall Mount?"
                                                />
                                            </Col>
                                            <Col xs={6} md={4} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="checkbox"
                                                    fieldName="is_rover"
                                                    inputLabel="Rover?"
                                                />
                                            </Col>
                                            <Col xs={6} md={4} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="checkbox"
                                                    fieldName="welcome_kiosk"
                                                    inputLabel="Welcome Kiosk?"
                                                />
                                            </Col>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="epic_workstation_id"
                                                    inputLabel="EPIC Workstation ID"
                                                    inputProps={{ disabled: true }}
                                                />
                                            </Col>
                                        </Row>
                                        {values.bca_pc &&
                                            <Row>
                                                <Col xs={6} md={4} className='mb-3'>
                                                    <AppInputFieldComponent
                                                        fieldType="checkbox"
                                                        fieldName="bca_sticker"
                                                        inputLabel="BCA Sticker?"
                                                    />
                                                </Col>
                                                <Col xs={6} md={4} className='mb-3'>
                                                    <AppInputFieldComponent
                                                        fieldType="checkbox"
                                                        fieldName="bca_local_printer"
                                                        inputLabel="BCA Local Printer?"
                                                    />
                                                </Col>
                                                <Col xs={6} md={4} className='mb-3'>
                                                    <AppInputFieldComponent
                                                        fieldType="checkbox"
                                                        fieldName="bca_ups"
                                                        inputLabel="BCA UPS?"
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Fieldset>
                                    <Fieldset legend="Peripherals for this Workstation" toggleable className='mb-3'>
                                        <Row>
                                            <Col xs={12} className='mb-3'>
                                                <Label for='field_display_default_printers'>Default Printer for</Label>
                                                <Field name="display_default_printers" id='display_default_printers' as={MultiSelect}
                                                    options={defaultPrinterFor} display="chip" placeholder="Select Default Printers for"
                                                />
                                            </Col>
                                        </Row>
                                        { /* TODO: Create Redux/Saga code to get PrinterTrayContent to replace the following with map()
                                        */
                                        }
                                        <Row>
                                            {values.display_default_printers.includes('default_printer_plain_paper') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_plain_paper_id'>Default Printer for Plain Paper</Label>
                                                    {dropdownDefaultPlainPaperFilterSet &&
                                                        <Field name="default_printer_plain_paper_id" id='field_default_printer_plain_paper_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultPlainPaperFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultPlainPaperFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_wristbands') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_wristbands_id'>Default Printer for Wrist Bands</Label>
                                                    {dropdownDefaultWristbandsFilterSet &&
                                                        <Field name="default_printer_wristbands_id" id='field_default_printer_wristbands_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultWristbandsFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultWristbandsFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_baby_wristbands') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_baby_wristbands_id'>Default Printer for Baby Wrist Bands</Label>
                                                    {dropdownDefaultBabyWristbandsFilterSet &&
                                                        <Field name="default_printer_baby_wristbands_id" id='field_default_printer_baby_wristbands_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultBabyWristbandsFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultBabyWristbandsFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_pharm_labels') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_pharm_labels_id'>Default Printer for Pharmacy Labels</Label>
                                                    {dropdownDefaultPharmLabelsFilterSet &&
                                                        <Field name="default_printer_pharm_labels_id" id='field_default_printer_pharm_labels_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultPharmLabelsFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultPharmLabelsFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_labels') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_labels_id'>Default Printer for Labels</Label>
                                                    {dropdownDefaultLabelsFilterSet &&
                                                        <Field name="default_printer_labels_id" id='field_default_printer_labels_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultLabelsFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultLabelsFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_scripts') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_scripts_id'>Default Printer for Scripts</Label>
                                                    {dropdownDefaultScriptsFilterSet &&
                                                        <Field name="default_printer_scripts_id" id='field_default_printer_scripts_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultScriptsFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultScriptsFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                            {values.display_default_printers.includes('default_printer_insurance_form') &&
                                                <Col xs={12} lg={6} className='mb-3'>
                                                    <Label for='field_default_printer_insurance_form_id'>Default Printer for Insurance Forms</Label>
                                                    {dropdownDefaultInsuranceFormFilterSet &&
                                                        <Field name="default_printer_insurance_form_id" id='field_default_printer_insurance_form_id' entityCode={ENTITY_SELECT_PRINTER} component={AppEntityDropdown}
                                                            filterSet={dropdownDefaultInsuranceFormFilterSet}
                                                        />
                                                    }
                                                    {!dropdownDefaultInsuranceFormFilterSet && EmptyBuildingAlert}
                                                </Col>
                                            }
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <Label for='field_default_cc_reader_id'>Default CC Reader</Label>
                                                {dropdownDefaultCCReadersFilterSet &&
                                                    <Field name="default_cc_reader_id" id='field_default_cc_reader_id' entityCode={ENTITY_SELECT_CREDIT_CARD_READER} component={AppEntityDropdown}
                                                        filterSet={dropdownDefaultCCReadersFilterSet}
                                                    />
                                                }
                                                {!dropdownDefaultCCReadersFilterSet &&
                                                    <Alert color="secondary">Select Building and Department to see the available CC Readers</Alert>
                                                }
                                            </Col>
                                        </Row>
                                        <Divider>Add Peripheral</Divider>
                                        <Row>
                                            {!recordId &&
                                                <Col xs={12} className='mb-3'>
                                                    <Alert color="info">Save the asset to add peripherals</Alert>
                                                </Col>
                                            }
                                            {recordId &&
                                                <Fragment>
                                                    <Col xs={12} lg={4} className='mb-3'>
                                                        <Label for='field_add_peripheral_asset_type_id'>Peripheral Type</Label>
                                                        <Field name="add_peripheral_asset_type_id" id='field_add_peripheral_asset_type_id' entityCode={ENTITY_ASSET_TYPE} component={AppEntityDropdown} optionsData={
                                                            assetTypesList.filter((assetType) => assetType.is_peripheral)
                                                        } />
                                                    </Col>
                                                    <Col xs={12} lg={6} className='mb-3'>
                                                        <Label for='field_add_peripheral_asset_id'>Select Peripheral</Label>
                                                        {dropdownAddPeripheralFilterSet &&
                                                            <Field name="add_peripheral_asset_id" id='field_add_peripheral_asset_id' entityCode={ENTITY_ASSET} component={AppEntityDropdown}
                                                                filterSet={dropdownAddPeripheralFilterSet}
                                                            />
                                                        }
                                                        {!dropdownAddPeripheralFilterSet &&
                                                            <Alert color="secondary">Select the Peripheral Type, Building and Department to see the available peripherals.</Alert>
                                                        }
                                                    </Col>
                                                    <Col xs={12} lg={2} className='mb-3'>
                                                        <Label>&nbsp;</Label>
                                                        <Button type="button" color="secondary" disabled={!values.add_peripheral_asset_id}
                                                            onClick={() => handleAddPeripheralConfirm(values.add_peripheral_asset_id)}
                                                        >Attach</Button>
                                                    </Col>
                                                </Fragment>
                                            }
                                        </Row>
                                        {recordId &&
                                            <Fragment>

                                                <Divider>Current Peripherals</Divider>
                                                <Row>
                                                    <Col xs={12} className='mb-3'>
                                                        <AssetPeripheralList parentAssetId={recordId} refreshListSignal={refreshPeripheralList} />
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                        }
                                    </Fieldset>
                                </Fragment>
                            }
                            {values.asset_type_select && values.asset_type_select === ASSET_TYPE.ASSET_TYPE_PRINTER &&
                                <Fragment>
                                    <Fieldset legend="Printer Information" toggleable className='mb-3'>
                                        <Row>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <Label for='field_printer_type_id'>Printer Type</Label>
                                                <Field name="printer_type_id" id='field_printer_type_id' entityCode={ENTITY_PRINTER_TYPE} component={AppEntityDropdown} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_queue_name"
                                                    inputPlaceHolder="Enter the printer queue name"
                                                    inputLabel="Printer Queue Name"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_ip_address"
                                                    inputPlaceHolder="Enter the printer IP"
                                                    inputLabel="Printer IP"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_comments"
                                                    inputPlaceHolder="Enter the printer comments"
                                                    inputLabel="Printer Comments"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_hostname"
                                                    inputPlaceHolder="Enter the printer hostname"
                                                    inputLabel="Printer Hostname"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_zeno_num"
                                                    inputPlaceHolder="Enter the printer Zeno number"
                                                    inputLabel="Printer Zeno Number"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="printer_epic_printer_name"
                                                    inputPlaceHolder="Enter the printer EPIC printer name"
                                                    inputLabel="Printer EPIC Printer Name"
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="checkbox"
                                                    fieldName="bca_printer"
                                                    inputLabel="BCA Printer?"
                                                />
                                            </Col>
                                        </Row>
                                    </Fieldset>
                                    <Fieldset legend="Printer Try Contents" toggleable className='mb-3'>
                                        <Row>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_1_content_id'>Tray 1 Content</Label>
                                                <Field name="tray_1_content_id" id='field_tray_1_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_2_content_id'>Tray 2 Content</Label>
                                                <Field name="tray_2_content_id" id='field_tray_2_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_3_content_id'>Tray 3 Content</Label>
                                                <Field name="tray_3_content_id" id='field_tray_3_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_4_content_id'>Tray 4 Content</Label>
                                                <Field name="tray_4_content_id" id='field_tray_4_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_5_content_id'>Tray 5 Content</Label>
                                                <Field name="tray_5_content_id" id='field_tray_5_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_tray_6_content_id'>Tray 6 Content</Label>
                                                <Field name="tray_6_content_id" id='field_tray_6_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                            <Col xs={12} md={6} className='mb-3'>
                                                <Label for='field_manual_tray_content_id'>Manual Tray Content</Label>
                                                <Field name="manual_tray_content_id" id='field_manual_tray_content_id' entityCode={ENTITY_PRINTER_TRAY_CONTENT} component={AppEntityDropdown} />
                                            </Col>
                                        </Row>
                                    </Fieldset>
                                </Fragment>
                            }
                            {values.asset_type_id && values.asset_type_id === mainAssetTypes.credit_card_reader_asset_type_id &&
                                <Fragment>
                                    <Fieldset legend="CC Reader Information" toggleable className='mb-3'>
                                        <Row>
                                            <Col xs={12} lg={6} className='mb-3'>
                                                <AppInputFieldComponent
                                                    fieldType="text"
                                                    fieldName="ipsn"
                                                    inputPlaceHolder="Enter the IPSN"
                                                    inputLabel="IPSN"
                                                />
                                            </Col>
                                        </Row>
                                    </Fieldset>
                                </Fragment>
                            }
                        </Col>
                        <Col xs={12} md={6}>
                            <Fieldset legend="Owner/Location Information" toggleable className='mb-3'>
                                <Row>
                                    {/* TODO Remove when we confirm that this way to select owner is not used
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="owner"
                                            inputPlaceHolder="Enter the owner"
                                            tooltipText="Enter the email owner here or select it from dropdown"
                                            inputLabel="Owner"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_owner_dropdown'>Search Owner</Label>
                                        <Field name="owner_dropdown" id="field_owner_dropdown" entityCode={ENTITY_ASSET_OWNER} component={AppEntityDropdown} />
                                    </Col>
                                    */}
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_owner'>Owner</Label>
                                        <Field name="owner" id="field_owner" entityCode={ENTITY_ASSET_OWNER} component={AppEntityDropdown} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_ou_id'>School</Label>
                                        <Field name="ou_id" id="field_ou_id" entityCode={ENTITY_OU} component={AppEntityDropdown} />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_building_id'>Building</Label>
                                        <Field name="building_id" id="field_building_id" entityCode={ENTITY_BUILDING} component={AppEntityDropdown} filterSet={dropdownBuildingFilterSet} />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_department_id'>Department</Label>
                                        <Field name="department_id" id="field_department_id" entityCode={ENTITY_SELECT_DEPARTMENT} component={AppEntityDropdown} filterSet={dropdownDepartmentFilterSet} />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="suite_num"
                                            inputLabel="Suite #"
                                            inputProps={{ disabled: true }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_specialty_id'>Specialty</Label>
                                        {values.department_id &&
                                            <Field name="specialty_id" id="field_specialty_id" entityCode={ENTITY_SPECIALTY} component={AppEntityDropdown} filterSet={dropdownSpecialtyFilterSet} />
                                        }
                                        {!values.department_id &&
                                            <Alert color="secondary">Select Department to see the available Specialties</Alert>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="location"
                                            inputPlaceHolder="Enter the address"
                                            inputLabel="Address"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="room"
                                            inputPlaceHolder="Enter the room"
                                            inputLabel="Room"
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Encryption Information" toggleable className='mb-3' >
                            </Fieldset>
                            <Fieldset legend="Document Information" toggleable className='mb-3'>
                            </Fieldset>
                            <Fieldset legend="Lease Information" toggleable className='mb-3'>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="lease_description"
                                            inputPlaceHolder="Enter the lease description"
                                            inputLabel="Lease Description"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="date"
                                            fieldName="lease_start_date"
                                            inputLabel="Lease Start Date"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="date"
                                            fieldName="lease_end_date"
                                            inputLabel="Lease End Date"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="date"
                                            fieldName="warranty_end_date"
                                            inputLabel="Warranty End Date"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="number"
                                            fieldName="rent"
                                            inputPlaceHolder="Enter the rent"
                                            inputLabel="Rent"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="lease_schedule"
                                            inputPlaceHolder="Enter the lease schedule"
                                            inputLabel="Lease Schedule"
                                        />
                                    </Col>
                                    {/* TODO Remove when we confirm that this field is not used
                                <Col xs={12} lg={6} className='mb-3'>
                                    <AppInputFieldComponent
                                        fieldType="text"
                                        fieldName="lease_chartstring"
                                        inputPlaceHolder="Enter the lease chartstring"
                                        inputLabel="Lease Chartstring"
                                    />
                                </Col> */}
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Other Information" toggleable className='mb-3'>
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="checkbox"
                                            fieldName="tdr_status"
                                            inputLabel={ASSET_TDR_STATUS_PASSED + '?'}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="checkbox"
                                            fieldName="followup_needed"
                                            inputLabel="Followup Needed?"
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="date"
                                            fieldName="asset_status_date"
                                            inputLabel="Asset Status Date"
                                            inputProps={{ disabled: true }}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppOptionSelect fieldName="asset_status" fieldLabel="Asset Status" selectOptions={ASSET_STATUSES_LABELS} />
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppOptionSelect fieldName="status" fieldLabel="Status" selectOptions={INFORMATION_STATUSES_LABELS} />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <Label for='field_notes'>Notes</Label>
                                        {recordId && activeRecord && <AssetNotes notes={activeRecord.notes} />}
                                    </Col>
                                    <Col xs={12} lg={6} className='mb-3'>
                                        <AppInputFieldComponent
                                            fieldType="text"
                                            fieldName="new_notes"
                                            inputLabel="New Notes"
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col className="text-center" md={6} xs={12}>
                            <Button color='secondary' className='mt-2 px-5' type='reset' onClick={close}>
                                Cancel
                            </Button>
                        </Col>
                        <Col className="text-center" md={6} xs={12}>
                            <Button color='success' className='mt-2 px-5' type='submit'>
                                Save
                            </Button>
                            {(Object.entries(status.apiErrors).length > 0) &&
                                <Alert color="danger" className="mt-2">
                                    Your changes were not saved. Check the error messages and submit the form again.
                                </Alert>
                            }
                        </Col>
                    </Row>
                </Form>
            )}
            </AppFormik>

            <ConfirmDialog visible={addPeripheralDialogState}
                onHide={addPeripheralHideDialog}
                className='delete-confirm-dialog'
                message={
                    <Fragment>
                        <p>Are you sure you want to add asset ID {toAddPeripheralAssetId} as a peripheral of this workstation?</p>
                        <p>Location and Owner will be updated to match the parent asset.</p>
                    </Fragment>
                }
                header='Add Peripheral Confirmation'
                acceptLabel='Confirm'
                rejectLabel='Cancel'
                accept={handleAddPeripheralSave} reject={addPeripheralHideDialog} />

        </Fragment>
    )
}

export default AssetForm;
