import {
    REFRESH_WORKSTATIONS_FAILED,
    REFRESH_WORKSTATIONS_START,
    REFRESH_WORKSTATIONS_SUCCESS,
    RESET_WORKSTATION_STATE,
    WORKSTATIONS_GET_LAST_REFRESH_TIME_SUCCESS,
} from "redux/actions/workstations.actions";

const initialWorkstationsState = {
    epicRefreshing: false,
    triggerRefreshDataTable: null,
    lastRefreshTime: null,
};

export const WorkstationsReducer = (state = initialWorkstationsState, action) => {
    switch (action.type) {
        case WORKSTATIONS_GET_LAST_REFRESH_TIME_SUCCESS:
            return {
                ...state,
                lastRefreshTime: action.payload,
            };
        case REFRESH_WORKSTATIONS_START:
            return {
                ...state,
                epicRefreshing: true,
            };
        case REFRESH_WORKSTATIONS_SUCCESS:
            return {
                ...state,
                epicRefreshing: false,
                triggerRefreshDataTable: new Date().getTime(),
            };
        case REFRESH_WORKSTATIONS_FAILED:
            return {
                ...state,
                epicRefreshing: false,
            };
        case RESET_WORKSTATION_STATE:
            return initialWorkstationsState;
        default:
            return state;
    }
}
