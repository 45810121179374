import { ASSET_STATUSES_LABELS } from 'assets/static_data/backend_exports';
import { CrudBaseList } from 'components/base/CrudBaseList';
import AssetStatus from 'components/ui/AssetStatus';
import { LEASED_ASSETS_BULK_UPDATE } from 'navigation/ROUTE_CONSTANTS';
import { ENTITY_LEASED_ASSET } from 'redux/entities_config';
import { AssetForm } from '../all_assets/AssetForm';

const dataTableColumns = [
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'ou.ou_name',
        header: 'School',
        sortable: true,
    },
    {
        field: 'department.department_name',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'asset_type.asset_type_name',
        header: 'Asset Type',
        sortable: true,
    },
    {
        field: 'asset_status',
        header: 'Asset Status',
        body: (rowData) => (<AssetStatus asset={rowData} />),
        sortable: true,
        align: 'center',
    },
    {
        field: 'id',
        header: 'Asset Id',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        field: 'lease_description',
        header: 'Lease Description',
        sortable: true,
    },
    {
        field: 'lease_end_date',
        header: 'Lease End Date',
        sortable: true,
    },
    {
        field: 'rent',
        header: 'Rent',
        sortable: true,
    },
    {
        field: 'lease_chartstring',
        header: 'Chartstring',
        sortable: true,
    },
    {
        field: 'lease_schedule',
        header: 'Lease Schedule',
        sortable: true,
    },
];

// Both below constants need to be in sync to allow exported xls file as same columns that required for import
export const importExcelRequiredColumns = [
    'School Code',
    'Department',
    'Asset Type',
    'Asset Status',
    'Asset Id',
    'Serial #',
    'Lease Description',
    'Lease End Date',
    'Rent',
    'Chartstring',
    'Lease Schedule',
];

const exportExcelFieldMap = (rowData) => {
    return {
        'School Code': rowData.ou ? rowData.ou.ou_name : '',
        'Department': rowData.department ? rowData.department.department_name : '',
        'Asset Type': rowData.asset_type ? rowData.asset_type.asset_type_name : '',
        'Asset Status': ASSET_STATUSES_LABELS[rowData.asset_status],
        'Asset Id': rowData.id,
        'Serial #': rowData.serial_num,
        'Lease Description': rowData.lease_description,
        'Lease End Date': rowData.lease_end_date,
        'Rent': rowData.rent,
        'Chartstring': rowData.lease_chartstring,
        'Lease Schedule': rowData.lease_schedule,
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 50 }, // School
    { wch: 50 }, // Department
    { wch: 50 }, // Asset Type
    { wch: 50 }, // Asset Status
    { wch: 20 }, // Asset Id
    { wch: 20 }, // Serial #
    { wch: 50 }, // Lease Description
    { wch: 20 }, // Lease End Date
    { wch: 20 }, // Rent
    { wch: 50 }, // Chartstring
    { wch: 50 }, // Lease Schedule
];

const LeasedAssetsList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_LEASED_ASSET}
            CrudBaseForm={AssetForm}
            bulkUpdateRoute={LEASED_ASSETS_BULK_UPDATE}
            bulkUpdateSupportAdd={false}
            dataTableColumns={dataTableColumns}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="All Leased Assets"
        />
    );
};

export default LeasedAssetsList;
