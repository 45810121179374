import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_STATE } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './StatesList';


const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    return {
        'state_name': rowData['Name'],
        'active': rowData['Status'] === 'Active' ? true : false,
    }
};

const apiToExcelColumn = {
    'state_name': 'Name',
    'active': 'Status',
};

const foreignKeyColumns = [
];

export const StatesBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_STATE}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
        />
    );
};

export default StatesBulkUpdate;
