import MetisMenu from "metismenujs";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { MENU_SECTIONS } from "assets/static_data/backend_exports";
import { DASHBOARD, INVENTORY_DASHBOARD, WORKSTATION_DASHBOARD } from "navigation/ROUTE_CONSTANTS";
import { SidebarMenuOptions } from "./SidebarMenuOptions";


export const SidebarContent = () => {
    const activeMenuSection = useSelector(state => state.ui.activeMenuSection);
    const metisMenu = useRef(null);
    const [dashboardLink, setDashboardLink] = useState(DASHBOARD);

    const updateMenuOptions = useCallback(() => {
        if (!metisMenu.current) {
            metisMenu.current = new MetisMenu('#side-menu');
        }
        metisMenu.current.dispose();

        // Below code is used to expand the proper sub-menu options when the user changes the menu section or when it is first page load
        // Use DOM API to remove all children a nodes from #side-menu that contains class mm-active
        const activeMenuItems = document.querySelectorAll('#side-menu .mm-active');
        activeMenuItems.forEach((item) => {
            item.classList.remove('mm-active');
        });

        // Search for the first anchor node from #side-menu that contains class 'active' and set the closest parent node ul with class 'mm-active'
        const activeMenuLink = document.querySelector("#side-menu a.active");
        if (activeMenuLink) {
            activeMenuLink.closest('li').closest('ul').closest('li')?.classList.add('mm-active');
        }

        metisMenu.current.update();
    }, []);

    useEffect(() => {
        if (activeMenuSection === MENU_SECTIONS.INVENTORY) {
            setDashboardLink(INVENTORY_DASHBOARD);
        } else if (activeMenuSection === MENU_SECTIONS.WORKSTATION) {
            setDashboardLink(WORKSTATION_DASHBOARD);
        }
    }, [activeMenuSection]);

    return (
        <Fragment>
            <div id="sidebar-menu" className={activeMenuSection + '-sidebar-menu'}>

                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Menu</li>

                    <li>
                        <NavLink to={dashboardLink} className="waves-effect">
                            <i className="ri-dashboard-line"></i>
                            <span className="ms-1">Main Page</span>
                        </NavLink>
                    </li>

                    <SidebarMenuOptions onUpdatedMenuOptions={updateMenuOptions} />

                </ul>
            </div>
        </Fragment>
    );
}

export default SidebarContent;
