import axios from "axios";

import { API_DUPLICATE_EPIC_WORKSTATIONS, URL_PARAM_DATA_IMPORT } from "./API_CONSTANTS";

export const entityDataImport = (apiEndpoint, recordData) => {
    let url = apiEndpoint + '/' + URL_PARAM_DATA_IMPORT;

    return new Promise((resolve, reject) => {
        axios.post(url, recordData)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};

export const duplicateEpicWorkstation = (recordData) => {
    return new Promise((resolve, reject) => {
        axios.post(API_DUPLICATE_EPIC_WORKSTATIONS, recordData)
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
};
