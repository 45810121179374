import { CrudBaseList } from 'components/base/CrudBaseList';
import { ENTITY_EPIC_WORKSTATION } from 'redux/entities_config';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';
import WorkstationsToolbar from './WorkstationsToolbar.js';


const dataTableColumns = [
    {
        field: 'ws_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'workstation_type',
        header: 'Type',
        sortable: true,
    },
    {
        field: 'functional_type.functional_type',
        header: 'Functional Type',
        sortable: true,
        body: (rowData) => rowData.functional_type ? rowData.functional_type : '',
    },
    {
        field: 'printer_type',
        header: 'Printer Type',
        sortable: true,
    },
    {
        field: 'status',
        header: 'Status',
        sortable: true,
    },
    {
        field: 'department',
        header: 'Department',
        sortable: true,
    },
    {
        field: 'ws_identifier',
        header: 'IDs',
        body: (rowData) => <div className='cell-text-small'>
            <span>Name: {rowData.ws_name}</span>
            <br />
            <span>Internal ID: {rowData.internal_identifier}</span>
            <br />
            <span>External ID: {rowData.external_identifier}</span>
            <br />
            <span>CID: {rowData.cid}</span>
        </div>,
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    },
];

const exportExcelFieldMap = (rowData) => {
    return {
        'Name': rowData.ws_name,
        'Type': rowData.workstation_type,
        'Functional Type': rowData.functional_type,
        'Printer Type': rowData.printer_type,
        'Status': rowData.status,
        'Department': rowData.department,
        'IDs': `Name: ${rowData.ws_name}\nInternal ID: ${rowData.internal_identifier}\nExternal ID: ${rowData.external_identifier}\nCID: ${rowData.cid}`,
        'Last Modified Date': rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : '',
    }
};

// Set column widths
const exportExcelColumnInfo = [
    { wch: 40 }, // Name
    { wch: 30 }, // Type
    { wch: 30 }, // Functional Type
    { wch: 30 }, // Printer Type
    { wch: 20 }, // Status
    { wch: 30 }, // Department
    { wch: 40 }, // IDs
    { wch: 30 }, // Last Modified Date
];

const WorkstationsList = () => {
    return (
        <CrudBaseList
            entityCode={ENTITY_EPIC_WORKSTATION}
            dataTableColumns={dataTableColumns}
            StartToolbarContent={WorkstationsToolbar}
            exportExcelFieldMap={exportExcelFieldMap}
            exportExcelColumnInfo={exportExcelColumnInfo}
            mainTitle="EPIC Workstations List"
            hasCreateButton={false}
        />
    );
};

export default WorkstationsList;
