import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { AdminSettingsReducer } from "./reducers/admin.settings.reducers";
import { UsersByAdminReducer } from './reducers/admin.users.reducers';
import { AssetTypesReducer } from './reducers/asset_types.reducer';
import { AuthReducer } from "./reducers/auth.reducer";
import { CrudBaseReducer } from './reducers/crud_base.reducer';
import { ErrorReducer } from "./reducers/error.reducer";
import { FunctionalTypesReducer } from './reducers/functional_types.reducer';
import { LayoutReducer } from "./reducers/layout.reducer";
import { RolesReducer } from './reducers/roles.reducer';
import { ServerValidationsReducer } from './reducers/server_validations.reducer';
import { UIReducer } from "./reducers/ui.reducer";
import { UsersReducer } from "./reducers/users.reducer";
import { WorkflowReqCategoriesReducer } from './reducers/workflow_req_categories.reducer';
import { WorkstationsReducer } from './reducers/workstations.reducer';
import appSaga from "./saga";

const appReducer = combineReducers({
    adminSettings: AdminSettingsReducer,
    assetTypes: AssetTypesReducer,
    auth: AuthReducer,
    crudBase: CrudBaseReducer,
    errors: ErrorReducer,
    functionalTypes: FunctionalTypesReducer,
    layout: LayoutReducer,
    roles: RolesReducer,
    serverValidations: ServerValidationsReducer,
    ui: UIReducer,
    users: UsersReducer,
    usersByAdmin: UsersByAdminReducer,
    workflowReqCategories: WorkflowReqCategoriesReducer,
    workstations: WorkstationsReducer,
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const devMode = process.env.NODE_ENV === 'development';

export const store = configureStore({
    reducer: appReducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) => {
        let middleware = getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
        if (devMode) {
            middleware.concat(logger);
        }
        return middleware;
    },
});

sagaMiddleware.run(appSaga);
