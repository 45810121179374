import { BaseBulkUpdate } from 'components/base/BaseBulkUpdate';
import { ENTITY_CREDIT_CARD_READER } from 'redux/entities_config';
import { convertArrayToObject } from 'utils';
import { importExcelRequiredColumns } from './CreditCardReadersList';
import { userHelpCreditCardReaders } from 'assets/static_data/common_constants';

const excelRowToApiData = (rowDataArray) => {
    let rowData = convertArrayToObject(rowDataArray, importExcelRequiredColumns);

    let apiData = {
        'id': rowData['Asset Id'] || null,
        'asset_name': rowData['Asset Name'] || null,
        'serial_num': rowData['Serial #'] || null,
        'ipsn': rowData['IPSN'] || null,
        'cam_tag_num': rowData['CAM Tag'] || null,
        'msit_tag_num': rowData['MSIT Tag'] || null,
        'w_p_num': rowData['CC #'] || null,
        'the_make_choice': rowData['Make'] || null,
        'the_model_choice': rowData['Model'] || null,
        'new_notes': rowData['New Notes'] || null,
        'suite_num': rowData['Suite #'] || null,
    }

    // Optional fields
    if (rowData['Main Asset Id']) {
        apiData.parent_asset_id = rowData['Main Asset Id'];
    }
    if (rowData['Department']) {
        apiData.department_name = rowData['Department'];
    }
    if (rowData['School Code']) {
        apiData.ou_name = rowData['School Code'];
    }
    if (rowData['Street']) {
        apiData.street = rowData['Street'];
    }

    return apiData;
};

const apiToExcelColumn = {
    'id': 'Workstation Asset Id',
    'asset_name': 'Asset Name',
    'serial_num': 'Serial #',
    'ipsn': 'IPSN',
    'cam_tag_num': 'CAM Tag',
    'msit_tag_num': 'MSIT Tag',
    'w_p_num': 'CC #',
    'the_make_choice': 'Make',
    'the_model_choice': 'Model',
    'new_notes': 'New Notes',
    'department_name': 'Department',
    'ou_name': 'School Code',
    'street': 'Street',
    'suite_num': 'Suite #',
    'parent_asset_id': 'Main Asset Id',
};

const foreignKeyColumns = [
    'Make',
    'Model',
    'Department',
    'School Code',
];

export const CreditCardReadersBulkUpdate = () => {
    return (
        <BaseBulkUpdate
            entityCode={ENTITY_CREDIT_CARD_READER}
            importExcelRequiredColumns={importExcelRequiredColumns}
            excelRowToApiData={excelRowToApiData}
            apiToExcelColumn={apiToExcelColumn}
            foreignKeyColumns={foreignKeyColumns}
            mainTitle="CC Readers Bulk Update"
            userHelpText={userHelpCreditCardReaders}
        />
    );
};

export default CreditCardReadersBulkUpdate;
