import { InfoOutlined } from '@mui/icons-material';
import { Field, useField } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Fragment, useEffect, useState } from 'react';
import { FormFeedback, InputGroup, InputGroupText, Label, UncontrolledTooltip } from 'reactstrap';

import '../../styles/OnBoarding.css';

export const AppCalendarComponent = ({ fieldName, inputPlaceHolder, tooltipText = null, inputLabel = null, inputProps = {} }) => {
    const [infoId] = useState(() => fieldName + '_Tooltip_' + Math.random().toString(36).substring(7));
    const [inputId] = useState(() => 'input_' + fieldName);
    const [formikField, meta] = useField({ name: fieldName, type: 'text' });
    const [isInvalidField, setIsInvalidField] = useState(false);

    useEffect(() => {
        if (meta.touched && meta.error) {
            setIsInvalidField(true);
        } else {
            setIsInvalidField(false);
        }
    }, [meta.touched, meta.error]);

    return (
        <Fragment>
            {inputLabel &&
                <Label for={inputId}>{inputLabel}</Label>
            }
            <InputGroup className='infinity-input'>
                <Field {...formikField} id={inputId} placeholder={inputPlaceHolder}
                    as={Calendar} showButtonBar type='text'
                    {...inputProps}
                    className={[isInvalidField && 'p-invalid is-invalid'].filter(Boolean).join(' ')}
                    aria-label={fieldName}
                />

                {tooltipText &&
                    <InputGroupText>
                        <InfoOutlined fontSize="small" id={infoId} />
                        <UncontrolledTooltip placement="top" target={infoId}>
                            {tooltipText}
                        </UncontrolledTooltip>
                    </InputGroupText>
                }
                {isInvalidField &&
                    <FormFeedback>
                        {meta.error}
                    </FormFeedback>
                }
            </InputGroup>
        </Fragment>
    );
};

export default AppCalendarComponent;
