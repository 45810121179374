import { DEPARTMENT_SCOPES, MENU_SECTIONS, USER_PERMISSIONS_LABELS } from "assets/static_data/backend_exports";
import { isDate, parse } from "date-fns";

export const cleanSession = (tenantCode) => {
    localStorage.removeItem('in-access-' + tenantCode);
    localStorage.removeItem('in-refresh-' + tenantCode);
}

export const validateTenant = (tenant) => {
    // Validate correct tenant object
    if (!tenant || !tenant.name || !tenant.code || !tenant.domains || tenant.domains.length === 0) {
        return false;
    }

    return true;
}

export const saveTenantBrowserStorage = (tenant) => {
    let isValid = validateTenant(tenant);
    if (isValid) {
        sessionStorage.setItem('in-tenant', JSON.stringify(tenant));
        localStorage.setItem('in-tenant', JSON.stringify(tenant));
    }

    return isValid;
}

export const getTenantBaseUrl = (tenant) => {
    let baseUrl = null;
    if (tenant && tenant.domains && tenant.domains.length > 0) {
        let tenantSubDomain = tenant.domains[0].split('.')[0];
        baseUrl = process.env.REACT_APP_API_PROTOCOL + '://' + tenantSubDomain + '.' + process.env.REACT_APP_API_BASE_URL;
    } else {
        console.error('Tenant does not have a domain configured');
    }

    return baseUrl;
}

export const setAxiosBaseUrl = (axiosInstance, tenant) => {
    let baseUrl = getTenantBaseUrl(tenant);
    if (baseUrl) {
        axiosInstance.defaults.baseURL = baseUrl;
    }
}

export const buildPaginationUrlParams = ({ page, limit, search, filterSet, sortOrder, sortField, status, client_id }) => {
    let url = new URLSearchParams();

    if (page) {
        url.append('page', page);
    }
    if (limit) {
        url.append('pageSize', limit);
    }
    if (search) {
        url.append('search', search);
    }
    if (filterSet && typeof filterSet === 'object' && Object.keys(filterSet).length > 0) {
        for (let [key, value] of Object.entries(filterSet)) {
            url.append(key, value);
        }
    }
    if (sortOrder && sortField && sortOrder < 0) {
        sortField = '-' + sortField;
    }
    if (sortField) {
        sortField = sortField.replaceAll('.', '__');
        url.append('ordering', sortField);
    }
    if (status) {
        url.append('status', status);
    }
    if (client_id) {
        url.append('client_id', client_id);
    }

    let queryString = url.toString();
    if (queryString) {
        queryString = '?' + queryString;
    }

    return queryString;
};

// Number to ordinal string formatter
export const currencyFormatter = (decimalPositions) => new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPositions,
    maximumFractionDigits: decimalPositions,
});

const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });

const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
};

export const numberToOrdinal = (number) => {
    const category = englishOrdinalRules.select(number);
    const suffix = suffixes[category];
    return (number + suffix);
}

export const setDateDayBeginning = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
}

export const setDateDayEnding = (date) => {
    date.setHours(23, 59, 59, 999);
    return date;
}

export const userFullName = (user) => {
    return user ? user.first_name + ' ' + user.last_name : '';
}

export const permissionName = (permission) => {
    return permission && (permission in USER_PERMISSIONS_LABELS) ? USER_PERMISSIONS_LABELS[permission] : '';
}

export const groupedPermissions = (activeTenant) => {
    let groupedPermissions = [];
    for (let [key, value] of Object.entries(USER_PERMISSIONS_LABELS)) {
        let [section, group] = key.split('/');

        // Check if section is enabled on active tenant
        let sectionEnabled = false;
        if (section === MENU_SECTIONS.COMMON) {
            sectionEnabled = true;
        } else if (section === MENU_SECTIONS.WORKSTATION && activeTenant?.workstation_enabled) {
            sectionEnabled = true;
        } else if (section === MENU_SECTIONS.INVENTORY && activeTenant?.inventory_enabled) {
            sectionEnabled = true;
        }
        if (!sectionEnabled) {
            continue;
        }

        // Grouped by section and group
        let groupKey = section + ' / ' + group;

        let groupIndex = groupedPermissions.findIndex((item) => item.label === groupKey);
        if (groupIndex === -1) {
            groupedPermissions.push({
                label: groupKey,
                items: [{ label: value, value: key }]
            });
        } else {
            groupedPermissions[groupIndex].items.push({ label: value, value: key });
        }
    }

    // Sort each group by label
    for (let group of groupedPermissions) {
        group.items.sort((a, b) => a.label.localeCompare(b.label));
    }

    return groupedPermissions;
}

export const yupParseDateString = (value, originalValue) => {
    const parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "MM/dd/yyyy", new Date());

    return parsedDate;
}

export const convertWSNameToIdentifier = (name) => {
    return name ? name.replace(/-/g, '') : null;
}

export const strToLowercaseUnderscore = (string) => {
    return string ? string.toLowerCase().replace(/\s+/g, '_') : null;
}

export const convertArrayToObject = (dataArray, keyFields) => {
    let dataObject = {};
    for (let i = 0; i < dataArray.length; i++) {
        let value = dataArray[i];
        // if string, trim it
        if (typeof value === 'string') {
            value = value.trim();
        }

        dataObject[keyFields[i]] = value;
    }
    return dataObject;
}

// Convert 'Yes'/'No' to boolean, any value other than 'Yes' is considered false
export const yesNoToBoolean = (value) => {
    if (typeof value !== 'string') {
        return false;
    }

    return value.toUpperCase() === 'YES' ? true : false;
}

export const booleanToYesNo = (value) => {
    return value ? 'Yes' : 'No';
}

export const activeInactiveToBoolean = (value) => {
    if (typeof value !== 'string') {
        return null;
    }

    return value.toUpperCase() === 'ACTIVE' ? true : false;
}

export const booleanToActiveInactive = (value) => {
    return value ? 'Active' : 'Inactive';
}

export const getObjectValueFromPath = (object, path) => {
    return path.split('.').reduce((o, i) => o[i], object);
}

export const departmentScopeToUsedByEpic = (value) => {
    if (typeof value !== 'string') {
        return null;
    }
    return (value === DEPARTMENT_SCOPES.IN_EPIC_SCOPE);
}

export const usedByEpicToDepartmentScope = (value) => {
    return value ? DEPARTMENT_SCOPES.IN_EPIC_SCOPE : DEPARTMENT_SCOPES.OUT_EPIC_SCOPE;
}

export const buildPrinterDescription = (printer) => {
    if (!printer) {
        return '';
    }
    // Is asset object ?
    if (printer.printer) {
        // Convert to printer
        printer = {
            ...printer,
            ...printer.printer,
        }
    }
    let msitTagNum = printer.msit_tag_num || 'n/a';
    let printerQueueName = printer.printer_queue_name || 'n/a';
    let assetName = printer.asset_name || 'n/a';
    let serialNum = printer.serial_num || 'n/a';

    return `MSIT Tag: ${msitTagNum}, Queue Name: ${printerQueueName}, Name: ${assetName}, Serial #: ${serialNum}`;
}

export const buildPeripheralDescription = (asset) => {
    if (!asset) {
        return '';
    }

    let assetId = asset.id || 'n/a';
    let msitTagNum = asset.msit_tag_num || 'n/a';
    let assetMake = asset.the_make.choice;
    let assetModel = asset.the_model.choice;
    let assetName = asset.asset_name || 'n/a';

    return `Asset ID: ${assetId}, MSIT Tag: ${msitTagNum}, Make: ${assetMake}, Make: ${assetModel}, Name: ${assetName}`;
}

export const saveAsExcelFile = (fileName, buffer) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + EXCEL_EXTENSION);
        }
    });
};

export const gitCommitTimestampToDate = (gitCommitTimestamp) => new Date(gitCommitTimestamp);
