import {
    GET_ROLES_MAP_SUCCESS,
    GET_ROLES_SUCCESS, RESET_ROLES_FOUND, RESET_ROLE_STATE, SEARCH_ROLES_SUCCESS
} from "redux/actions/roles.actions";

const initialRolesState = {
    rolesList: [],
    next: null,
    previous: null,
    count: 0,
    rolesFound: [],
    rolesMap: {},
};

const getRolesMap = (results) => {
    let rolesMap = {};
    for (let role of results) {
        rolesMap[role.id] = role.name;
    }
    return rolesMap;
}

export const RolesReducer = (state = initialRolesState, action) => {
    switch (action.type) {
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                rolesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                rolesMap: getRolesMap(action.payload.results),
            };
        case SEARCH_ROLES_SUCCESS:
            return {
                ...state, rolesFound: action.payload.results
            };
        case RESET_ROLES_FOUND:
            return {
                ...state, rolesFound: []
            };
        case GET_ROLES_MAP_SUCCESS:
            return {
                ...state,
                rolesMap: getRolesMap(action.payload.results),
            }
        case RESET_ROLE_STATE:
            return initialRolesState;
        default:
            return state;
    }
}