import React from 'react';

export const StateFieldIndicator = ({ state }) => {
    return (
        <span className='fs-5'>
            {state ?
                (<i className='ri-checkbox-circle-line text-primary' />)
                :
                (<i className='ri-close-circle-line text-warning' />)
            }
        </span>
    )
}

export default StateFieldIndicator;
