import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';

import AssetNotes from 'components/ui/AssetNotes';
import AssetTags from 'components/ui/AssetTags';
import PeripheralForm from 'pages/inventory/all_assets/PeripheralForm';
import { crudBaseDeleteStart } from 'redux/actions/crud_base.actions';
import { disableOverlay, enableOverlay, setToast } from 'redux/actions/ui.actions';
import { ENTITIES_CONFIG, ENTITY_ASSET, INITIAL_ROWS_PER_PAGE } from 'redux/entities_config';
import { TOAST_SEVERITY_ERROR, TOAST_SEVERITY_SUCCESS } from 'redux/reducers/ui.reducer';
import { API_ASSETS, API_ASSETS_SET_PARENT } from 'services/API_CONSTANTS';
import { crudBaseGetList, crudBasePatch } from 'services/crudBaseService';
import { userFullName } from 'utils';
import { FORMAT_DATETIME_SHORT, isoStringToFormatted } from 'utils/date_time';

import 'styles/datatables.scss';
import 'styles/list_components.scss';

const dataTableColumns = [
    {
        field: 'asset_type.asset_type_name',
        header: 'Asset Type',
        sortable: true,
    },
    {
        field: 'the_make.choice',
        header: 'Make',
        sortable: true,
    },
    {
        field: 'the_model.choice',
        header: 'Model',
        sortable: true,
    },
    {
        field: 'id',
        header: 'Asset ID',
        sortable: true,
    },
    {
        field: 'asset_name',
        header: 'Name',
        sortable: true,
    },
    {
        field: 'serial_num',
        header: 'Serial #',
        sortable: true,
    },
    {
        header: 'Tags',
        body: (rowData) => (
            <AssetTags asset={rowData} />
        ),
    },
];

const dataTableExpansionDefinition = [
    {
        field: 'lease_description',
        header: 'Lease Description',
    },
    {
        field: 'lease_start_date',
        header: 'Lease Start Date',
        body: (rowData) => (
            rowData.lease_start_date ? isoStringToFormatted(rowData.lease_start_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
    {
        field: 'lease_end_date',
        header: 'Lease End Date',
        body: (rowData) => (
            rowData.lease_end_date ? isoStringToFormatted(rowData.lease_end_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
    {
        field: 'warranty_end_date',
        header: 'Warranty End Date',
        body: (rowData) => (
            rowData.warranty_end_date ? isoStringToFormatted(rowData.warranty_end_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
    {
        field: 'ipsn',
        header: 'IPSN',
    },
    {
        field: 'created_by.username',
        header: 'Created By',
        body: (rowData) => (
            userFullName(rowData.created_by)
        ),
    },
    {
        field: 'created_date',
        header: 'Created Date',
        body: (rowData) => (
            rowData.created_date ? isoStringToFormatted(rowData.created_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
    {
        field: 'last_modified_by.username',
        header: 'Last Modified By',
        body: (rowData) => (
            userFullName(rowData.last_modified_by)
        ),
    },
    {
        field: 'last_modified_date',
        header: 'Last Modified Date',
        sortable: true,
        body: (rowData) => (
            rowData.last_modified_date ? isoStringToFormatted(rowData.last_modified_date, FORMAT_DATETIME_SHORT) : ''
        ),
    },
    {
        field: 'notes',
        header: 'Notes',
        body: (rowData) => (
            <AssetNotes notes={rowData.notes} />
        ),
    },
];

const entityCode = ENTITY_ASSET;
const entityLabel = 'Peripheral';
const hasCreateButton = true;

export const AssetPeripheralList = ({ parentAssetId = null, refreshListSignal }) => {
    const dispatch = useDispatch();
    const dt = useRef(null);

    const [entityConfig] = useState(ENTITIES_CONFIG[entityCode]);

    const [tableRowsData, setTableRowsData] = useState([]);
    const [tableRowsCount, setTableRowsCount] = useState(0);

    const [selectedTableRows, setSelectedTableRows] = useState(null);
    const [deleteRecordDialogState, setDeleteRecordDialogState] = useState(false);
    const [deleteRecordMessage, setDeleteRecordMessage] = useState('');

    const [detachRecordDialogState, setDetachRecordDialogState] = useState(false);
    const [detachRecordMessage, setDetachRecordMessage] = useState('');

    const [formModalTitle, setFormModalTitle] = useState('');
    const [isCreateForm, setIsCreateForm] = useState(false);
    const formSubmittedSuccess = useSelector(state => state.ui.formSubmittedSuccess);
    const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);

    const [createEditPeripheralFormDialogState, setCreateEditPeripheralFormDialogState] = useState(false);


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [, globalFilterDebouncedValue, setGlobalFilterValue] = useDebounce('', 400);

    const [expandedRows, setExpandedRows] = useState(null);

    const refreshRowsData = useCallback(() => {
        crudBaseGetList(API_ASSETS, {
            limit: 9999,
            page: 1,
            filterSet: {
                parent_id: parentAssetId,
            }
        }).then((data) => {
            setTableRowsData(data.results);
            setTableRowsCount(data.count);
        }).catch((err) => {
            dispatch(setToast({
                severity: TOAST_SEVERITY_ERROR,
                summary: 'Error loading peripherals',
                detail: err.message,
            }));
        });
    }, [dispatch, parentAssetId]);

    useEffect(() => {
        refreshRowsData();
    }, [entityConfig.nameSingular, refreshRowsData]);

    useEffect(() => {
        if (refreshListSignal) {
            refreshRowsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshListSignal]);

    useEffect(() => {
        setFilters({
            ...filters,
            global: {
                ...filters.global,
                value: globalFilterDebouncedValue
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilterDebouncedValue]);

    const performDeleteRecord = () => {
        dispatch(crudBaseDeleteStart({
            entityCode: entityCode,
            recordId: selectedTableRows.id,
        }));
        setDeleteRecordDialogState(false);
        setSelectedTableRows([]);
    };

    const performDetachRecord = () => {
        if (selectedTableRows.id) {
            let detachPeripheralData = {
                id: selectedTableRows.id,
                parent_asset_id: null,
            };

            dispatch(enableOverlay('Detaching Peripheral'));

            crudBasePatch(API_ASSETS_SET_PARENT, detachPeripheralData)
                .then((data) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_SUCCESS,
                        detail: 'Peripheral detached',
                    }));
                    refreshRowsData();
                })
                .catch((err) => {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Error detaching peripheral',
                        detail: Object.values(err.response.data).map((value, index) => (
                            value
                        )).join('. '),
                    }));
                })
                .finally(() => {
                    dispatch(disableOverlay());
                });
        }

        setDetachRecordDialogState(false);
        setSelectedTableRows([]);
    };

    const hideDeleteRowDialog = () => {
        setDeleteRecordDialogState(false);
    };

    const hideDetachRowDialog = () => {
        setDetachRecordDialogState(false);
    };

    const openCreatePeripheralDialog = () => {
        setFormModalTitle('Add Peripheral');
        setIsCreateForm(true);
        setCreateEditPeripheralFormDialogState(true);
    };

    const openEditPeripheralDialog = () => {
        setFormModalTitle('Edit Peripheral');
        setIsCreateForm(false);
        setCreateEditPeripheralFormDialogState(true);
    };

    const closeCreateEditPeripheralForm = () => {
        setCreateEditPeripheralFormDialogState(false);
        if (formSubmittedSuccess) {
            refreshRowsData();
        }
    };

    const handleRefreshButton = () => {
        refreshRowsData();
    };

    const onClickButtonDelete = () => {
        setDeleteRecordMessage(`Are you sure you want to DETACH and DELETE permanently ${entityLabel}: "${selectedTableRows['id']}" ?`);
        setDeleteRecordDialogState(true);
    };

    const onClickButtonDetach = () => {
        setDetachRecordMessage(`Are you sure you want to DETACH ${entityLabel}: "${selectedTableRows['id']}" ?`);
        setDetachRecordDialogState(true);
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: INITIAL_ROWS_PER_PAGE, value: INITIAL_ROWS_PER_PAGE },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <Button type='button' icon="ri-refresh-line" severity='secondary' outlined size='small' className="me-2" onClick={handleRefreshButton} />
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.target.value)} />
                </Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <span className="me-2">Rows: {options.totalRecords} |</span>
                    <span className="page-number-button">Page: {options.currentPage}</span>
                    <span className="page-of-button"> of </span>
                    <span className="page-number-button">{options.totalPages}</span>
                </span>
            );
        },
    };

    const rowExpansionTemplate = useCallback((rowData) => {
        return (
            <Card>
                <CardBody>
                    <Row>
                        {dataTableExpansionDefinition.map((column, index) => (
                            <Col xs={12} md={4} key={index}>
                                <b className='text-muted'>{column.header}</b>: {column.body ? column.body(rowData) : rowData[column.field]}
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={12} md={8} className='d-flex justify-content-between'>
                            <Button label="Edit" icon="ri-pencil-fill" className="record-action-button me-2" size='small' type='button'
                                onClick={openEditPeripheralDialog}
                                disabled={!selectedTableRows}
                            />
                            <Button label="Delete" icon="ri-delete-bin-line" className="record-action-button me-2" size='small' type='button'
                                onClick={() => onClickButtonDelete()}
                                disabled={!selectedTableRows}
                            />
                            <Button label="Detach" icon="ri-scissors-2-line" className="record-action-button me-2" size='small' type='button'
                                onClick={() => onClickButtonDetach()}
                                disabled={!selectedTableRows}
                            />
                            {hasCreateButton &&
                                <Button label="Create" icon="ri-add-circle-line" className="record-action-button me-2" size='small' type='button'
                                    onClick={openCreatePeripheralDialog}
                                />
                            }
                        </Col>
                        <Col xs={12} md={4} className='d-flex justify-content-between'>
                            <label htmlFor="list-global-search" className="visually-hidden">Search</label>
                            <span className="p-input-icon-left">
                                <i className="ri-search-line" style={{ marginTop: -10 }} />
                                <InputText placeholder="Search" onChange={(e) => setGlobalFilterValue(e.target.value)} type='search' id='list-global-search' />
                            </span>
                        </Col>
                    </Row>
                    <DataTable value={tableRowsData}
                        size='small'
                        dataKey="id"
                        rows={rowsPerPage}
                        paginator
                        paginatorPosition='top'
                        paginatorTemplate={paginatorTemplate}
                        totalRecords={tableRowsCount}

                        onFilter={(e) => setFilters(e.filters)}

                        emptyMessage={`No ${entityConfig.namePlural} found.`}
                        selection={selectedTableRows}
                        onSelectionChange={(e) => setSelectedTableRows(e.value)}
                        filters={filters}
                        globalFilterFields={[
                            'id', 'asset_type.asset_type_name', 'the_make.choice', 'the_model.choice', 'asset_name',
                            'serial_num', 'msit_tag_num',
                        ]}
                        ref={dt}

                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                    >
                        <Column selectionMode='single' />

                        <Column expander={true} />

                        {dataTableColumns.map((column, index) => (
                            <Column key={index} {...column} />
                        ))}

                    </DataTable>
                </CardBody>
            </Card>

            <Dialog visible={createEditPeripheralFormDialogState} header={formModalTitle} modal onHide={closeCreateEditPeripheralForm} className="p-fluid" maximizable>
                <PeripheralForm close={closeCreateEditPeripheralForm} recordId={(!isCreateForm && selectedTableRows) ? selectedTableRows.id : null} parentAssetId={parentAssetId} />
            </Dialog>

            <ConfirmDialog visible={deleteRecordDialogState}
                onHide={hideDeleteRowDialog}
                className='delete-confirm-dialog'
                message={deleteRecordMessage}
                header={`Delete ${entityLabel}`}
                acceptLabel={`Delete ${entityLabel}`}
                rejectLabel='Cancel'
                accept={performDeleteRecord} reject={hideDeleteRowDialog} />

            <ConfirmDialog visible={detachRecordDialogState}
                onHide={hideDetachRowDialog}
                className='delete-confirm-dialog'
                message={detachRecordMessage}
                header={`Detach ${entityLabel}`}
                acceptLabel={`Detach ${entityLabel}`}
                rejectLabel='Cancel'
                accept={performDetachRecord} reject={hideDetachRowDialog} />

        </Fragment>
    );
};

export default AssetPeripheralList;
