import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import RolesList from "pages/administration/roles/RolesList";
import OrganizationSettings from "pages/administration/settings/OrganizationSettings";
import UsersList from "pages/administration/users/UsersList";
import InventoryDashboard from "pages/dashboard/InventoryDashboard";
import WorkstationDashboard from "pages/dashboard/WorkstationDashboard";
import NotAuthorized from "pages/error/NotAuthorized";
import NotFound from "pages/error/NotFound";
import PlaceHolderPage from "pages/error/PlaceHolderPage";
import AllAssetsBulkUpdate from "pages/inventory/all_assets/AllAssetsBulkUpdate";
import AllAssetsList from "pages/inventory/all_assets/AllAssetsList";
import AssetPageForm from "pages/inventory/all_assets/AssetPageForm";
import WorkstationPrinterOtherAssetsBulkUpdate from "pages/inventory/all_assets/WorkstationPrinterOtherAssetsBulkUpdate";
import AssetsToFollowUpList from "pages/inventory/assets_to_follow_up/AssetsToFollowUpList";
import BuildingsBulkUpdate from "pages/inventory/buildings/BuildingsBulkUpdate";
import BuildingsList from "pages/inventory/buildings/BuildingsList";
import CitiesBulkUpdate from "pages/inventory/cities/CitiesBulkUpdate";
import CitiesList from "pages/inventory/cities/CitiesList";
import ClinicSummaryList from "pages/inventory/clinic_summary/ClinicSummaryList";
import CreditCardReadersBulkUpdate from "pages/inventory/credit_card_readers/CreditCardReadersBulkUpdate";
import CreditCardReadersList from "pages/inventory/credit_card_readers/CreditCardReadersList";
import DepartmentsBulkUpdate from "pages/inventory/departments/DepartmentsBulkUpdate";
import DepartmentsList from "pages/inventory/departments/DepartmentsList";
import DropdownsList from "pages/inventory/dropdowns/DropdownsList";
import GapReportList from "pages/inventory/gap_report/GapReportList";
import LeasedAssetsBulkUpdate from "pages/inventory/leased_assets/LeasedAssetsBulkUpdate";
import LeasedAssetsList from "pages/inventory/leased_assets/LeasedAssetsList";
import OusBulkUpdate from "pages/inventory/ous/OusBulkUpdate";
import OusList from "pages/inventory/ous/OusList";
import SpecialtiesBulkUpdate from "pages/inventory/specialties/SpecialtiesBulkUpdate";
import SpecialtiesList from "pages/inventory/specialties/SpecialtiesList";
import StatesBulkUpdate from "pages/inventory/states/StatesBulkUpdate";
import StatesList from "pages/inventory/states/StatesList";
import WorkflowReqsBulkUpdate from "pages/inventory/workflow_reqs/WorkflowReqsBulkUpdate";
import WorkflowReqsList from "pages/inventory/workflow_reqs/WorkflowReqsList";
import WorkflowsBulkUpdate from "pages/inventory/workflows/WorkflowsBulkUpdate";
import WorkflowsList from "pages/inventory/workflows/WorkflowsList";
import WorkstationAndPrinterAssetsBulkUpdate from "pages/inventory/workstation_and_printer_assets/WorkstationAndPrinterAssetsBulkUpdate";
import WorkstationAndPrinterAssetsList from "pages/inventory/workstation_and_printer_assets/WorkstationAndPrinterAssetsList";
import WorkstationDefaultPrintersBulkUpdate from "pages/inventory/workstation_default_printers/WorkstationDefaultPrintersBulkUpdate";
import WorkstationDefaultPrintersList from "pages/inventory/workstation_default_printers/WorkstationDefaultPrintersList";
import WorkstationsTDRNotPassedList from "pages/inventory/workstations_tdr_not_passed/WorkstationsTDRNotPassedList";
import WorkstationsWOPrimaryUseList from "pages/inventory/workstations_wo_primary_use/WorkstationsWOPrimaryUseList";
import CreateAccount from "pages/on-boarding/CreateAccount";
import { Login } from "pages/on-boarding/Login";
import Logout from "pages/on-boarding/Logout";
import OnBoarding from "pages/on-boarding/OnBoarding";
import ResetPassword from "pages/on-boarding/ResetPassword";
import RetrieveOrganization from "pages/on-boarding/RetrieveOrganization";
import SamlAuthFinish from "pages/on-boarding/SamlAuthFinish";
import SelectOrganization from "pages/on-boarding/SelectOrganization";
import ChangePassword from "pages/user/ChangePassword";
import EditProfile from "pages/user/EditProfile";
import Profile from "pages/user/Profile";
import DuplicationHistoryList from "pages/workstation/duplication_history/DuplicationHistoryList";
import FunctionalTypesBulkUpdate from "pages/workstation/functional_types/FunctionalTypesBulkUpdate";
import FunctionalTypesList from "pages/workstation/functional_types/FunctionalTypesList";
import { WorkstationsDuplicate } from "pages/workstation/workstations/WorkstationsDuplicate";
import WorkstationsList from "pages/workstation/workstations/WorkstationsList";
import { setNavigateTo, setRedirectAfterLogin, setToast } from "redux/actions/ui.actions";
import { TOAST_SEVERITY_ERROR } from "redux/reducers/ui.reducer";
import Layout from "theme/components/layout";
import DropdownsBulkUpdate from "../pages/inventory/dropdowns/DropdownsBulkUpdate";
import {
    ADMIN_ROLES,
    ADMIN_USERS,
    ALL_ASSETS_BULK_UPDATE,
    ALL_ASSETS_LIST,
    ASSETS_TO_FOLLOW_UP_LIST,
    ASSET_ADD,
    ASSET_EDIT,
    BUILDINGS_BULK_UPDATE,
    BUILDINGS_LIST,
    CITIES_BULK_UPDATE,
    CITIES_LIST,
    CLINIC_SUMMARY_LIST,
    CREATE_ACCOUNT,
    CREDIT_CARD_READER_BULK_UPDATE,
    CREDIT_CARD_READER_LIST,
    DASHBOARD,
    DEPARTMENTS_BULK_UPDATE,
    DEPARTMENTS_LIST,
    DROPDOWNS_BULK_UPDATE,
    DROPDOWNS_LIST,
    DUPLICATE_WORKSTATIONS,
    DUPLICATION_HISTORY,
    EDIT_PROFILE,
    FUNCTIONAL_TYPES,
    FUNCTIONAL_TYPES_BULK_UPDATE,
    GAP_REPORT_LIST,
    INVENTORY_DASHBOARD,
    LEASED_ASSETS_BULK_UPDATE,
    LEASED_ASSETS_LIST,
    LOGIN_FORM,
    LOGOUT,
    ON_BOARDING,
    ORGANIZATION_SETTINGS,
    OUS_BULK_UPDATE,
    OUS_LIST,
    PAGES_NOT_AUTHORIZED,
    PROCESS_SAML_FINISH,
    RESET_PASSWORD,
    RETRIEVE_ORGANIZATION,
    ROOT,
    SELECT_ORGANIZATION,
    SPECIALTIES_BULK_UPDATE,
    SPECIALTIES_LIST,
    STATES_BULK_UPDATE,
    STATES_LIST,
    USER_CHANGE_PASSWORD,
    USER_PROFILE,
    WAP_ASSETS_BULK_UPDATE,
    WAP_ASSETS_LIST,
    WORKFLOWS_BULK_UPDATE,
    WORKFLOWS_LIST,
    WORKFLOW_DEFAULT_REQS_BULK_UPDATE,
    WORKFLOW_DEFAULT_REQS_LIST,
    WORKSTATIONS_LIST,
    WORKSTATIONS_TDR_NOT_PASSED,
    WORKSTATIONS_WO_PRIMARY_USE,
    WORKSTATION_DASHBOARD,
    WORKSTATION_DEFAULT_PRINTERS_BULK_UPDATE,
    WORKSTATION_DEFAULT_PRINTERS_LIST,
    WPO_BULK_UPDATE
} from "./ROUTE_CONSTANTS";
import { menuOptionsPermissionsMap } from "./menu_constants";

const privateRoutes = [
    { 'path': LOGOUT, 'component': Logout },
    { 'path': INVENTORY_DASHBOARD, 'component': InventoryDashboard },
    { 'path': WORKSTATION_DASHBOARD, 'component': WorkstationDashboard },
    { 'path': DUPLICATE_WORKSTATIONS, 'component': WorkstationsDuplicate },
    { 'path': FUNCTIONAL_TYPES, 'component': FunctionalTypesList },
    { 'path': USER_PROFILE, 'component': Profile },
    { 'path': EDIT_PROFILE, 'component': EditProfile },
    { 'path': USER_CHANGE_PASSWORD, 'component': ChangePassword },
    { 'path': ADMIN_ROLES, 'component': RolesList },
    { 'path': ADMIN_USERS, 'component': UsersList },
    { 'path': ORGANIZATION_SETTINGS, 'component': OrganizationSettings },
    { 'path': WORKSTATIONS_LIST, 'component': WorkstationsList },
    { 'path': DEPARTMENTS_LIST, 'component': DepartmentsList },
    { 'path': DEPARTMENTS_BULK_UPDATE, 'component': DepartmentsBulkUpdate },
    /*
    { 'path': EPIC_DEPARTMENTS_LIST, 'component': EpicDepartmentsList },
    { 'path': EPIC_DEPARTMENTS_BULK_UPDATE, 'component': EpicDepartmentsBulkUpdate },
    */
    { 'path': OUS_LIST, 'component': OusList },
    { 'path': OUS_BULK_UPDATE, 'component': OusBulkUpdate },
    { 'path': STATES_LIST, 'component': StatesList },
    { 'path': STATES_BULK_UPDATE, 'component': StatesBulkUpdate },
    { 'path': CITIES_LIST, 'component': CitiesList },
    { 'path': CITIES_BULK_UPDATE, 'component': CitiesBulkUpdate },
    { 'path': BUILDINGS_LIST, 'component': BuildingsList },
    { 'path': BUILDINGS_BULK_UPDATE, 'component': BuildingsBulkUpdate },
    { 'path': SPECIALTIES_LIST, 'component': SpecialtiesList },
    { 'path': SPECIALTIES_BULK_UPDATE, 'component': SpecialtiesBulkUpdate },
    /*
    { 'path': LEGACY_DEPARTMENTS_LIST, 'component': LegacyDepartmentsList },
    { 'path': LEGACY_DEPARTMENTS_BULK_UPDATE, 'component': LegacyDepartmentsBulkUpdate },
    */
    { 'path': WORKFLOWS_LIST, 'component': WorkflowsList },
    { 'path': WORKFLOWS_BULK_UPDATE, 'component': WorkflowsBulkUpdate },
    { 'path': DROPDOWNS_LIST, 'component': DropdownsList },
    { 'path': DROPDOWNS_BULK_UPDATE, 'component': DropdownsBulkUpdate },
    { 'path': WAP_ASSETS_LIST, 'component': WorkstationAndPrinterAssetsList },
    { 'path': WAP_ASSETS_BULK_UPDATE, 'component': WorkstationAndPrinterAssetsBulkUpdate },
    { 'path': WPO_BULK_UPDATE, 'component': WorkstationPrinterOtherAssetsBulkUpdate },
    { 'path': ALL_ASSETS_LIST, 'component': AllAssetsList },
    { 'path': ALL_ASSETS_BULK_UPDATE, 'component': AllAssetsBulkUpdate },
    { 'path': WORKSTATION_DEFAULT_PRINTERS_LIST, 'component': WorkstationDefaultPrintersList },
    { 'path': WORKSTATION_DEFAULT_PRINTERS_BULK_UPDATE, 'component': WorkstationDefaultPrintersBulkUpdate },
    { 'path': CREDIT_CARD_READER_LIST, 'component': CreditCardReadersList },
    { 'path': CREDIT_CARD_READER_BULK_UPDATE, 'component': CreditCardReadersBulkUpdate },
    { 'path': LEASED_ASSETS_LIST, 'component': LeasedAssetsList },
    { 'path': LEASED_ASSETS_BULK_UPDATE, 'component': LeasedAssetsBulkUpdate },
    { 'path': ASSET_ADD, 'component': AssetPageForm },
    { 'path': ASSET_EDIT, 'component': AssetPageForm },
    { 'path': WORKSTATIONS_WO_PRIMARY_USE, 'component': WorkstationsWOPrimaryUseList },
    { 'path': WORKSTATIONS_TDR_NOT_PASSED, 'component': WorkstationsTDRNotPassedList },
    { 'path': ASSETS_TO_FOLLOW_UP_LIST, 'component': AssetsToFollowUpList },
    { 'path': WORKFLOW_DEFAULT_REQS_LIST, 'component': WorkflowReqsList },
    { 'path': WORKFLOW_DEFAULT_REQS_BULK_UPDATE, 'component': WorkflowReqsBulkUpdate },
    { 'path': GAP_REPORT_LIST, 'component': GapReportList },
    { 'path': CLINIC_SUMMARY_LIST, 'component': ClinicSummaryList },
    { 'path': FUNCTIONAL_TYPES_BULK_UPDATE, 'component': FunctionalTypesBulkUpdate },
    { 'path': DUPLICATION_HISTORY, 'component': DuplicationHistoryList },
];

export const RouterConfig = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const uiNavigateTo = useSelector(state => state.ui.navigateTo);
    const activeTenantWorkstationEnabled = useSelector(state => state.ui.activeTenant.workstation_enabled);
    const { userLogged, userPermissions } = useSelector(state => state.users);
    const [lastNavigateTo, setLastNavigateTo] = useState(null);

    const requireLogin = useCallback((to, from, next) => {
        const auth = to.meta.auth || false;
        if (auth) {
            if (userLogged) {
                next();
            } else {
                dispatch(setRedirectAfterLogin(to.location.pathname));
                next.redirect(LOGIN_FORM);
            }
        } else {
            next();
        }
    }, [dispatch, userLogged]);

    const requireRole = useCallback((to, from, next) => {
        const role = to.meta.role || null;
        if (role) {
            // Check if to url exist in menuOptionsPermissionsMap and then verify if userPermissions contains the permission required by the to url
            if (!(to.location.pathname in menuOptionsPermissionsMap)) {
                // Not exists in menuOptionsPermissionsMap
                next();
            } else if (!menuOptionsPermissionsMap[to.location.pathname]) {
                // No permission required
                next();
            } else {
                let hasPermission = false;
                for (let requiredPermission of menuOptionsPermissionsMap[to.location.pathname]) {
                    if (userPermissions && userPermissions.includes(requiredPermission)) {
                        hasPermission = true;
                        break;
                    }
                }

                if (hasPermission) {
                    next();
                } else {
                    dispatch(setToast({
                        severity: TOAST_SEVERITY_ERROR,
                        summary: 'Permission Denied',
                        detail: 'You do not have permission to access this page.'
                    }));
                    next.redirect(from.location.pathname);
                }
            }
        } else {
            next();
        }
    }, [dispatch, userPermissions]);

    useEffect(() => {
        if (uiNavigateTo && uiNavigateTo !== lastNavigateTo) {
            if (uiNavigateTo !== location.pathname) {
                setLastNavigateTo(uiNavigateTo);
                history.push(uiNavigateTo);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiNavigateTo]);

    useEffect(() => {
        setLastNavigateTo(null);
        dispatch(setNavigateTo(null));
    }, [dispatch, userLogged]);

    return (
        <GuardProvider guards={[requireLogin, requireRole]} error={NotFound}>
            <Switch>
                {/* List all public routes here */}
                <GuardedRoute exact path={ON_BOARDING}>
                    <OnBoarding />
                </GuardedRoute>
                <GuardedRoute exact path={LOGIN_FORM}>
                    <Login />
                </GuardedRoute>
                <GuardedRoute exact path={RESET_PASSWORD}>
                    <ResetPassword />
                </GuardedRoute>

                <GuardedRoute exact path={CREATE_ACCOUNT}>
                    <CreateAccount />
                </GuardedRoute>

                <GuardedRoute exact path={SELECT_ORGANIZATION}>
                    <SelectOrganization />
                </GuardedRoute>
                <GuardedRoute path={RETRIEVE_ORGANIZATION}>
                    <RetrieveOrganization />
                </GuardedRoute>

                <GuardedRoute path={PROCESS_SAML_FINISH}>
                    <SamlAuthFinish />
                </GuardedRoute>

                <GuardedRoute path={PAGES_NOT_AUTHORIZED} >
                    <NotAuthorized />
                </GuardedRoute>

                {/* List all private/auth routes here */}
                <Layout>
                    <GuardedRoute
                        exact
                        path={ROOT}
                        meta={{ auth: true }}>
                        <Redirect to={DASHBOARD} />
                    </GuardedRoute>

                    <GuardedRoute
                        exact
                        path={DASHBOARD}
                        meta={{ auth: true }}>
                        <Redirect to={activeTenantWorkstationEnabled ? WORKSTATION_DASHBOARD : INVENTORY_DASHBOARD} />
                    </GuardedRoute>

                    {privateRoutes.map((route, index) => (
                        <GuardedRoute
                            key={index}
                            exact
                            path={route.path}
                            meta={{ auth: true, role: true }}
                            component={route.component}
                        />
                    ))}

                    <GuardedRoute
                        path='/to-dev/'
                        component={PlaceHolderPage}
                    />
                </Layout>

                {/* List a generic 404-Not Found route here */}
                <GuardedRoute path="*">
                    <NotFound />
                </GuardedRoute>
            </Switch>
        </GuardProvider>
    );
};
