import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row } from "reactstrap";

import { CREATE_ACCOUNT, LOGIN_FORM, RESET_PASSWORD } from "navigation/ROUTE_CONSTANTS";
import { logoutSuccess } from "redux/actions/auth.actions";
import { resetUserState } from "redux/actions/users.actions";
import { OnBoardingLayout } from "./OnBoardingLayout";

import 'styles/OnBoarding.css';

export const OnBoarding = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutSuccess());
        dispatch(resetUserState());
    }, [dispatch]);

    return (
        <OnBoardingLayout contentTitle="">

            <Row className='mt-5'>
                <Col className="text-center">
                    <Link to={LOGIN_FORM} type="button" className="w-100 btn btn-infinity-primary mw-333">Login</Link>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className="text-center">
                    <Link to={CREATE_ACCOUNT} className="w-100 btn btn-infinity-secondary font-weight-bold mw-333">Create new account</Link>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className="text-center">
                    <Link className="forgot-password" to={RESET_PASSWORD}>I forgot my password</Link>
                </Col>
            </Row>

        </OnBoardingLayout >
    )
}

export default OnBoarding;
